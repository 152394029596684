import React from "react";
import styled from "styled-components";
import { Location, Radar } from "../../domain/model";
import { OldColors } from "../appearance/Colors";
import { FormRoundInput } from "../appearance/FormRoundInput";
import { RoundButton } from "../appearance/RoundButton";
import { RoundInput } from "../appearance/RoundInput";
import * as Rx from "rxjs";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { showError } from "../../utils/MessageUtils";
import { DistanceUnit } from "../../domain/model/DistanceUnit";
import { DistanceFormatFunction, DistanceFormatter, DistanceFormatType } from "../../domain/DistanceFormatter";
import { Observable } from "rxjs";
import { parseNumberOrZero } from "../../utils/StringUtils";
import { PanelDescription } from "../appearance/panels/PanelText";
import { t } from "i18next";
import { TextButton } from "../appearance/TextButton";
import { DividerHorizontal } from "../appearance/Divider";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button {
        margin: 0;
    }
`;

const InputContainer = styled.p`
    display: flex;
    flex-direction: column;
    padding-bottom: 14px;
    span {
        font-size: 12px;
        line-height: 1.83;
        color: ${OldColors.primaryTint};
        padding-bottom: 4px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    button {
        margin-top: 10px;
    }
`;

const InfoButtonContainer = styled(InputContainer)`
    margin: 0;
    padding-bottom: 5px;
`;

const LeftTextButton = styled(TextButton)`
    padding-left: 0;
    text-align: left;
`;

interface Props {
    altitudeUnitObservable: Observable<DistanceUnit>;
    distanceFormatter: DistanceFormatter;
    onRequestAutoPosition: () => Rx.Observable<void>;
    onRequestPreviewNewPosition: (position: Location, groundLevel: number | null) => void;
    onRequestSaveNewPosition: (position: Location, groundLevel: number | null) => Rx.Observable<void>;
    previewLocationFromMapObservable: Rx.Observable<Location>;
    radar: Radar;
    showAltitudeExplanationModal: () => void;
}

interface State {
    isLoading: boolean;
    isAutoPositioning: boolean;
    canApplyChanges: boolean;
    hasGroundLevelConfig: boolean;
    altitudeDisplayValue: string;
    altitudeUnitShortName: string;
    latitude: number;
    longitude: number;
    groundLevelDisplayValue: string;
}

export class RadarReposition extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private initialGroundLevel: number;

    private readonly altitudeInput: React.RefObject<RoundInput>;
    private readonly latitudeInput: React.RefObject<RoundInput>;
    private readonly longitudeInput: React.RefObject<RoundInput>;
    private readonly groundLevelInput: React.RefObject<RoundInput>;

    private readonly altitudeSubject: Rx.BehaviorSubject<number>;
    private readonly groundLevelSubject: Rx.BehaviorSubject<number>;

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.altitudeInput = React.createRef();
        this.latitudeInput = React.createRef();
        this.longitudeInput = React.createRef();
        this.groundLevelInput = React.createRef();

        this.initialGroundLevel = (props.radar.status && props.radar.status.groundLevel) || 0;

        this.altitudeSubject = new Rx.BehaviorSubject<number>(props.radar.position.altitude);
        this.groundLevelSubject = new Rx.BehaviorSubject<number>(this.initialGroundLevel);

        const radar = props.radar;
        this.state = {
            isLoading: false,
            isAutoPositioning: false,
            canApplyChanges: false,
            altitudeDisplayValue: "",
            altitudeUnitShortName: "",
            latitude: radar.position.latitude,
            longitude: radar.position.longitude,
            groundLevelDisplayValue: "",
            hasGroundLevelConfig: (radar.status && radar.status.groundLevel != null) || false,
        };
    }

    public componentDidMount(): void {
        this.subscribeToMapChanges();
        this.subscribeToAltitudeSubject();
        this.subscribeToGroundLevelSubject();
        this.subscribeToDistanceUnit();
        this.props.onRequestPreviewNewPosition(this.props.radar.position, this.groundLevelSubject.value);
    }

    public render(): React.ReactNode {
        return (
            <Container>
                <form action={"#"}>
                    <PanelDescription>{t("radarControlPanel.descriptionRepositioning")}</PanelDescription>
                    <InputContainer>
                        <span>{t("radarControlPanel.latitude")} (DD)</span>
                        <FormRoundInput
                            disabled={this.state.isLoading}
                            onTextChange={this.onLatitudeChange.bind(this)}
                            ref={this.latitudeInput}
                            value={this.state.latitude.toString()}
                            type={"number"}
                        />
                    </InputContainer>
                    <InputContainer>
                        <span>{t("radarControlPanel.longitude")} (DD)</span>
                        <FormRoundInput
                            disabled={this.state.isLoading}
                            onTextChange={this.onLongitudeChange.bind(this)}
                            ref={this.longitudeInput}
                            value={this.state.longitude.toString()}
                            type={"number"}
                        />
                    </InputContainer>
                    <InputContainer>
                        <span>
                            {t("radarControlPanel.altitude")} ({this.state.altitudeUnitShortName})
                        </span>
                        <FormRoundInput
                            disabled={this.state.isLoading}
                            onTextChange={this.onAltitudeChange.bind(this)}
                            ref={this.altitudeInput}
                            value={this.state.altitudeDisplayValue}
                            type={"number"}
                        />
                    </InputContainer>
                    {this.state.hasGroundLevelConfig && (
                        <InputContainer>
                            <span>
                                {t("radarControlPanel.groundLevel")} ({this.state.altitudeUnitShortName})
                            </span>
                            <FormRoundInput
                                disabled={this.state.isLoading}
                                onTextChange={this.onGroundLevelChange.bind(this)}
                                ref={this.groundLevelInput}
                                value={this.state.groundLevelDisplayValue}
                                type={"number"}
                            />
                        </InputContainer>
                    )}
                    <InfoButtonContainer>
                        <LeftTextButton
                            onClick={() => this.props.showAltitudeExplanationModal()}
                            text={t("radarControlPanel.toggleAltitudeFilterExplanation")}
                        />
                    </InfoButtonContainer>
                </form>
                <ButtonContainer>
                    {this.state.canApplyChanges && (
                        <RoundButton
                            isLoading={this.state.isLoading}
                            text={t("radarControlPanel.applyChanges")}
                            onClick={this.saveChanges.bind(this)}
                        />
                    )}
                    {this.props.radar.status!.canPerformAutoPosition && (
                        <>
                            <DividerHorizontal />
                            <RoundButton
                                disabled={this.state.isLoading}
                                isLoading={this.state.isAutoPositioning}
                                text={t("radarControlPanel.autoPosition")}
                                onClick={this.autoPosition.bind(this)}
                            />
                        </>
                    )}
                </ButtonContainer>
            </Container>
        );
    }

    // Private functions

    private saveChanges(): void {
        const newLocation = new Location(this.state.latitude, this.state.longitude, this.altitudeSubject.value);
        this.setState({ isLoading: true });
        const groundLevel = this.state.hasGroundLevelConfig ? this.groundLevelSubject.value : null;
        const subscription = this.props.onRequestSaveNewPosition(newLocation, groundLevel).subscribe({
            complete: () => {
                this.setState({ isLoading: false });
            },
            error: (error) => {
                showError(error);
                this.setState({ isLoading: false });
            },
        });
        this.collectSubscription(subscription);
    }

    private autoPosition(): void {
        this.setState({ isAutoPositioning: true });
        const subscription = this.props.onRequestAutoPosition().subscribe({
            complete: () => this.setState({ isAutoPositioning: false }),
            error: (error) => {
                showError(error);
                this.setState({ isAutoPositioning: false });
            },
        });
        this.collectSubscription(subscription);
    }

    private onAltitudeChange(text: string): void {
        let newValue = parseNumberOrZero(text);
        newValue = this.props.distanceFormatter.convertValueFromCurrentUnit(
            newValue,
            DistanceUnit.METRIC,
            DistanceFormatFunction.NONE,
        );
        if (this.altitudeSubject.value !== newValue) {
            this.onChange(newValue, this.state.latitude, this.state.longitude, this.groundLevelSubject.value);
        }
    }

    private onLatitudeChange(text: string): void {
        const newValue = parseNumberOrZero(text);
        if (this.state.latitude !== newValue) {
            this.onChange(this.altitudeSubject.value, newValue, this.state.longitude, this.groundLevelSubject.value);
        }
    }

    private onLongitudeChange(text: string): void {
        const newValue = parseNumberOrZero(text);
        if (this.state.longitude !== newValue) {
            this.onChange(this.altitudeSubject.value, this.state.latitude, newValue, this.groundLevelSubject.value);
        }
    }

    private onGroundLevelChange(text: string): void {
        let newValue = parseNumberOrZero(text);
        newValue = this.props.distanceFormatter.convertValueFromCurrentUnit(
            newValue,
            DistanceUnit.METRIC,
            DistanceFormatFunction.NONE,
        );
        if (this.groundLevelSubject.value !== newValue) {
            this.onChange(this.altitudeSubject.value, this.state.latitude, this.state.longitude, newValue);
        }
    }

    private onChange(
        altitude: number,
        latitude: number,
        longitude: number,
        groundLevel: number,
        fromMap = false,
    ): void {
        let canApplyChanges = false;
        if (
            altitude !== this.props.radar.position.altitude ||
            latitude !== this.props.radar.position.latitude ||
            longitude !== this.props.radar.position.longitude ||
            groundLevel !== this.initialGroundLevel
        ) {
            canApplyChanges = true;
        }
        this.setState({
            latitude: latitude,
            longitude: longitude,
            canApplyChanges: canApplyChanges,
        });
        this.altitudeSubject.next(altitude);
        this.groundLevelSubject.next(groundLevel);
        if (!fromMap) {
            this.props.onRequestPreviewNewPosition(new Location(latitude, longitude, altitude), groundLevel);
        }
    }

    private subscribeToMapChanges(): void {
        const subscription = this.props.previewLocationFromMapObservable.subscribe((location) =>
            this.onChange(
                this.altitudeSubject.value,
                location.latitude,
                location.longitude,
                this.groundLevelSubject.value,
                true,
            ),
        );
        this.collectSubscription(subscription);
    }

    private subscribeToAltitudeSubject(): void {
        const subscription = this.props.distanceFormatter
            .formatObservable(this.altitudeSubject.asObservable(), (value, formatter) =>
                formatter(value, DistanceUnit.METRIC, {
                    formatFunction: DistanceFormatFunction.ROUND_DECIMAL_2,
                    formatType: DistanceFormatType.NONE,
                }),
            )
            .subscribe((altitude) => this.setState({ altitudeDisplayValue: altitude }));
        this.collectSubscription(subscription);
    }

    private subscribeToGroundLevelSubject(): void {
        const subscription = this.props.distanceFormatter
            .formatObservable(this.groundLevelSubject.asObservable(), (value, formatter) =>
                formatter(value, DistanceUnit.METRIC, {
                    formatFunction: DistanceFormatFunction.ROUND_DECIMAL_2,
                    formatType: DistanceFormatType.NONE,
                }),
            )
            .subscribe((groundLevel) => this.setState({ groundLevelDisplayValue: groundLevel }));
        this.collectSubscription(subscription);
    }

    private subscribeToDistanceUnit(): void {
        const subscription = this.props.altitudeUnitObservable.subscribe((unit) => {
            this.setState({
                altitudeUnitShortName:
                    unit === DistanceUnit.METRIC ? t("unit.meter_shorthand") : t("unit.foot_shorthand"),
            });
        });
        this.collectSubscription(subscription);
    }
}
