import _ from "lodash";
import { isVerticalRadar, Radar } from "../domain/model";
import * as turf from "@turf/turf";

// This will remove the horizontal radars if there is a vertical radar close to them
export function cleanupRadarsByDistance(radars: Radar[]): Radar[] {
    const result: Radar[] = [];
    const sortedByVerticalFirst = radars.sort((a, b) => {
        if (isVerticalRadar(a) && isVerticalRadar(b)) {
            return 0;
        } else if (isVerticalRadar(a)) {
            return -1;
        } else {
            return 1;
        }
    });
    sortedByVerticalFirst.forEach((radar) => {
        const radarLngLat = [radar.position.longitude, radar.position.latitude];
        const existingCloseRadar = _.find(
            result,
            (v) => turf.distance(radarLngLat, [v.position.longitude, v.position.latitude], { units: "meters" }) <= 100,
        );
        if (existingCloseRadar) {
            return;
        }
        result.push(radar);
    });
    return result;
}
