import React, { useEffect } from "react";
import * as Rx from "rxjs";
import { InputField } from "./InputField";
import styled from "styled-components";
import { BASE_TEXT_STYLE } from "../theme/GlobalStyles";
import { t } from "i18next";
import { LINE_HEIGHT_LARGE } from "../theme/Spacing";

const Component = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
        ${BASE_TEXT_STYLE}
        color: ${({ theme }) => theme.colors.text.text300};
    }
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.x2};
`;

const HorizontalLine = styled.div`
    margin-top: ${LINE_HEIGHT_LARGE};
    width: ${({ theme }) => theme.spacing.x6};
    height: 2px;
    background-color: ${({ theme }) => theme.colors.interactive.input};
`;

interface Props {
    onMaxChange: (max: number) => number | void;
    onMinChange: (max: number) => number | void;

    maxInitialValue?: number | "";
    maxLabel?: string;
    maxObservable?: Rx.Observable<number>;
    maxPlaceholder?: string;
    maxStep?: number;
    minInitialValue?: number | "";
    minLabel?: string;
    minObservable?: Rx.Observable<number>;
    minPlaceholder?: string;
    minStep?: number;
}

/**
 * Two input fields for min and max values in a horizontal layout
 * @param onMaxChange Required. Callback for when the max value changes
 * @param onMinChange Required. Callback for when the min value changes
 *
 * @param maxDefault Optional. Set a default value for the max input field
 * @param maxLabel Optional. Set a label for the max input field. Uses "Max" when not set
 * @param maxObservable Optional. Set an observable for the max input field
 * @param maxPlaceholder Optional. Set a placeholder for the max input field
 * @param maxStep Optional. Set a step value for the max input field
 * @param minDefault Optional. Set a default value for the min input field
 * @param minLabel Optional. Set a label for the min input field. Uses "Min" when not set
 * @param minObservable Optional. Set an observable for the min input field
 * @param minPlaceholder Optional. Set a placeholder for the min input field
 * @param minStep Optional. Set a step value for the min input field
 * @param updateMax Optional. Set a new value for the max input field
 * @param updateMin Optional. Set a new value for the min input field
 * @returns
 */
export const FormMinMax = ({
    onMaxChange,
    onMinChange,

    maxInitialValue = "",
    maxLabel,
    maxObservable: updateMax,
    maxPlaceholder,
    maxStep = 1,
    minInitialValue = "",
    minLabel,
    minObservable: updateMin,
    minPlaceholder,
    minStep = 1,
}: Props): JSX.Element => {
    // Properties & hooks

    const [min, setMin] = React.useState(minInitialValue);
    const [max, setMax] = React.useState(maxInitialValue);

    useEffect(() => {
        if (updateMin) {
            updateMin.subscribe((value) => setMin(value));
        }
        if (updateMax) {
            updateMax.subscribe((value) => setMax(value));
        }
    }, []);

    // Local functions

    const handleMinChange = (value: number): void => {
        const newMin = onMinChange(value);
        if (!updateMin && newMin) {
            // Parent component didn't provide an observable, but did return a value
            setMin(newMin);
        } else if (!updateMin && !newMin) {
            // Parent component didn't provide an observable, and didn't return a value
            setMin(value);
        }
    };

    const handleMaxChange = (value: number): void => {
        const newMax = onMaxChange(value);
        if (!updateMax && newMax) {
            // Parent component didn't provide an observable, but did return a value
            setMax(newMax);
        } else if (!updateMax && !newMax) {
            // Parent component didn't provide an observable, and didn't return a value
            setMax(value);
        }
    };

    // Render

    return (
        <Component>
            <Column>
                <label>{minLabel || t("general.min")}</label>
                <InputField
                    data-testid="min-input"
                    onChange={(e) => handleMinChange(e.target.valueAsNumber)}
                    onKeyDown={(e) => e.key.match("[\\.+]") && !e.key.match("e") && e.preventDefault()}
                    placeholder={minPlaceholder}
                    step={minStep}
                    type="number"
                    value={min}
                />
            </Column>
            <HorizontalLine />
            <Column>
                <label>{maxLabel || t("general.max")}</label>
                <InputField
                    data-testid="max-input"
                    onChange={(e) => handleMaxChange(e.target.valueAsNumber)}
                    onKeyDown={(e) => e.key.match("[\\.+]") && !e.key.match("e") && e.preventDefault()}
                    placeholder={maxPlaceholder}
                    step={maxStep}
                    type="number"
                    value={max}
                />
            </Column>
        </Component>
    );
};
