import { useEffect } from "react";
import { detect, Browser } from "detect-browser";
import { showWarning } from "../utils/MessageUtils";
import { t } from "i18next";

// crios = chrome on iOS
const SUPPORTED_BROWSERS: Browser[] = ["chrome", "crios", "ios", "edge-chromium"];

export function useBrowserDetect(): void {
    useEffect(() => {
        const detectBrowser = detect();
        const browserSupported = (detectBrowser && SUPPORTED_BROWSERS.some((b) => b === detectBrowser.name)) || false;
        if (browserSupported) {
            return;
        }

        showWarning({
            title: t("messages.unsupportedBrowserMessageTitle"),
            message: t("messages.unsupportedBrowserMessageDescription"),
            duration: 0,
        });
    }, []);
}
