import React from "react";
import styled from "styled-components";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { RadarControlPanelViewModel } from "./RadarControlPanelViewModel";
import Loading from "../appearance/Loading";
import { TextButton } from "../appearance/TextButton";
import { Radar, Location, BlankingSector, RadarOperatingMode, RadarOperatingModeStatus } from "../../domain/model";
import { ArrowLeftIcon, ArrowRightIcon } from "../appearance/Arrow";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import { RadarReposition } from "./RadarReposition";
import * as RxOperators from "rxjs/operators";
import * as Rx from "rxjs";
import { RadarStartStopView } from "./RadarStartStop";
import { RadarAlignment } from "./RadarAlignment";
import { showError } from "../../utils/MessageUtils";
import { RadarBlankingSectors } from "./RadarBlankingSectors";
import { t } from "i18next";
import { OperatingModeSwitcher } from "./OperatingModeSwitcher";
import { PanelRoot } from "../appearance/panels/PanelRoot";
import { PanelTitle as PanelTitleBase } from "../appearance/panels/PanelTitle";
import { OVERLAY_SPACING } from "../appearance/theme/Spacing";

const Container = styled(PanelRoot)`
    width: 250px;
    -ms-overflow-style: none;
    padding: ${OVERLAY_SPACING};

    ::-webkit-scrollbar {
        display: none;
    }
`;

const BackNavigationContainer = styled.div`
    display: flex;
    flex-direction: row;

    img {
        cursor: pointer;
    }

    span,
    input {
        background-color: black;
        font-size: 16px;
        font-weight: 500;
        max-width: 160px;
        color: white;
        text-transform: uppercase;
        margin-left: 16px;
        border: none;
        padding: 0;
    }
`;

const ContentContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    padding-right: 22px;

    ::-webkit-scrollbar {
        display: none;
    }
`;

const PanelTitle = styled(PanelTitleBase)`
    flex: 1;
`;

const LoadingContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1001;
`;

const OverviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const PanelLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 0 20px 0;
`;

const PanelLinkContainer = styled.div`
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: space-between;

    button {
        padding-left: 0;
    }
`;

export enum Panel {
    Overview,
    Reposition,
    Alignment,
    OperatingMode,
    BlankingSectors,
    Loading,
}

interface RadarNameEditState {
    radarId: number;
    newName: string;
    saving: boolean;
}

interface State {
    activeOperatingMode: RadarOperatingModeStatus | undefined;
    activePanel: Panel;
    availableOperatingModes: RadarOperatingMode[];
    canShowRepositionPanel: boolean;
    canShowAlignmentPanel: boolean;
    hasMultipleRadars: boolean;
    radarNameEditState: RadarNameEditState | null;
    selectedRadar: Radar | undefined;
}

export class RadarControlPanel extends BaseSubscriptionHandlerComponent<{}, State> {
    // Properties

    private viewModel: RadarControlPanelViewModel = DI.get(TYPES.RadarControlPanelViewModel);

    // Lifecycle

    public constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            activeOperatingMode: undefined,
            activePanel: Panel.Loading,
            availableOperatingModes: [],
            canShowRepositionPanel: this.viewModel.canShowRepositionPanel,
            canShowAlignmentPanel: this.viewModel.canShowAlignmentPanel,
            hasMultipleRadars: false,
            radarNameEditState: null,
            selectedRadar: undefined,
        };
    }

    public componentDidMount(): void {
        this.subscribeToRadars();
        this.subscribeToOperatingModes();
        this.subscribeToIsDynamicPositioningEnabled();
        this.subscribeToErrors();
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount();

        this.viewModel.canShowRepositionPanel && this.viewModel.hideRepositioningPreview();
        this.viewModel.canShowAlignmentPanel && this.viewModel.hideAlignmentPreview();
        this.viewModel.hideBlankingSectorsPreview();
    }

    // Public functions

    public render(): React.ReactNode {
        return (
            <Container>
                {this.renderNavigationBar()}
                <ContentContainer>
                    {(this.state.activePanel === Panel.Loading || this.state.selectedRadar === undefined) && (
                        <LoadingContainer>
                            <Loading />
                        </LoadingContainer>
                    )}
                    {this.state.activePanel === Panel.Overview && this.state.selectedRadar && (
                        <OverviewContainer>
                            <PanelLinksContainer>
                                {this.state.canShowRepositionPanel && this.renderPanelLinkButton(Panel.Reposition)}
                                {this.state.canShowAlignmentPanel && this.renderPanelLinkButton(Panel.Alignment)}
                                {this.state.availableOperatingModes.length > 1 &&
                                    this.renderPanelLinkButton(Panel.OperatingMode)}
                                {this.renderPanelLinkButton(Panel.BlankingSectors)}
                            </PanelLinksContainer>
                            {this.state.selectedRadar.status && (
                                <RadarStartStopView
                                    onRequestToggleRadar={() =>
                                        this.viewModel.toggleRadar(this.state.selectedRadar!.id)
                                    }
                                    radarStatus={this.state.selectedRadar.status}
                                />
                            )}
                        </OverviewContainer>
                    )}
                    {this.state.activePanel === Panel.Reposition && this.state.selectedRadar && (
                        <RadarReposition
                            altitudeUnitObservable={this.viewModel.altitudeUnitObservable}
                            distanceFormatter={this.viewModel.distanceFormatter}
                            onRequestAutoPosition={() =>
                                Rx.combineLatest([
                                    this.viewModel.autoPositionRadar(this.state.selectedRadar!.id),
                                    this.viewModel.selectedRadarObservable.pipe(RxOperators.take(2)),
                                ]).pipe(RxOperators.ignoreElements())
                            }
                            onRequestPreviewNewPosition={(position) =>
                                this.viewModel.showRepositioningPreview(position)
                            }
                            onRequestSaveNewPosition={this.savePositionForRadar.bind(this)}
                            previewLocationFromMapObservable={this.viewModel.previewPositionFromDraggingMap}
                            radar={this.state.selectedRadar}
                            showAltitudeExplanationModal={() => this.viewModel.showAltitudeExplanationModal()}
                        />
                    )}
                    {this.state.activePanel === Panel.Alignment && this.state.selectedRadar && (
                        <RadarAlignment
                            contrast={this.viewModel.getAlignmentImageContrast()}
                            onContrastSet={(value) => this.viewModel.setAlignmentImageContrast(value)}
                            onRequestAutoAlignment={() => this.viewModel.autoAlignRadar(this.state.selectedRadar!.id)}
                            onRequestPreviewNewAlignment={(dAngle, contrast) =>
                                this.viewModel.showAlignmentPreview(dAngle, this.state.selectedRadar!.id, contrast)
                            }
                            onRequestSaveNewAlignment={this.saveNewAlignment.bind(this)}
                            radar={this.state.selectedRadar}
                        />
                    )}
                    {this.state.activePanel === Panel.OperatingMode && this.state.selectedRadar && (
                        <OperatingModeSwitcher
                            activeMode={this.state.activeOperatingMode}
                            modes={this.state.availableOperatingModes}
                            setNewMode={this.setOperatingMode.bind(this)}
                        />
                    )}
                    {this.state.activePanel === Panel.BlankingSectors && this.state.selectedRadar && (
                        <RadarBlankingSectors
                            blankingSectors={this.viewModel.getBlankingSectors(this.state.selectedRadar.id)}
                            maxSectors={this.viewModel.getMaxNumberOfBlankingSectors(this.state.selectedRadar.id)}
                            blankingSectorsState={this.viewModel.getBlankingSectorsState()}
                            onRequestPreview={(blankingSectors) =>
                                this.viewModel.showBlankingSectorsPreview(this.state.selectedRadar!.id, blankingSectors)
                            }
                            onRequestSave={(blankingSectors) =>
                                this.saveNewBlankingSectors(this.state.selectedRadar!.id, blankingSectors)
                            }
                            radar={this.viewModel.getRadarById(this.state.selectedRadar.id)}
                        />
                    )}
                </ContentContainer>
            </Container>
        );
    }

    // Private functions

    private renderPanelLinkButton(panel: Panel): React.ReactNode {
        return (
            <PanelLinkContainer onClick={() => this.showPanel(panel)}>
                <TextButton onClick={() => {}} text={this.getNameForPanel(panel)} />
                <img src={ArrowRightIcon} alt={""} />
            </PanelLinkContainer>
        );
    }

    private renderNavigationBar(): React.ReactNode | null {
        switch (this.state.activePanel) {
            case Panel.Overview:
                return <PanelTitle>{t("radarControlPanel.radarControlPanel")}</PanelTitle>;
            case Panel.Reposition:
            case Panel.Alignment:
            case Panel.OperatingMode:
            case Panel.BlankingSectors:
                return (
                    <BackNavigationContainer>
                        <img
                            src={ArrowLeftIcon}
                            onClick={() => this.showPanel(Panel.Overview)}
                            alt={t("radarControlPanel.backToOverview")}
                        />
                        <PanelTitle>{this.getNameForPanel(this.state.activePanel)}</PanelTitle>
                    </BackNavigationContainer>
                );
            case Panel.Loading:
                return null;
        }
    }

    private getNameForPanel(panel: Panel): string {
        switch (panel) {
            case Panel.Reposition:
                return t("radarControlPanel.positioning");
            case Panel.Alignment:
                return t("radarControlPanel.alignment");
            case Panel.OperatingMode:
                return t("radarControlPanel.operatingMode");
            case Panel.BlankingSectors:
                return t("radarControlPanel.blankingSectors");
            default:
                return "";
        }
    }

    private showPanel(panel: Panel): void {
        if (panel !== Panel.Reposition && this.viewModel.canShowRepositionPanel) {
            this.viewModel.hideRepositioningPreview();
        }
        if (panel !== Panel.Alignment && this.viewModel.canShowAlignmentPanel) {
            this.viewModel.hideAlignmentPreview();
        }
        if (panel !== Panel.BlankingSectors) {
            this.viewModel.hideBlankingSectorsPreview();
        }
        this.setState({ activePanel: panel });
    }

    private savePositionForRadar(position: Location, groundLevel: number | null): Rx.Observable<void> {
        return this.viewModel.savePositionForRadar(this.state.selectedRadar!.id, position, groundLevel).pipe(
            RxOperators.tap({
                complete: () => this.showPanel(Panel.Overview),
            }),
        );
    }

    private saveNewAlignment(angle: number): Rx.Observable<void> {
        return this.viewModel.saveNewAlignment(this.state.selectedRadar!.id, angle).pipe(
            RxOperators.tap({
                complete: () => this.showPanel(Panel.Overview),
            }),
        );
    }

    private saveNewBlankingSectors(radarId: int, sectors: BlankingSector[]): Rx.Observable<void> {
        return this.viewModel.saveBlankingSectors(radarId, sectors).pipe(
            RxOperators.tap({
                complete: () => this.showPanel(Panel.Overview),
            }),
        );
    }

    private subscribeToErrors(): void {
        const subscription = this.viewModel.errorObservable.subscribe((error) => showError(error));
        this.collectSubscription(subscription);
    }

    private subscribeToRadars(): void {
        this.collectSubscriptions(
            this.viewModel.hasMultipleRadarsObservable.subscribe((hasMultipleRadars) => {
                this.setState({
                    hasMultipleRadars,
                });
            }),
            this.viewModel.selectedRadarObservable.subscribe((radar) => {
                if (radar === undefined) {
                    console.warn("List of radars is empty");
                    this.setState({
                        activePanel: Panel.Loading,
                        selectedRadar: undefined,
                    });
                } else if (this.state.activePanel === Panel.Loading) {
                    this.setState({ activePanel: Panel.Overview, selectedRadar: radar });
                } else {
                    this.setState({ selectedRadar: radar });
                }
                if (radar && radar.status && radar.status.operatingMode) {
                    this.setState({ activeOperatingMode: radar.status.operatingMode });
                } else {
                    this.setState({ activeOperatingMode: undefined });
                }
            }),
        );
    }

    private subscribeToOperatingModes(): void {
        const subscription = this.viewModel.availableOperatingModesObservable.subscribe((availableOperatingModes) =>
            this.setState({ availableOperatingModes }),
        );
        this.collectSubscription(subscription);
    }

    private setOperatingMode(modeId: string): void {
        this.viewModel.setOperatingMode(modeId).subscribe();
    }

    private subscribeToIsDynamicPositioningEnabled(): void {
        const subscription = this.viewModel.isDynamicPositioningEnabled.subscribe((isDynamicPositioningEnabled) =>
            this.setState({
                canShowRepositionPanel: this.viewModel.canShowRepositionPanel && !isDynamicPositioningEnabled,
                canShowAlignmentPanel: this.viewModel.canShowAlignmentPanel && !isDynamicPositioningEnabled,
            }),
        );
        this.collectSubscription(subscription);
    }
}
