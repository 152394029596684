import { ActionItemViewModel } from "../../SettingItemViewModel";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { SettingItemIcon } from "../../SettingItemIcon";
import { getVelocityUnit, VelocityUnit } from "../../../../domain/model/VelocityUnit";
import VelocityIcon from "../../../../res/images/settings/velocity.svg";
import * as RxUtils from "../../../../utils/RxUtils";
import { LocalUserPreferenceKeys } from "../../../../domain/model/PreferencesData";
import * as RxOperators from "rxjs/operators";
import { t } from "i18next";

export class VelocityUnitSettingItemViewModel extends ActionItemViewModel {
    // Properties

    public constructor(private readonly localPreferencesRepository: LocalPreferencesRepository) {
        super(
            t("settings.velocityUnit"),
            SettingItemIcon.withUrl(VelocityIcon),
            () => this.toggleVelocityUnit(),
            RxUtils.nonNullObservable(
                localPreferencesRepository.observePreference<string>(LocalUserPreferenceKeys.appearance.velocityUnit),
            ).pipe(
                RxOperators.map((value) => getVelocityUnit(value)),
                RxOperators.map((unitName) => this.getVelocityUnitName(unitName)),
            ),
        );
    }

    // Private functions

    private toggleVelocityUnit(): void {
        this.setVelocityUnit(this.velocityUnit === VelocityUnit.MSEC ? VelocityUnit.KMH : VelocityUnit.MSEC);
    }

    private get velocityUnit(): VelocityUnit {
        const value = this.localPreferencesRepository.getPreference<string>(
            LocalUserPreferenceKeys.appearance.velocityUnit,
        );
        return getVelocityUnit(value);
    }

    private setVelocityUnit(unit: VelocityUnit): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.appearance.velocityUnit, unit as string);
    }

    private getVelocityUnitName(unit: VelocityUnit): string {
        switch (unit) {
            case VelocityUnit.KMH:
                return `${t("unit.kilometerPerHour")}`;
            case VelocityUnit.MSEC:
            default:
                return `${t("unit.meterPerSecond")}`;
        }
    }
}
