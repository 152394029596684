import React from "react";
import { withTheme } from "styled-components";
import * as RxOperators from "rxjs/operators";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import { OverlayEditorViewModel } from "./OverlayEditorViewModel";
import { TYPES } from "../../../di/Types";
import DI from "../../../di/DI";
import { cleanUpOverlayName, UnknownShape, Shape, PrimitiveShape, PolyLineShape } from "../../../domain/model";
import { EditMode, isAddingMode, OverlaySelection } from "../../../domain/repositories";
import { FlavorConfig } from "../../../infrastructure/FlavorConfig";
import { OverlayShapeRemover } from "./OverlayShapeRemover";
import { t } from "i18next";
import { Theme } from "../../appearance/theme/Theme";
import { Panel } from "../../appearance/panels/Panel";
import { PanelHeader } from "../../appearance/panels/PanelHeader";
import { PanelSection } from "../../appearance/panels/PanelSection";
import { Form } from "../../appearance/forms/Form";
import { ButtonGroupFullWidth, ButtonGroupOutsideRounded } from "../../appearance/button/ButtonGroup";
import { Button } from "../../appearance/button/Button";
import { FormControl } from "../../appearance/forms/FormControl";
import { FormErrorText, FormText } from "../../appearance/forms/FormText";
import { InputField } from "../../appearance/forms/InputField";
import { FormMinMax } from "../../appearance/forms/FormMinMax";
import { PanelFooter, PanelFooterText } from "../../appearance/panels/PanelFooter";

interface Props {
    overlayName: string;
    onClose?: () => void;
    theme: Theme;
}

interface State {
    mode: EditMode;
    modified: boolean;
    hasSelection: boolean;
    activeShape: PrimitiveShape | null;
    minAltitude: number | undefined;
    maxAltitude: number | undefined;
    minMaxAltitudeInputError: boolean;
    widthUnit: string;
}

class OverlayEditorComponent extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private viewModel: OverlayEditorViewModel = DI.get(TYPES.OverlayEditorViewModel);
    private flavorConfig: FlavorConfig = DI.get(TYPES.FlavorConfig);

    private readonly lineWidthInput: React.RefObject<HTMLInputElement>;

    public constructor(props: Readonly<Props>) {
        super(props);

        this.lineWidthInput = React.createRef();

        this.state = {
            mode: EditMode.SELECT,
            modified: false,
            hasSelection: false,
            activeShape: null,
            minAltitude: undefined,
            maxAltitude: undefined,
            minMaxAltitudeInputError: false,
            widthUnit: "meters",
        };
    }

    // Public functions

    public componentDidMount(): void {
        this.subscribeToEditState();
        this.viewModel.isolateOverlays([this.props.overlayName]);

        this.collectSubscriptions(
            this.viewModel.editState.subscribe({
                next: (state) => (state.payload instanceof Shape ? this.updateShapeData(state.payload) : true),
                error: (error) => console.error(error),
            }),
            this.viewModel.widthUnit.subscribe((widthUnit) => this.setState({ widthUnit })),
        );
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount();
        this.viewModel.isolateOverlays([]);
    }

    public render(): React.ReactNode {
        return (
            <Panel>
                <PanelHeader
                    label={t("overlays.createOverlay")}
                    onBack={() => this.props.onClose && this.props.onClose()}
                />
                <PanelSection>
                    <Form vertical>
                        <FormControl title={t("overlays.overlayType")} vertical>
                            <FormText>
                                {cleanUpOverlayName(
                                    this.props.overlayName,
                                    this.flavorConfig.shouldRemoveOverlayNamePrefix,
                                )}
                            </FormText>
                        </FormControl>
                        {!this.state.hasSelection && (
                            <FormControl title={t("overlays.selectShape")} vertical>
                                <ButtonGroupOutsideRounded>
                                    <Button
                                        active={
                                            this.state.mode === EditMode.SELECT ||
                                            this.state.mode === EditMode.ADD_POLYGON
                                        }
                                        iconType="SHAPE_POLYGON"
                                        layout="radio"
                                        onClick={() => this.onModeButtonClicked(EditMode.ADD_POLYGON)}
                                    />
                                    <Button
                                        active={
                                            this.state.mode === EditMode.SELECT ||
                                            this.state.mode === EditMode.ADD_POLYLINE
                                        }
                                        iconType="SHAPE_POLYLINE"
                                        layout="radio"
                                        onClick={() => this.onModeButtonClicked(EditMode.ADD_POLYLINE)}
                                    />
                                    <Button
                                        active={
                                            this.state.mode === EditMode.SELECT ||
                                            this.state.mode === EditMode.ADD_CIRCLE
                                        }
                                        iconType="SHAPE_CIRCLE"
                                        layout="radio"
                                        onClick={() => this.onModeButtonClicked(EditMode.ADD_CIRCLE)}
                                    />
                                </ButtonGroupOutsideRounded>
                            </FormControl>
                        )}
                        {this.renderWidthInput()}
                        {this.renderMinMaxAltitudeInputs()}
                    </Form>
                </PanelSection>
                <PanelFooter>
                    {this.state.modified ? (
                        <ButtonGroupFullWidth>
                            <Button
                                disabled={!this.state.modified}
                                label={t("general.save")}
                                onClick={this.endShape.bind(this)}
                            />
                            <Button
                                color="danger"
                                label={t("general.cancel")}
                                layout="inline-fill"
                                onClick={() => this.viewModel.discardShape()}
                            />
                        </ButtonGroupFullWidth>
                    ) : this.state.mode === EditMode.SELECT ? (
                        <PanelFooterText text={t("overlays.descriptionEditor")} />
                    ) : (
                        <PanelFooterText text={t("overlays.startDrawingShape")} />
                    )}
                    <OverlayShapeRemover />
                </PanelFooter>
            </Panel>
        );
    }

    // Private functions

    private endShape(): void {
        if (this.checkMinMaxAltitudeError()) {
            this.viewModel.endShape(this.props.overlayName);
        }
    }

    private updateShapeData(payload: Shape): void {
        this.setState({
            activeShape: payload.shape,
            minAltitude: payload.minAltitude,
            maxAltitude: payload.maxAltitude,
        });
    }

    private subscribeToEditState(): void {
        let subscription = this.viewModel.editState.pipe(RxOperators.distinctUntilKeyChanged("mode")).subscribe({
            next: (state) =>
                this.setState({
                    mode: state.mode,
                }),
            error: (error) => console.error(error),
        });
        this.collectSubscription(subscription);

        subscription = this.viewModel.editState.subscribe({
            next: (state) =>
                this.setState({
                    modified:
                        isAddingMode(state.mode) &&
                        state.payload != null &&
                        state.payload instanceof Shape &&
                        !(state.payload.shape instanceof UnknownShape),
                    hasSelection:
                        state.mode === EditMode.SELECT ? (state.payload as OverlaySelection[]).length > 0 : false,
                }),
            error: (error) => console.error(error),
        });

        this.collectSubscription(subscription);
    }

    private onModeButtonClicked(mode: EditMode): void {
        const newMode = this.state.mode === mode ? EditMode.SELECT : mode;

        if (isAddingMode(newMode)) {
            this.viewModel.setMode(newMode);
            this.viewModel.beginShape();
        } else {
            this.viewModel.discardShape();
        }
    }

    private onLineWidthChanged(e: React.ChangeEvent<HTMLInputElement>): void {
        const value = this.viewModel.convertValueToMeters(e.target.value);
        const shape = this.state.activeShape;
        if (shape instanceof PolyLineShape && !isNaN(value)) {
            this.viewModel.updateActiveShape(new PolyLineShape(shape.locations, value));
        }
    }

    private renderWidthInput(): React.ReactNode {
        const shouldShow =
            this.state.modified &&
            this.state.mode === EditMode.ADD_POLYLINE &&
            this.state.activeShape instanceof PolyLineShape;

        return (
            shouldShow && (
                <FormControl title={`${t("overlays.width")} (${this.state.widthUnit})`} vertical>
                    <InputField
                        type="number"
                        onChange={this.onLineWidthChanged.bind(this)}
                        value={this.viewModel.getNumberInCurrentUnit((this.state.activeShape as PolyLineShape).width)}
                        ref={this.lineWidthInput}
                    />
                </FormControl>
            )
        );
    }

    private updateAltitudeData(value: number, minOrMax: "min" | "max"): void {
        const minAltitude =
            minOrMax === "min" ? this.viewModel.convertNumericValueToMeters(value) : this.state.minAltitude;
        const maxAltitude =
            minOrMax === "max" ? this.viewModel.convertNumericValueToMeters(value) : this.state.maxAltitude;

        this.viewModel.updateMinMaxAltitude(minAltitude, maxAltitude);

        // Wait a bit to allow the user to finish typing
        setTimeout(() => this.checkMinMaxAltitudeError(), 400);
    }

    private checkMinMaxAltitudeError(): boolean {
        if (this.state.minAltitude && this.state.maxAltitude && this.state.minAltitude >= this.state.maxAltitude) {
            this.setState({ minMaxAltitudeInputError: true });
            return false;
        }
        this.setState({ minMaxAltitudeInputError: false });
        return true;
    }

    private renderMinMaxAltitudeInputs(): React.ReactNode {
        const shouldShow =
            this.state.modified && this.state.mode !== EditMode.NONE && this.state.mode !== EditMode.SELECT;

        return (
            shouldShow && (
                <FormControl title={`${t("overlays.altitude")} (${this.state.widthUnit})`} vertical>
                    <FormMinMax
                        maxInitialValue={this.convertAltitudeValueToCurrentUnit(this.state.maxAltitude)}
                        maxPlaceholder={t("general.max")}
                        minInitialValue={this.convertAltitudeValueToCurrentUnit(this.state.minAltitude)}
                        minPlaceholder={t("general.min")}
                        onMaxChange={(v: number) => this.updateAltitudeData(v, "max")}
                        onMinChange={(v: number) => this.updateAltitudeData(v, "min")}
                    />
                    {this.state.minMaxAltitudeInputError && (
                        <FormErrorText>{t("overlays.minMaxAltitudeError")}</FormErrorText>
                    )}
                </FormControl>
            )
        );
    }

    private convertAltitudeValueToCurrentUnit(minOrMaxAltitude: number | undefined): number | undefined {
        if (minOrMaxAltitude) {
            return this.viewModel.getNumberInCurrentUnit(minOrMaxAltitude);
        }
        return undefined;
    }
}

export const OverlayEditor = withTheme(OverlayEditorComponent);
