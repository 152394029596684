import React from "react";
import styled from "styled-components";
import { BlankingSector, BlankingSectorsState, Radar } from "../../domain/model";
import { FormRoundInput } from "../appearance/FormRoundInput";
import { RoundButton } from "../appearance/RoundButton";
import { RoundInput } from "../appearance/RoundInput";
import * as Rx from "rxjs";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { showError } from "../../utils/MessageUtils";
import { parseNumberOrNull } from "../../utils/StringUtils";
import { ToggleSwitch } from "../appearance/ToggleSwitch";
import { Collapse } from "react-collapse";
import _ from "lodash";
import Loading from "../appearance/Loading";
import { InputGroup, InputGroupItem } from "../appearance/InputGroup";
import { t } from "i18next";

const RADAR_BLANKING_SECTOR_DEFAULT_SPAN = 15;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 900px;
    overflow-y: scroll;
    -ms-overflow-style: none;

    ::-webkit-scrollbar {
        display: none;
    }

    button {
        margin: 0;
    }
`;

const ErrorMessage = styled.span`
    font-size: 12px;
    line-height: 1.83;
    color: ${({ theme }) => theme.colors.text.text};
    padding: 8px 0px;
`;

const Sector = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 16px 1px 0 0;
`;

const SectorToggle = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    label {
        flex-grow: 1;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        font-size: 14px;
        color: white;
    }
`;

const LoadingContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1001;
`;

interface Props {
    maxSectors: Rx.Observable<number>;
    blankingSectors: Rx.Observable<BlankingSector[]>;
    blankingSectorsState: Rx.Observable<BlankingSectorsState>;
    onRequestPreview: (blankingSectors: BlankingSector[]) => void;
    onRequestSave: (blankingSectors: BlankingSector[]) => Rx.Observable<void>;
    radar: Radar;
}

interface State {
    isLoading: boolean;
    canApplyChanges: boolean;
    maxSectors: number;
    blankingSectors: BlankingSector[];
    enabledSectorIndices: int[];
    blankingSectorsError?: string;
}

export class RadarBlankingSectors extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private sectorInputs: React.RefObject<RoundInput>[][];
    private initialBlankingSectors: BlankingSector[] | null = null;

    public constructor(props: Props) {
        super(props);
        this.sectorInputs = [];
        this.state = {
            isLoading: false,
            canApplyChanges: false,
            maxSectors: 0,
            blankingSectors: [],
            enabledSectorIndices: [],
            blankingSectorsError: undefined,
        };
    }

    // Public functions

    public render(): React.ReactNode {
        this.sectorInputs = [];
        if (this.state.blankingSectorsError) {
            return <ErrorMessage>{this.state.blankingSectorsError}</ErrorMessage>;
        }
        if (this.state.blankingSectors.length === 0) {
            return (
                <LoadingContainer>
                    <Loading />
                </LoadingContainer>
            );
        }
        return this.renderSectors();
    }

    public componentDidMount(): void {
        this.collectSubscriptions(
            Rx.combineLatest([this.props.maxSectors, this.props.blankingSectors]).subscribe({
                next: ([maxSectors, sectors]) => {
                    this.initialBlankingSectors = sectors;
                    this.updateSectors(maxSectors, sectors);
                },
                error: (error) =>
                    this.setState({
                        blankingSectorsError: `${t("radarControlPanel.errorFailedToLoadBlankingSector")}: ${
                            error.message || t("radarControlPanel.errorServerError")
                        }`,
                    }),
            }),
            Rx.combineLatest([this.props.maxSectors, this.props.blankingSectorsState]).subscribe({
                next: ([maxSectors, state]) => this.updateSectors(maxSectors, state.blankingSectors || [], false),
                error: (error) => console.error(error),
            }),
        );
    }

    // Private functions

    private renderSectors(): React.ReactNode {
        return (
            <Container>
                <form action={"#"}>
                    {this.state.blankingSectors.map((sector, index) => this.getBlankingSectorView(sector, index))}
                </form>
                {this.state.canApplyChanges && (
                    <RoundButton
                        isLoading={this.state.isLoading}
                        text={t("radarControlPanel.applyChanges")}
                        onClick={this.saveChanges.bind(this)}
                    />
                )}
            </Container>
        );
    }

    private updateSectors(maxSectors: number, sectors: BlankingSector[], broadcast = true): void {
        if (maxSectors < 1) {
            this.setState({ blankingSectorsError: t("radarControlPanel.errorRadarHasNoBlankingSectors") });
            return;
        }

        const enabledIndices = sectors.reduce<int[]>((indices, sector, i) => {
            sector.span !== 0 && indices.push(i);
            return indices;
        }, []);
        const toBeAdded = maxSectors - sectors.length;
        for (let i = 0; i < toBeAdded; i++) {
            sectors.push(new BlankingSector(0, 0));
        }
        // In case the server returned more than [maxSectors] number of sectors, remove the extra sectors
        sectors = _.take(sectors, maxSectors);
        this.setState({
            maxSectors: maxSectors,
            blankingSectors: sectors,
            enabledSectorIndices: enabledIndices,
        });

        this.validate(sectors, broadcast);
    }

    private saveChanges(): void {
        const sectors: BlankingSector[] = [...this.state.blankingSectors];
        this.setState({ isLoading: true });
        const subscription = this.props.onRequestSave(sectors).subscribe({
            complete: () => {
                this.setState({ isLoading: false });
            },
            error: (error) => {
                showError(error);
                this.setState({ isLoading: false });
            },
        });
        this.collectSubscription(subscription);
    }

    private getBlankingSectorView(sector: BlankingSector, index: int): React.ReactNode {
        const startAngleInput: React.RefObject<RoundInput> = React.createRef();
        const endAngleInput: React.RefObject<RoundInput> = React.createRef();

        this.sectorInputs[index] = [];
        this.sectorInputs[index].push(startAngleInput);
        this.sectorInputs[index].push(endAngleInput);
        const enabled = this.state.enabledSectorIndices.includes(index);

        return (
            <Sector key={index}>
                <SectorToggle>
                    <label>
                        {t("radarControlPanel.sector")} {index + 1}
                    </label>
                    <ToggleSwitch value={enabled} onChange={(checked) => this.toggleSector(index, checked)} />
                </SectorToggle>
                <Collapse isOpened={enabled}>
                    <InputGroup>
                        <InputGroupItem label={t("radarControlPanel.blankingSectorStartingAngle")}>
                            <FormRoundInput
                                key={index * 10 + 1}
                                disabled={this.state.isLoading}
                                ref={this.sectorInputs[index][0]}
                                value={sector.startAngle.toFixed()}
                                onTextChange={(text) => this.onSectorStartAngleChanged(text, index)}
                                type={"number"}
                            />
                        </InputGroupItem>
                        <InputGroupItem label={t("radarControlPanel.blankingSectorEndingAngle")}>
                            <FormRoundInput
                                key={index * 10 + 2}
                                disabled={this.state.isLoading}
                                ref={this.sectorInputs[index][1]}
                                value={this.getEndAngle(sector.startAngle, sector.span).toFixed()}
                                onTextChange={(text) => this.onSectorEndAngleChanged(text, index)}
                                type={"number"}
                            />
                        </InputGroupItem>
                    </InputGroup>
                </Collapse>
            </Sector>
        );
    }

    private getEndAngle(startAngle: number, span: number): number {
        const endAngle = Math.floor(startAngle + span);
        return endAngle > 360 ? endAngle % 360 : endAngle;
    }

    private getSpan(startAngle: number, endAngle: number): number {
        const span = endAngle - startAngle;
        return span < 0 ? span + 360 : span;
    }

    private onSectorStartAngleChanged(text: string, sectorIndex: int): void {
        const sectors = [...this.state.blankingSectors];
        let startAngle = parseNumberOrNull(text);
        if (startAngle === null) {
            return;
        }
        startAngle = startAngle < 0 ? startAngle + 360 : startAngle;
        startAngle = startAngle % 360;
        if (startAngle >= 0 && startAngle <= 360) {
            sectors[sectorIndex] = new BlankingSector(startAngle, sectors[sectorIndex].span);
        }
        this.setState({ blankingSectors: sectors });
        this.validate(sectors);
    }

    private onSectorEndAngleChanged(text: string, sectorIndex: int): void {
        const sectors = [...this.state.blankingSectors];
        let endAngle = parseNumberOrNull(text);
        if (endAngle === null) {
            return;
        }
        endAngle = endAngle < 0 ? endAngle + 360 : endAngle;
        endAngle = endAngle % 360;
        const span = this.getSpan(sectors[sectorIndex].startAngle, endAngle);
        if (span > 0 && span <= 360) {
            sectors[sectorIndex] = new BlankingSector(sectors[sectorIndex].startAngle, span);
        }
        this.setState({ blankingSectors: sectors });
        this.validate(sectors);
    }

    private validate(sectors: BlankingSector[], requestPreview = true): void {
        const canApplyChanges = !_.isEqual(sectors, this.initialBlankingSectors) && this.initialBlankingSectors != null;
        this.setState({ canApplyChanges });

        if (requestPreview) {
            this.props.onRequestPreview(sectors);
        }
    }

    private toggleSector(index: int, enabled: boolean): void {
        let newEnabledSectorIndices = [...this.state.enabledSectorIndices];
        if (!enabled) {
            newEnabledSectorIndices = newEnabledSectorIndices.filter((s) => s !== index);
        } else {
            newEnabledSectorIndices = [...this.state.enabledSectorIndices, index];
        }

        const sectors = [...this.state.blankingSectors];
        // Set the sector to have a default span value if enabled or 0 if disabled
        sectors[index] = new BlankingSector(0, enabled ? RADAR_BLANKING_SECTOR_DEFAULT_SPAN : 0);
        this.setState({ enabledSectorIndices: newEnabledSectorIndices, blankingSectors: sectors });
        this.validate(sectors);
    }
}
