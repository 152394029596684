import * as Rx from "rxjs";
import {
    OverlayEditorRepository,
    EditMode,
    EditState,
    EditModePayload,
    OverlaySelection,
} from "../../../../domain/repositories";
import { PrimitiveShape } from "../../../../domain/model";

export class BirdViewerOverlayEditorRepository implements OverlayEditorRepository {
    // Properties

    public editState: Rx.Observable<EditState> = Rx.NEVER;
    public isolatedOverlays: Rx.Observable<string[]> = Rx.NEVER;

    // Public functions

    /* eslint-disable  @typescript-eslint/no-unused-vars */
    public setMode(mode: EditMode, payload?: EditModePayload): void {
        throw new Error("Method not implemented.");
    }

    public beginShape(): void {
        throw new Error("Method not implemented.");
    }

    public updateActiveShape(update: PrimitiveShape): void {
        throw new Error("Method not implemented.");
    }

    public updateMinMaxAltitude(minAltitude?: number, maxAltitude?: number): void {
        throw new Error("Method not implemented.");
    }

    public discardShape(): void {
        throw new Error("Method not implemented.");
    }

    public endShape(overlay: string): Rx.Observable<never> {
        throw new Error("Method not implemented.");
    }

    public isolateOverlays(overlayIds: string[]): void {
        throw new Error("Method not implemented.");
    }

    public deleteShapes(selection: OverlaySelection[]): Rx.Observable<never> {
        throw new Error("Method not implemented.");
    }
    /* eslint-enable  @typescript-eslint/no-unused-vars */
}
