export class OrderLayer {
    // Lower zOrder: higher priority - more on top. as zOrder increases, the map layers go further behind.
    public constructor(public readonly id: string, public readonly zOrder: int) {}
}

let order = 100;
export const ORDER_LAYER_USER_LOCATION = new OrderLayer("ol-user-location", order);
export const ORDER_LAYER_USER_LOCATION_RANGE = new OrderLayer("ol-user-location-range", ++order);
export const ORDER_LAYER_MEASUREMENTS = new OrderLayer("ol-measurements", ++order);
export const ORDER_LAYER_DETERRENCE_DEVICES = new OrderLayer("ol-deterrence-devices", ++order);
export const ORDER_LAYER_GROUND_OBSERVATIONS = new OrderLayer("ol-ground-observations", ++order);
export const ORDER_LAYER_TRACKS = new OrderLayer("ol-tracks", ++order);
export const ORDER_LAYER_RADARS = new OrderLayer("ol-radars", ++order);
export const ORDER_LAYER_OVERLAY_EDITOR = new OrderLayer("ol-overlay-editor", order);
export const ORDER_LAYER_OVERLAYS = new OrderLayer("ol-overlays", ++order);
export const ORDER_LAYER_RADAR_ALIGNMENT = new OrderLayer("ol-alignment", ++order);
export const ORDER_LAYER_RADAR_BLANKING_SECTORS = new OrderLayer("ol-blanking-sectors", ++order);
export const ORDER_LAYER_RADAR_BLANKING_SECTORS_PREVIEW = new OrderLayer("ol-blanking-sectors-preview", ++order);
export const ORDER_LAYER_RADAR_RANGE = new OrderLayer("ol-range", ++order);
export const ORDER_LAYER_HEATMAP = new OrderLayer("ol-heatmap", ++order);
export const ORDER_LAYER_VERTICAL_RADAR_AZIMUTH = new OrderLayer("ol-vr-azimuth", ++order);
// Keep ORDER_LAYER_COLORS at the bottom. This should be below every other layer.
export const ORDER_LAYER_COLORS = new OrderLayer("ol-colors", ++order);

export const ORDER_LAYERS = [
    ORDER_LAYER_COLORS,
    ORDER_LAYER_HEATMAP,
    ORDER_LAYER_OVERLAYS,
    ORDER_LAYER_OVERLAY_EDITOR,
    ORDER_LAYER_RADARS,
    ORDER_LAYER_TRACKS,
    ORDER_LAYER_RADAR_ALIGNMENT,
    ORDER_LAYER_RADAR_RANGE,
    ORDER_LAYER_RADAR_BLANKING_SECTORS,
    ORDER_LAYER_RADAR_BLANKING_SECTORS_PREVIEW,
    ORDER_LAYER_GROUND_OBSERVATIONS,
    ORDER_LAYER_DETERRENCE_DEVICES,
    ORDER_LAYER_USER_LOCATION_RANGE,
    ORDER_LAYER_MEASUREMENTS,
    ORDER_LAYER_USER_LOCATION,
    ORDER_LAYER_VERTICAL_RADAR_AZIMUTH,
].sort((a, b) => (a.zOrder > b.zOrder ? -1 : 1));
