import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import { Feature, GeoJsonProperties, Geometry } from "geojson";

const EARTH_BOUNDS = [
    [180, 90],
    [180, -90],
    [-180, -90],
    [-180, 90],
    [180, 90],
];

export class ColorLayer {
    public static attachedTo(
        map: mapboxgl.Map,
        orderLayer: string,
        id: string,
        color: string,
        opacity: float,
    ): ColorLayer {
        return new ColorLayer(map, orderLayer, id, color, opacity);
    }

    // Properties

    private constructor(
        private readonly map: mapboxgl.Map,
        private readonly orderLayer: string,
        public readonly id: string,
        color: string,
        opacity: float,
    ) {
        this.setup(color, opacity);
    }

    // Public functions

    public setColor(color: string): void {
        this.map.setPaintProperty(this.id, "fill-color", color);
    }

    public setOpacity(opacity: float): void {
        this.map.setPaintProperty(this.id, "fill-opacity", opacity);
    }

    public getColor(): string {
        return this.map.getPaintProperty(this.id, "fill-color");
    }

    public getOpacity(): float {
        return this.map.getPaintProperty(this.id, "fill-opacity");
    }

    // Private functions

    private setup(color: string, opacity: float): void {
        const feature = turf.polygon([EARTH_BOUNDS]) as Feature<Geometry, GeoJsonProperties>;
        this.map.addLayer(
            {
                type: "fill",
                layout: {},
                paint: {
                    "fill-color": color,
                    "fill-opacity": opacity,
                },
                id: this.id,
                source: {
                    type: "geojson",
                    data: feature,
                },
            },
            this.orderLayer,
        );
    }
}
