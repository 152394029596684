import { BaseViewModel } from "../BaseViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { LocalPreferencesRepository, RunwayTrafficRepository } from "../../domain/repositories";
import { LocalUserPreferenceKeys, RunwayCrossingData } from "../../domain/model";
import { nonNullObservable } from "../../utils/RxUtils";
import { TimeDisplayMode } from "../../domain/model/TimeDisplayMode";

export class RunwayCrossingChartViewModel extends BaseViewModel {
    // Properties

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly runwayTrafficRepository: RunwayTrafficRepository,
    ) {
        super();
    }

    // Public functions

    public observeCurrentCount(runwayId: number): Rx.Observable<number> {
        return this.runwayTrafficRepository.runwayCrossingCurrent.pipe(
            RxOperators.map((data) => {
                return data.get(runwayId)?.count ?? 0;
            }),
        );
    }

    public get runwayCrossingHistory(): Rx.Observable<Map<number, RunwayCrossingData[]>> {
        return this.runwayTrafficRepository.runwayCrossingHistory;
    }

    public get runwayCrossingThreshold(): Rx.Observable<int> {
        return this.runwayTrafficRepository.crossingThreshold;
    }

    public get timeDisplayMode(): Rx.Observable<TimeDisplayMode> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference(LocalUserPreferenceKeys.appearance.timeDisplayMode),
        );
    }
}
