import React from "react";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { OverlayManagementViewModel } from "./OverlayManagementViewModel";
import { TYPES } from "../../di/Types";
import DI from "../../di/DI";
import { OverlayEditor } from "./edit/OverlayEditor";
import { cleanUpOverlayName } from "../../domain/model";
import { FlavorConfig } from "../../infrastructure/FlavorConfig";
import { EditMode } from "../../domain/repositories";
import { OverlayShapeRemover } from "./edit/OverlayShapeRemover";
import { t } from "i18next";
import { Panel } from "../appearance/panels/Panel";
import { PanelHeader } from "../appearance/panels/PanelHeader";
import { PanelSection } from "../appearance/panels/PanelSection";
import { PanelFooter, PanelFooterText } from "../appearance/panels/PanelFooter";
import { Button } from "../appearance/button/Button";
import { Form } from "../appearance/forms/Form";
import { FormToggle } from "../appearance/forms/FormToggle";
import { FormControl } from "../appearance/forms/FormControl";

enum OverlayViewMode {
    Overview,
    Selection,
    Edit,
}

interface Props {
    editEnabled: boolean;
}

interface State {
    mode: OverlayViewMode;
    overlayNames: string[];
    selectedOverlayIdForEditing: string | null;
}

export class OverlayManagement extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private viewModel: OverlayManagementViewModel = DI.get(TYPES.OverlayManagementViewModel);
    private flavorConfig: FlavorConfig = DI.get(TYPES.FlavorConfig);

    public constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            mode: OverlayViewMode.Overview,
            overlayNames: [],
            selectedOverlayIdForEditing: null,
        };
    }

    // Public functions

    public render(): React.ReactNode {
        let view: React.ReactNode;
        switch (this.state.mode) {
            case OverlayViewMode.Overview:
            case OverlayViewMode.Selection:
                view = this.renderOverview();
                break;
            case OverlayViewMode.Edit:
                view = (
                    <OverlayEditor
                        overlayName={this.state.selectedOverlayIdForEditing!}
                        onClose={() => {
                            this.setState({ selectedOverlayIdForEditing: null });
                            this.setSelectionModeEnabled(true);
                        }}
                    />
                );
                break;
            default:
                throw Error(`Panel ${this.state.mode} is not expected.`);
        }
        return view;
    }

    public componentDidMount(): void {
        const subscription = this.viewModel.getOverlays().subscribe({
            next: (names) => this.setState({ overlayNames: names }),
            error: (error) => console.error(error),
        });
        this.collectSubscription(subscription);
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount();
        this.setSelectionModeEnabled(false);
    }

    // Private functions

    private renderOverview(): React.ReactNode {
        return (
            <Panel>
                <PanelHeader label={t("overlays.overlays")} onClose={() => this.viewModel.closeOverlayPanel()} />
                <PanelSection>
                    <Form>
                        {this.state.overlayNames.map((name) =>
                            this.state.mode === OverlayViewMode.Overview ? (
                                <FormControl title={this.renderOverlayName(name)} key={name}>
                                    <FormToggle
                                        isActive={this.viewModel.observeVisibility(name)}
                                        type="EYE"
                                        onToggle={(active) => this.viewModel.toggleVisibility(name, active)}
                                    />
                                </FormControl>
                            ) : (
                                <Button
                                    iconType="CHEVRON_RIGHT"
                                    label={this.renderOverlayName(name)}
                                    layout="inline-fill"
                                    onClick={() => this.onOverlayClicked(name)}
                                    key={name}
                                />
                            ),
                        )}
                    </Form>
                    <OverlayShapeRemover />
                </PanelSection>
                {this.props.editEnabled && (
                    <PanelFooter>
                        {this.state.mode === OverlayViewMode.Selection && (
                            <PanelFooterText text={t("overlays.description")} />
                        )}
                        {this.state.mode === OverlayViewMode.Overview && (
                            <Button label={t("general.edit")} onClick={() => this.setSelectionModeEnabled(true)} />
                        )}
                        {this.state.mode === OverlayViewMode.Selection && (
                            <Button label={t("general.back")} onClick={() => this.setSelectionModeEnabled(false)} />
                        )}
                    </PanelFooter>
                )}
            </Panel>
        );
    }

    private setSelectionModeEnabled(isEnabled: boolean): void {
        this.setState({ mode: isEnabled ? OverlayViewMode.Selection : OverlayViewMode.Overview });
        if (this.props.editEnabled) {
            this.viewModel.setEditMode(isEnabled ? EditMode.SELECT : EditMode.NONE);
        }
    }

    private onOverlayClicked(name: string): void {
        if (this.state.mode !== OverlayViewMode.Selection) {
            return;
        }

        this.setState({ selectedOverlayIdForEditing: name, mode: OverlayViewMode.Edit });
    }

    private renderOverlayName(name: string): string {
        return cleanUpOverlayName(name, this.flavorConfig.shouldRemoveOverlayNamePrefix);
    }
}
