import { TracksSnapshot, Range, Track } from "./model";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { nonNullObservable } from "../utils/RxUtils";
import { RunwayTrafficRepository, TrackRepository } from "./repositories";
import {
    aggregateSectorMessages,
    aggregateSectorTrafficRate,
    aggregateTracksOnRunway,
    RunwayTraffic,
} from "./model/RunwayTraffic";
import _ from "lodash";
import { t } from "i18next";
import { Cache, newMemoryCache } from "../utils/Cache";
import { showErrorWithOptions } from "../utils/MessageUtils";

const DEFAULT_CHUNK_TIME_MILLIS = 300000;
const DEFAULT_STEP_SIZE_MILLIS = 1000;
const DEFAULT_CACHE_SIZE = 20;

export const SPEED_NORMAL = 1;

export enum PlaybackState {
    LOADING = 1,
    PAUSED = 2,
    PLAYING = 3,
    STOPPED = 4,
}

export interface PlaybackScene {
    startTimestamp: long;
    endTimestamp: long;
    state: Rx.Observable<PlaybackState>;
    tracks: Rx.Observable<TracksSnapshot>;
    runwayTraffic: Rx.Observable<RunwayTraffic[]>;

    playFrom(timestamp: long, speed?: number): void;
    pause(): void;
    stop(): void;
}

/* ChunkedLoaderPlaybackScene is An implementation of PlaybackScene which loads the replay data in small chunks
 * to avoid memory issues and long loadings to download huge amounts of data from backend.
 */
export class ChunkedLoaderPlaybackScene implements PlaybackScene {
    // Properties

    public state: Rx.Observable<PlaybackState>;
    public tracks: Rx.Observable<TracksSnapshot>;
    public runwayTraffic: Rx.Observable<RunwayTraffic[]>;

    private stateSubject = new Rx.BehaviorSubject<PlaybackState | null>(null);
    private tracksSubject = new Rx.BehaviorSubject<TracksSnapshot | null>(null);
    private runwayTrafficSubject = new Rx.BehaviorSubject<RunwayTraffic[] | null>(null);
    private subscription: Rx.Subscription | null = null;
    private chunkSize: long;
    private stepSize: long;
    private tracksSnapshotCache: Cache<TracksSnapshot>;
    private runwayTrafficCache: Cache<RunwayTraffic[]>;

    public constructor(
        public readonly startTimestamp: long,
        public readonly endTimestamp: long,
        private readonly trackRepository: TrackRepository,
        private readonly onError?: (err: Error) => void,
        private readonly runwayTrafficRepository?: RunwayTrafficRepository,
        chunkSize?: long | null,
        stepSize?: long | null,
        cacheSize?: int | null,
    ) {
        this.chunkSize = chunkSize || DEFAULT_CHUNK_TIME_MILLIS;
        this.stepSize = stepSize || DEFAULT_STEP_SIZE_MILLIS;
        const replayCacheSize = cacheSize ?? DEFAULT_CACHE_SIZE;
        this.tracksSnapshotCache = newMemoryCache<TracksSnapshot>(replayCacheSize);
        this.runwayTrafficCache = newMemoryCache<RunwayTraffic[]>(replayCacheSize);

        if (endTimestamp <= startTimestamp) {
            throw new Error("End timestamp cannot be before start timestamp");
        }

        this.state = nonNullObservable(this.stateSubject.asObservable());
        this.tracks = nonNullObservable(this.tracksSubject.asObservable());
        this.runwayTraffic = nonNullObservable(this.runwayTrafficSubject.asObservable());
    }

    // Public functions

    /**
     * Initiates playback from the given timestamp.
     * @param timestamp Playback will start from this timestamp
     * @param speed Speed of playback. 1 = normal speed, 2 = 2x speed, etc.
     *
     * @description
     * To avoid loading the entire scene at once, playback is requested in chunks of time relative
     * to the scene start timestamp. The initial chunk will be requested immediately, and then each
     * subsequent chunk will be requested halfway through playback of the preceding chunk.
     *
     * The data is always requested in whole chunks so that we can cache the data in memory.
     *
     * @example
     *   startTime                                                                      endTime
     *   |-----------------------|-----------------------|-----------------------|------|
     *            chunk 0                 chunk 1                 chunk 2         chunk 3
     * 1.^start playback
     *   ^request   ^request
     *    chunk 0    chunk 1
     *    (api call) (api call)
     * 2.                             ^increase playback speed
     *                                ^request  ^request             ^request
     *                                 chunk 1   chunk 2              chunk 3
     *                                 (cache)   (api call)           (api call)
     * 3.          ^seek backwards
     *             ^request ^request          ^request               ^request
     *              chunk 0  chunk 1           chunk 2                chunk 3
     *              (cache)  (cache)           (cache)                (cache)
     *
     */
    public playFrom(timestamp: long, speed: number = SPEED_NORMAL): void {
        this.pause();
        this.setState(PlaybackState.PLAYING);

        // Calculate initial time range
        const initialChunkRange = this.timeRangeForChunkAtTimestamp(timestamp);
        const initialTimeRange: Range = [timestamp, initialChunkRange[1]];
        const initialChunkSize = initialTimeRange[1] - initialTimeRange[0];

        // Create observable stream that emits once in the initial chunk and then once every subsequent chunk
        this.subscription = Rx.merge(
            Rx.timer(initialChunkSize / speed / 2), // Clock index: 0
            Rx.timer(
                (initialChunkSize + this.chunkSize / 2) / speed, // Clock index: 1
                this.chunkSize / speed, // Clock index: 2, 3, 4, ...
            ),
        )
            // Clock pipe
            .pipe(
                // Map the timer indexes to a sequential clock index
                RxOperators.map((_, index) => index),
                // Start with -1 to emit initial time range
                RxOperators.startWith(-1),
                // Map clock index to a time range
                RxOperators.map((clockIndex) => this.timeRangeForClockIndex(clockIndex, initialTimeRange)),
                // Clamp time range to start and end timestamps of scene
                RxOperators.map((range) => this.clampToSceneTimeRange(range)),
                // Take until the end of the scene
                RxOperators.takeWhile((timeRange) => timeRange != null && timeRange[1] <= this.endTimestamp),
            )
            // Data pipe
            .pipe(
                RxOperators.tap(() => this.setState(PlaybackState.LOADING)),
                RxOperators.flatMap((timeRange) => this.requestReplayInRange(timeRange!)),
                RxOperators.tap(() => this.setState(PlaybackState.PLAYING)),
                RxOperators.concatMap((result) => this.breakReplayIntoTimeSteps(result.data, result.range, speed)),
            )
            .subscribe({
                next: (data) => {
                    this.tracksSubject.next(data.tracksSnapshot);
                    this.runwayTrafficSubject.next(data.runwayTraffic);
                },
                error: (error) => {
                    console.error("Error with playback", error);
                    showErrorWithOptions({
                        title: t("general.error"),
                        message: t("replay.replayError"),
                    });
                    this.setState(PlaybackState.STOPPED);
                    this.onError?.(error);
                },
                complete: () => this.setState(PlaybackState.PAUSED),
            });
    }

    public pause(): void {
        this.setState(PlaybackState.PAUSED);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.subscription = null;
    }

    public stop(): void {
        if (this.stateSubject.value !== PlaybackState.PAUSED) {
            this.pause();
        }
        this.setState(PlaybackState.STOPPED);
    }

    // Private functions

    /**
     * Gets the current chunk time range based on the given clock index
     * @param clockIndex a sequential index that increments every time a chunk is requested
     * @param initialRange the initial time range that was requested during this playback session
     * @returns a time range in milliseconds
     */
    private timeRangeForClockIndex(clockIndex: number, initialRange: Range): Range {
        // If clockIndex is -1, return initial range
        if (clockIndex < 0) {
            return initialRange;
        }
        const chunkStart = initialRange[1] + clockIndex * this.chunkSize;
        return [chunkStart, chunkStart + this.chunkSize];
    }

    /**
     * Gets the time range of the chunk at the given timestamp
     * @param chunkIndex index of the chunk
     * @returns a time range in milliseconds
     */
    private timeRangeForChunkAtTimestamp(timestamp: long): Range {
        const chunkIndex = Math.max(0, Math.floor((timestamp - this.startTimestamp) / this.chunkSize));
        const chunkStart = this.startTimestamp + this.chunkSize * chunkIndex;
        const chunkEnd = Math.min(chunkStart + this.chunkSize, this.endTimestamp);
        return [chunkStart, chunkEnd];
    }

    /**
     * Clamp the given time range to the start and end timestamps of the scene
     * @param range a time range in milliseconds
     * @returns a time range in milliseconds, or null if the range is outside the scene
     */
    private clampToSceneTimeRange(range: Range): Range | null {
        if (range[0] >= this.endTimestamp) {
            return null;
        }
        const clampedStart = Math.max(this.startTimestamp, range[0]);
        const clampedEnd = Math.min(range[1], this.endTimestamp);
        return [clampedStart, clampedEnd];
    }

    /**
     * Given a time range, request a replay of the data in the data chunk that contains that range
     * @param timeRange a time range in milliseconds
     * @returns an observable that emits a replay result
     */
    private requestReplayInRange(timeRange: Range): Rx.Observable<ReplayResult> {
        const requestRange = this.timeRangeForChunkAtTimestamp(timeRange[0]);
        const tracksSnapshot = this.trackRepository.loadReplay(
            requestRange[0],
            requestRange[1],
            this.tracksSnapshotCache,
        );

        // Repository is always undefined for Drone Viewer
        const runwayTraffic = this.runwayTrafficRepository
            ? this.runwayTrafficRepository.loadReplay(requestRange[0], requestRange[1], this.runwayTrafficCache)
            : Rx.of([]);

        return Rx.zip([tracksSnapshot, runwayTraffic]).pipe(
            RxOperators.map(([tracksSnapshot, runwayTraffic]) => ({
                data: { tracksSnapshot, runwayTraffic },
                range: timeRange,
            })),
        );
    }

    private breakReplayIntoTimeSteps(data: ReplayData, timeRange: Range, speed: number): Rx.Observable<ReplayData> {
        const snapshots = this.chunkTracksInRange(data.tracksSnapshot, timeRange);
        const runwayTrafficArrays = this.chunkRunwayTrafficInRange(data.runwayTraffic, timeRange);
        const limit = snapshots.length;
        const delayObservable = Rx.interval(this.stepSize / speed).pipe(
            RxOperators.takeWhile((value) => value < limit),
        );
        return Rx.zip([delayObservable, Rx.from(snapshots), Rx.from(runwayTrafficArrays)]).pipe(
            RxOperators.map(([, tracksSnapshot, runwayTraffic]) => ({
                tracksSnapshot,
                runwayTraffic,
            })),
        );
    }

    private chunkTracksInRange(tracksSnapshot: TracksSnapshot, timeRange: Range): TracksSnapshot[] {
        const snapshots: TracksSnapshot[] = [];
        for (let i = timeRange[0]; i < timeRange[1]; i += this.stepSize) {
            const filteredTracks = new Map<int, Track>();
            // Only take the tracks that have an estimate at this moment or in future
            tracksSnapshot.tracks.forEach((track, key) => {
                if (track.endTime == null || track.endTime >= i) {
                    filteredTracks.set(key, track);
                }
            });
            snapshots.push(new TracksSnapshot(filteredTracks, tracksSnapshot.rainPercentage, i));
        }
        return snapshots;
    }

    private chunkRunwayTrafficInRange(runwayTraffic: RunwayTraffic[], timeRange: Range): RunwayTraffic[][] {
        const runwayTrafficArrays: RunwayTraffic[][] = [];
        const groupedByRunway = _.groupBy(runwayTraffic, (rt) => rt.runwayId);
        for (let i = timeRange[0]; i < timeRange[1]; i += this.stepSize) {
            const runwayTrafficArray: RunwayTraffic[] = [];
            for (const runwayId in groupedByRunway) {
                const filteredRunwayTraffic = groupedByRunway[runwayId].filter(
                    (rt) => rt.timestamp >= i && rt.timestamp < i + this.stepSize,
                );
                if (filteredRunwayTraffic.length === 0 && runwayTrafficArrays.length !== 0) {
                    /*
                    If the filtered runway traffic's length is zero then the sector message/traffic rate
                    will be an empty array which means there is no data since the previous time step.
                    In this case we want to continue showing the previous value.
                    */
                    const previousRunwayTraffic = runwayTrafficArrays
                        .slice(-1)[0]
                        .find((traffic) => traffic.runwayId === Number(runwayId));
                    if (previousRunwayTraffic) {
                        runwayTrafficArray.push({
                            ...previousRunwayTraffic,
                            timestamp: i,
                        });
                        continue;
                    }
                }
                // Only update the current timestamp value if there is an update in this time step
                const aggregateSTR = aggregateSectorTrafficRate(filteredRunwayTraffic);
                const aggregateSMs = aggregateSectorMessages(filteredRunwayTraffic);
                const aggregateTOR = aggregateTracksOnRunway(filteredRunwayTraffic);
                runwayTrafficArray.push({
                    runwayId: Number(runwayId),
                    timestamp: i,
                    tracksOnRunway: aggregateTOR,
                    sectorTrafficRate: aggregateSTR,
                    sectorMessages: aggregateSMs,
                });
            }
            runwayTrafficArrays.push(runwayTrafficArray);
        }
        return runwayTrafficArrays;
    }

    private setState(state: PlaybackState): void {
        this.stateSubject.next(state);
    }
}

interface ReplayData {
    tracksSnapshot: TracksSnapshot;
    runwayTraffic: RunwayTraffic[];
}

interface ReplayResult {
    data: ReplayData;
    range: Range;
}
