import React, { Component } from "react";
import styled from "styled-components";
import { Colors } from "./Colors";

const Input = styled.input<{ borderColor: string; borderRadius: number; disabled: boolean }>`
    padding: 11px 20px 11px 20px;
    border: 1px solid ${(props) => props.borderColor};
    border-radius: ${(props) => props.borderRadius}px;
    color: rgba(255, 255, 255, ${(props) => (props.disabled ? 0.6 : 1)});
    font-weight: 500;
    font-size: 14px;
    background-color: black;
    min-width: 0;
    :focus {
        border: 1px solid ${({ theme }) => theme.colors.status.info};
    }
    :invalid {
        box-shadow: none;
    }
`;

interface RoundInputProps {
    autoFocus?: boolean;
    autoCapitalize?: "off" | "sentences" | "words" | "characters";
    disabled: boolean;
    autoComplete: string;
    text: string | null;
    type: string;
    step?: string | number;
    placeholder: string;
    alwaysShowBorder: boolean;
    borderRadius: number;
    borderColor: string;
    onTextChange?: (text: string) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    value?: string | string[] | number;
    className?: string;
}

interface State {
    errorOccurred: boolean;
}

/**
 * @deprecated Use `InputField` instead.
 */
export class RoundInput extends Component<RoundInputProps & React.HTMLAttributes<HTMLDivElement>, State> {
    // Properties

    public static defaultProps = {
        autoFocus: false,
        disabled: false,
        autoComplete: "",
        text: "",
        type: "text",
        step: undefined,
        placeholder: "",
        alwaysShowBorder: false,
        borderRadius: 100,
        borderColor: Colors.status.info,
        errorOccurred: false,
    };

    public get value(): string {
        if (this.input.current == null) {
            return "";
        }
        return this.input.current.value;
    }

    private readonly input: React.RefObject<HTMLInputElement>;

    // Lifecycle

    public constructor(props: RoundInputProps & React.HTMLAttributes<HTMLDivElement>) {
        super(props);
        this.input = React.createRef();
        this.state = {
            errorOccurred: false,
        };
    }

    public render(): JSX.Element {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { onTextChange, ...rest } = this.props;
        return (
            <Input
                onChange={this.onChange.bind(this)}
                onFocus={this.onFocus.bind(this)}
                ref={this.input}
                defaultValue={this.props.text || undefined}
                {...rest}
                borderColor={this.getBorderColor()}
                disabled={this.props.disabled}
                autoFocus={this.props.autoFocus}
                autoCapitalize={this.props.autoCapitalize}
            />
        );
    }

    // Public functions

    public showError(): void {
        this.setState({ errorOccurred: true });
    }

    public hideError(): void {
        this.setState({ errorOccurred: false });
    }

    // Private functions

    private onFocus(event: React.FocusEvent<HTMLInputElement>): void {
        this.hideError();
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    }

    private onChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.hideError();
        if (this.props.onTextChange) {
            this.props.onTextChange(event.target.value);
        }
    }

    private getBorderColor(): string {
        if (this.state.errorOccurred) {
            return Colors.status.danger;
        }
        if (!this.props.alwaysShowBorder) {
            return "black";
        }
        return this.props.borderColor;
    }
}
