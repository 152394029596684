import { LocalPreferencesRepository, ServerConfigRepository, SessionRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BaseViewModel } from "../BaseViewModel";
import { LocalUserPreferenceKeys, ServerConfig, SessionState } from "../../domain/model";
import { generateUUID } from "../../utils/UUID";

export class LoginViewModel extends BaseViewModel {
    // Properties

    public get isBusy(): Rx.Observable<boolean> {
        return this.isBusySubject.asObservable();
    }

    public get serverConfig(): Rx.Observable<ServerConfig> {
        return this.serverConfigRepository.config;
    }

    public get currentUsername(): Rx.Observable<string | undefined> {
        return this.sessionRepository.session.pipe(
            RxOperators.map((session) => {
                if (session?.state === SessionState.LoggingOutDueToUnauthorisedResponse) {
                    return this.sessionRepository.usernameForReauth;
                }
                return undefined;
            }),
        );
    }

    private isBusySubject = new Rx.BehaviorSubject<boolean>(false);
    private nonce: string;
    private authorizeUrlSubject = new Rx.Subject<string>();

    // Lifecycle

    public constructor(
        private readonly sessionRepository: SessionRepository,
        private readonly serverConfigRepository: ServerConfigRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
    ) {
        super();
        this.nonce = this.retrieveNonce();
    }

    // Public functions

    public login(username: string, password: string): Rx.Observable<void> {
        this.isBusySubject.next(true);
        return this.sessionRepository.loginBasicAuth(username, password).pipe(
            RxOperators.finalize(() => this.isBusySubject.next(false)),
            RxOperators.ignoreElements(),
        );
    }

    public getOauthAuthorizeUrl(): Rx.Observable<string> {
        return this.serverConfig.pipe(
            RxOperators.map((config) => {
                let params = [
                    `client_id=${config.clientId}`,
                    `scope=openid email`,
                    `response_type=code`,
                    `nonce=${this.generateNonce()}`,
                    `redirect_uri=${this.getOauthRedirectUrl()}`,
                    `state=${this.generateOauthState()}`,
                ].join("&");

                if (
                    config.ssoProvider === "MICROSOFT_ADFS" ||
                    (config.ssoProvider === "MICROSOFT_AZURE" && config.ssoVersion === 1)
                ) {
                    params += `&resource=${config.clientId}`;
                }
                if (config.ssoProvider !== "MICROSOFT_AZURE") {
                    params += `&prompt=consent`;
                }
                return `${config.authorizeUrl}?${params}`;
            }),
        );
    }

    public authenticate(oauthCode: string): Rx.Observable<void> {
        this.isBusySubject.next(true);
        const redirectUri = this.getOauthRedirectUrl();
        return this.sessionRepository.loginOpenIdConnect(oauthCode, redirectUri, this.nonce).pipe(
            RxOperators.finalize(() => this.isBusySubject.next(false)),
            RxOperators.ignoreElements(),
        );
    }

    public removeOauthState(): void {
        this.localPreferencesRepository.removePreference(LocalUserPreferenceKeys.user.oauthState);
    }

    // Private functions

    private getOauthRedirectUrl(): string {
        // No need to add `.port` here because the port is already included in the host
        const url = window.location.protocol + "//" + window.location.host + "/oauth";
        return url;
    }

    private generateNonce(): string {
        this.nonce = generateUUID();
        this.saveNonce(this.nonce);
        return this.nonce;
    }

    private generateOauthState(): string {
        const state = generateUUID();
        this.saveOauthState(state);
        return state;
    }

    private saveNonce(nonce: string): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.user.nonce, nonce);
    }

    private saveOauthState(state: string): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.user.oauthState, state);
    }

    private retrieveNonce(): string {
        const nonce = this.localPreferencesRepository.getPreference<string>(LocalUserPreferenceKeys.user.nonce);
        return nonce || this.generateNonce();
    }

    private retrieveOauthState(): string {
        const state = this.localPreferencesRepository.getPreference<string>(LocalUserPreferenceKeys.user.oauthState);
        return state || this.generateOauthState();
    }
}
