import * as Rx from "rxjs";
import { Classification, TrackObservation, Species, Track } from "../model";
import { StartableRepository } from "./BaseRepository";

export enum TrackObservationMode {
    None,
    SingleTrackObservation,
    MultiTrackObservation,
}

export interface TrackObservationRepository extends StartableRepository {
    mode: Rx.Observable<TrackObservationMode>;
    selectedTracks: Rx.Observable<Track[]>;
    selectedTracksClassification: Rx.Observable<Classification | undefined>;
    observedTrackIds: Rx.Observable<number[]>;
    speciesList: Rx.Observable<Species[]>;

    setMode(mode: TrackObservationMode): void;
    toggleTrack(track: Track, replayTimestamp?: long): void;
    submitSelectedTrackObservation(observation: TrackObservation): Rx.Observable<void>;
    clearSelectedTracks(): void;
}
