import React, { useEffect, useRef, useState } from "react";
import * as Rx from "rxjs";
import styled from "styled-components";
import { StatusValueContainer } from "./RadarStatus";
import ResetIcon from "../../../../res/images/ic_reset.svg";
import { t } from "i18next";
import { RoundButton } from "../../../appearance/RoundButton";
import { OldColors } from "../../../appearance/Colors";
import { showError } from "../../../../utils/MessageUtils";
import Tooltip from "../../../appearance/Tooltip";

const ResetButton = styled(RoundButton)`
    width: 28px;
    height: 28px;
    cursor: pointer;
    border-radius: 14px;
    margin-left: 4px;
    padding: 4px;
    position: absolute;
    margin-top: -2px;
    background-color: transparent;
    :hover {
        background-color: ${OldColors.primaryHoverTint};
    }
`;

const RADAR_SENSITIVITY_TOOLTIP_ID = "radar-sensitivity-tooltip";

interface Props {
    onResetSensitivityRequest: () => Rx.Observable<void>;
    status: number;
}

export const RadarSensitivity = ({ onResetSensitivityRequest, status }: Props): JSX.Element => {
    // Properties

    const [isResettingSensitivity, setIsResettingSensitivity] = useState(false);

    const subscriptionRef = useRef<Rx.Subscription>();
    useEffect(() => {
        const subscription = new Rx.Subscription();
        subscriptionRef.current = subscription;
        return () => subscription.unsubscribe();
    }, []);

    // Local functions

    const resetSensitivity = (): void => {
        setIsResettingSensitivity(true);
        subscriptionRef.current!.add(
            onResetSensitivityRequest().subscribe({
                complete: () => setIsResettingSensitivity(false),
                error: (error) => {
                    showError(error);
                    setIsResettingSensitivity(false);
                },
            }),
        );
    };

    // Render

    const stringValue = status != null ? status + " %" : "NA";

    return (
        <StatusValueContainer>
            <span>
                <label>{t("radarStatus.sensitivity")}</label>
                <ResetButton
                    data-for={RADAR_SENSITIVITY_TOOLTIP_ID}
                    data-tip={t("radarStatus.resetSensitivity")}
                    disabled={isResettingSensitivity}
                    isLoading={isResettingSensitivity}
                    onClick={() => resetSensitivity()}
                >
                    <img alt={t("radarStatus.resetSensitivity")} src={ResetIcon} />
                </ResetButton>
                <Tooltip id={RADAR_SENSITIVITY_TOOLTIP_ID} effect={"solid"} />
            </span>
            <strong>{stringValue}</strong>
        </StatusValueContainer>
    );
};
