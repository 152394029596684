import { t } from "i18next";
import { SelectOption } from "./SelectOption";

export enum AltitudeConfig {
    WGS84 = "wgs84",
    GROUND_LEVEL = "ground_level",
}

export const convertAltitudeConfigToOption = (altitudeConfig: AltitudeConfig): SelectOption => {
    let titleKey;
    switch (altitudeConfig) {
        case AltitudeConfig.WGS84:
            titleKey = "altitudeExplanation.wgs84";
            break;
        default:
            titleKey = "altitudeExplanation.groundLevel";
            break;
    }

    return {
        id: altitudeConfig,
        title: t(titleKey),
    };
};
