import * as Rx from "rxjs";
import { DistanceFormatter } from "../../../../domain/DistanceFormatter";
import { ADSBFlight, LocalUserPreferenceKeys, MeasurementPoint, Track } from "../../../../domain/model";
import { VelocityUnit } from "../../../../domain/model/VelocityUnit";
import {
    LocalPreferencesRepository,
    LocationInfoRepository,
    MeasurementPointsRepository,
    RadarRepository,
} from "../../../../domain/repositories";
import { TrackSelectionRepository } from "../../../../domain/repositories/TrackSelectionRepository";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { BaseViewModel } from "../../../BaseViewModel";

export class TrackInfoViewModel extends BaseViewModel {
    // Properties
    public get displayNegativeAltitudeAsZero(): Rx.Observable<boolean> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference(
                LocalUserPreferenceKeys.appearance.displayNegativeAltitudeAsZero,
            ),
        );
    }

    public get velocityUnit(): Rx.Observable<VelocityUnit> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<VelocityUnit>(
                LocalUserPreferenceKeys.appearance.velocityUnit,
            ),
        );
    }

    public get radarGroundLevel(): Rx.Observable<number | null> {
        return this.radarRepository.groundLevel;
    }

    public selectedTrack: Rx.Observable<Track | ADSBFlight | undefined> = this.trackSelectionRepository.selectedTrack;
    public measurementPoints: Rx.Observable<MeasurementPoint[]> = this.measurementPointsRepository.measurementPoints;
    public lastSnapshotTime: Rx.Observable<number> = this.trackSelectionRepository.lastSnapshotTime;
    public showAltitude: Rx.Observable<boolean> = this.locationInfoRepository.hasReliableAltitudeInfo;

    constructor(
        private readonly trackSelectionRepository: TrackSelectionRepository,
        private readonly measurementPointsRepository: MeasurementPointsRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly locationInfoRepository: LocationInfoRepository,
        private readonly radarRepository: RadarRepository,
        public readonly distanceFormatter: DistanceFormatter,
    ) {
        super();
    }

    // Public functions

    public deselectTracks(): void {
        this.trackSelectionRepository.deselectTracks();
    }
}
