import React, { useEffect, useState } from "react";
import { AlertViewFrame, AlertViewContainer } from "../appearance/AlertView";
import { OldColors } from "../appearance/Colors";
import styled from "styled-components";
import { VerticalSpace } from "../appearance/Space";
import { RoundButton } from "../appearance/RoundButton";
import { isInStandaloneMode } from "../../utils/PwaUtils";
import { t } from "i18next";

const commonStyling = `
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    line-height: normal;
    letter-spacing: normal;
`;

const PrimaryText = styled.div`
    ${commonStyling}
    color: ${({ theme }) => theme.colors.text.text};
`;

const SecondaryText = styled.div`
    ${commonStyling}
    color: ${OldColors.grey};
`;

export interface ErrorViewProps {
    primaryText: string;
    secondaryText?: string;
    allowRefresh?: boolean;
}

export const ErrorView = ({ primaryText, secondaryText, allowRefresh }: ErrorViewProps): React.ReactElement | null => {
    const [isPwa, setIsPwa] = useState(false);

    useEffect(() => {
        setIsPwa(isInStandaloneMode());
    }, []);

    return (
        <AlertViewContainer position="center" visible={true}>
            <AlertViewFrame backgroundColor={OldColors.black}>
                <PrimaryText>{primaryText}</PrimaryText>
                {secondaryText && (
                    <>
                        <VerticalSpace height={10} />
                        <SecondaryText>{secondaryText}</SecondaryText>
                    </>
                )}
                {(allowRefresh || isPwa) && (
                    <>
                        <VerticalSpace height={10} />
                        <RoundButton text={t("general.refresh")} onClick={() => window.location.reload()} />
                    </>
                )}
            </AlertViewFrame>
        </AlertViewContainer>
    );
};
