import React, { useCallback } from "react";
import * as Rx from "rxjs";
import { Button } from "../button/Button";
import { IconType } from "../Icon";
import { Switch } from "../button/Switch";
import { PropsWithTooltip } from "../Tooltip";
import { useObservable } from "../../../hooks";

interface Props {
    type: "switch" | IconType;
    isActive: boolean | Rx.Observable<boolean>;
    onToggle: (active: boolean) => void;
}

export const FormToggle = ({ type, isActive, onToggle, ...rest }: PropsWithTooltip<Props>): JSX.Element => {
    const value = typeof isActive === "boolean" ? isActive : useObservable(isActive, false);

    const handleToggle = useCallback(
        (event: { stopPropagation: () => void }): void => {
            event.stopPropagation();
            onToggle(!value);
        },
        [value, onToggle],
    );

    // Render

    return type === "switch" ? (
        <Switch isActive={value} onToggle={handleToggle} {...rest} />
    ) : (
        <Button active={value} iconType={type} layout="inline" onClick={handleToggle} {...rest} />
    );
};
