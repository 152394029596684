import React, { useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { AlertViewFrame } from "../appearance/AlertView";
import { ReactComponent as WarningIcon } from "../../res/images/warning.svg";
import { AlarmViewModel } from "./AlarmViewModel";
import { AlarmDataType } from "../../domain/model";

const AlarmIcon = styled(WarningIcon)`
    margin-right: 16px;
    color: white;
`;

const AlarmMessage = styled.span`
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white;
`;

interface Props {
    alarmType: AlarmDataType;
    message: string;
    viewModel: AlarmViewModel;
}

export default function AlarmRow({ message, viewModel, alarmType }: Props): React.ReactElement {
    const theme = useTheme();
    useEffect(() => {
        return () => viewModel.onMouseOutAlertBox(alarmType);
    }, []);
    return (
        <AlertViewFrame
            backgroundColor={theme.colors.status.danger}
            onMouseEnter={() => viewModel.onMouseOverAlertBox(alarmType)}
            onMouseLeave={() => viewModel.onMouseOutAlertBox(alarmType)}
        >
            <span>
                <AlarmIcon />
                <AlarmMessage>{message}</AlarmMessage>
            </span>
        </AlertViewFrame>
    );
}
