import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { ClassificationList } from "../../../../domain/model/proto/generated/classificationlist3_pb";
import {
    TrackData as TrackDataProto,
    TrackDataUpdates as TrackDataUpdatesProto,
    TrackUpdate as TrackUpdateProto,
    Radar as RadarProto,
    RadarUpdate as RadarUpdateProto,
    DroneAlarmType as DroneAlarmTypeProto,
    TrackAlertType,
} from "../../../../domain/model/proto/generated/tracklist3_pb";
import {
    TracksSnapshot,
    Classification,
    Radar,
    Track,
    Estimate,
    ServerUserPreferenceKeys,
    PreferencesMap,
    Location,
    RepositioningState,
    AlignmentState,
    BlankingSector,
    BlankingSectorsState,
    RadarOperatingMode,
    RadarMotionData,
} from "../../../../domain/model";
import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import {
    TrackRepository,
    RadarRepository,
    ServerPreferencesRepository,
    AbstractStartableRepository,
} from "../../../../domain/repositories";
import { RepeatableCallSubscription } from "../../../../utils/RepeatableCallSubscription";
import { TrackSelector } from "../../../../domain/repositories/impl/TrackSelector";
import {
    BlankingSector as BlankingSectorProto,
    GetRequest as BlankingSectorsGetRequest,
    SetRequest as BlankingSectorsSetRequest,
} from "../../../../domain/model/proto/generated/blankingsectors3_pb";
import { TrackUpdater } from "../../../../domain/repositories/impl/TrackUpdater";
import { GetStatusRequest as GetRadarStatusRequest } from "../../../../domain/model/proto/generated/radar3_pb";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { Cache, cachedRequest } from "../../../../utils/Cache";

export class BirdViewerTrackAndRadarRepository
    extends AbstractStartableRepository
    implements TrackRepository, RadarRepository
{
    // Properties
    public readonly shouldShowAltitudeFilterGuideline = true;
    public readonly classificationsMap: Rx.Observable<Map<string, Classification>>;
    public readonly tracksSnapshot: Rx.Observable<TracksSnapshot | null>;
    public readonly radars: Rx.Observable<Radar[]>;
    public readonly selectedRadarId: Rx.Observable<number>;
    public readonly canEditRadarName: boolean = false;
    public readonly canRepositionRadar: boolean = false;
    public readonly canAlignRadar: boolean = false;
    public readonly repositioningState: Rx.Observable<RepositioningState>;
    public readonly alignmentState: Rx.Observable<AlignmentState>;
    public readonly blankingSectorsState: Rx.Observable<BlankingSectorsState>;
    public readonly selectedTrackId: Rx.Observable<number | null>;
    public readonly isDynamicPositioningEnabled: Rx.Observable<boolean> = Rx.of(false);

    public get motionData(): Rx.Observable<RadarMotionData> {
        // Motion data is not supported in Bird Radar
        return Rx.EMPTY;
    }

    public readonly availableRadarOperatingModes: Rx.Observable<RadarOperatingMode[]> = Rx.of([]);
    public readonly radarHasMultipleOperatingModes: Rx.Observable<boolean> = Rx.of(false);

    private readonly classificationMapSubject = new Rx.BehaviorSubject<Map<string, Classification>>(new Map());
    private readonly tracksSnapshotSubject = new Rx.BehaviorSubject<TracksSnapshot | null>(null);
    private readonly radarsSubject = new Rx.BehaviorSubject(new Array<Radar>());
    private readonly selectedRadarIdSubject = new Rx.BehaviorSubject<long | null>(null);
    private readonly trackSelector = new TrackSelector();
    private readonly blankingSectorsStateSubject = new Rx.BehaviorSubject<BlankingSectorsState>({
        isActive: false,
    });
    private readonly trackUpdater = new TrackUpdater();

    private repeatableCallSubscription = new RepeatableCallSubscription();
    private subscriptions = new Rx.Subscription();

    public constructor(
        private readonly api: BirdViewerAPI,
        private readonly serverPreferencesRepository: ServerPreferencesRepository,
    ) {
        super();
        this.tracksSnapshot = this.tracksSnapshotSubject.asObservable();
        this.classificationsMap = this.classificationMapSubject.asObservable();
        this.radars = this.radarsSubject.asObservable();
        this.selectedRadarId = nonNullObservable(this.selectedRadarIdSubject.asObservable());
        this.selectedTrackId = this.trackSelector.selectedTrackId;
        this.repositioningState = Rx.NEVER;
        this.alignmentState = Rx.NEVER;
        this.blankingSectorsState = this.blankingSectorsStateSubject.asObservable();
    }

    // Public functions

    public start(): void {
        this.subscriptions = new Rx.Subscription();
        this.fetchClassifications();
    }

    public stop(): void {
        this.repeatableCallSubscription.dispose();
        this.subscriptions.unsubscribe();
        this.tracksSnapshotSubject.next(null);
        this.classificationMapSubject.next(new Map());
    }

    public loadReplay(
        startTimestamp: long,
        endTimestamp: long,
        cache: Cache<TracksSnapshot>,
    ): Rx.Observable<TracksSnapshot> {
        return cachedRequest(
            this.api
                .getReplayData(startTimestamp, endTimestamp)
                .pipe(RxOperators.map((data) => TracksSnapshot.fromProto(data, this.classificationMapSubject.value))),
            cache,
            `${startTimestamp}-${endTimestamp}`,
        );
    }

    public toggleSelectedTrackId(trackId: number | null): void {
        this.trackSelector.toggleSelectedTrackId(trackId);
    }

    public getDefaultApplyAltitudeFilterSetting(classification: Classification): boolean {
        return classification.isBird;
    }

    public selectRadar(radarId: long): void {
        this.selectedRadarIdSubject.next(radarId);
    }

    /* eslint-disable  @typescript-eslint/no-unused-vars */
    public startRadar(radarId: number): Rx.Observable<void> {
        throw new Error("Not implemented");
    }
    public stopRadar(radarId: number): Rx.Observable<void> {
        throw new Error("Not implemented");
    }
    public setRepositioningState(state: RepositioningState): void {
        throw new Error("Not implemented");
    }
    public savePositionForRadar(radarId: number, position: Location, groundLevel: number | null): Rx.Observable<void> {
        throw new Error("Not implemented");
    }
    public previewAlignment(angle: number, radar: Radar): void {
        throw new Error("Not implemented");
    }
    public stopPreviewAlignment(): void {
        throw new Error("Not implemented");
    }
    public saveNewAlignment(radarId: number, angle: number): Rx.Observable<void> {
        throw new Error("Not implemented");
    }
    public autoAlign(radarId: number): Rx.Observable<void> {
        throw new Error("Not implemented");
    }
    public autoPosition(radarId: number): Rx.Observable<void> {
        throw new Error("Not implemented");
    }
    public setRadarName(radarId: number, name: string): Rx.Observable<void> {
        throw new Error("Not implemented");
    }
    public resetSensitivity(radarId: number): Rx.Observable<void> {
        throw new Error("Method not implemented.");
    }
    public getMaxNumberOfBlankingSectors(radarId: number): Rx.Observable<number> {
        const request = new GetRadarStatusRequest();
        request.setRadarid(radarId);
        return this.api
            .getRadarStatus(request)
            .pipe(RxOperators.map((status) => status.getMaxnumberofblankingsectors()));
    }
    public getBlankingSectors(radarId: number): Rx.Observable<BlankingSector[]> {
        const request = new BlankingSectorsGetRequest();
        request.setRadarid(radarId);
        return this.api
            .getBlankingSectors(request)
            .pipe(
                RxOperators.map((list) =>
                    list.getSectorList().map((sectorsList) => BlankingSector.fromProto(sectorsList)),
                ),
            );
    }
    public setBlankingSectors(radarId: number, sectors: BlankingSector[]): Rx.Observable<void> {
        const request = new BlankingSectorsSetRequest();
        request.setRadarid(radarId);
        request.setSectorList(
            sectors.map((sector) => {
                const sectorProto = new BlankingSectorProto();
                sectorProto.setStartangledeg(sector.startAngle);
                sectorProto.setSpandeg(sector.span);
                return sectorProto;
            }),
        );
        return this.api.setBlankingSectors(request).pipe(RxOperators.ignoreElements());
    }
    public setBlankingSectorsState(state: BlankingSectorsState): void {
        this.blankingSectorsStateSubject.next(state);
    }
    public getAvailableRadarOperatingModes(): Rx.Observable<RadarOperatingMode[]> {
        // Just to make sure some value is sent back for evaluation
        return Rx.of([]);
    }
    public setRadarOperatingMode(modeId: string): Rx.Observable<void> {
        throw new Error("Method not implemented.");
    }
    public get groundLevel(): Rx.Observable<number | null> {
        return this.radars.pipe(
            Rx.map((radars) => {
                return radars?.[0]?.status?.groundLevel ?? null;
            }),
        );
    }

    /* eslint-enable  @typescript-eslint/no-unused-vars */

    // Private functions
    private restart(): void {
        this.stop();
        this.start();
    }

    private fetchClassifications(): void {
        const subscription = Rx.zip(
            this.api.getClassifications(),
            this.serverPreferencesRepository.preferencesMap,
        ).subscribe(
            ([classifications, preferences]) => this.handleFetchClassificationsResponse(classifications, preferences),
            (error) => this.retryIfStartedAndImplemented(() => this.fetchClassifications(), error),
        );
        this.subscriptions.add(subscription);
    }

    private fetchInitialTrackData(): void {
        const subscription = this.api.getInitialTrackData().subscribe(
            (response) => this.handleFetchInitialTrackDataResponse(response),
            (error) => this.retryIfStartedAndImplemented(() => this.fetchInitialTrackData(), error),
        );
        this.subscriptions.add(subscription);
    }

    private fetchTrackDataUpdates(): void {
        const subscription = this.api.getTrackDataUpdates(this.repeatableCallSubscription).subscribe(
            (response) => this.handleFetchTrackUpdatesResponse(response),
            (error) => this.retryIfStartedAndImplemented(() => this.fetchTrackDataUpdates(), error),
        );
        this.subscriptions.add(subscription);
    }

    private handleFetchClassificationsResponse(response: ClassificationList, preferences: PreferencesMap): void {
        const map = new Map<string, Classification>();
        response.getClassificationList().forEach((value) => {
            const classificationName = value.getClassification();
            const colorKey = ServerUserPreferenceKeys.track.classificationColors.forName(classificationName);
            let preferredColor = colorKey != null ? preferences.get(colorKey) : null;
            if (preferredColor === "") {
                preferredColor = null;
            }
            const overrides: Partial<Classification> = {
                defaultColor: preferredColor || value.getDefaultcolor(),
                name: classificationName,
            };
            map.set(classificationName, Classification.fromProto(value, overrides));
        });
        this.classificationMapSubject.next(map);
        this.fetchInitialTrackData();
    }

    private handleFetchInitialTrackDataResponse(response: TrackDataProto): void {
        this.updateRadars(response.getRadarList());
        const snapshot = TracksSnapshot.fromProto(response, this.classificationMapSubject.value);
        this.tracksSnapshotSubject.next(snapshot);
        this.fetchTrackDataUpdates();
    }

    private handleFetchTrackUpdatesResponse(response: TrackDataUpdatesProto): void {
        const lastSnapshot = this.tracksSnapshotSubject.value;
        const tracks = lastSnapshot ? new Map(lastSnapshot.tracks) : new Map<int, Track>();
        this.processUpdate(response, tracks);
        const snapshot = new TracksSnapshot(tracks, response.getRainPercentage(), response.getImageTimestampMsec());
        this.tracksSnapshotSubject.next(snapshot);
    }

    private processUpdate(response: TrackDataUpdatesProto, tracks: Map<int, Track>): void {
        const trackUpdates = response.getTracklistupdateList().flatMap((value) => value.getTrackupdateList());
        if (this.shouldResync(trackUpdates)) {
            console.info("Resync event was called");
            this.restart();
            return;
        }
        for (const trackUpdate of trackUpdates) {
            this.processTrackUpdate(trackUpdate, tracks);
        }
        this.processRadarUpdates(response.getRadarupdatesList());
    }

    private shouldResync(trackUpdates: TrackUpdateProto[]): boolean {
        return trackUpdates.some(
            (trackUpdate) => trackUpdate.getUpdatetypeCase() === TrackUpdateProto.UpdatetypeCase.RESYNCTRACKLIST,
        );
    }

    private processTrackUpdate(trackUpdate: TrackUpdateProto, tracks: Map<int, Track>): void {
        const trackId = trackUpdate.getTrackid();
        switch (trackUpdate.getUpdatetypeCase()) {
            case TrackUpdateProto.UpdatetypeCase.NEWTRACK:
                const newTrack = trackUpdate.getNewtrack()?.getTrack();
                if (newTrack) {
                    this.trackUpdater.processNewTrack(
                        tracks,
                        trackId,
                        Track.fromProto(newTrack, this.classificationMapSubject.value),
                    );
                }
                break;
            case TrackUpdateProto.UpdatetypeCase.ADDESTIMATE:
                const update = trackUpdate.getAddestimate();
                const protoEstimate = update?.getEstimate();
                if (update && protoEstimate && protoEstimate.getTimestampMsec() > 0) {
                    const trackPlotType = Estimate.plotTypeFrom(update.getTracktype());
                    const estimate = Estimate.fromProto(protoEstimate, null, []);
                    this.trackUpdater.processNewEstimation(tracks, trackId, estimate, trackPlotType);
                }
                break;
            case TrackUpdateProto.UpdatetypeCase.DROPTRACK:
                const droppedTrackId = trackUpdate.getDroptrack()?.getTrackid();
                if (droppedTrackId !== undefined) {
                    this.trackUpdater.processDropTrack(tracks, droppedTrackId);
                }
                break;
            case TrackUpdateProto.UpdatetypeCase.CLASSIFICATION:
                const classificationUpdate = trackUpdate.getClassification();
                if (classificationUpdate) {
                    this.trackUpdater.processClassificationChange(
                        tracks,
                        trackId,
                        classificationUpdate.getClassification(),
                        this.classificationMapSubject.value,
                    );
                }
                break;
            case TrackUpdateProto.UpdatetypeCase.FUSETRACK:
                const fusingTrackId = trackUpdate.getFusetrack()?.getFusingtrackid();
                if (fusingTrackId) {
                    this.trackUpdater.processFusedTrack(tracks, trackId, fusingTrackId);
                }
                break;
            case TrackUpdateProto.UpdatetypeCase.FLIGHTINFO:
                const flightInfo = trackUpdate.getFlightinfo();
                if (flightInfo) {
                    this.trackUpdater.processFlightInfo(
                        tracks,
                        trackId,
                        flightInfo.getIcao(),
                        Track.flightPhaseFrom(flightInfo.getFlightphase()),
                    );
                }
                break;
            case TrackUpdateProto.UpdatetypeCase.DRONEALARMCHANGE:
                const droneAlarmChange = trackUpdate.getDronealarmchange();
                if (droneAlarmChange) {
                    this.trackUpdater.processDroneAlarmChange(
                        tracks,
                        trackId,
                        droneAlarmChange.getAlarmtype() === DroneAlarmTypeProto.DRONE_ALARM,
                    );
                }
                break;
            case TrackUpdateProto.UpdatetypeCase.RESYNCTRACKLIST:
            case TrackUpdateProto.UpdatetypeCase.AREAENTRYEXITCHANGE:
                const areaEntryExitChange = trackUpdate.getAreaentryexitchange();
                if (areaEntryExitChange) {
                    const isAlarm = areaEntryExitChange.getAlerttype() === TrackAlertType.ALARM;
                    const overlayIds = areaEntryExitChange.getAreaentryexitsList().map((a) => a.getAreaname());
                    this.trackUpdater.processAreaExitEntryChange(tracks, trackId, isAlarm, overlayIds);
                }
                break;
            case TrackUpdateProto.UpdatetypeCase.DATABASEIDCHANGE:
                const databaseIdChange = trackUpdate.getDatabaseidchange();
                if (databaseIdChange) {
                    this.trackUpdater.processDatabaseIdChange(tracks, trackId, databaseIdChange.getDbtrackid());
                }
                break;
            case TrackUpdateProto.UpdatetypeCase.ADDOBSERVATION:
            case TrackUpdateProto.UpdatetypeCase.UPDATETYPE_NOT_SET:
            default:
                break;
        }
    }

    private updateRadars(radarList: RadarProto[]): void {
        const radars = radarList.map((value) => Radar.fromProto(value));
        this.radarsSubject.next(radars);

        // Select first value from the list of radars if none already selected, or if the selected radar is not in the list
        if (
            this.selectedRadarIdSubject.value == null ||
            radars.every((radar) => radar.id !== this.selectedRadarIdSubject.value)
        ) {
            this.selectRadar(radars[0].id);
        }
    }

    private processRadarUpdates(radarUpdates: RadarUpdateProto[]): void {
        const radars = this.radarsSubject.value;
        radarUpdates.forEach((radarUpdate) => {
            const radarId = radarUpdate.getSiteId();
            const index = radars.findIndex((r) => r.id === radarId);
            if (index > -1) {
                radars[index] = Radar.updateFromProto(radars[index], radarUpdate);
            }
        });
        this.radarsSubject.next(radars);
    }
}
