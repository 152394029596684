import mapboxgl from "mapbox-gl";
import { ORDER_LAYER_TRACKS } from "../Orders";
import { ADSBFlightsModuleViewModel as ADSBFlightsModuleViewModel } from "./ADSBFlightsModuleViewModel";
import { Location, ADSBFlight } from "../../../../domain/model";
import { DistanceFormatter } from "../../../../domain/DistanceFormatter";
import { LayerManager } from "../LayerManager";
import { ADSBFlightsLayer as ADSBFlightsLayer } from "../../layers";
import { TrackLayerOptions } from "../../layers/tracks/BaseTracksLayer";
import { PlaybackState } from "../../../../domain/PlaybackScene";
import { BaseTracksModule } from "./BaseTracksModule";
import * as Rx from "rxjs";
import { FlavorConfig } from "../../../../infrastructure/FlavorConfig";

export class ADSBFlightsModule extends BaseTracksModule<ADSBFlightsModuleViewModel, ADSBFlight> {
    public constructor(
        protected readonly viewModel: ADSBFlightsModuleViewModel,
        private readonly distanceFormatter: DistanceFormatter,
        private readonly adsbFlightsLayerOptions: TrackLayerOptions<ADSBFlight>,
        private readonly flavorConfig: FlavorConfig,
    ) {
        super(viewModel);
    }

    // Public functions

    public setup(map: mapboxgl.Map, referenceLocation: Location, layerManager: LayerManager): void {
        super.setup(map, referenceLocation, layerManager);

        const layer = ADSBFlightsLayer.attachedTo(
            map,
            ORDER_LAYER_TRACKS.id,
            this.distanceFormatter,
            this.adsbFlightsLayerOptions,
            this.onTrackSelected,
            this.flavorConfig,
        );

        layer.referenceAltitude = referenceLocation.altitude;
        const vm = this.viewModel;
        this.collectSubscriptions(
            vm.finishedTrackOpacity.subscribe((value) => layer.setFinishedTrackOpacity(value)),
            vm.showClassificationHistoryInTrajectory.subscribe((value) =>
                layer.setClassificationHistoryOnTrajectoryEnabled(value),
            ),
            Rx.combineLatest([vm.playbackState, vm.visibleEmitterCategories]).subscribe({
                next: ([state, visibleEmitterCategories]) => {
                    if (state != null && state !== PlaybackState.STOPPED) {
                        layer.setVisibility(false);
                    } else {
                        layer.setVisibleEmitterCategories(visibleEmitterCategories);
                    }
                },
                error: (error) => console.error(error),
            }),
            vm.applyAltitudeFilterEmitterCategories.subscribe((emitterCategories) =>
                layer.setApplyAltitudeFilterEmitterCategories(emitterCategories),
            ),
        );
        this.subscribeToCommonEvents(layer);
    }
}
