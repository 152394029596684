import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { LocationInfoRepository, TrackRepository } from "../../../../domain/repositories";
import { DateFormatter } from "../../../../infrastructure/DateFormatter";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { BaseViewModel } from "../../../BaseViewModel";

export interface TimeDifferenceUpdate {
    difference: number;
    text: string;
}

export class TimeViewModel extends BaseViewModel {
    // Properties

    public get time(): Rx.Observable<string> {
        return this.dateFormatter.formatFullDateObservable(nonNullObservable(this.timeSubject), {
            excludeDateToday: true,
        });
    }

    private timeSubject = new Rx.BehaviorSubject<Date | null>(null);

    public constructor(
        private readonly dateFormatter: DateFormatter,
        private readonly trackRepository: TrackRepository,
        private readonly locationInfoRepository: LocationInfoRepository,
    ) {
        super();

        this.collectSubscriptions(this.subscribeDisplayTime());
    }

    // Private functions

    private subscribeDisplayTime(): Rx.Subscription {
        const snapshotTimestamp = nonNullObservable(this.trackRepository.tracksSnapshot).pipe(
            RxOperators.map((s) => s.timestamp),
        );
        const systemTimestamp = Rx.timer(0, 1000).pipe(RxOperators.map(() => Date.now()));
        return this.locationInfoRepository.isSimulation
            .pipe(Rx.switchMap((isSimulation) => (isSimulation ? snapshotTimestamp : systemTimestamp)))
            .subscribe((timestamp) => this.broadcastTime(timestamp));
    }

    private broadcastTime(timestamp: long): void {
        const date = new Date(timestamp);
        this.timeSubject.next(date);
    }
}
