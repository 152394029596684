import React from "react";
import styled, { withTheme } from "styled-components";
import { CheckBox } from "../../../appearance/CheckBox";
import { t } from "i18next";
import { Button } from "../../../appearance/button/Button";
import { InteractionMode } from "./AlarmHistory";
import { Theme } from "../../../appearance/theme/Theme";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.x2};
`;

interface Props {
    interactionMode: InteractionMode;
    label: string;
    isOpen: boolean;
    checked: boolean;
    onCheckedChange: (checked: boolean) => void;
    onClick: (isOpen: boolean) => void;
    theme: Theme;
}

export class DayComponent extends React.Component<Props> {
    public render(): React.ReactNode {
        return (
            <Container>
                {this.props.interactionMode === InteractionMode.SELECTION && (
                    <CheckBox
                        color={this.props.theme.colors.status.danger}
                        style={{
                            marginRight: this.props.theme.spacing.x2,
                        }}
                        value={this.props.checked}
                        onChange={(isOn) => this.props.onCheckedChange(isOn)}
                    />
                )}
                <Button
                    color="neutral"
                    iconType={this.props.isOpen ? "CHEVRON_UP" : "CHEVRON_DOWN"}
                    iconPosition="right"
                    label={this.props.label}
                    layout="inline"
                    onClick={() => this.props.onClick(this.props.isOpen)}
                    tooltip={t("general.toggleClickToExpandCollapse")}
                />
            </Container>
        );
    }
}

export const Day = withTheme(DayComponent);
