import React from "react";
import _ from "lodash";
import DI from "../../../../di/DI";
import { TYPES } from "../../../../di/Types";
import { BaseSubscriptionHandlerComponent } from "../../../BaseSubscriptionHandlerComponent";
import { AlarmHistoryViewModel } from "./AlarmHistoryViewModel";
import { Alarm } from "../../../../domain/model";
import { Collapse } from "react-collapse";
import { Day } from "./Day";
import { DateFormatter } from "../../../../infrastructure/DateFormatter";
import { DAY } from "../../../../utils/DateTimeUtils";
import { t } from "i18next";
import { Button } from "../../../appearance/button/Button";
import { ButtonGroup } from "../../../appearance/button/ButtonGroup";
import { PanelDescription } from "../../../appearance/panels/PanelText";
import { HistoricEvent } from "./Event";

export enum InteractionMode {
    NORMAL,
    SELECTION,
}

interface State {
    mode: InteractionMode;
    alarms: Alarm[];
    selectedAlarmDatabaseIds: int[];
    expandedDayUniqueIds: string[];
    selectedTrackForPlayback: number | null;
}

export class AlarmHistory extends BaseSubscriptionHandlerComponent<{}, State> {
    // Properties

    private viewModel: AlarmHistoryViewModel = DI.get(TYPES.AlarmHistoryViewModel);
    private dateFormatter = DI.get<DateFormatter>(TYPES.DateFormatter);

    public constructor(props: Readonly<{}>) {
        super(props);
        this.state = {
            alarms: [],
            expandedDayUniqueIds: [],
            mode: InteractionMode.NORMAL,
            selectedAlarmDatabaseIds: [],
            selectedTrackForPlayback: null,
        };
    }

    public render(): React.ReactNode {
        const selectButtonLabel =
            this.state.mode === InteractionMode.NORMAL ? t("general.select") : t("general.cancel");

        return this.state.alarms.length === 0 ? (
            <PanelDescription>{t("alarmHistory.noResults")}</PanelDescription>
        ) : (
            <>
                <ButtonGroup>
                    {this.state.mode === InteractionMode.SELECTION &&
                        this.state.selectedAlarmDatabaseIds.length > 0 && (
                            <Button
                                color="danger"
                                label={`${t("general.delete")} (${this.state.selectedAlarmDatabaseIds.length})`}
                                layout="inline"
                                onClick={() => {
                                    this.deleteSelectedAlarms();
                                    this.toggleInteractionMode();
                                }}
                                tooltip={t("general.delete")}
                            />
                        )}
                    <Button
                        color="info"
                        disabled={this.state.alarms.length === 0}
                        label={selectButtonLabel}
                        layout="inline"
                        onClick={() => this.toggleInteractionMode()}
                        tooltip={selectButtonLabel}
                    />
                </ButtonGroup>
                {this.renderAlarmsTree(this.state.alarms)}
            </>
        );
    }

    public componentDidMount(): void {
        this.collectSubscriptions(
            this.viewModel.history.subscribe((alarms) => {
                this.setState({ alarms: alarms });
            }),
            // Clear the selected track for playback when the user stops replaying
            this.viewModel.isNotReplaying.subscribe(
                (isNotReplaying) => isNotReplaying && this.setState({ selectedTrackForPlayback: null }),
            ),
        );
    }

    // Private functions

    private toggleInteractionMode(): void {
        switch (this.state.mode) {
            case InteractionMode.NORMAL:
                this.setState({ mode: InteractionMode.SELECTION });
                break;
            case InteractionMode.SELECTION:
                this.setState({
                    mode: InteractionMode.NORMAL,
                    selectedAlarmDatabaseIds: [],
                });
                break;
        }
    }

    private deleteSelectedAlarms(): void {
        this.deleteAlarms(this.state.selectedAlarmDatabaseIds);
    }

    private deleteAlarms(databaseIds: number[]): void {
        const subscription = this.viewModel
            .deleteAlarms(databaseIds)
            .subscribe({ error: (error) => console.error(error) });
        this.collectSubscription(subscription);
    }

    private renderAlarmsTree(alarms: Alarm[]): React.ReactNode {
        const sortedAlarms = alarms.sort((a, b) => this.getStartTimestamp(b) - this.getStartTimestamp(a));
        const alarmsByDay = _.sortBy(
            _.groupBy(sortedAlarms, (alarm) => this.getDayUniqueId(this.getStartTimestamp(alarm))),
            (alarmsForDay) => this.getStartTimestamp(alarmsForDay[0]),
        ).reverse();
        return _.map(alarmsByDay, (alarmsForDay) => this.renderDayHeader(alarmsForDay));
    }

    private renderDayHeader(alarms: Alarm[]): React.ReactNode {
        const dayTimestamp = this.getStartTimestamp(alarms[0]) - (this.getStartTimestamp(alarms[0]) % DAY);
        const dayId = this.getDayUniqueId(dayTimestamp);
        const label = this.dateFormatter.formatRelativeDate(new Date(dayTimestamp));
        const isOpen = this.state.expandedDayUniqueIds.includes(dayId);

        return (
            <div key={dayId}>
                <Day
                    interactionMode={this.state.mode}
                    checked={alarms.every((alarm) =>
                        this.state.selectedAlarmDatabaseIds.includes(this.getAlarmDatabaseId(alarm)),
                    )}
                    isOpen={isOpen}
                    label={`${label} (${alarms.length})`}
                    onClick={(isOpen) => this.toggleDay(dayId, isOpen)}
                    onCheckedChange={(checked) => this.setAlarmsChecked(alarms, checked)}
                />
                <Collapse isOpened={isOpen}>
                    {alarms.map((alarm) => (
                        <HistoricEvent
                            checked={this.state.selectedAlarmDatabaseIds.includes(this.getAlarmDatabaseId(alarm))}
                            eventData={alarm.alarmData}
                            interactionMode={this.state.mode}
                            isReplaying={this.state.selectedTrackForPlayback === alarm.track.databaseId}
                            onCheckedChange={(checked) => this.setAlarmsChecked([alarm], checked)}
                            onDelete={() => this.deleteAlarms([this.getAlarmDatabaseId(alarm)])}
                            onRequestReplay={() => this.requestReplay(alarm)}
                            startTime={this.formatStartTime(alarm)}
                            key={this.getAlarmDatabaseId(alarm)}
                        />
                    ))}
                </Collapse>
            </div>
        );
    }

    private getStartTimestamp(alarm: Alarm): long {
        return alarm.track.startTime || 0;
    }

    private getDayUniqueId(timestamp: long): string {
        return Math.floor(timestamp / DAY).toString();
    }

    private getAlarmDatabaseId(alarm: Alarm): number {
        return alarm.track.databaseId;
    }

    private toggleDay(dayId: string, isOpen: boolean): void {
        const expandedDays = [...this.state.expandedDayUniqueIds].filter((id) => id !== dayId);
        if (!isOpen) {
            expandedDays.push(dayId);
        }
        this.setState({ expandedDayUniqueIds: expandedDays });
    }

    private setAlarmsChecked(alarms: Alarm[], checked: boolean): void {
        const alarmTrackDatabaseIds = alarms.map((alarm) => this.getAlarmDatabaseId(alarm));
        let selectedTrackDatabaseIds = [...this.state.selectedAlarmDatabaseIds].filter(
            (id) => !alarmTrackDatabaseIds.includes(id),
        );
        if (checked) {
            selectedTrackDatabaseIds = selectedTrackDatabaseIds.concat(alarmTrackDatabaseIds);
        }
        this.setState({ selectedAlarmDatabaseIds: selectedTrackDatabaseIds });
    }

    private formatStartTime(alarm: Alarm): string {
        return this.dateFormatter.formatTime(new Date(this.getStartTimestamp(alarm)));
    }

    private requestReplay(alarm: Alarm): void {
        this.viewModel.requestReplay(alarm);
        this.setState({ selectedTrackForPlayback: alarm.track.databaseId });
    }
}
