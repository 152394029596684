// source: blankingsectors3.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.robin.proto3.blankingsectors.BlankingSector', null, global);
goog.exportSymbol('proto.robin.proto3.blankingsectors.GetRequest', null, global);
goog.exportSymbol('proto.robin.proto3.blankingsectors.List', null, global);
goog.exportSymbol('proto.robin.proto3.blankingsectors.SetRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.blankingsectors.BlankingSector = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.blankingsectors.BlankingSector, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.blankingsectors.BlankingSector.displayName = 'proto.robin.proto3.blankingsectors.BlankingSector';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.blankingsectors.List = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.blankingsectors.List.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.blankingsectors.List, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.blankingsectors.List.displayName = 'proto.robin.proto3.blankingsectors.List';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.blankingsectors.SetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.blankingsectors.SetRequest.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.blankingsectors.SetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.blankingsectors.SetRequest.displayName = 'proto.robin.proto3.blankingsectors.SetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.blankingsectors.GetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.blankingsectors.GetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.blankingsectors.GetRequest.displayName = 'proto.robin.proto3.blankingsectors.GetRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.blankingsectors.BlankingSector.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.blankingsectors.BlankingSector.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.blankingsectors.BlankingSector} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.blankingsectors.BlankingSector.toObject = function(includeInstance, msg) {
  var f, obj = {
    startangledeg: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    spandeg: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.blankingsectors.BlankingSector}
 */
proto.robin.proto3.blankingsectors.BlankingSector.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.blankingsectors.BlankingSector;
  return proto.robin.proto3.blankingsectors.BlankingSector.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.blankingsectors.BlankingSector} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.blankingsectors.BlankingSector}
 */
proto.robin.proto3.blankingsectors.BlankingSector.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStartangledeg(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpandeg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.blankingsectors.BlankingSector.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.blankingsectors.BlankingSector.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.blankingsectors.BlankingSector} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.blankingsectors.BlankingSector.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartangledeg();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getSpandeg();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float startAngleDeg = 1;
 * @return {number}
 */
proto.robin.proto3.blankingsectors.BlankingSector.prototype.getStartangledeg = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.blankingsectors.BlankingSector} returns this
 */
proto.robin.proto3.blankingsectors.BlankingSector.prototype.setStartangledeg = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float spanDeg = 2;
 * @return {number}
 */
proto.robin.proto3.blankingsectors.BlankingSector.prototype.getSpandeg = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.blankingsectors.BlankingSector} returns this
 */
proto.robin.proto3.blankingsectors.BlankingSector.prototype.setSpandeg = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.blankingsectors.List.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.blankingsectors.List.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.blankingsectors.List.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.blankingsectors.List} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.blankingsectors.List.toObject = function(includeInstance, msg) {
  var f, obj = {
    sectorList: jspb.Message.toObjectList(msg.getSectorList(),
    proto.robin.proto3.blankingsectors.BlankingSector.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.blankingsectors.List}
 */
proto.robin.proto3.blankingsectors.List.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.blankingsectors.List;
  return proto.robin.proto3.blankingsectors.List.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.blankingsectors.List} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.blankingsectors.List}
 */
proto.robin.proto3.blankingsectors.List.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.blankingsectors.BlankingSector;
      reader.readMessage(value,proto.robin.proto3.blankingsectors.BlankingSector.deserializeBinaryFromReader);
      msg.addSector(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.blankingsectors.List.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.blankingsectors.List.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.blankingsectors.List} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.blankingsectors.List.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSectorList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.blankingsectors.BlankingSector.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BlankingSector sector = 1;
 * @return {!Array<!proto.robin.proto3.blankingsectors.BlankingSector>}
 */
proto.robin.proto3.blankingsectors.List.prototype.getSectorList = function() {
  return /** @type{!Array<!proto.robin.proto3.blankingsectors.BlankingSector>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.blankingsectors.BlankingSector, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.blankingsectors.BlankingSector>} value
 * @return {!proto.robin.proto3.blankingsectors.List} returns this
*/
proto.robin.proto3.blankingsectors.List.prototype.setSectorList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.blankingsectors.BlankingSector=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.blankingsectors.BlankingSector}
 */
proto.robin.proto3.blankingsectors.List.prototype.addSector = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.blankingsectors.BlankingSector, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.blankingsectors.List} returns this
 */
proto.robin.proto3.blankingsectors.List.prototype.clearSectorList = function() {
  return this.setSectorList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.blankingsectors.SetRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.blankingsectors.SetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.blankingsectors.SetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.blankingsectors.SetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.blankingsectors.SetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    radarid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sectorList: jspb.Message.toObjectList(msg.getSectorList(),
    proto.robin.proto3.blankingsectors.BlankingSector.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.blankingsectors.SetRequest}
 */
proto.robin.proto3.blankingsectors.SetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.blankingsectors.SetRequest;
  return proto.robin.proto3.blankingsectors.SetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.blankingsectors.SetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.blankingsectors.SetRequest}
 */
proto.robin.proto3.blankingsectors.SetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRadarid(value);
      break;
    case 2:
      var value = new proto.robin.proto3.blankingsectors.BlankingSector;
      reader.readMessage(value,proto.robin.proto3.blankingsectors.BlankingSector.deserializeBinaryFromReader);
      msg.addSector(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.blankingsectors.SetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.blankingsectors.SetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.blankingsectors.SetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.blankingsectors.SetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRadarid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSectorList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.robin.proto3.blankingsectors.BlankingSector.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 radarId = 1;
 * @return {number}
 */
proto.robin.proto3.blankingsectors.SetRequest.prototype.getRadarid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.blankingsectors.SetRequest} returns this
 */
proto.robin.proto3.blankingsectors.SetRequest.prototype.setRadarid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated BlankingSector sector = 2;
 * @return {!Array<!proto.robin.proto3.blankingsectors.BlankingSector>}
 */
proto.robin.proto3.blankingsectors.SetRequest.prototype.getSectorList = function() {
  return /** @type{!Array<!proto.robin.proto3.blankingsectors.BlankingSector>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.blankingsectors.BlankingSector, 2));
};


/**
 * @param {!Array<!proto.robin.proto3.blankingsectors.BlankingSector>} value
 * @return {!proto.robin.proto3.blankingsectors.SetRequest} returns this
*/
proto.robin.proto3.blankingsectors.SetRequest.prototype.setSectorList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.robin.proto3.blankingsectors.BlankingSector=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.blankingsectors.BlankingSector}
 */
proto.robin.proto3.blankingsectors.SetRequest.prototype.addSector = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.robin.proto3.blankingsectors.BlankingSector, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.blankingsectors.SetRequest} returns this
 */
proto.robin.proto3.blankingsectors.SetRequest.prototype.clearSectorList = function() {
  return this.setSectorList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.blankingsectors.GetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.blankingsectors.GetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.blankingsectors.GetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.blankingsectors.GetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    radarid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.blankingsectors.GetRequest}
 */
proto.robin.proto3.blankingsectors.GetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.blankingsectors.GetRequest;
  return proto.robin.proto3.blankingsectors.GetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.blankingsectors.GetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.blankingsectors.GetRequest}
 */
proto.robin.proto3.blankingsectors.GetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRadarid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.blankingsectors.GetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.blankingsectors.GetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.blankingsectors.GetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.blankingsectors.GetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRadarid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 radarId = 1;
 * @return {number}
 */
proto.robin.proto3.blankingsectors.GetRequest.prototype.getRadarid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.blankingsectors.GetRequest} returns this
 */
proto.robin.proto3.blankingsectors.GetRequest.prototype.setRadarid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.robin.proto3.blankingsectors);
