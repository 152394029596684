import mapboxgl from "mapbox-gl";
import { MapModule } from "../MapModule";
import { RadarsModuleViewModel } from "./RadarsModuleViewModel";
import { ORDER_LAYER_RADAR_RANGE, ORDER_LAYER_RADARS, ORDER_LAYER_VERTICAL_RADAR_AZIMUTH } from "../Orders";
import { RadarsLayer } from "../../layers";
import * as RxOperators from "rxjs/operators";
import { Location } from "../../../../domain/model";
import { RadarRangeLayer } from "../../layers/RadarRangeLayer";
import { LayerManager } from "../LayerManager";
import { VerticalRadarAzimuthLayer } from "../../layers/VerticalRadarAzimuthLayer";

export class RadarsModule extends MapModule<RadarsModuleViewModel> {
    // Properties

    public constructor(protected readonly viewModel: RadarsModuleViewModel) {
        super();
    }

    // Public functions

    public setup(map: mapboxgl.Map, referenceLocation: Location, layerManager: LayerManager): void {
        super.setup(map, referenceLocation, layerManager);

        const radarsLayer = RadarsLayer.attachedTo(map, ORDER_LAYER_RADARS.id);
        const radarRangeLayer = RadarRangeLayer.attachedTo(map, ORDER_LAYER_RADAR_RANGE.id);

        this.collectSubscriptions(
            this.viewModel.radarUpdates.subscribe({
                next: (updates) => radarsLayer.setRadarUpdates(updates),
                error: (error) => console.error(error),
            }),
            this.viewModel.radarUpdates.subscribe({
                next: (updates) =>
                    radarRangeLayer.setCenterPositions(
                        ...updates.map((u) => ({ id: u.radarId, position: u.position })),
                    ),
            }),
            this.viewModel.shouldShowRadarRangeById.subscribe((value) => radarRangeLayer.setEnabled(value)),
            this.viewModel.radarRangeRingsOpacity.subscribe((value) => radarRangeLayer.setOpacity(value)),
            this.viewModel.radarRangeMarkers.subscribe((value) => radarRangeLayer.setRangeMarkers(value)),
        );

        if (this.viewModel.shouldAddVerticalRadarAzimuthLayer) {
            const verticalRadarAzimuthLayer = VerticalRadarAzimuthLayer.attachedTo(
                map,
                ORDER_LAYER_VERTICAL_RADAR_AZIMUTH.id,
            );
            this.collectSubscriptions(
                this.viewModel.radarUpdates
                    .pipe(RxOperators.map((updates) => updates.filter(({ isVertical }) => isVertical)))
                    .subscribe({
                        next: (updates) => verticalRadarAzimuthLayer.setRadarUpdates(updates),
                    }),
                this.viewModel.shouldShowVerticalRadarAzimuthLayer.subscribe((value) =>
                    verticalRadarAzimuthLayer.setEnabled(value),
                ),
            );
        }
    }
}
