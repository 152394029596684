import { LocalPreferencesRepository, ReplayRepository, TrackRepository } from "../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { Estimate, LocalUserPreferenceKeys, Track, TracksSnapshot } from "../../domain/model";
import { BaseViewModel } from "../BaseViewModel";
import { nonNullObservable } from "../../utils/RxUtils";
import { DistanceFormatFunction, DistanceFormatter, DistanceFormatType } from "../../domain/DistanceFormatter";
import { DistanceUnit } from "../../domain/model/DistanceUnit";
import _ from "lodash";
import { AltitudeSliderConfig, FlavorConfig } from "../../infrastructure/FlavorConfig";

// We only want to count the tracks which their last estimate is from "just a moment" ago.
// This 2 seconds here makes up for possible connection problems.
const VALID_TIME_OFFSET_OF_LAST_ALTITUDE_MILLIS = 2000;

export interface AltitudeGuide {
    altitude: number;
    label: string;
}

export class AltitudeSliderViewModel extends BaseViewModel {
    // Properties

    public get shouldShowGuideline(): boolean {
        return this.trackRepository.shouldShowAltitudeFilterGuideline;
    }
    public get minObservable(): Rx.Observable<number> {
        return this.configObservable.pipe(
            RxOperators.map((config) =>
                this.distanceFormatter.convertValueFromCurrentUnit(
                    config.lowerLimit,
                    DistanceUnit.METRIC,
                    DistanceFormatFunction.ROUND_DECIMAL_2,
                ),
            ),
        );
    }
    public get maxObservable(): Rx.Observable<number> {
        return this.configObservable.pipe(
            RxOperators.map((config) =>
                this.distanceFormatter.convertValueFromCurrentUnit(
                    config.upperLimit,
                    DistanceUnit.METRIC,
                    DistanceFormatFunction.ROUND_DECIMAL_2,
                ),
            ),
        );
    }
    public get stepObservable(): Rx.Observable<number> {
        return this.configObservable.pipe(
            RxOperators.map((config) =>
                this.distanceFormatter.convertValueFromCurrentUnit(
                    config.stepSize,
                    DistanceUnit.METRIC,
                    DistanceFormatFunction.ROUND_DECIMAL_2,
                ),
            ),
        );
    }
    public get currentConfig(): AltitudeSliderConfig {
        return this.distanceFormatter.selectedDistanceUnit === DistanceUnit.METRIC
            ? this.flavorConfig.altitudeSliderConfig.metric
            : this.flavorConfig.altitudeSliderConfig.imperial;
    }

    public get formattedMaxAltitudeOfInterest(): Rx.Observable<number> {
        return this.distanceFormatter
            .formatObservable(this.getMaxAltitudeOfInterestObservable(), (value, formatter) =>
                formatter(value, DistanceUnit.METRIC, {
                    formatFunction: DistanceFormatFunction.ROUND_DECIMAL_2,
                    formatType: DistanceFormatType.NONE,
                }),
            )
            .pipe(RxOperators.map((value) => Number(value)));
    }

    public get formattedMinAltitudeOfInterest(): Rx.Observable<number> {
        return this.distanceFormatter
            .formatObservable(this.getMinAltitudeOfInterestObservable(), (value, formatter) =>
                formatter(value, DistanceUnit.METRIC, {
                    formatFunction: DistanceFormatFunction.ROUND_DECIMAL_2,
                    formatType: DistanceFormatType.NONE,
                }),
            )
            .pipe(RxOperators.map((value) => Number(value)));
    }

    private readonly configObservable = this.distanceFormatter.selectedDistanceUnitObservable.pipe(
        RxOperators.map((unit) =>
            unit === DistanceUnit.METRIC
                ? this.flavorConfig.altitudeSliderConfig.metric
                : this.flavorConfig.altitudeSliderConfig.imperial,
        ),
    );
    private altitudeToTrackDensityMapSubject = new Rx.BehaviorSubject<Map<number, number>>(new Map());
    private aircraftAltitudesSubject = new Rx.BehaviorSubject<number[]>([]);

    // Lifecycle

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly trackRepository: TrackRepository,
        private readonly replayRepository: ReplayRepository,
        private readonly distanceFormatter: DistanceFormatter,
        private readonly flavorConfig: FlavorConfig,
    ) {
        super();
        this.subscribeToTrackUpdates();
        this.subscribeToDistanceUnitChange();
    }

    // Public functions

    public setMinAltitudeOfInterest(value: number | null): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.filters.minAltitudeOfInterest, value);
    }

    public setMaxAltitudeOfInterest(value: number | null): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.filters.maxAltitudeOfInterest, value);
    }

    public getMinAltitudeOfInterestObservable(): Rx.Observable<number> {
        return this.localPreferencesRepository
            .observePreference<number>(LocalUserPreferenceKeys.filters.minAltitudeOfInterest)
            .pipe(RxOperators.switchMap((value) => (value == null ? this.minObservable : Rx.of(value))));
    }

    public getMaxAltitudeOfInterestObservable(): Rx.Observable<number> {
        return this.localPreferencesRepository
            .observePreference<number>(LocalUserPreferenceKeys.filters.maxAltitudeOfInterest)
            .pipe(RxOperators.switchMap((value) => (value == null ? this.maxObservable : Rx.of(value))));
    }

    public getAltitudeToTrackDensityObservable(): Rx.Observable<Map<number, number>> {
        return this.altitudeToTrackDensityMapSubject.asObservable().pipe(RxOperators.distinctUntilChanged());
    }

    public getAircraftAltitudesObservable(): Rx.Observable<number[]> {
        return this.aircraftAltitudesSubject.asObservable().pipe(RxOperators.distinctUntilChanged());
    }

    public getHandleLabels(): Rx.Observable<string[]> {
        return Rx.combineLatest([
            this.getMaxAltitudeOfInterestObservable(),
            this.maxObservable,
            this.getMinAltitudeOfInterestObservable(),
        ]).pipe(
            RxOperators.map(([maxAltitudeOfInterest, max, minAltitudeOfInterest]) => {
                const formatType =
                    maxAltitudeOfInterest === max ? DistanceFormatType.ABOVE_SPACED : DistanceFormatType.SPACED;
                const formatFunction = DistanceFormatFunction.ROUND_DECIMAL_2;
                const top = this.distanceFormatter.formatValueWithCurrentUnit(
                    maxAltitudeOfInterest,
                    DistanceUnit.METRIC,
                    { formatType, formatFunction },
                );

                const bottom = this.distanceFormatter.formatValueWithCurrentUnit(
                    minAltitudeOfInterest,
                    DistanceUnit.METRIC,
                    { formatFunction },
                );
                return [top, bottom];
            }),
        );
    }

    public getAltitudeGuidesObservable(): Rx.Observable<AltitudeGuide[]> {
        const getGuideAltitudes: (config: AltitudeSliderConfig) => number[] = (config) => {
            const guideAltitudes: number[] = [];
            for (let altitude = config.lowerLimit; altitude <= config.upperLimit; altitude += config.axisLabelsStep) {
                guideAltitudes.push(altitude);
            }
            return guideAltitudes;
        };
        return this.configObservable.pipe(
            RxOperators.map((config) => getGuideAltitudes(config)),
            RxOperators.map((guideAltitudes) => {
                return guideAltitudes.map((altitude) => ({
                    altitude: this.distanceFormatter.convertValueFromCurrentUnit(
                        altitude,
                        DistanceUnit.METRIC,
                        DistanceFormatFunction.ROUND_DECIMAL_2,
                    ),
                    label: this.distanceFormatter.formatValueWithCurrentUnit(
                        altitude,
                        this.distanceFormatter.selectedDistanceUnit,
                    ),
                }));
            }),
        );
    }

    // Private functions

    private subscribeToTrackUpdates(): void {
        const subscription = this.replayRepository.currentPlaybackScene
            .pipe(
                RxOperators.switchMap((scene) =>
                    scene == null ? nonNullObservable(this.trackRepository.tracksSnapshot) : scene.tracks,
                ),
            )
            .subscribe((snapshot) => this.processTrackSnapshot(snapshot));
        this.collectSubscription(subscription);
    }

    private subscribeToDistanceUnitChange(): void {
        const subscription = Rx.combineLatest([this.minObservable, this.maxObservable, this.stepObservable]).subscribe(
            ([min, max, step]) => {
                this.snapPreferenceToStep(LocalUserPreferenceKeys.filters.minAltitudeOfInterest, min, max, step);
                this.snapPreferenceToStep(LocalUserPreferenceKeys.filters.maxAltitudeOfInterest, min, max, step);
            },
        );
        this.collectSubscription(subscription);
    }

    private snapPreferenceToStep(preferenceKey: string, min: number, max: number, step: number): void {
        const currentValue = this.localPreferencesRepository.getPreference<number>(preferenceKey);
        if (currentValue) {
            const newValue = Math.round(currentValue / step) * step;
            if (min <= newValue && newValue <= max) {
                this.localPreferencesRepository.setPreference(preferenceKey, newValue);
            } else {
                this.localPreferencesRepository.setPreference(preferenceKey, null);
            }
        }
    }

    private processTrackSnapshot(snapshot: TracksSnapshot): void {
        const tracks = Array.from(snapshot.tracks.values())
            .filter((value) => this.getClosestEstimateTo(value, snapshot.timestamp).classification != null)
            .filter((value) => value.hasRangeAndAzimuthCompatible());
        this.updateAltitudeToBirdDensity(tracks, snapshot.timestamp);
        this.updateAircraftAltitudes(tracks);
    }

    private updateAltitudeToBirdDensity(processedTracks: Track[], timestamp: long): void {
        const minAltitudeStep = Math.max(this.currentConfig.stepSize, 1);
        const altitudes = processedTracks
            .filter((value) => {
                const c = this.getClosestEstimateTo(value, timestamp).classification;
                return c && c.isBird;
            })
            .map((track) => this.getLatestAltitude(track, timestamp, VALID_TIME_OFFSET_OF_LAST_ALTITUDE_MILLIS))
            .filter((value) => value != null && !isNaN(value))
            .map((value) => Math.round(value!));

        const map = new Map<number, number>(); //<altitude, number of tracks>
        altitudes.forEach((altitude) => {
            const key = Math.round(altitude / minAltitudeStep);
            const current = map.get(key) || 0;
            map.set(key, current + 1);
        });
        this.altitudeToTrackDensityMapSubject.next(map);
    }

    private updateAircraftAltitudes(processedTracks: Track[]): void {
        const altitudes = processedTracks
            .filter((track) => track.isAirCraft)
            .map((track) => {
                const location = track.getCurrentLocation();
                if (location == null) {
                    return 0;
                }
                return Math.round(location.altitude);
            })
            .filter((altitude) => altitude > 0);
        this.aircraftAltitudesSubject.next(altitudes);
    }

    private getLatestAltitude(track: Track, snapshotTimestamp: long, validTimeOffset: long): float | null {
        const processedEstimates = _.dropRightWhile(
            Array.from(track.estimates),
            (estimate) => estimate.timestamp > snapshotTimestamp,
        ).filter((estimate) => estimate != null);

        if (processedEstimates.length === 0) {
            return null;
        }

        const lastEstimation = processedEstimates[processedEstimates.length - 1];
        if (Math.abs(lastEstimation.timestamp - snapshotTimestamp) > validTimeOffset) {
            return null;
        }

        const lastLocation = lastEstimation.location;
        if (lastLocation == null) {
            return null;
        }
        return lastLocation.altitude;
    }

    private getClosestEstimateTo(track: Track, timestamp: long): Estimate {
        return track.getClosestEstimateTo(timestamp) || track.lastEstimate;
    }
}
