import { RadarStatusInfoJSON } from "./json";
import { Inclination, RadarComponentState, RadarOperatingModeStatus, RadarSensorStatus } from ".";

export enum RadarStatusImageQuality {
    Good,
    Degraded,
    Bad,
    Unknown,
}

export class RadarStatus {
    public static fromJson(model: RadarStatusInfoJSON): RadarStatus {
        return new RadarStatus(
            model.componentStates.map((cs) => RadarComponentState.fromJson(cs)),
            model.driveRunning,
            RadarSensorStatus.fromJson(model.sensorStatus),
            model.canPerformAutoAlignment,
            model.canPerformAutoPosition,
            model.hasReliableAltitudeInfo,
            model.maxNumberOfBlankingSectors,
            model.isOnAutoStart,
            !model.isLive,
            this.imageQualityFromJSON(model),
            model.sensitivity,
            model.groundLevel,
            model.inclination && Inclination.fromJson(model.inclination),
            model.dynamicPositioning,
            model.operatingMode && RadarOperatingModeStatus.fromJson(model.operatingMode),
        );
    }

    public constructor(
        public readonly componentStates: RadarComponentState[],
        public readonly driveRunning: boolean,
        public readonly sensorStatus: RadarSensorStatus,
        public readonly canPerformAutoAlignment: boolean,
        public readonly canPerformAutoPosition: boolean,
        public readonly hasReliableAltitudeInfo: boolean,
        public readonly maxNumberOfBlankingSectors: number,
        public readonly isOnAutoStart: boolean,
        public readonly isSimulation: boolean,
        public readonly imageQualityState: RadarStatusImageQuality,
        public readonly sensitivity?: number,
        public readonly groundLevel?: number,
        public readonly inclination?: Inclination,
        public readonly dynamicPositioning?: boolean,
        public readonly operatingMode?: RadarOperatingModeStatus,
    ) {}

    // Private functions

    private static imageQualityFromJSON(model: RadarStatusInfoJSON): RadarStatusImageQuality {
        switch (model.imageQualityState) {
            case "good":
                return RadarStatusImageQuality.Good;
                break;
            case "degraded":
                return RadarStatusImageQuality.Degraded;
                break;
            case "bad":
                return RadarStatusImageQuality.Bad;
                break;
            default:
                return RadarStatusImageQuality.Unknown;
                break;
        }
    }
}
