import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { LocalPreferencesRepository, TrackRepository } from "../../domain/repositories";
import {
    Classification,
    EmitterCategoryGroup,
    getClassificationAltitudeFilterKey,
    LocalUserPreferenceKeys,
} from "../../domain/model";
import { BaseViewModel } from "../BaseViewModel";
import DI from "../../di/DI";
import { TYPES } from "../../di/Types";
import { nonNullObservable } from "../../utils/RxUtils";
import { getADSBGroupIconProps, getComponentFromClassification, getComponentFromProps } from "../legend/LegendIcon";
import { FilterItemProps } from "./AltitudeFilterSettings";
import { t } from "i18next";
import { AltitudeFilterSection } from "./AltitudeFilterSection";

export class AltitudeFilterSettingsViewModel extends BaseViewModel {
    public get applyAllObservable(): Rx.Observable<boolean> {
        return this.classificationSwitchItems.pipe(
            RxOperators.flatMap((items) => Rx.combineLatest(items.map((item) => item.observableValue))),
            RxOperators.map((settings) => settings.reduce((value, setting) => value && setting, true)),
        );
    }
    public get classificationSwitchItems(): Rx.Observable<FilterItemProps[]> {
        const adsb = this.getADSBFlightsVisibilitySwitchViewModel();
        return this.trackRepository.classificationsMap.pipe(
            RxOperators.filter((map) => map.size > 0),
            RxOperators.take(1),
            RxOperators.map((c) => {
                const settings = this.getClassificationVisibilitySettings(Array.from(c.values()));
                adsb && settings.push(...adsb);
                return settings;
            }),
        );
    }
    public get showTracksWithoutAltitudeObservable(): Rx.Observable<boolean> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<boolean>(
                LocalUserPreferenceKeys.filters.showTracksWithoutAltitude,
            ),
            true,
        );
    }
    public get filterSections(): Rx.Observable<AltitudeFilterSection[]> {
        const altitudeFilterSectionOpenObservables = [this.classificationsSectionId, this.adsbSectionId].map((key) => {
            return this.localPreferencesRepository.observePreference(
                LocalUserPreferenceKeys.altitudeFilterSectionOpenPrefix + key,
                false,
            );
        });
        return Rx.combineLatest([
            this.trackRepository.classificationsMap.pipe(
                RxOperators.filter((map) => map.size > 0),
                RxOperators.take(1),
            ),
            ...altitudeFilterSectionOpenObservables,
        ]).pipe(
            RxOperators.map(([c, isClassificationOpen, isAdsbOpen]) => {
                const settings = this.getClassificationVisibilitySettings(Array.from(c.values()));
                const adsbItems = this.getADSBFlightsVisibilitySwitchViewModel();
                const sections = [
                    {
                        id: this.classificationsSectionId,
                        label: "legend.classifications",
                        entries: settings,
                        isOpen: !!isClassificationOpen,
                    },
                ];
                adsbItems &&
                    sections.push({
                        id: this.adsbSectionId,
                        label: "map.adsb",
                        entries: adsbItems,
                        isOpen: !!isAdsbOpen,
                    });
                return sections;
            }),
        );
    }

    public get allSectionEntriesFiltered(): Rx.Observable<Record<string, boolean>> {
        return this.filterSections.pipe(
            RxOperators.map((sections) => {
                const sectionResults: Rx.Observable<{ id: string; allTrue: boolean }>[] = [];
                for (const section of sections) {
                    const entriesResults: Rx.Observable<boolean>[] = [];

                    for (const entry of section.entries) {
                        entriesResults.push(entry.observableValue);
                    }

                    const allTrue$ = Rx.combineLatest(entriesResults).pipe(
                        RxOperators.map((values: boolean[]) => values.every((value) => value)),
                    );

                    sectionResults.push(allTrue$.pipe(RxOperators.map((allTrue) => ({ id: section.id, allTrue }))));
                }
                return Rx.combineLatest(sectionResults).pipe(
                    RxOperators.map((results) => {
                        const finalResult: Record<string, boolean> = {};

                        for (const result of results) {
                            finalResult[result.id] = result.allTrue;
                        }

                        return finalResult;
                    }),
                );
            }),
            RxOperators.mergeMap((obs) => obs),
        );
    }

    private hasADSBData = DI.isBound(TYPES.ADSBFlightRepository);
    private classificationsSectionId = "classifications";
    private adsbSectionId = "adsb";

    // Lifecycle

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly trackRepository: TrackRepository,
    ) {
        super();
        this.getADSBFilterItemProps = this.getADSBFilterItemProps.bind(this);
    }

    // Public functions

    public onChangeShowTracksWithoutAltitude(value: boolean): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.filters.showTracksWithoutAltitude, value);
    }

    public setSectionVisibility(sectionId: string, isVisible: boolean): void {
        const key = LocalUserPreferenceKeys.altitudeFilterSectionOpenPrefix + sectionId;
        this.localPreferencesRepository.setPreference(key, isVisible);
    }

    // Private functions

    private getClassificationVisibilitySettings(classifications: Classification[]): FilterItemProps[] {
        return classifications
            .sort((a, b) => (a.zOrder > b.zOrder ? 1 : -1))
            .map((c) => this.getClassificationVisibilitySwitchViewModel(c));
    }

    private getClassificationVisibilitySwitchViewModel(classification: Classification): FilterItemProps {
        const key = getClassificationAltitudeFilterKey(classification.name);
        return {
            title: classification.prettyName,
            icon: getComponentFromClassification(classification, 1)!,
            observableValue: nonNullObservable(
                this.localPreferencesRepository.observePreference<boolean>(key),
                this.trackRepository.getDefaultApplyAltitudeFilterSetting(classification),
            ),
            onChange: (value) => this.localPreferencesRepository.setPreference(key, value),
        };
    }

    private getADSBFilterItemProps({
        group,
        name,
        preferenceKey,
    }: {
        group: EmitterCategoryGroup;
        name: string;
        preferenceKey: string;
    }): FilterItemProps {
        return {
            title: t(name).toUpperCase(),
            icon: getComponentFromProps(getADSBGroupIconProps(group, 1)),
            observableValue: nonNullObservable(
                this.localPreferencesRepository.observePreference<boolean>(preferenceKey),
                false,
            ),
            onChange: (value) => this.localPreferencesRepository.setPreference(preferenceKey, value),
        };
    }

    private getADSBFlightsVisibilitySwitchViewModel(): FilterItemProps[] {
        if (!this.hasADSBData) {
            return [];
        }
        return [
            {
                name: "classification.ADSBAircraft",
                group: EmitterCategoryGroup.AircraftEmitterCategory,
                preferenceKey: LocalUserPreferenceKeys.filters.applyAltitudeFilterADSBAircraft,
            },
            {
                name: "classification.ADSBVehicle",
                group: EmitterCategoryGroup.VehicleEmitterCategory,
                preferenceKey: LocalUserPreferenceKeys.filters.applyAltitudeFilterADSBVehicles,
            },
            {
                name: "classification.drone",
                group: EmitterCategoryGroup.DroneEmitterCategory,
                preferenceKey: LocalUserPreferenceKeys.filters.applyAltitudeFilterADSBDrone,
            },
            {
                name: "classification.ADSBRotorcraft",
                group: EmitterCategoryGroup.RotorcraftEmitterCategory,
                preferenceKey: LocalUserPreferenceKeys.filters.applyAltitudeFilterADSBRotorcraft,
            },
            {
                name: "classification.other",
                group: EmitterCategoryGroup.OtherEmitterCategory,
                preferenceKey: LocalUserPreferenceKeys.filters.applyAltitudeFilterADSBOther,
            },
        ].map(this.getADSBFilterItemProps);
    }
}
