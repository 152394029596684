import { BaseViewModel } from "../BaseViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import {
    LocalPreferencesRepository,
    RunwayTrafficRepository,
    TrackRepository,
    ReplayRepository,
    LocationInfoRepository,
} from "../../domain/repositories";
import {
    DistanceUnit,
    Classification,
    LocalUserPreferenceKeys,
    Track,
    Trackable,
    TracksSnapshot,
} from "../../domain/model";
import { nonNullObservable } from "../../utils/RxUtils";
import { RunwayTraffic } from "../../domain/model/RunwayTraffic";
import { DistanceFormatter } from "../../domain/DistanceFormatter";
import { FunnelViewGuideline } from "./FunnelViewGuideline";
import _ from "lodash";
import { PlaybackScene } from "../../domain/PlaybackScene";
import { getShowParkingCorridorObservable } from "../funnelview/FunnelViewUtils";

export const DEFAULT_FUNNEL_MAX_HEIGHT_METERS = 750;
export const DEFAULT_FUNNEL_GUIDELINE_DISTANCES_METERS = 100;

export class DetailedFunnelViewViewModel extends BaseViewModel {
    // Properties

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly runwayTrafficRepository: RunwayTrafficRepository,
        private readonly distanceFormatter: DistanceFormatter,
        private readonly trackRepository: TrackRepository,
        private readonly replayRepository: ReplayRepository,
        private readonly locationInfoRepository: LocationInfoRepository,
    ) {
        super();
    }

    public get referenceAltitude(): Rx.Observable<number> {
        return this.locationInfoRepository.referenceLocation.pipe(RxOperators.map((l) => l.altitude));
    }

    public get funnelViewThreshold(): Rx.Observable<int> {
        return this.runwayTrafficRepository.funnelThreshold;
    }

    public get trailLength(): Rx.Observable<number> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<number>(LocalUserPreferenceKeys.appearance.trailLength),
        );
    }

    public get classifications(): Rx.Observable<Map<string, Classification>> {
        return this.trackRepository.classificationsMap;
    }

    public getShowParkingCorridor(runwayId: int): Rx.Observable<boolean> {
        return getShowParkingCorridorObservable(this.localPreferencesRepository, this.locationInfoRepository, runwayId);
    }

    public observeRunwayTraffic(runwayId: int): Rx.Observable<[number, RunwayTraffic, Track[]]> {
        // Only include tracks from the top sector (5th sector in the array) if the parking corridor is shown
        const includeTopSector = this.getShowParkingCorridor(runwayId);
        return Rx.combineLatest([this.trackUpdates, this.runwayTraffic(runwayId), includeTopSector]).pipe(
            RxOperators.mergeMap(([trackSnapshot, traffic, includeTopSector]) => {
                const allIds = traffic.sectorMessages
                    .filter((_, index) => includeTopSector || index < 4)
                    .flatMap((sector) => sector.trackIds.concat(sector.aircraftIds));
                const filteredTracks = _.uniq(allIds)
                    .map((id) => trackSnapshot.tracks.get(id))
                    .filter((t) => t != null && this.shouldDraw(t))
                    .map((t) => t!);
                const data: [number, RunwayTraffic, Track[]] = [trackSnapshot.timestamp, traffic, filteredTracks];
                return Rx.of(data);
            }),
        );
    }

    /**
     * Generates the guidelines for the y-axis of the funnel with heights in meters
     * @returns Observable<FunnelViewGuideline[]>
     */
    public generateFunnelViewGuideLines(funnelHeightMax: number): Rx.Observable<FunnelViewGuideline[]> {
        return new Rx.Observable<number[]>((subscriber) => {
            const funnelHeightLimit = this.calculateFunnelViewHeight(funnelHeightMax);
            const guidelineDistanceMeters = DEFAULT_FUNNEL_GUIDELINE_DISTANCES_METERS;
            const numberOfHorizontalLines = Math.ceil(funnelHeightLimit / guidelineDistanceMeters);

            const heights: number[] = [];
            for (let i = 0; i < numberOfHorizontalLines; i++) {
                const meters = i * guidelineDistanceMeters;
                heights.push(meters);
            }
            if (funnelHeightLimit % guidelineDistanceMeters !== 0) {
                heights.push(funnelHeightLimit);
            }
            subscriber.next(heights);
        }).pipe(
            RxOperators.mergeMap((heights) =>
                this.distanceFormatter.formatValue(heights, (lines, formatter) =>
                    lines.map((l) => ({
                        height: l,
                        label: formatter(l, DistanceUnit.METRIC),
                    })),
                ),
            ),
        );
    }

    /**
     * Returns the funnel chart height in meters by taking the highest layer, rounding it up to the nearest 100 and adding 200 meters
     * @param funnelHeightMax number - The max height of the funnel as provided by the API
     * @returns number - The calculated funnel height
     */
    public calculateFunnelViewHeight(funnelHeightMax: number): number {
        const funnelHeight = Math.ceil(funnelHeightMax / 100) * 100 + 200;
        return Math.max(funnelHeight, DEFAULT_FUNNEL_MAX_HEIGHT_METERS);
    }

    /**
     * Selects a track in the track repository by trackID
     * @param trackId Number
     */
    public selectTrack(trackId: number): void {
        this.trackRepository.toggleSelectedTrackId(trackId);
    }

    private get trackUpdates(): Rx.Observable<TracksSnapshot> {
        return this.replayHandler(nonNullObservable(this.trackRepository.tracksSnapshot), (s) => s.tracks);
    }

    private runwayTraffic(runwayId: int): Rx.Observable<RunwayTraffic> {
        return nonNullObservable(
            this.replayHandler(
                this.runwayTrafficRepository.observeRunwayTraffic([runwayId]),
                (s) => s.runwayTraffic,
            ).pipe(RxOperators.map((runways) => runways.find((r) => r.runwayId === runwayId))),
        );
    }

    private replayHandler<T>(
        observable: Rx.Observable<T>,
        handleReplay: (scene: PlaybackScene) => Rx.Observable<T>,
    ): Rx.Observable<T> {
        return this.replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((scene) => (scene == null ? observable : handleReplay(scene))),
        );
    }

    private shouldDraw(trackable: Trackable): boolean {
        return !(trackable instanceof Track) || trackable.isBird || trackable.isAirCraft || trackable.isDrone;
    }
}
