import mapboxgl from "mapbox-gl";
import { MapModule } from "../MapModule";
import { RadarBlankingSectorsModuleViewModel } from "./RadarBlankingSectorsModuleViewModel";
import { ORDER_LAYER_RADAR_BLANKING_SECTORS, ORDER_LAYER_RADAR_BLANKING_SECTORS_PREVIEW } from "../Orders";
import {
    RadarBlankingSectorsLayer,
    RadarBlankingSectorsPreviewLayer,
    BLANKING_SECTORS_LAYER_ID,
    BLANKING_SECTOR_RADAR_ICON_LAYER_ID,
    BLANKING_SECTOR_HANDLES_LAYER_ID,
} from "../../layers";
import { BlankingSectorsState, Location } from "../../../../domain/model";
import { LayerManager } from "../LayerManager";
import { LAYER_ID_BACKGROUND_DIM } from "../dimcolor/DimColorModule";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";

export class RadarBlankingSectorsModule extends MapModule<RadarBlankingSectorsModuleViewModel> {
    // Properties
    private onBlankingSectorsUpdated = new Rx.Subject<boolean>();

    private lastBlankingSectorState: BlankingSectorsState | undefined;

    public constructor(protected readonly viewModel: RadarBlankingSectorsModuleViewModel) {
        super();
    }

    // Public functions

    public setup(map: mapboxgl.Map, referenceLocation: Location, layerManager: LayerManager): void {
        super.setup(map, referenceLocation, layerManager);

        const blankingSectorsLayer = RadarBlankingSectorsLayer.attachedTo(
            map,
            ORDER_LAYER_RADAR_BLANKING_SECTORS.id,
            (indexOfUpdatedSector, updatedSector) => {
                const sectorsBeforeUpdate = this.lastBlankingSectorState?.blankingSectors;
                const sectorsAfterUpdate = sectorsBeforeUpdate ? [...sectorsBeforeUpdate] : [];
                sectorsAfterUpdate[indexOfUpdatedSector] = updatedSector;
                this.viewModel.setBlankingSectorsState({
                    ...this.lastBlankingSectorState!,
                    blankingSectors: sectorsAfterUpdate,
                });
            },
        );
        const blankingSectorsPreviewLayer = RadarBlankingSectorsPreviewLayer.attachedTo(
            map,
            ORDER_LAYER_RADAR_BLANKING_SECTORS_PREVIEW.id,
        );

        let subscription = this.viewModel.radars.subscribe((value) => blankingSectorsLayer.setRadars(value));
        this.collectSubscription(subscription);

        subscription = this.viewModel.blankingSectorsState.subscribe((value) => {
            this.lastBlankingSectorState = value;
            blankingSectorsLayer.setBlankingSectorState(value.isActive, value.radarId, value.blankingSectors);
            if (value.isActive) {
                layerManager.setAllEnabled(
                    false,
                    [
                        BLANKING_SECTORS_LAYER_ID,
                        BLANKING_SECTOR_RADAR_ICON_LAYER_ID,
                        BLANKING_SECTOR_HANDLES_LAYER_ID,
                        LAYER_ID_BACKGROUND_DIM,
                    ],
                    true,
                );
            } else {
                layerManager.restoreState();
            }
            this.onBlankingSectorsUpdated.next(true);
        });
        this.collectSubscription(subscription);

        subscription = this.viewModel.blankingSectorsState
            .pipe(
                RxOperators.map((b) => b.isActive),
                RxOperators.distinctUntilChanged(),
            )
            .subscribe((value) => blankingSectorsLayer.setEnabled(value));

        subscription = this.viewModel.radars
            .pipe(
                RxOperators.take(1),
                RxOperators.repeatWhen(() => this.onBlankingSectorsUpdated),
                RxOperators.debounceTime(1000),
                RxOperators.flatMap((radars) =>
                    Rx.from(radars).pipe(
                        RxOperators.flatMap((r) => Rx.zip(Rx.of(r), this.viewModel.getBlankingSectors(r.id))),
                        RxOperators.toArray(),
                    ),
                ),
                RxOperators.distinctUntilChanged(),
            )
            .subscribe((radarsAndSectors) => blankingSectorsPreviewLayer.setRadarsAndSectors(radarsAndSectors));

        this.collectSubscription(subscription);
        subscription = this.viewModel.previewEnabled.subscribe((value) => {
            blankingSectorsPreviewLayer.setEnabled(value);
        });
        this.collectSubscription(subscription);
    }
}
