import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { GridsSnapshot, GridDataChunk, Grid } from "../../../../domain/model";
import { AbstractStartableRepository } from "../../../../domain/repositories";
import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import {
    GridAnalysisData as GridAnalysisDataProto,
    GridAnalysisList as GridAnalysisListProto,
    GridAnalysis as GridAnalysisProto,
    GridAnalysisDataRequest as GridAnalysisDataRequestProto,
} from "../../../../domain/model/proto/generated/gridanalysis3_pb";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { GridAnalysisRepository } from "../../../../domain/repositories/GridAnalysisRepository";

export class BirdViewerGridAnalysisRepository extends AbstractStartableRepository implements GridAnalysisRepository {
    // Properties
    public readonly gridSnapshot: Rx.Observable<GridsSnapshot>;
    private readonly gridSnapshotSubject = new Rx.BehaviorSubject<GridsSnapshot | null>(null);
    private subscriptions: Rx.Subscription | undefined;

    public constructor(private readonly api: BirdViewerAPI) {
        super();
        this.gridSnapshot = nonNullObservable(this.gridSnapshotSubject.asObservable());
    }

    // Public functions
    public start(): void {
        this.subscriptions = new Rx.Subscription();
        this.getGridList();
    }
    public stop(): void {
        this.subscriptions!.unsubscribe();
    }
    private getGridList(): void {
        const subscription = this.api
            .getGridAnalysisList()
            .pipe(
                RxOperators.flatMap((result) => this.processGridListResult(result)),
                RxOperators.map((result) => GridsSnapshot.from(result)),
            )
            .subscribe(
                (result) => this.gridSnapshotSubject.next(result),
                (error) => this.retryIfStartedAndImplemented(() => this.getGridList(), error),
            );
        this.subscriptions!.add(subscription);
    }

    // Private functions
    private getGridDetails(grid: GridAnalysisProto): Rx.Observable<Grid> {
        const request = new GridAnalysisDataRequestProto();
        request.setUuid(grid.getUuid());
        request.setTimestampMsec(Date.now());
        return this.api.getGridAnalysisData(request).pipe(
            RxOperators.map((data) => this.processChunks(data)),
            RxOperators.map((gridChunks) => Grid.fromProto(grid, gridChunks)),
        );
    }
    private processGridListResult(result: GridAnalysisListProto): Rx.Observable<Grid[]> {
        return Rx.from(result.getGridanalysisList()).pipe(
            RxOperators.flatMap((gridAnalysis) => this.getGridDetails(gridAnalysis)),
            RxOperators.toArray(),
        );
    }
    private processChunks(data: GridAnalysisDataProto): GridDataChunk[] {
        const gridDataChunks: GridDataChunk[] = [];
        data.getDataList().forEach((dataChunk) => {
            for (let i = 0; i < dataChunk.getTrackcountValuesList().length; i++) {
                const vectorValue =
                    dataChunk.getVectorLengthList().length > 0 ? 125 : dataChunk.getVectorValuesList()[i];
                const gridDataChunk = new GridDataChunk(
                    dataChunk.getRowindex(),
                    dataChunk.getColindex() + i,
                    dataChunk.getTrackcountValuesList()[i],
                    dataChunk.getVectorValuesList()[i],
                    vectorValue,
                );
                gridDataChunks.push(gridDataChunk);
            }
        });
        return gridDataChunks;
    }
}
