import React, { useEffect } from "react";
import { FullscreenLoading } from "../appearance/FullscreenLoading";
import { showErrorWithOptions } from "../../utils/MessageUtils";
import { t } from "i18next";
import { useOauth } from "./useOauth";

interface Props {
    onError: (error: Error) => void;
}

export const OpenIdConnectRedirect = ({ onError }: Props): JSX.Element | null => {
    // Properties

    const { authenticate } = useOauth();

    useEffect(() => {
        const subscription = authenticate().subscribe({
            error: (error) => {
                console.warn("Oauth login failed", error);
                showErrorWithOptions({ title: t("general.error"), message: t("login.loginFailed") });
                onError(error);
            },
        });
        return () => subscription.unsubscribe();
    }, []);

    // Render

    return <FullscreenLoading />;
};
