import React from "react";
import styled from "styled-components";
import { SectionHeader } from "../appearance/SectionHeader";
import { TrackListEntry, TrackListEntryProps } from "./TrackListEntry";

const TrackListContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

interface Props {
    trackProps: TrackListEntryProps[];
    headerLabel: string;
    message?: string;
}

interface State {
    expanded: boolean;
}

export class TrackListSection extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            expanded: true,
        };
    }

    public render(): React.ReactNode {
        return (
            <TrackListContainer>
                <SectionHeader label={this.props.headerLabel} isOpen={this.state.expanded} onClick={this.onClick} />
                {this.state.expanded &&
                    (this.props.message ? (
                        <TrackListEntry name={this.props.message} />
                    ) : (
                        this.props.trackProps.map((props, index) => <TrackListEntry {...props} key={index} />)
                    ))}
            </TrackListContainer>
        );
    }

    private onClick = (isOpen: boolean): void => {
        this.setState({ expanded: !isOpen });
    };
}
