import styled from "styled-components";

export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${({ theme }) => `${theme.spacing.x2} ${theme.spacing.x1}`};
`;

export const ButtonGroupFullWidth = styled(ButtonGroup)`
    justify-content: space-between;
    width: 100%;
`;

export const ButtonGroupOutsideRounded = styled(ButtonGroup)`
    button {
        flex: 1;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;

        &:first-child {
            border-top-left-radius: ${({ theme }) => theme.spacing.x2};
            border-bottom-left-radius: ${({ theme }) => theme.spacing.x2};
        }
        &:last-of-type {
            border-top-right-radius: ${({ theme }) => theme.spacing.x2};
            border-bottom-right-radius: ${({ theme }) => theme.spacing.x2};
        }
    }
`;
