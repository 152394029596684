// source: logger3.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var common3_pb = require('./common3_pb.js');
goog.object.extend(proto, common3_pb);
var deterrencedevices3_pb = require('./deterrencedevices3_pb.js');
goog.object.extend(proto, deterrencedevices3_pb);
goog.exportSymbol('proto.robin.proto3.logger.Behavior', null, global);
goog.exportSymbol('proto.robin.proto3.logger.DeleteReportMsg', null, global);
goog.exportSymbol('proto.robin.proto3.logger.DeletedLoggerReport', null, global);
goog.exportSymbol('proto.robin.proto3.logger.DeterrenceMean', null, global);
goog.exportSymbol('proto.robin.proto3.logger.InspectionTimer', null, global);
goog.exportSymbol('proto.robin.proto3.logger.InspectionTimerUpdates', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LogDeterrenceActionMsg', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LogFeedbackMsg', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LogNoteMsg', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LogObservationMsg', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LogRunwayInspectionMsg', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LoggerCommandMsg', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LoggerCommandMsg.SubcommandCase', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LoggerReport', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LoggerReport.DeterrenceAction', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LoggerReport.Flying', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LoggerReport.Note', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LoggerReport.Sitting', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LoggerReportList', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LoggerReportRequest', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LoggerReportType', null, global);
goog.exportSymbol('proto.robin.proto3.logger.LoggerSettingsList', null, global);
goog.exportSymbol('proto.robin.proto3.logger.ObservationType', null, global);
goog.exportSymbol('proto.robin.proto3.logger.RunwayInspectionType', null, global);
goog.exportSymbol('proto.robin.proto3.logger.Species', null, global);
goog.exportSymbol('proto.robin.proto3.logger.TimeMsg', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.LoggerSettingsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.logger.LoggerSettingsList.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.logger.LoggerSettingsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.LoggerSettingsList.displayName = 'proto.robin.proto3.logger.LoggerSettingsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.Species = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.Species, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.Species.displayName = 'proto.robin.proto3.logger.Species';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.Behavior = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.Behavior, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.Behavior.displayName = 'proto.robin.proto3.logger.Behavior';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.DeterrenceMean = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.DeterrenceMean, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.DeterrenceMean.displayName = 'proto.robin.proto3.logger.DeterrenceMean';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.InspectionTimerUpdates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.logger.InspectionTimerUpdates.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.logger.InspectionTimerUpdates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.InspectionTimerUpdates.displayName = 'proto.robin.proto3.logger.InspectionTimerUpdates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.InspectionTimer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.InspectionTimer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.InspectionTimer.displayName = 'proto.robin.proto3.logger.InspectionTimer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.LoggerReportList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.logger.LoggerReportList.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.logger.LoggerReportList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.LoggerReportList.displayName = 'proto.robin.proto3.logger.LoggerReportList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.LoggerReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.LoggerReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.LoggerReport.displayName = 'proto.robin.proto3.logger.LoggerReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.LoggerReport.Note = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.LoggerReport.Note, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.LoggerReport.Note.displayName = 'proto.robin.proto3.logger.LoggerReport.Note';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.LoggerReport.Sitting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.LoggerReport.Sitting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.LoggerReport.Sitting.displayName = 'proto.robin.proto3.logger.LoggerReport.Sitting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.LoggerReport.Flying = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.LoggerReport.Flying, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.LoggerReport.Flying.displayName = 'proto.robin.proto3.logger.LoggerReport.Flying';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.LoggerReport.DeterrenceAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.LoggerReport.DeterrenceAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.LoggerReport.DeterrenceAction.displayName = 'proto.robin.proto3.logger.LoggerReport.DeterrenceAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.DeletedLoggerReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.DeletedLoggerReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.DeletedLoggerReport.displayName = 'proto.robin.proto3.logger.DeletedLoggerReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.LogNoteMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.LogNoteMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.LogNoteMsg.displayName = 'proto.robin.proto3.logger.LogNoteMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.LogObservationMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.robin.proto3.logger.LogObservationMsg.repeatedFields_, null);
};
goog.inherits(proto.robin.proto3.logger.LogObservationMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.LogObservationMsg.displayName = 'proto.robin.proto3.logger.LogObservationMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.LogDeterrenceActionMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.LogDeterrenceActionMsg.displayName = 'proto.robin.proto3.logger.LogDeterrenceActionMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.LogRunwayInspectionMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.LogRunwayInspectionMsg.displayName = 'proto.robin.proto3.logger.LogRunwayInspectionMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.LogFeedbackMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.LogFeedbackMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.LogFeedbackMsg.displayName = 'proto.robin.proto3.logger.LogFeedbackMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.DeleteReportMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.DeleteReportMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.DeleteReportMsg.displayName = 'proto.robin.proto3.logger.DeleteReportMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.LoggerCommandMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.robin.proto3.logger.LoggerCommandMsg.oneofGroups_);
};
goog.inherits(proto.robin.proto3.logger.LoggerCommandMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.LoggerCommandMsg.displayName = 'proto.robin.proto3.logger.LoggerCommandMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.LoggerReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.LoggerReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.LoggerReportRequest.displayName = 'proto.robin.proto3.logger.LoggerReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.robin.proto3.logger.TimeMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.robin.proto3.logger.TimeMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.robin.proto3.logger.TimeMsg.displayName = 'proto.robin.proto3.logger.TimeMsg';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.logger.LoggerSettingsList.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.LoggerSettingsList.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.LoggerSettingsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.LoggerSettingsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerSettingsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    speciesList: jspb.Message.toObjectList(msg.getSpeciesList(),
    proto.robin.proto3.logger.Species.toObject, includeInstance),
    behaviorList: jspb.Message.toObjectList(msg.getBehaviorList(),
    proto.robin.proto3.logger.Behavior.toObject, includeInstance),
    deterrencemeanList: jspb.Message.toObjectList(msg.getDeterrencemeanList(),
    proto.robin.proto3.logger.DeterrenceMean.toObject, includeInstance),
    inspectiontimerList: jspb.Message.toObjectList(msg.getInspectiontimerList(),
    proto.robin.proto3.logger.InspectionTimer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.LoggerSettingsList}
 */
proto.robin.proto3.logger.LoggerSettingsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.LoggerSettingsList;
  return proto.robin.proto3.logger.LoggerSettingsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.LoggerSettingsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.LoggerSettingsList}
 */
proto.robin.proto3.logger.LoggerSettingsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.logger.Species;
      reader.readMessage(value,proto.robin.proto3.logger.Species.deserializeBinaryFromReader);
      msg.addSpecies(value);
      break;
    case 2:
      var value = new proto.robin.proto3.logger.Behavior;
      reader.readMessage(value,proto.robin.proto3.logger.Behavior.deserializeBinaryFromReader);
      msg.addBehavior(value);
      break;
    case 3:
      var value = new proto.robin.proto3.logger.DeterrenceMean;
      reader.readMessage(value,proto.robin.proto3.logger.DeterrenceMean.deserializeBinaryFromReader);
      msg.addDeterrencemean(value);
      break;
    case 4:
      var value = new proto.robin.proto3.logger.InspectionTimer;
      reader.readMessage(value,proto.robin.proto3.logger.InspectionTimer.deserializeBinaryFromReader);
      msg.addInspectiontimer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.LoggerSettingsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.LoggerSettingsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.LoggerSettingsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerSettingsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpeciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.logger.Species.serializeBinaryToWriter
    );
  }
  f = message.getBehaviorList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.robin.proto3.logger.Behavior.serializeBinaryToWriter
    );
  }
  f = message.getDeterrencemeanList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.robin.proto3.logger.DeterrenceMean.serializeBinaryToWriter
    );
  }
  f = message.getInspectiontimerList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.robin.proto3.logger.InspectionTimer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Species species = 1;
 * @return {!Array<!proto.robin.proto3.logger.Species>}
 */
proto.robin.proto3.logger.LoggerSettingsList.prototype.getSpeciesList = function() {
  return /** @type{!Array<!proto.robin.proto3.logger.Species>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.logger.Species, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.logger.Species>} value
 * @return {!proto.robin.proto3.logger.LoggerSettingsList} returns this
*/
proto.robin.proto3.logger.LoggerSettingsList.prototype.setSpeciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.logger.Species=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.logger.Species}
 */
proto.robin.proto3.logger.LoggerSettingsList.prototype.addSpecies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.logger.Species, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.logger.LoggerSettingsList} returns this
 */
proto.robin.proto3.logger.LoggerSettingsList.prototype.clearSpeciesList = function() {
  return this.setSpeciesList([]);
};


/**
 * repeated Behavior behavior = 2;
 * @return {!Array<!proto.robin.proto3.logger.Behavior>}
 */
proto.robin.proto3.logger.LoggerSettingsList.prototype.getBehaviorList = function() {
  return /** @type{!Array<!proto.robin.proto3.logger.Behavior>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.logger.Behavior, 2));
};


/**
 * @param {!Array<!proto.robin.proto3.logger.Behavior>} value
 * @return {!proto.robin.proto3.logger.LoggerSettingsList} returns this
*/
proto.robin.proto3.logger.LoggerSettingsList.prototype.setBehaviorList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.robin.proto3.logger.Behavior=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.logger.Behavior}
 */
proto.robin.proto3.logger.LoggerSettingsList.prototype.addBehavior = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.robin.proto3.logger.Behavior, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.logger.LoggerSettingsList} returns this
 */
proto.robin.proto3.logger.LoggerSettingsList.prototype.clearBehaviorList = function() {
  return this.setBehaviorList([]);
};


/**
 * repeated DeterrenceMean deterrenceMean = 3;
 * @return {!Array<!proto.robin.proto3.logger.DeterrenceMean>}
 */
proto.robin.proto3.logger.LoggerSettingsList.prototype.getDeterrencemeanList = function() {
  return /** @type{!Array<!proto.robin.proto3.logger.DeterrenceMean>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.logger.DeterrenceMean, 3));
};


/**
 * @param {!Array<!proto.robin.proto3.logger.DeterrenceMean>} value
 * @return {!proto.robin.proto3.logger.LoggerSettingsList} returns this
*/
proto.robin.proto3.logger.LoggerSettingsList.prototype.setDeterrencemeanList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.robin.proto3.logger.DeterrenceMean=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.logger.DeterrenceMean}
 */
proto.robin.proto3.logger.LoggerSettingsList.prototype.addDeterrencemean = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.robin.proto3.logger.DeterrenceMean, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.logger.LoggerSettingsList} returns this
 */
proto.robin.proto3.logger.LoggerSettingsList.prototype.clearDeterrencemeanList = function() {
  return this.setDeterrencemeanList([]);
};


/**
 * repeated InspectionTimer inspectionTimer = 4;
 * @return {!Array<!proto.robin.proto3.logger.InspectionTimer>}
 */
proto.robin.proto3.logger.LoggerSettingsList.prototype.getInspectiontimerList = function() {
  return /** @type{!Array<!proto.robin.proto3.logger.InspectionTimer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.logger.InspectionTimer, 4));
};


/**
 * @param {!Array<!proto.robin.proto3.logger.InspectionTimer>} value
 * @return {!proto.robin.proto3.logger.LoggerSettingsList} returns this
*/
proto.robin.proto3.logger.LoggerSettingsList.prototype.setInspectiontimerList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.robin.proto3.logger.InspectionTimer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.logger.InspectionTimer}
 */
proto.robin.proto3.logger.LoggerSettingsList.prototype.addInspectiontimer = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.robin.proto3.logger.InspectionTimer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.logger.LoggerSettingsList} returns this
 */
proto.robin.proto3.logger.LoggerSettingsList.prototype.clearInspectiontimerList = function() {
  return this.setInspectiontimerList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.Species.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.Species.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.Species} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.Species.toObject = function(includeInstance, msg) {
  var f, obj = {
    speciesid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    commonname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.Species}
 */
proto.robin.proto3.logger.Species.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.Species;
  return proto.robin.proto3.logger.Species.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.Species} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.Species}
 */
proto.robin.proto3.logger.Species.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setSpeciesid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommonname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.Species.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.Species.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.Species} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.Species.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpeciesid();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getCommonname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional sint64 speciesId = 1;
 * @return {number}
 */
proto.robin.proto3.logger.Species.prototype.getSpeciesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.Species} returns this
 */
proto.robin.proto3.logger.Species.prototype.setSpeciesid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string commonName = 2;
 * @return {string}
 */
proto.robin.proto3.logger.Species.prototype.getCommonname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.Species} returns this
 */
proto.robin.proto3.logger.Species.prototype.setCommonname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.Behavior.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.Behavior.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.Behavior} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.Behavior.toObject = function(includeInstance, msg) {
  var f, obj = {
    behaviorid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.Behavior}
 */
proto.robin.proto3.logger.Behavior.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.Behavior;
  return proto.robin.proto3.logger.Behavior.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.Behavior} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.Behavior}
 */
proto.robin.proto3.logger.Behavior.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setBehaviorid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.Behavior.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.Behavior.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.Behavior} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.Behavior.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBehaviorid();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional sint64 behaviorId = 1;
 * @return {number}
 */
proto.robin.proto3.logger.Behavior.prototype.getBehaviorid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.Behavior} returns this
 */
proto.robin.proto3.logger.Behavior.prototype.setBehaviorid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.robin.proto3.logger.Behavior.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.Behavior} returns this
 */
proto.robin.proto3.logger.Behavior.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.DeterrenceMean.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.DeterrenceMean.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.DeterrenceMean} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.DeterrenceMean.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    typename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.DeterrenceMean}
 */
proto.robin.proto3.logger.DeterrenceMean.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.DeterrenceMean;
  return proto.robin.proto3.logger.DeterrenceMean.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.DeterrenceMean} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.DeterrenceMean}
 */
proto.robin.proto3.logger.DeterrenceMean.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypename(value);
      break;
    case 4:
      var value = /** @type {!proto.robin.proto3.deterrence.DeterrenceDeviceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.DeterrenceMean.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.DeterrenceMean.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.DeterrenceMean} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.DeterrenceMean.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTypename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional sint64 id = 1;
 * @return {number}
 */
proto.robin.proto3.logger.DeterrenceMean.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.DeterrenceMean} returns this
 */
proto.robin.proto3.logger.DeterrenceMean.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.robin.proto3.logger.DeterrenceMean.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.DeterrenceMean} returns this
 */
proto.robin.proto3.logger.DeterrenceMean.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string typeName = 3;
 * @return {string}
 */
proto.robin.proto3.logger.DeterrenceMean.prototype.getTypename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.DeterrenceMean} returns this
 */
proto.robin.proto3.logger.DeterrenceMean.prototype.setTypename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional robin.proto3.deterrence.DeterrenceDeviceType type = 4;
 * @return {!proto.robin.proto3.deterrence.DeterrenceDeviceType}
 */
proto.robin.proto3.logger.DeterrenceMean.prototype.getType = function() {
  return /** @type {!proto.robin.proto3.deterrence.DeterrenceDeviceType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.robin.proto3.deterrence.DeterrenceDeviceType} value
 * @return {!proto.robin.proto3.logger.DeterrenceMean} returns this
 */
proto.robin.proto3.logger.DeterrenceMean.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.logger.InspectionTimerUpdates.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.InspectionTimerUpdates.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.InspectionTimerUpdates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.InspectionTimerUpdates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.InspectionTimerUpdates.toObject = function(includeInstance, msg) {
  var f, obj = {
    inspectiontimerList: jspb.Message.toObjectList(msg.getInspectiontimerList(),
    proto.robin.proto3.logger.InspectionTimer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.InspectionTimerUpdates}
 */
proto.robin.proto3.logger.InspectionTimerUpdates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.InspectionTimerUpdates;
  return proto.robin.proto3.logger.InspectionTimerUpdates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.InspectionTimerUpdates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.InspectionTimerUpdates}
 */
proto.robin.proto3.logger.InspectionTimerUpdates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.logger.InspectionTimer;
      reader.readMessage(value,proto.robin.proto3.logger.InspectionTimer.deserializeBinaryFromReader);
      msg.addInspectiontimer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.InspectionTimerUpdates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.InspectionTimerUpdates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.InspectionTimerUpdates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.InspectionTimerUpdates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInspectiontimerList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.logger.InspectionTimer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InspectionTimer inspectionTimer = 1;
 * @return {!Array<!proto.robin.proto3.logger.InspectionTimer>}
 */
proto.robin.proto3.logger.InspectionTimerUpdates.prototype.getInspectiontimerList = function() {
  return /** @type{!Array<!proto.robin.proto3.logger.InspectionTimer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.logger.InspectionTimer, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.logger.InspectionTimer>} value
 * @return {!proto.robin.proto3.logger.InspectionTimerUpdates} returns this
*/
proto.robin.proto3.logger.InspectionTimerUpdates.prototype.setInspectiontimerList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.logger.InspectionTimer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.logger.InspectionTimer}
 */
proto.robin.proto3.logger.InspectionTimerUpdates.prototype.addInspectiontimer = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.logger.InspectionTimer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.logger.InspectionTimerUpdates} returns this
 */
proto.robin.proto3.logger.InspectionTimerUpdates.prototype.clearInspectiontimerList = function() {
  return this.setInspectiontimerList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.InspectionTimer.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.InspectionTimer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.InspectionTimer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.InspectionTimer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    interval: jspb.Message.getFieldWithDefault(msg, 5, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    nextinspection: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.InspectionTimer}
 */
proto.robin.proto3.logger.InspectionTimer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.InspectionTimer;
  return proto.robin.proto3.logger.InspectionTimer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.InspectionTimer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.InspectionTimer}
 */
proto.robin.proto3.logger.InspectionTimer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setId(value);
      break;
    case 2:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInterval(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextinspection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.InspectionTimer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.InspectionTimer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.InspectionTimer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.InspectionTimer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInterval();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getNextinspection();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.robin.proto3.logger.InspectionTimer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.InspectionTimer} returns this
 */
proto.robin.proto3.logger.InspectionTimer.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional robin.proto3.LatLonAltPosition position = 2;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.logger.InspectionTimer.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 2));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.logger.InspectionTimer} returns this
*/
proto.robin.proto3.logger.InspectionTimer.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.InspectionTimer} returns this
 */
proto.robin.proto3.logger.InspectionTimer.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.InspectionTimer.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.robin.proto3.logger.InspectionTimer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.InspectionTimer} returns this
 */
proto.robin.proto3.logger.InspectionTimer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 interval = 5;
 * @return {number}
 */
proto.robin.proto3.logger.InspectionTimer.prototype.getInterval = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.InspectionTimer} returns this
 */
proto.robin.proto3.logger.InspectionTimer.prototype.setInterval = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool enabled = 6;
 * @return {boolean}
 */
proto.robin.proto3.logger.InspectionTimer.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.logger.InspectionTimer} returns this
 */
proto.robin.proto3.logger.InspectionTimer.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 nextInspection = 7;
 * @return {number}
 */
proto.robin.proto3.logger.InspectionTimer.prototype.getNextinspection = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.InspectionTimer} returns this
 */
proto.robin.proto3.logger.InspectionTimer.prototype.setNextinspection = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.logger.LoggerReportList.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.LoggerReportList.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.LoggerReportList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.LoggerReportList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerReportList.toObject = function(includeInstance, msg) {
  var f, obj = {
    loggerreportList: jspb.Message.toObjectList(msg.getLoggerreportList(),
    proto.robin.proto3.logger.LoggerReport.toObject, includeInstance),
    deletedloggerreportList: jspb.Message.toObjectList(msg.getDeletedloggerreportList(),
    proto.robin.proto3.logger.DeletedLoggerReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.LoggerReportList}
 */
proto.robin.proto3.logger.LoggerReportList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.LoggerReportList;
  return proto.robin.proto3.logger.LoggerReportList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.LoggerReportList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.LoggerReportList}
 */
proto.robin.proto3.logger.LoggerReportList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.logger.LoggerReport;
      reader.readMessage(value,proto.robin.proto3.logger.LoggerReport.deserializeBinaryFromReader);
      msg.addLoggerreport(value);
      break;
    case 2:
      var value = new proto.robin.proto3.logger.DeletedLoggerReport;
      reader.readMessage(value,proto.robin.proto3.logger.DeletedLoggerReport.deserializeBinaryFromReader);
      msg.addDeletedloggerreport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.LoggerReportList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.LoggerReportList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.LoggerReportList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerReportList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoggerreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.robin.proto3.logger.LoggerReport.serializeBinaryToWriter
    );
  }
  f = message.getDeletedloggerreportList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.robin.proto3.logger.DeletedLoggerReport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LoggerReport loggerReport = 1;
 * @return {!Array<!proto.robin.proto3.logger.LoggerReport>}
 */
proto.robin.proto3.logger.LoggerReportList.prototype.getLoggerreportList = function() {
  return /** @type{!Array<!proto.robin.proto3.logger.LoggerReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.logger.LoggerReport, 1));
};


/**
 * @param {!Array<!proto.robin.proto3.logger.LoggerReport>} value
 * @return {!proto.robin.proto3.logger.LoggerReportList} returns this
*/
proto.robin.proto3.logger.LoggerReportList.prototype.setLoggerreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.robin.proto3.logger.LoggerReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.logger.LoggerReport}
 */
proto.robin.proto3.logger.LoggerReportList.prototype.addLoggerreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.robin.proto3.logger.LoggerReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.logger.LoggerReportList} returns this
 */
proto.robin.proto3.logger.LoggerReportList.prototype.clearLoggerreportList = function() {
  return this.setLoggerreportList([]);
};


/**
 * repeated DeletedLoggerReport deletedLoggerReport = 2;
 * @return {!Array<!proto.robin.proto3.logger.DeletedLoggerReport>}
 */
proto.robin.proto3.logger.LoggerReportList.prototype.getDeletedloggerreportList = function() {
  return /** @type{!Array<!proto.robin.proto3.logger.DeletedLoggerReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.robin.proto3.logger.DeletedLoggerReport, 2));
};


/**
 * @param {!Array<!proto.robin.proto3.logger.DeletedLoggerReport>} value
 * @return {!proto.robin.proto3.logger.LoggerReportList} returns this
*/
proto.robin.proto3.logger.LoggerReportList.prototype.setDeletedloggerreportList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.robin.proto3.logger.DeletedLoggerReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.logger.DeletedLoggerReport}
 */
proto.robin.proto3.logger.LoggerReportList.prototype.addDeletedloggerreport = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.robin.proto3.logger.DeletedLoggerReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.logger.LoggerReportList} returns this
 */
proto.robin.proto3.logger.LoggerReportList.prototype.clearDeletedloggerreportList = function() {
  return this.setDeletedloggerreportList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.LoggerReport.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.LoggerReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.LoggerReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timestampMsec: jspb.Message.getFieldWithDefault(msg, 2, 0),
    loggerreporttype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f),
    username: jspb.Message.getFieldWithDefault(msg, 5, ""),
    note: (f = msg.getNote()) && proto.robin.proto3.logger.LoggerReport.Note.toObject(includeInstance, f),
    sitting: (f = msg.getSitting()) && proto.robin.proto3.logger.LoggerReport.Sitting.toObject(includeInstance, f),
    flying: (f = msg.getFlying()) && proto.robin.proto3.logger.LoggerReport.Flying.toObject(includeInstance, f),
    deterrenceaction: (f = msg.getDeterrenceaction()) && proto.robin.proto3.logger.LoggerReport.DeterrenceAction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.LoggerReport}
 */
proto.robin.proto3.logger.LoggerReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.LoggerReport;
  return proto.robin.proto3.logger.LoggerReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.LoggerReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.LoggerReport}
 */
proto.robin.proto3.logger.LoggerReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setReportid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampMsec(value);
      break;
    case 3:
      var value = /** @type {!proto.robin.proto3.logger.LoggerReportType} */ (reader.readEnum());
      msg.setLoggerreporttype(value);
      break;
    case 4:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 10:
      var value = new proto.robin.proto3.logger.LoggerReport.Note;
      reader.readMessage(value,proto.robin.proto3.logger.LoggerReport.Note.deserializeBinaryFromReader);
      msg.setNote(value);
      break;
    case 11:
      var value = new proto.robin.proto3.logger.LoggerReport.Sitting;
      reader.readMessage(value,proto.robin.proto3.logger.LoggerReport.Sitting.deserializeBinaryFromReader);
      msg.setSitting(value);
      break;
    case 12:
      var value = new proto.robin.proto3.logger.LoggerReport.Flying;
      reader.readMessage(value,proto.robin.proto3.logger.LoggerReport.Flying.deserializeBinaryFromReader);
      msg.setFlying(value);
      break;
    case 13:
      var value = new proto.robin.proto3.logger.LoggerReport.DeterrenceAction;
      reader.readMessage(value,proto.robin.proto3.logger.LoggerReport.DeterrenceAction.deserializeBinaryFromReader);
      msg.setDeterrenceaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.LoggerReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.LoggerReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.LoggerReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLoggerreporttype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNote();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.robin.proto3.logger.LoggerReport.Note.serializeBinaryToWriter
    );
  }
  f = message.getSitting();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.robin.proto3.logger.LoggerReport.Sitting.serializeBinaryToWriter
    );
  }
  f = message.getFlying();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.robin.proto3.logger.LoggerReport.Flying.serializeBinaryToWriter
    );
  }
  f = message.getDeterrenceaction();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.robin.proto3.logger.LoggerReport.DeterrenceAction.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.LoggerReport.Note.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.LoggerReport.Note.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.LoggerReport.Note} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerReport.Note.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.LoggerReport.Note}
 */
proto.robin.proto3.logger.LoggerReport.Note.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.LoggerReport.Note;
  return proto.robin.proto3.logger.LoggerReport.Note.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.LoggerReport.Note} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.LoggerReport.Note}
 */
proto.robin.proto3.logger.LoggerReport.Note.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.LoggerReport.Note.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.LoggerReport.Note.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.LoggerReport.Note} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerReport.Note.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.robin.proto3.logger.LoggerReport.Note.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.LoggerReport.Note} returns this
 */
proto.robin.proto3.logger.LoggerReport.Note.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.LoggerReport.Sitting.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.LoggerReport.Sitting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.LoggerReport.Sitting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerReport.Sitting.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.LoggerReport.Sitting}
 */
proto.robin.proto3.logger.LoggerReport.Sitting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.LoggerReport.Sitting;
  return proto.robin.proto3.logger.LoggerReport.Sitting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.LoggerReport.Sitting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.LoggerReport.Sitting}
 */
proto.robin.proto3.logger.LoggerReport.Sitting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.LoggerReport.Sitting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.LoggerReport.Sitting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.LoggerReport.Sitting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerReport.Sitting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.robin.proto3.logger.LoggerReport.Sitting.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.LoggerReport.Sitting} returns this
 */
proto.robin.proto3.logger.LoggerReport.Sitting.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.LoggerReport.Flying.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.LoggerReport.Flying.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.LoggerReport.Flying} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerReport.Flying.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.LoggerReport.Flying}
 */
proto.robin.proto3.logger.LoggerReport.Flying.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.LoggerReport.Flying;
  return proto.robin.proto3.logger.LoggerReport.Flying.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.LoggerReport.Flying} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.LoggerReport.Flying}
 */
proto.robin.proto3.logger.LoggerReport.Flying.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.LoggerReport.Flying.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.LoggerReport.Flying.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.LoggerReport.Flying} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerReport.Flying.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.robin.proto3.logger.LoggerReport.Flying.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.LoggerReport.Flying} returns this
 */
proto.robin.proto3.logger.LoggerReport.Flying.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.LoggerReport.DeterrenceAction.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.LoggerReport.DeterrenceAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.LoggerReport.DeterrenceAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerReport.DeterrenceAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.LoggerReport.DeterrenceAction}
 */
proto.robin.proto3.logger.LoggerReport.DeterrenceAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.LoggerReport.DeterrenceAction;
  return proto.robin.proto3.logger.LoggerReport.DeterrenceAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.LoggerReport.DeterrenceAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.LoggerReport.DeterrenceAction}
 */
proto.robin.proto3.logger.LoggerReport.DeterrenceAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.LoggerReport.DeterrenceAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.LoggerReport.DeterrenceAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.LoggerReport.DeterrenceAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerReport.DeterrenceAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.robin.proto3.logger.LoggerReport.DeterrenceAction.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.LoggerReport.DeterrenceAction} returns this
 */
proto.robin.proto3.logger.LoggerReport.DeterrenceAction.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional sint64 reportId = 1;
 * @return {number}
 */
proto.robin.proto3.logger.LoggerReport.prototype.getReportid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.LoggerReport} returns this
 */
proto.robin.proto3.logger.LoggerReport.prototype.setReportid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 timestamp_msec = 2;
 * @return {number}
 */
proto.robin.proto3.logger.LoggerReport.prototype.getTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.LoggerReport} returns this
 */
proto.robin.proto3.logger.LoggerReport.prototype.setTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional LoggerReportType loggerReportType = 3;
 * @return {!proto.robin.proto3.logger.LoggerReportType}
 */
proto.robin.proto3.logger.LoggerReport.prototype.getLoggerreporttype = function() {
  return /** @type {!proto.robin.proto3.logger.LoggerReportType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.robin.proto3.logger.LoggerReportType} value
 * @return {!proto.robin.proto3.logger.LoggerReport} returns this
 */
proto.robin.proto3.logger.LoggerReport.prototype.setLoggerreporttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional robin.proto3.LatLonAltPosition position = 4;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.logger.LoggerReport.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 4));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.logger.LoggerReport} returns this
*/
proto.robin.proto3.logger.LoggerReport.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LoggerReport} returns this
 */
proto.robin.proto3.logger.LoggerReport.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LoggerReport.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string userName = 5;
 * @return {string}
 */
proto.robin.proto3.logger.LoggerReport.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.LoggerReport} returns this
 */
proto.robin.proto3.logger.LoggerReport.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Note note = 10;
 * @return {?proto.robin.proto3.logger.LoggerReport.Note}
 */
proto.robin.proto3.logger.LoggerReport.prototype.getNote = function() {
  return /** @type{?proto.robin.proto3.logger.LoggerReport.Note} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.logger.LoggerReport.Note, 10));
};


/**
 * @param {?proto.robin.proto3.logger.LoggerReport.Note|undefined} value
 * @return {!proto.robin.proto3.logger.LoggerReport} returns this
*/
proto.robin.proto3.logger.LoggerReport.prototype.setNote = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LoggerReport} returns this
 */
proto.robin.proto3.logger.LoggerReport.prototype.clearNote = function() {
  return this.setNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LoggerReport.prototype.hasNote = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Sitting sitting = 11;
 * @return {?proto.robin.proto3.logger.LoggerReport.Sitting}
 */
proto.robin.proto3.logger.LoggerReport.prototype.getSitting = function() {
  return /** @type{?proto.robin.proto3.logger.LoggerReport.Sitting} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.logger.LoggerReport.Sitting, 11));
};


/**
 * @param {?proto.robin.proto3.logger.LoggerReport.Sitting|undefined} value
 * @return {!proto.robin.proto3.logger.LoggerReport} returns this
*/
proto.robin.proto3.logger.LoggerReport.prototype.setSitting = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LoggerReport} returns this
 */
proto.robin.proto3.logger.LoggerReport.prototype.clearSitting = function() {
  return this.setSitting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LoggerReport.prototype.hasSitting = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Flying flying = 12;
 * @return {?proto.robin.proto3.logger.LoggerReport.Flying}
 */
proto.robin.proto3.logger.LoggerReport.prototype.getFlying = function() {
  return /** @type{?proto.robin.proto3.logger.LoggerReport.Flying} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.logger.LoggerReport.Flying, 12));
};


/**
 * @param {?proto.robin.proto3.logger.LoggerReport.Flying|undefined} value
 * @return {!proto.robin.proto3.logger.LoggerReport} returns this
*/
proto.robin.proto3.logger.LoggerReport.prototype.setFlying = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LoggerReport} returns this
 */
proto.robin.proto3.logger.LoggerReport.prototype.clearFlying = function() {
  return this.setFlying(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LoggerReport.prototype.hasFlying = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional DeterrenceAction deterrenceAction = 13;
 * @return {?proto.robin.proto3.logger.LoggerReport.DeterrenceAction}
 */
proto.robin.proto3.logger.LoggerReport.prototype.getDeterrenceaction = function() {
  return /** @type{?proto.robin.proto3.logger.LoggerReport.DeterrenceAction} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.logger.LoggerReport.DeterrenceAction, 13));
};


/**
 * @param {?proto.robin.proto3.logger.LoggerReport.DeterrenceAction|undefined} value
 * @return {!proto.robin.proto3.logger.LoggerReport} returns this
*/
proto.robin.proto3.logger.LoggerReport.prototype.setDeterrenceaction = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LoggerReport} returns this
 */
proto.robin.proto3.logger.LoggerReport.prototype.clearDeterrenceaction = function() {
  return this.setDeterrenceaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LoggerReport.prototype.hasDeterrenceaction = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.DeletedLoggerReport.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.DeletedLoggerReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.DeletedLoggerReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.DeletedLoggerReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    loggerreporttype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.DeletedLoggerReport}
 */
proto.robin.proto3.logger.DeletedLoggerReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.DeletedLoggerReport;
  return proto.robin.proto3.logger.DeletedLoggerReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.DeletedLoggerReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.DeletedLoggerReport}
 */
proto.robin.proto3.logger.DeletedLoggerReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setReportid(value);
      break;
    case 2:
      var value = /** @type {!proto.robin.proto3.logger.LoggerReportType} */ (reader.readEnum());
      msg.setLoggerreporttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.DeletedLoggerReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.DeletedLoggerReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.DeletedLoggerReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.DeletedLoggerReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getLoggerreporttype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional sint64 reportId = 1;
 * @return {number}
 */
proto.robin.proto3.logger.DeletedLoggerReport.prototype.getReportid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.DeletedLoggerReport} returns this
 */
proto.robin.proto3.logger.DeletedLoggerReport.prototype.setReportid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional LoggerReportType loggerReportType = 2;
 * @return {!proto.robin.proto3.logger.LoggerReportType}
 */
proto.robin.proto3.logger.DeletedLoggerReport.prototype.getLoggerreporttype = function() {
  return /** @type {!proto.robin.proto3.logger.LoggerReportType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.robin.proto3.logger.LoggerReportType} value
 * @return {!proto.robin.proto3.logger.DeletedLoggerReport} returns this
 */
proto.robin.proto3.logger.DeletedLoggerReport.prototype.setLoggerreporttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.LogNoteMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.LogNoteMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.LogNoteMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LogNoteMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    comment: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.LogNoteMsg}
 */
proto.robin.proto3.logger.LogNoteMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.LogNoteMsg;
  return proto.robin.proto3.logger.LogNoteMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.LogNoteMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.LogNoteMsg}
 */
proto.robin.proto3.logger.LogNoteMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 2:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.LogNoteMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.LogNoteMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.LogNoteMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LogNoteMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
};


/**
 * optional string comment = 1;
 * @return {string}
 */
proto.robin.proto3.logger.LogNoteMsg.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.LogNoteMsg} returns this
 */
proto.robin.proto3.logger.LogNoteMsg.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional robin.proto3.LatLonAltPosition position = 2;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.logger.LogNoteMsg.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 2));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.logger.LogNoteMsg} returns this
*/
proto.robin.proto3.logger.LogNoteMsg.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LogNoteMsg} returns this
 */
proto.robin.proto3.logger.LogNoteMsg.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LogNoteMsg.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.robin.proto3.logger.LogObservationMsg.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.LogObservationMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.LogObservationMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LogObservationMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    comment: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f),
    observationtype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    speciesid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    birdcount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    behaviorid: jspb.Message.getFieldWithDefault(msg, 6, 0),
    bearing: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    timestampMsec: jspb.Message.getFieldWithDefault(msg, 8, 0),
    trackid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    trackidsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    trackdatabaseidsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.LogObservationMsg}
 */
proto.robin.proto3.logger.LogObservationMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.LogObservationMsg;
  return proto.robin.proto3.logger.LogObservationMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.LogObservationMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.LogObservationMsg}
 */
proto.robin.proto3.logger.LogObservationMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 2:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 3:
      var value = /** @type {!proto.robin.proto3.logger.ObservationType} */ (reader.readEnum());
      msg.setObservationtype(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSpeciesid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setBirdcount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setBehaviorid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBearing(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampMsec(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setTrackid(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint32() : [reader.readSint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTrackids(values[i]);
      }
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint64() : [reader.readSint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTrackdatabaseids(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.LogObservationMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.LogObservationMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LogObservationMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
  f = message.getObservationtype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSpeciesid();
  if (f !== 0) {
    writer.writeSint32(
      4,
      f
    );
  }
  f = message.getBirdcount();
  if (f !== 0) {
    writer.writeSint32(
      5,
      f
    );
  }
  f = message.getBehaviorid();
  if (f !== 0) {
    writer.writeSint64(
      6,
      f
    );
  }
  f = message.getBearing();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getTimestampMsec();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getTrackid();
  if (f !== 0) {
    writer.writeSint32(
      9,
      f
    );
  }
  f = message.getTrackidsList();
  if (f.length > 0) {
    writer.writePackedSint32(
      10,
      f
    );
  }
  f = message.getTrackdatabaseidsList();
  if (f.length > 0) {
    writer.writePackedSint64(
      11,
      f
    );
  }
};


/**
 * optional string comment = 1;
 * @return {string}
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional robin.proto3.LatLonAltPosition position = 2;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 2));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
*/
proto.robin.proto3.logger.LogObservationMsg.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ObservationType observationType = 3;
 * @return {!proto.robin.proto3.logger.ObservationType}
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.getObservationtype = function() {
  return /** @type {!proto.robin.proto3.logger.ObservationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.robin.proto3.logger.ObservationType} value
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.setObservationtype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional sint32 speciesId = 4;
 * @return {number}
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.getSpeciesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.setSpeciesid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional sint32 birdCount = 5;
 * @return {number}
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.getBirdcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.setBirdcount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional sint64 behaviorId = 6;
 * @return {number}
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.getBehaviorid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.setBehaviorid = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional float bearing = 7;
 * @return {number}
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.getBearing = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.setBearing = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int64 timestamp_msec = 8;
 * @return {number}
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.getTimestampMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.setTimestampMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional sint32 trackId = 9;
 * @return {number}
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.getTrackid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.setTrackid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated sint32 trackIds = 10;
 * @return {!Array<number>}
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.getTrackidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.setTrackidsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.addTrackids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.clearTrackidsList = function() {
  return this.setTrackidsList([]);
};


/**
 * repeated sint64 trackDatabaseIds = 11;
 * @return {!Array<number>}
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.getTrackdatabaseidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.setTrackdatabaseidsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.addTrackdatabaseids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.robin.proto3.logger.LogObservationMsg} returns this
 */
proto.robin.proto3.logger.LogObservationMsg.prototype.clearTrackdatabaseidsList = function() {
  return this.setTrackdatabaseidsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.LogDeterrenceActionMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.LogDeterrenceActionMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    deterrencemeansid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    speciesid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    speciescount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    bearing: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.LogDeterrenceActionMsg}
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.LogDeterrenceActionMsg;
  return proto.robin.proto3.logger.LogDeterrenceActionMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.LogDeterrenceActionMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.LogDeterrenceActionMsg}
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setDeterrencemeansid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSpeciesid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSpeciescount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBearing(value);
      break;
    case 5:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.LogDeterrenceActionMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.LogDeterrenceActionMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeterrencemeansid();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getSpeciesid();
  if (f !== 0) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = message.getSpeciescount();
  if (f !== 0) {
    writer.writeSint32(
      3,
      f
    );
  }
  f = message.getBearing();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
};


/**
 * optional sint64 deterrenceMeansId = 1;
 * @return {number}
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.prototype.getDeterrencemeansid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.LogDeterrenceActionMsg} returns this
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.prototype.setDeterrencemeansid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint32 speciesId = 2;
 * @return {number}
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.prototype.getSpeciesid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.LogDeterrenceActionMsg} returns this
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.prototype.setSpeciesid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional sint32 speciesCount = 3;
 * @return {number}
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.prototype.getSpeciescount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.LogDeterrenceActionMsg} returns this
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.prototype.setSpeciescount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional float bearing = 4;
 * @return {number}
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.prototype.getBearing = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.LogDeterrenceActionMsg} returns this
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.prototype.setBearing = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional robin.proto3.LatLonAltPosition position = 5;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 5));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.logger.LogDeterrenceActionMsg} returns this
*/
proto.robin.proto3.logger.LogDeterrenceActionMsg.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LogDeterrenceActionMsg} returns this
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LogDeterrenceActionMsg.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.LogRunwayInspectionMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.LogRunwayInspectionMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    comment: jspb.Message.getFieldWithDefault(msg, 1, ""),
    runway: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requestedbyatc: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fod: jspb.Message.getFieldWithDefault(msg, 4, 0),
    birdspresent: jspb.Message.getFieldWithDefault(msg, 5, 0),
    snow: jspb.Message.getFieldWithDefault(msg, 6, 0),
    slippery: jspb.Message.getFieldWithDefault(msg, 7, 0),
    activaterunway: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.LogRunwayInspectionMsg}
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.LogRunwayInspectionMsg;
  return proto.robin.proto3.logger.LogRunwayInspectionMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.LogRunwayInspectionMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.LogRunwayInspectionMsg}
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunway(value);
      break;
    case 3:
      var value = /** @type {!proto.robin.proto3.logger.RunwayInspectionType} */ (reader.readEnum());
      msg.setRequestedbyatc(value);
      break;
    case 4:
      var value = /** @type {!proto.robin.proto3.logger.RunwayInspectionType} */ (reader.readEnum());
      msg.setFod(value);
      break;
    case 5:
      var value = /** @type {!proto.robin.proto3.logger.RunwayInspectionType} */ (reader.readEnum());
      msg.setBirdspresent(value);
      break;
    case 6:
      var value = /** @type {!proto.robin.proto3.logger.RunwayInspectionType} */ (reader.readEnum());
      msg.setSnow(value);
      break;
    case 7:
      var value = /** @type {!proto.robin.proto3.logger.RunwayInspectionType} */ (reader.readEnum());
      msg.setSlippery(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActivaterunway(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.LogRunwayInspectionMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.LogRunwayInspectionMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRunway();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestedbyatc();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFod();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getBirdspresent();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSnow();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSlippery();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getActivaterunway();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string comment = 1;
 * @return {string}
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.LogRunwayInspectionMsg} returns this
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string runway = 2;
 * @return {string}
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.getRunway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.LogRunwayInspectionMsg} returns this
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.setRunway = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RunwayInspectionType requestedByAtc = 3;
 * @return {!proto.robin.proto3.logger.RunwayInspectionType}
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.getRequestedbyatc = function() {
  return /** @type {!proto.robin.proto3.logger.RunwayInspectionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.robin.proto3.logger.RunwayInspectionType} value
 * @return {!proto.robin.proto3.logger.LogRunwayInspectionMsg} returns this
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.setRequestedbyatc = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional RunwayInspectionType fod = 4;
 * @return {!proto.robin.proto3.logger.RunwayInspectionType}
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.getFod = function() {
  return /** @type {!proto.robin.proto3.logger.RunwayInspectionType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.robin.proto3.logger.RunwayInspectionType} value
 * @return {!proto.robin.proto3.logger.LogRunwayInspectionMsg} returns this
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.setFod = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional RunwayInspectionType birdsPresent = 5;
 * @return {!proto.robin.proto3.logger.RunwayInspectionType}
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.getBirdspresent = function() {
  return /** @type {!proto.robin.proto3.logger.RunwayInspectionType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.robin.proto3.logger.RunwayInspectionType} value
 * @return {!proto.robin.proto3.logger.LogRunwayInspectionMsg} returns this
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.setBirdspresent = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional RunwayInspectionType snow = 6;
 * @return {!proto.robin.proto3.logger.RunwayInspectionType}
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.getSnow = function() {
  return /** @type {!proto.robin.proto3.logger.RunwayInspectionType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.robin.proto3.logger.RunwayInspectionType} value
 * @return {!proto.robin.proto3.logger.LogRunwayInspectionMsg} returns this
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.setSnow = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional RunwayInspectionType slippery = 7;
 * @return {!proto.robin.proto3.logger.RunwayInspectionType}
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.getSlippery = function() {
  return /** @type {!proto.robin.proto3.logger.RunwayInspectionType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.robin.proto3.logger.RunwayInspectionType} value
 * @return {!proto.robin.proto3.logger.LogRunwayInspectionMsg} returns this
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.setSlippery = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool activateRunway = 8;
 * @return {boolean}
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.getActivaterunway = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.robin.proto3.logger.LogRunwayInspectionMsg} returns this
 */
proto.robin.proto3.logger.LogRunwayInspectionMsg.prototype.setActivaterunway = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.LogFeedbackMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.LogFeedbackMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.LogFeedbackMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LogFeedbackMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    position: (f = msg.getPosition()) && common3_pb.LatLonAltPosition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.LogFeedbackMsg}
 */
proto.robin.proto3.logger.LogFeedbackMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.LogFeedbackMsg;
  return proto.robin.proto3.logger.LogFeedbackMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.LogFeedbackMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.LogFeedbackMsg}
 */
proto.robin.proto3.logger.LogFeedbackMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new common3_pb.LatLonAltPosition;
      reader.readMessage(value,common3_pb.LatLonAltPosition.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.LogFeedbackMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.LogFeedbackMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.LogFeedbackMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LogFeedbackMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common3_pb.LatLonAltPosition.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.robin.proto3.logger.LogFeedbackMsg.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.robin.proto3.logger.LogFeedbackMsg} returns this
 */
proto.robin.proto3.logger.LogFeedbackMsg.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional robin.proto3.LatLonAltPosition position = 2;
 * @return {?proto.robin.proto3.LatLonAltPosition}
 */
proto.robin.proto3.logger.LogFeedbackMsg.prototype.getPosition = function() {
  return /** @type{?proto.robin.proto3.LatLonAltPosition} */ (
    jspb.Message.getWrapperField(this, common3_pb.LatLonAltPosition, 2));
};


/**
 * @param {?proto.robin.proto3.LatLonAltPosition|undefined} value
 * @return {!proto.robin.proto3.logger.LogFeedbackMsg} returns this
*/
proto.robin.proto3.logger.LogFeedbackMsg.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LogFeedbackMsg} returns this
 */
proto.robin.proto3.logger.LogFeedbackMsg.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LogFeedbackMsg.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.DeleteReportMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.DeleteReportMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.DeleteReportMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.DeleteReportMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    loggerreporttype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.DeleteReportMsg}
 */
proto.robin.proto3.logger.DeleteReportMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.DeleteReportMsg;
  return proto.robin.proto3.logger.DeleteReportMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.DeleteReportMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.DeleteReportMsg}
 */
proto.robin.proto3.logger.DeleteReportMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setReportid(value);
      break;
    case 2:
      var value = /** @type {!proto.robin.proto3.logger.LoggerReportType} */ (reader.readEnum());
      msg.setLoggerreporttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.DeleteReportMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.DeleteReportMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.DeleteReportMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.DeleteReportMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportid();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getLoggerreporttype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional sint64 reportId = 1;
 * @return {number}
 */
proto.robin.proto3.logger.DeleteReportMsg.prototype.getReportid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.DeleteReportMsg} returns this
 */
proto.robin.proto3.logger.DeleteReportMsg.prototype.setReportid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional LoggerReportType loggerReportType = 2;
 * @return {!proto.robin.proto3.logger.LoggerReportType}
 */
proto.robin.proto3.logger.DeleteReportMsg.prototype.getLoggerreporttype = function() {
  return /** @type {!proto.robin.proto3.logger.LoggerReportType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.robin.proto3.logger.LoggerReportType} value
 * @return {!proto.robin.proto3.logger.DeleteReportMsg} returns this
 */
proto.robin.proto3.logger.DeleteReportMsg.prototype.setLoggerreporttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.robin.proto3.logger.LoggerCommandMsg.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.robin.proto3.logger.LoggerCommandMsg.SubcommandCase = {
  SUBCOMMAND_NOT_SET: 0,
  LOGNOTE: 1,
  LOGOBSERVATION: 2,
  LOGDETERRENCEACTION: 3,
  LOGRUNWAYINSPECTION: 4,
  LOGFEEDBACK: 5,
  DELETEREPORT: 6
};

/**
 * @return {proto.robin.proto3.logger.LoggerCommandMsg.SubcommandCase}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.getSubcommandCase = function() {
  return /** @type {proto.robin.proto3.logger.LoggerCommandMsg.SubcommandCase} */(jspb.Message.computeOneofCase(this, proto.robin.proto3.logger.LoggerCommandMsg.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.LoggerCommandMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.LoggerCommandMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerCommandMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    lognote: (f = msg.getLognote()) && proto.robin.proto3.logger.LogNoteMsg.toObject(includeInstance, f),
    logobservation: (f = msg.getLogobservation()) && proto.robin.proto3.logger.LogObservationMsg.toObject(includeInstance, f),
    logdeterrenceaction: (f = msg.getLogdeterrenceaction()) && proto.robin.proto3.logger.LogDeterrenceActionMsg.toObject(includeInstance, f),
    logrunwayinspection: (f = msg.getLogrunwayinspection()) && proto.robin.proto3.logger.LogRunwayInspectionMsg.toObject(includeInstance, f),
    logfeedback: (f = msg.getLogfeedback()) && proto.robin.proto3.logger.LogFeedbackMsg.toObject(includeInstance, f),
    deletereport: (f = msg.getDeletereport()) && proto.robin.proto3.logger.DeleteReportMsg.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.LoggerCommandMsg}
 */
proto.robin.proto3.logger.LoggerCommandMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.LoggerCommandMsg;
  return proto.robin.proto3.logger.LoggerCommandMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.LoggerCommandMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.LoggerCommandMsg}
 */
proto.robin.proto3.logger.LoggerCommandMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.robin.proto3.logger.LogNoteMsg;
      reader.readMessage(value,proto.robin.proto3.logger.LogNoteMsg.deserializeBinaryFromReader);
      msg.setLognote(value);
      break;
    case 2:
      var value = new proto.robin.proto3.logger.LogObservationMsg;
      reader.readMessage(value,proto.robin.proto3.logger.LogObservationMsg.deserializeBinaryFromReader);
      msg.setLogobservation(value);
      break;
    case 3:
      var value = new proto.robin.proto3.logger.LogDeterrenceActionMsg;
      reader.readMessage(value,proto.robin.proto3.logger.LogDeterrenceActionMsg.deserializeBinaryFromReader);
      msg.setLogdeterrenceaction(value);
      break;
    case 4:
      var value = new proto.robin.proto3.logger.LogRunwayInspectionMsg;
      reader.readMessage(value,proto.robin.proto3.logger.LogRunwayInspectionMsg.deserializeBinaryFromReader);
      msg.setLogrunwayinspection(value);
      break;
    case 5:
      var value = new proto.robin.proto3.logger.LogFeedbackMsg;
      reader.readMessage(value,proto.robin.proto3.logger.LogFeedbackMsg.deserializeBinaryFromReader);
      msg.setLogfeedback(value);
      break;
    case 6:
      var value = new proto.robin.proto3.logger.DeleteReportMsg;
      reader.readMessage(value,proto.robin.proto3.logger.DeleteReportMsg.deserializeBinaryFromReader);
      msg.setDeletereport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.LoggerCommandMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.LoggerCommandMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerCommandMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLognote();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.robin.proto3.logger.LogNoteMsg.serializeBinaryToWriter
    );
  }
  f = message.getLogobservation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.robin.proto3.logger.LogObservationMsg.serializeBinaryToWriter
    );
  }
  f = message.getLogdeterrenceaction();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.robin.proto3.logger.LogDeterrenceActionMsg.serializeBinaryToWriter
    );
  }
  f = message.getLogrunwayinspection();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.robin.proto3.logger.LogRunwayInspectionMsg.serializeBinaryToWriter
    );
  }
  f = message.getLogfeedback();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.robin.proto3.logger.LogFeedbackMsg.serializeBinaryToWriter
    );
  }
  f = message.getDeletereport();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.robin.proto3.logger.DeleteReportMsg.serializeBinaryToWriter
    );
  }
};


/**
 * optional LogNoteMsg logNote = 1;
 * @return {?proto.robin.proto3.logger.LogNoteMsg}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.getLognote = function() {
  return /** @type{?proto.robin.proto3.logger.LogNoteMsg} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.logger.LogNoteMsg, 1));
};


/**
 * @param {?proto.robin.proto3.logger.LogNoteMsg|undefined} value
 * @return {!proto.robin.proto3.logger.LoggerCommandMsg} returns this
*/
proto.robin.proto3.logger.LoggerCommandMsg.prototype.setLognote = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.robin.proto3.logger.LoggerCommandMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LoggerCommandMsg} returns this
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.clearLognote = function() {
  return this.setLognote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.hasLognote = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LogObservationMsg logObservation = 2;
 * @return {?proto.robin.proto3.logger.LogObservationMsg}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.getLogobservation = function() {
  return /** @type{?proto.robin.proto3.logger.LogObservationMsg} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.logger.LogObservationMsg, 2));
};


/**
 * @param {?proto.robin.proto3.logger.LogObservationMsg|undefined} value
 * @return {!proto.robin.proto3.logger.LoggerCommandMsg} returns this
*/
proto.robin.proto3.logger.LoggerCommandMsg.prototype.setLogobservation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.robin.proto3.logger.LoggerCommandMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LoggerCommandMsg} returns this
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.clearLogobservation = function() {
  return this.setLogobservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.hasLogobservation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LogDeterrenceActionMsg logDeterrenceAction = 3;
 * @return {?proto.robin.proto3.logger.LogDeterrenceActionMsg}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.getLogdeterrenceaction = function() {
  return /** @type{?proto.robin.proto3.logger.LogDeterrenceActionMsg} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.logger.LogDeterrenceActionMsg, 3));
};


/**
 * @param {?proto.robin.proto3.logger.LogDeterrenceActionMsg|undefined} value
 * @return {!proto.robin.proto3.logger.LoggerCommandMsg} returns this
*/
proto.robin.proto3.logger.LoggerCommandMsg.prototype.setLogdeterrenceaction = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.robin.proto3.logger.LoggerCommandMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LoggerCommandMsg} returns this
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.clearLogdeterrenceaction = function() {
  return this.setLogdeterrenceaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.hasLogdeterrenceaction = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LogRunwayInspectionMsg logRunwayInspection = 4;
 * @return {?proto.robin.proto3.logger.LogRunwayInspectionMsg}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.getLogrunwayinspection = function() {
  return /** @type{?proto.robin.proto3.logger.LogRunwayInspectionMsg} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.logger.LogRunwayInspectionMsg, 4));
};


/**
 * @param {?proto.robin.proto3.logger.LogRunwayInspectionMsg|undefined} value
 * @return {!proto.robin.proto3.logger.LoggerCommandMsg} returns this
*/
proto.robin.proto3.logger.LoggerCommandMsg.prototype.setLogrunwayinspection = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.robin.proto3.logger.LoggerCommandMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LoggerCommandMsg} returns this
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.clearLogrunwayinspection = function() {
  return this.setLogrunwayinspection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.hasLogrunwayinspection = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional LogFeedbackMsg logFeedback = 5;
 * @return {?proto.robin.proto3.logger.LogFeedbackMsg}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.getLogfeedback = function() {
  return /** @type{?proto.robin.proto3.logger.LogFeedbackMsg} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.logger.LogFeedbackMsg, 5));
};


/**
 * @param {?proto.robin.proto3.logger.LogFeedbackMsg|undefined} value
 * @return {!proto.robin.proto3.logger.LoggerCommandMsg} returns this
*/
proto.robin.proto3.logger.LoggerCommandMsg.prototype.setLogfeedback = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.robin.proto3.logger.LoggerCommandMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LoggerCommandMsg} returns this
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.clearLogfeedback = function() {
  return this.setLogfeedback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.hasLogfeedback = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DeleteReportMsg deleteReport = 6;
 * @return {?proto.robin.proto3.logger.DeleteReportMsg}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.getDeletereport = function() {
  return /** @type{?proto.robin.proto3.logger.DeleteReportMsg} */ (
    jspb.Message.getWrapperField(this, proto.robin.proto3.logger.DeleteReportMsg, 6));
};


/**
 * @param {?proto.robin.proto3.logger.DeleteReportMsg|undefined} value
 * @return {!proto.robin.proto3.logger.LoggerCommandMsg} returns this
*/
proto.robin.proto3.logger.LoggerCommandMsg.prototype.setDeletereport = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.robin.proto3.logger.LoggerCommandMsg.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.robin.proto3.logger.LoggerCommandMsg} returns this
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.clearDeletereport = function() {
  return this.setDeletereport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.robin.proto3.logger.LoggerCommandMsg.prototype.hasDeletereport = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.LoggerReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.LoggerReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.LoggerReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    history: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.LoggerReportRequest}
 */
proto.robin.proto3.logger.LoggerReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.LoggerReportRequest;
  return proto.robin.proto3.logger.LoggerReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.LoggerReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.LoggerReportRequest}
 */
proto.robin.proto3.logger.LoggerReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHistory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.LoggerReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.LoggerReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.LoggerReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.LoggerReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHistory();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 history = 1;
 * @return {number}
 */
proto.robin.proto3.logger.LoggerReportRequest.prototype.getHistory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.LoggerReportRequest} returns this
 */
proto.robin.proto3.logger.LoggerReportRequest.prototype.setHistory = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.robin.proto3.logger.TimeMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.robin.proto3.logger.TimeMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.robin.proto3.logger.TimeMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.TimeMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeMsec: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.robin.proto3.logger.TimeMsg}
 */
proto.robin.proto3.logger.TimeMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.robin.proto3.logger.TimeMsg;
  return proto.robin.proto3.logger.TimeMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.robin.proto3.logger.TimeMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.robin.proto3.logger.TimeMsg}
 */
proto.robin.proto3.logger.TimeMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeMsec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.robin.proto3.logger.TimeMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.robin.proto3.logger.TimeMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.robin.proto3.logger.TimeMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.robin.proto3.logger.TimeMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeMsec();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 time_msec = 1;
 * @return {number}
 */
proto.robin.proto3.logger.TimeMsg.prototype.getTimeMsec = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.robin.proto3.logger.TimeMsg} returns this
 */
proto.robin.proto3.logger.TimeMsg.prototype.setTimeMsec = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.robin.proto3.logger.LoggerReportType = {
  SITTING: 0,
  FLYING: 1,
  DETERRENCE_ACTION: 2,
  NOTE: 3,
  UNKNOWN: 100
};

/**
 * @enum {number}
 */
proto.robin.proto3.logger.ObservationType = {
  OB_UNKNOWN: 0,
  OB_SITTING: 1,
  OB_FLYING: 2
};

/**
 * @enum {number}
 */
proto.robin.proto3.logger.RunwayInspectionType = {
  RI_YES: 0,
  RI_NO: 1,
  RI_NOTAPPLICABLE: 2,
  RI_NOTSET: 3
};

goog.object.extend(exports, proto.robin.proto3.logger);
