import * as mapboxgl from "mapbox-gl";
import { RadarOrientation } from "../../../domain/model";
import * as MapUtils from "../../../utils/MapUtils";
import { Feature, Position } from "geojson";
import { radToDeg } from "../../../utils/MathUtils";
import * as turf from "@turf/turf";
import { RadarUpdate } from "../modules/radars/RadarsModuleViewModel";

const POLYLINE_LAYER_ID = "layer-vr-azimuth-polyline";
const POLYGON_LAYER_ID = "layer-vr-azimuth-polygon";
const SOURCE_ID = "source-vr-azimuth";

const ARCS_ANGLE_DEGREES = 20.0;
const ARC_OPTIONS: { steps?: number; units?: turf.Units } = { units: "meters", steps: 128 };
const SHAPE_COLOR = "#ffffff";
const SHAPE_FILL_OPACITY = 0.2;
const SHAPE_BORDER_OPACITY = 0.8;

/**
 * a.k.a. Bowtie layer
 */
export class VerticalRadarAzimuthLayer {
    // Static functions

    public static attachedTo(map: mapboxgl.Map, orderLayer: string): VerticalRadarAzimuthLayer {
        return new VerticalRadarAzimuthLayer(map, orderLayer);
    }

    // Properties

    private constructor(private readonly map: mapboxgl.Map, private orderLayer: string) {
        this.map.addSource(SOURCE_ID, MapUtils.EMPTY_GEOJSON_SOURCE);

        const polygonLayer = this.getPolygonLayer();
        const polylineLayer = this.getPolylineLayer();
        this.map.addLayer(polygonLayer, this.orderLayer);
        this.map.addLayer(polylineLayer, polygonLayer.id);
    }

    // Public functions

    public setRadarUpdates(updates: RadarUpdate[]): void {
        const source = this.map.getSource(SOURCE_ID) as mapboxgl.GeoJSONSource;

        if (source == null) {
            return;
        }

        source.setData({
            type: "FeatureCollection",
            features: updates.map((update) => this.getFeaturesFromRadar(update.position, update.orientation)).flat(),
        });
    }

    public setEnabled(enabled: boolean): void {
        const visibility = enabled ? "visible" : "none";
        this.map.setLayoutProperty(POLYLINE_LAYER_ID, "visibility", visibility);
        this.map.setLayoutProperty(POLYGON_LAYER_ID, "visibility", visibility);
    }

    // Private functions

    private getPolylineLayer(): mapboxgl.LineLayer {
        return {
            id: POLYLINE_LAYER_ID,
            type: "line",
            source: SOURCE_ID,
            paint: {
                "line-color": SHAPE_COLOR,
                "line-opacity": SHAPE_BORDER_OPACITY,
                "line-width": 1,
            },
        };
    }

    private getPolygonLayer(): mapboxgl.FillLayer {
        return {
            id: POLYGON_LAYER_ID,
            type: "fill",
            source: SOURCE_ID,
            filter: ["!=", "$type", "LineString"],
            paint: {
                "fill-color": SHAPE_COLOR,
                "fill-opacity": SHAPE_FILL_OPACITY,
                "fill-antialias": true,
            },
        };
    }

    // Returns a bowtie-looking shape with a fill polygon and a border polyline
    private getFeaturesFromRadar(position: Position, orientation: RadarOrientation): Feature[] {
        const radarRange = orientation.range;
        const azimuth = radToDeg(orientation.azimuth);
        const d = ARCS_ANGLE_DEGREES / 2;
        const arcA = turf.lineArc(position, radarRange, azimuth - d, azimuth + d, ARC_OPTIONS).geometry;
        const arcB = turf.lineArc(position, radarRange, azimuth + 180.0 - d, azimuth + 180.0 + d, ARC_OPTIONS).geometry;
        const coords = [position, ...arcA.coordinates, position, ...arcB.coordinates, position];

        // Post-processing
        const bowtiePolygon = turf.polygon([coords]);
        const circleCut = turf.circle(position, radarRange / 10, ARC_OPTIONS);
        const fill = turf.flatten(turf.difference(bowtiePolygon, circleCut)!).features.flat();
        const border = fill.map((p) => turf.lineString(p.geometry.coordinates[0]));
        return [...fill, ...border];
    }
}
