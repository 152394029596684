import React, { useState } from "react";
import { Button } from "../appearance/button/Button";
import { FormControl } from "../appearance/forms/FormControl";
import { InputField } from "../appearance/forms/InputField";
import { t } from "i18next";
import { LoginFormContainer } from "./LoginFormContainer";
import { useBasicAuth } from "./useBasicAuth";
import { showError } from "../../utils/MessageUtils";

interface Props {
    currentUsername?: string;
}

/**
 * Renders a login form with username and password inputs, handling login calls via {@link useBasicAuth} hook.
 * @param currentUsername If set, the username input will be disabled and the value will be set to this.
 */
export const BasicAuthForm = ({ currentUsername }: Props): JSX.Element => {
    // Properties
    const isUsernameDisabled = currentUsername !== undefined && currentUsername.length > 0;
    const [usernameIsValid, setUsernameIsValid] = useState(true);
    const [passwordIsValid, setPasswordIsValid] = useState(true);
    const [username, setUsername] = useState(currentUsername || "");
    const [password, setPassword] = useState("");
    const { login, isBusy } = useBasicAuth({ onError: (error) => showError(error) });

    // Local functions

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value.trim();
        setUsername(value);
        setUsernameIsValid(value.length > 0);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value.trim();
        setPassword(value);
        setPasswordIsValid(value.length > 0);
    };

    const submitLoginForm = (e: React.MouseEvent): void => {
        e.preventDefault();
        if (!usernameIsValid || !passwordIsValid) {
            return;
        }
        login(username, password);
    };

    return (
        <LoginFormContainer vertical action={"#"}>
            <FormControl fullWidth vertical>
                <InputField
                    autoFocus
                    autoCapitalize="off"
                    disabled={isBusy || isUsernameDisabled}
                    hasError={!usernameIsValid}
                    large
                    onChange={handleUsernameChange}
                    placeholder={t("login.usernamePlaceholder")}
                    value={username}
                />
            </FormControl>
            <FormControl fullWidth vertical>
                <InputField
                    autoComplete="current-password"
                    disabled={isBusy}
                    hasError={!passwordIsValid}
                    large
                    onChange={handlePasswordChange}
                    placeholder={t("login.passwordPlaceholder")}
                    type="password"
                    value={password}
                />
            </FormControl>
            <Button disabled={isBusy} label={t("login.login")} onClick={(e) => submitLoginForm(e)} />
        </LoginFormContainer>
    );
};
