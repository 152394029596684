import { BaseViewModel } from "../BaseViewModel";
import {
    LocalPreferencesRepository,
    SessionRepository,
    LocationInfoRepository,
    RadarRepository,
    UIControlRepository,
    ServerConfigRepository,
} from "../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { LocalUserPreferenceKeys, SessionState, HomeUIState, User, AuthMethod } from "../../domain/model";
import { PlaybackState } from "../../domain/PlaybackScene";
import { ReplayRepository } from "../../domain/repositories/ReplayRepository";
import { nonNullObservable } from "../../utils/RxUtils";
import { TrackSelectionRepository } from "../../domain/repositories/TrackSelectionRepository";

export class HomeViewModel extends BaseViewModel {
    // Properties

    public get playbackState(): Rx.Observable<PlaybackState | null> {
        return this.replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((scene) => (scene != null ? scene.state : Rx.of(null))),
        );
    }

    /**
     * Session state observable which emits:
     * - `true` if the session is expired and we have the previously logged in user's username
     * - `false` if the user has logged in
     * - nothing for any other situation - in this case the session state will be handled by the App component
     *
     * In the case of NoAuth, this observable will not emit anything and silently log the user in again with empty credentials.
     */
    public get isSessionExpiredObservable(): Rx.Observable<boolean> {
        const isSessionExpiredObservable = nonNullObservable(
            this.sessionRepository.session.pipe(
                RxOperators.map((session) => session.state),
                RxOperators.distinctUntilChanged(),
                RxOperators.map((state) => {
                    switch (state) {
                        case SessionState.LoggedIn:
                            return false;
                        case SessionState.LoggingOutDueToUnauthorisedResponse:
                            return this.sessionRepository.usernameForReauth ? true : undefined;
                        default:
                            return undefined;
                    }
                }),
            ),
        );
        return this.serverConfigRepository.config.pipe(
            RxOperators.switchMap((config) => {
                if (config.authMethod !== AuthMethod.NoAuth) {
                    return isSessionExpiredObservable;
                }
                /**
                 * For NoAuth, we need to login with empty credentials and suppress the session expiry trigger.
                 */
                return isSessionExpiredObservable.pipe(
                    RxOperators.filter((isSessionExpired) => isSessionExpired),
                    RxOperators.switchMap(() =>
                        this.sessionRepository.loginBasicAuth("", "").pipe(RxOperators.ignoreElements()),
                    ),
                );
            }),
        );
    }

    public get currentUser(): Rx.Observable<User> {
        return nonNullObservable(this.sessionRepository.session.pipe(RxOperators.map((session) => session.user)));
    }

    public get hasSelectedTrack(): Rx.Observable<boolean> {
        return this.trackSelectionRepository.hasSelectedTrack;
    }

    public get radarHasMultipleOperatingModes(): Rx.Observable<boolean> {
        return this.radarRepository.radarHasMultipleOperatingModes;
    }

    public get isDynamicPositioningEnabled(): Rx.Observable<boolean> {
        return this.radarRepository.isDynamicPositioningEnabled.pipe(RxOperators.first());
    }

    public get hasRunways(): Rx.Observable<boolean> {
        return this.locationInfoRepository.hasRunways;
    }

    public get UIState(): Rx.Observable<HomeUIState> {
        return this.uiControlRepository.homeUIState;
    }

    public constructor(
        private readonly serverConfigRepository: ServerConfigRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly locationInfoRepository: LocationInfoRepository,
        private readonly sessionRepository: SessionRepository,
        private readonly replayRepository: ReplayRepository,
        private readonly trackSelectionRepository: TrackSelectionRepository,
        private readonly radarRepository: RadarRepository,
        private readonly uiControlRepository: UIControlRepository,
    ) {
        super();
        this.sessionRepository.subscribeToNewAccessTokens();
    }

    // Public functions

    public requestReplay(startTimestamp: long, endTimestamp: long): void {
        const scene = this.replayRepository.makeNewPlaybackScene(startTimestamp, endTimestamp, () => {
            this.uiControlRepository.toggleHomeUIComponent("isRequestingReplayVisible", { isVisible: true });
        });
        scene.playFrom(startTimestamp, this.getReplaySpeed());
    }

    public closeAllPanels(): void {
        this.uiControlRepository.hideHomeUIComponentSets("left", "right");
    }

    public closeAllModals(): void {
        this.uiControlRepository.hideHomeUIComponentSets("modal");
    }

    public hideAltitudeFilter(): void {
        this.uiControlRepository.toggleHomeUIComponent("isAltitudeFilterVisible", { isVisible: false });
        this.uiControlRepository.toggleHomeUIComponent("isAltitudeFilterSettingsVisible", { isVisible: false });
    }

    public toggleAltitudeFilterSettings(isVisible: boolean): void {
        this.uiControlRepository.toggleHomeUIComponent("isAltitudeFilterSettingsVisible", { isVisible });
    }

    public hideRadarLocationList(): void {
        this.uiControlRepository.toggleHomeUIComponent("isRadarLocationListModalVisible", { isVisible: false });
    }

    public toggleRunwayTrafficBottomSheet(isVisible: boolean): void {
        this.uiControlRepository.toggleHomeUIComponent("isRunwayTrafficBottomSheetVisible", { isVisible });
    }

    public hideSettings(): void {
        this.uiControlRepository.toggleHomeUIComponent("isSettingsVisible", { isVisible: false });
    }

    public toggleAboutModal(isVisible: boolean): void {
        this.uiControlRepository.toggleHomeUIComponent("isAboutModalVisible", { isVisible });
    }

    public hideAltitudeExplanationModal(): void {
        this.uiControlRepository.toggleHomeUIComponent("isAltitudeExplanationModalVisible", { isVisible: false });
    }

    public toggleUserManagementModal(isVisible: boolean): void {
        this.uiControlRepository.toggleHomeUIComponent("isUserManagementModalVisible", { isVisible });
    }

    public toggleLanguageSelectionModal(isVisible: boolean): void {
        this.uiControlRepository.toggleHomeUIComponent("isLanguageSelectionModalVisible", { isVisible });
    }

    public toggleLoginModal(isVisible: boolean): void {
        this.uiControlRepository.toggleHomeUIComponent("isLoginModalVisible", { isVisible });
    }

    public toggleTileProviderSelectionModal(isVisible: boolean): void {
        this.uiControlRepository.toggleHomeUIComponent("isTileProviderSelectionModalVisible", { isVisible });
    }

    public hideTrackList(): void {
        this.uiControlRepository.toggleHomeUIComponent("isTrackListVisible", { isVisible: false });
    }

    public hideDeterrence(): void {
        this.uiControlRepository.toggleHomeUIComponent("isDeterrenceVisible", { isVisible: false });
    }

    public hideObservation(): void {
        this.uiControlRepository.toggleHomeUIComponent("isObservationVisible", { isVisible: false });
    }

    // Private functions

    private getReplaySpeed(): number {
        return this.localPreferencesRepository.getPreference<number>(LocalUserPreferenceKeys.replay.speed)!;
    }
}
