import * as Rx from "rxjs";
import { GeolocationPosition } from "../../../domain/model";
import { UserLocationRepository, UserLocationState } from "../";

export class CommonUserLocationRepository implements UserLocationRepository {
    public readonly userLocationPosition: Rx.Observable<GeolocationPosition | undefined>;
    public readonly userLocationState: Rx.Observable<UserLocationState>;

    private readonly userLocationPositionSubject = new Rx.Subject<GeolocationPosition | undefined>();
    private readonly userLocationStateSubject = new Rx.BehaviorSubject<UserLocationState>(UserLocationState.INACTIVE);
    private subscriptions: Rx.Subscription | undefined;

    public constructor() {
        this.userLocationPosition = this.userLocationPositionSubject.asObservable();
        this.userLocationState = this.userLocationStateSubject.asObservable();
    }

    public setUserLocationPosition(position: GeolocationPosition | undefined): void {
        if (this.userLocationStateSubject.value !== UserLocationState.INACTIVE) {
            this.userLocationPositionSubject.next(position);
        }
    }

    public setUserLocationState(state: UserLocationState): void {
        this.userLocationStateSubject.next(state);
        if (state === UserLocationState.INACTIVE) {
            this.userLocationPositionSubject.next(undefined);
        }
    }
}
