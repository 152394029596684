import React from "react";
import { RadarStatus } from "../../domain/model";
import { RoundButton } from "../appearance/RoundButton";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import ReactTooltip from "react-tooltip";
import * as Rx from "rxjs";
import { t } from "i18next";
import { Theme } from "../appearance/theme/Theme";
import { withTheme } from "styled-components";

const TOOLTIP_ID = "radar-auto-start-tooltip";

interface Props {
    onRequestToggleRadar: () => Rx.Observable<void>;
    radarStatus: RadarStatus;
    theme: Theme;
}

interface State {
    isTogglingRadar: boolean;
}

class RadarStartStop extends BaseSubscriptionHandlerComponent<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            isTogglingRadar: false,
        };
    }

    // Public functions

    public render(): React.ReactNode {
        return (
            <div>
                <span
                    data-for={this.props.radarStatus.isOnAutoStart ? TOOLTIP_ID : null}
                    data-tip={t("radarControlPanel.radarAutoStartEnabled")}
                >
                    <RoundButton
                        background={
                            this.isRadarRunning()
                                ? this.props.theme.colors.status.danger
                                : this.props.theme.colors.status.info
                        }
                        isLoading={this.state.isTogglingRadar}
                        onClick={this.toggleSelectedRadar.bind(this)}
                        disabled={this.props.radarStatus.isOnAutoStart}
                        text={
                            this.isRadarRunning() ? t("radarControlPanel.stopRadar") : t("radarControlPanel.startRadar")
                        }
                    />
                </span>
                <ReactTooltip id={TOOLTIP_ID} effect={"solid"} place={"right"} />
            </div>
        );
    }

    // Private functions

    private isRadarRunning(): boolean {
        return this.props.radarStatus != null && this.props.radarStatus.driveRunning;
    }

    private toggleSelectedRadar(): void {
        this.setState({ isTogglingRadar: true });
        const subscription = this.props.onRequestToggleRadar().subscribe({
            complete: () => {
                this.setState({ isTogglingRadar: false });
            },
            error: (e) => {
                console.error(e);
                this.setState({ isTogglingRadar: false });
            },
        });
        this.collectSubscription(subscription);
    }
}

export const RadarStartStopView = withTheme(RadarStartStop);
