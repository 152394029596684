import * as RxOperators from "rxjs/operators";
import { AuthMethod, EMPTY_SERVER_VERSIONS, LocalUserPreferenceKeys, ServerConfig } from "../../../../domain/model";
import {
    SERVER_CONFIG_TIMEOUT_MSEC,
    ServerConfigRepository,
} from "../../../../domain/repositories/ServerConfigRepository";
import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import { isUnimplementedError } from "../../infrastructure/api/BirdViewerGrpcAPI";
import { LocalPreferencesRepository } from "../../../../domain/repositories";
import { UserManagementPermission } from "../../../../domain/model/UserPermission";

/**
 * This empty server config is only used to support older bird radar backends which do not implement getServerConfig
 */
const EMPTY_SERVER_CONFIG: ServerConfig = {
    authMethod: AuthMethod.BasicAuth,
    authorizeUrl: "",
    clientId: "",
    ssoProvider: undefined,
    ssoVersion: undefined,
    tokenIssuer: "",
    userManagementPermission: UserManagementPermission.None,
};

export class BirdViewerServerConfigRepository extends ServerConfigRepository {
    // Public functions

    public constructor(
        private readonly api: BirdViewerAPI,
        readonly localPreferencesRepository: LocalPreferencesRepository,
    ) {
        super(localPreferencesRepository);
        this.subscribeToServerConfig();
    }

    // Private functions

    private subscribeToServerConfig(): void {
        const subscription = this.api
            .getServerConfig()
            .pipe(RxOperators.timeout(SERVER_CONFIG_TIMEOUT_MSEC))
            .subscribe({
                next: (response) => {
                    this.configSubject.next(ServerConfig.fromProto(response));
                    const serverVersions = ServerConfig.serverVersionsfromProto(response);
                    if (serverVersions.radarSoftwareVersion === "-1") {
                        // If the server does not provide a radar software version, use the version from the local preferences
                        // For legacy systems
                        serverVersions.radarSoftwareVersion =
                            this.localPreferencesRepository.getPreference(
                                LocalUserPreferenceKeys.version.apiVersionName,
                            ) || "-1";
                    }
                    this.serverVersionsSubject.next(serverVersions);
                    this.isConfigLoadedSubject.next(true);
                },
                error: (error) => {
                    if (isUnimplementedError(error)) {
                        console.warn("Server config is not implemented", error);
                        this.configSubject.next(EMPTY_SERVER_CONFIG);
                        this.serverVersionsSubject.next(EMPTY_SERVER_VERSIONS);
                    } else {
                        console.error("Could not get server config", error);
                        this.configSubject.error(error);
                        this.serverVersionsSubject.error(error);
                    }
                },
            });
        this.subscriptions.add(subscription);
    }
}
