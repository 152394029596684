import { BaseViewModel } from "../BaseViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { SettingsSectionsProvider } from "./SettingsSectionsProvider";
import { SettingsErrorHandler } from "./SettingsErrorHandler";
import { SettingsUIActionRequestHandler } from "./SettingsUIActionRequestHandler";
import { SettingItemViewModel } from "./SettingItemViewModel";
import { nonNullObservable } from "../../utils/RxUtils";
import { SessionRepository, LocalPreferencesRepository } from "../../domain/repositories";
import { UserPermission } from "../../domain/model/UserPermission";
import { SettingsItemSection } from "./SettingsItemSection";
import { LocalUserPreferenceKeys } from "../../domain/model";

export class SettingsViewModel extends BaseViewModel {
    // Properties

    public get settingSectionsVisibilities(): Rx.Observable<Map<string, boolean>> {
        return this.settingSectionsVisibilitiesSubject.asObservable();
    }

    private get availablePermissions(): Rx.Observable<UserPermission[]> {
        return nonNullObservable(this.sessionRepository.session.pipe(RxOperators.map((session) => session.user))).pipe(
            RxOperators.map((user) => user.role.permissions),
        );
    }
    private readonly settingItemsSections = this.sectionsProvider.provide();
    private readonly settingSectionsVisibilitiesSubject = new Rx.BehaviorSubject<Map<string, boolean>>(new Map());

    public constructor(
        private readonly sectionsProvider: SettingsSectionsProvider,
        private readonly sessionRepository: SessionRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
    ) {
        super();

        this.getSectionIds()
            .pipe(RxOperators.flatMap((sectionId) => this.isSectionOpen(sectionId.id)))
            .subscribe((visibility) => {
                const newMap = new Map(this.settingSectionsVisibilitiesSubject.value);
                newMap.set(visibility[0], visibility[1]);
                this.settingSectionsVisibilitiesSubject.next(newMap);
            });
    }

    // Public functions

    public getSectionIds(): Rx.Observable<SettingsItemSection> {
        return this.settingItemsSections.pipe(RxOperators.flatMap((sections) => Rx.from(sections)));
    }

    public getSettingItems(
        sectionId: string,
        errorHandler: SettingsErrorHandler,
        uiActionRequestHandler: SettingsUIActionRequestHandler,
    ): Rx.Observable<SettingItemViewModel[][]> {
        return this.getSectionById(sectionId).pipe(
            RxOperators.flatMap((section) =>
                this.checkPermissions(section.generate(errorHandler, uiActionRequestHandler)),
            ),
            RxOperators.toArray(),
        );
    }

    public setSectionOpen(sectionId: string, open: boolean): void {
        const key = LocalUserPreferenceKeys.settingsSectionOpenPrefix + sectionId;
        this.localPreferencesRepository.setPreference<boolean>(key, open);
    }

    // Private functions

    private isSectionOpen(sectionId: string): Rx.Observable<[string, boolean]> {
        const key = LocalUserPreferenceKeys.settingsSectionOpenPrefix + sectionId;
        const subject = new Rx.BehaviorSubject<[string, boolean]>([sectionId, true]);

        this.localPreferencesRepository.observePreference<boolean>(key).subscribe((isOpen) => {
            subject.next([sectionId, isOpen!]);
        });
        return subject;
    }

    private checkPermissions(
        generatedModels: Rx.Observable<SettingItemViewModel[]>,
    ): Rx.Observable<SettingItemViewModel[]> {
        return Rx.combineLatest([generatedModels, this.availablePermissions]).pipe(
            RxOperators.take(1),
            RxOperators.map(([viewModels, availablePermissions]) =>
                viewModels.filter((vm) => vm.requiredPermissions.every((p) => availablePermissions.includes(p))),
            ),
        );
    }

    private getSectionById(sectionId: string): Rx.Observable<SettingsItemSection> {
        return this.settingItemsSections.pipe(
            RxOperators.flatMap((sections) => Rx.from(sections)),
            RxOperators.filter((section) => section.id === sectionId),
            RxOperators.take(1),
        );
    }
}
