import * as RxOperators from "rxjs/operators";
import { LocalPreferencesRepository } from "../domain/repositories";
import { useObservable } from "./useObservable";
import { TYPES } from "../di/Types";
import DI from "../di/DI";

export function usePreference<T>(key: string, defaultValue: T): T {
    const localPreferencesRepository = DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository);
    return useObservable(
        localPreferencesRepository.observePreference<T>(key).pipe(RxOperators.map((theme) => theme ?? defaultValue)),
        defaultValue,
    );
}
