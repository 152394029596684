import { BlankingSectorJSON } from "./json";
import { BlankingSector as BlankingSectorProto } from "./proto/generated/blankingsectors3_pb";

export class BlankingSector {
    // Static functions

    public static fromProto(model: BlankingSectorProto): BlankingSector {
        return new BlankingSector(model.getStartangledeg(), model.getSpandeg());
    }

    public static fromJson(model: BlankingSectorJSON): BlankingSector {
        return new BlankingSector(model.startAngle, model.span);
    }

    public constructor(public readonly startAngle: number, public readonly span: number) {}
}
