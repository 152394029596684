import mapboxgl, { GeoJSONSourceRaw, ImageSourceRaw } from "mapbox-gl";
import * as turf from "@turf/turf";
import { renderToStaticMarkup } from "react-dom/server";
import { Location } from "../domain/model";
import { degreesToRadians } from "@turf/turf";

const EARTH_RADIUS = 6378.137; // Earth radius at equator in km
const TILE_SIZE = 512; // Map tile size in pixels

export const EMPTY_FEATURE_COLLECTION: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: "FeatureCollection",
    features: [],
};

export const EMPTY_GEOJSON_SOURCE: GeoJSONSourceRaw = {
    type: "geojson",
    data: EMPTY_FEATURE_COLLECTION,
};

export const EMPTY_IMAGE_SOURCE: ImageSourceRaw = {
    type: "image",
    // Empty image
    url: "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
    coordinates: [
        [0, 0],
        [1, 0],
        [1, 1],
        [0, 1],
    ],
};

export const DEFAULT_MAP_STYLE_URL = "/mapstyle.default.json";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function generateStops(): any[][] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stops: any[][] = [];
    for (let i = -360; i < 360; i++) {
        stops.push([i, i]);
    }
    return stops;
}

export const ROTATION_STOPS = generateStops();

export function latLngPlusKM(latLng: double[], dx: double, dy: double): double[] {
    const latitude = latLng[0];
    const longitude = latLng[1];
    const newLatitude = latitude + (dy / EARTH_RADIUS) * (180 / Math.PI);
    const newLongitude = longitude + ((dx / EARTH_RADIUS) * (180 / Math.PI)) / Math.cos((latitude * Math.PI) / 180);
    return [newLatitude, newLongitude];
}

export function lngLatPlusKM(lngLat: double[], dx: double, dy: double): double[] {
    const result = latLngPlusKM([lngLat[1], lngLat[0]], dx, dy);
    return [result[1], result[0]];
}

export function loadImageFromSVG(svg: string, onload: (image: HTMLImageElement) => void): void {
    const image = new Image();
    image.onload = () => {
        onload(image);
    };
    image.src = svg;
}

export function encodeSvg(reactElement: React.ReactElement): string {
    return "data:image/svg+xml," + escape(renderToStaticMarkup(reactElement));
}

export function flyToIfFarAway(
    map: mapboxgl.Map,
    target: Location,
    cameraCenter: Location,
    distanceThresholdMeters: number,
): void {
    const currentDistance = turf.distance(target.toGeoJSONLocation(), cameraCenter.toGeoJSONLocation(), {
        units: "meters",
    });
    if (currentDistance > distanceThresholdMeters) {
        map.flyTo({ center: [target.longitude, target.latitude] });
    }
}

export function getMetersPerPixel(map: mapboxgl.Map): number {
    const latitudeRadians = degreesToRadians(map.getCenter().lat);
    const equatorialCircumferenceMeters = 2 * Math.PI * EARTH_RADIUS * 1_000;
    const zoomLevel = map.getZoom();
    // Formula taken from here: https://wiki.openstreetmap.org/wiki/Zoom_levels
    const metersPerTile = (equatorialCircumferenceMeters * Math.cos(latitudeRadians)) / Math.pow(2, zoomLevel);
    return metersPerTile / TILE_SIZE;
}
