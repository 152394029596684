import { kebabify } from "../../utils/StringUtils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PreferencesMap = Map<string, any>;

const USER_PREFERENCES = "USER_PREFERENCES";
const SETTINGS = USER_PREFERENCES + ".SETTINGS";
const FILTERS = USER_PREFERENCES + ".FILTERS";
const TRACK = USER_PREFERENCES + ".TRACK";
const CLASSIFICATION_COLOR_PREFIX = TRACK + ".CLASSIFICATION_COLOR_PREFIX_";

export const ServerUserPreferenceKeys = {
    settings: {
        showInboundIncursionToggle: SETTINGS + ".SHOW_INBOUND_INCURSION_TOGGLE",
    },
    filters: {
        showDeterrenceDevices: FILTERS + ".SHOW_DETERRENCE_DEVICES",
        showGrid: FILTERS + ".SHOW_GRID",
    },
    track: {
        classificationColors: {
            forName: function (name: string): string {
                return CLASSIFICATION_COLOR_PREFIX + name;
            },
            matches: function (dataKey: string): boolean {
                return dataKey.startsWith(CLASSIFICATION_COLOR_PREFIX);
            },
        },
    },
};

export const LocalUserPreferenceKeys = {
    replay: {
        speed: "replay-speed",
    },
    user: {
        deviceId: "device-id",
        sessionId: "session-id",
        name: "user-name",
        accessToken: "access-token",
        nonce: "nonce",
        oauthState: "oauthState",
        user: "user",
    },
    filters: {
        showRadarRange: "show-radar-range",
        deprecatedShowAltitudes: "show-altitudes",
        deprecatedShowADSB: "show-adsb",
        showADSBAircraft: "show-adsb-aircraft",
        showADSBVehicles: "show-adsb-vehicles",
        showADSBRotorcraft: "show-adsb-rotorcraft",
        showADSBDrones: "show-adsb-drones",
        showADSBOther: "show-adsb-other",
        showUserLocationRange: "show-user-location-range",
        minAltitudeOfInterest: "min-altitude-of-interest",
        maxAltitudeOfInterest: "max-altitude-of-interest",
        classificationVisibilityPrefix: "classification-visibility-",
        overlayVisibilityPrefix: "overlay-visibility-",
        trackLabel: "track-label",
        classificationApplyAltitudeFilterPrefix: "classification-apply-altitude-filter-",
        applyAltitudeFilterADSBAircraft: "apply-altitude-filter-adsb-aircraft",
        applyAltitudeFilterADSBVehicles: "apply-altitude-filter-adsb-vehicles",
        applyAltitudeFilterADSBRotorcraft: "apply-altitude-filter-adsb-rotorcraft",
        applyAltitudeFilterADSBDrone: "apply-altitude-filter-adsb-drone",
        applyAltitudeFilterADSBOther: "apply-altitude-filter-adsb-other",
        showTracksWithoutAltitude: "show-tracks-without-altitude",
        lockMapToVehicleOrientation: "lock-map-to-vehicle-orientation",
    },
    selections: {
        selectedDistanceUnitName: "distance-unit-name",
        selectedTileProviderId: "tile-provider-id",
        selectedAirBaseId: "selected-airbase-id",
    },
    appearance: {
        iconScale: "icon-scale",
        trailLength: "trail-length",
        mapBrightness: "map-brightness",
        finishedTrackLifetime: "finished-track-lifetime",
        finishedTrackOpacity: "finished-track-opacity",
        showClassificationHistoryInTrajectory: "show-classification-history-in-trajectory",
        showBlankingSectors: "show-blanking-sectors",
        rangeRingsOpacity: "range-rings-opacity",
        displayNegativeAltitudeAsZero: "display-negative-altitude-as-zero",
        timeDisplayMode: "time-display-mode",
        showTrackMeasurements: "show-track-measurements",
        showVRAzimuth: "show-vr-azimuth",
        velocityUnit: "velocity-unit",
        showVrOnlyTracks: "show-vr-only-tracks",
        theme: "theme",
        altitudeConfig: "altitude-config",
    },
    charts: {
        showRunwayTrafficBottomSheet: "show-runway-traffic-bottom-sheet",
        selectedRunwayId: "selected-runway-id",
        showFunnelViewParkingCorridor: "show-funnel-view-parking-corridor",
        runwayCrossingsThreshold: "runway-crossings-threshold",
        funnelViewThreshold: "funnel-view-threshold",
    },
    alarm: {
        deprecatedSoundEnabled: "alarm-sound-active",
        alarmTrack: "alarm-track-active",
        areaEntry: "area-entry-active",
        alarmRunwayCrossing: "alarm-runway-crossing-enabled",
        alarmFunnelTraffic: "alarm-funnel-traffic-enabled",
        soundEnabledTrack: "sound-enabled-track",
        soundEnabledAreaEntry: "sound-enabled-area-entry",
        soundEnabledRunwayCrossing: "sound-enabled-runway-crossing",
        soundEnabledFunnelTraffic: "sound-enabled-funnel-traffic",
    },
    radar: {
        imageContrast: "radar-image-contrast",
    },
    settingsSectionOpenPrefix: "settings-section-open-",
    legendSectionOpenPrefix: "legend-section-open-",
    altitudeFilterSectionOpenPrefix: "altitude-filter-section-open-",
    language: "language",
    version: {
        /**
         * @deprecated This key is deprecated, use version info from server config instead.
         */
        apiVersionName: "api-version",
    },
    observation: {
        recentlyUsed: "recently-used",
    },
};

export function getClassificationVisibilityKey(classificationName: string): string {
    const prefix = LocalUserPreferenceKeys.filters.classificationVisibilityPrefix;
    return prefix + kebabify(classificationName);
}

export function getClassificationAltitudeFilterKey(classificationName: string): string {
    const prefix = LocalUserPreferenceKeys.filters.classificationApplyAltitudeFilterPrefix;
    return prefix + kebabify(classificationName);
}

export const ALARMS_ENABLED_KEYS = [
    LocalUserPreferenceKeys.alarm.alarmTrack,
    LocalUserPreferenceKeys.alarm.areaEntry,
    LocalUserPreferenceKeys.alarm.alarmRunwayCrossing,
    LocalUserPreferenceKeys.alarm.alarmFunnelTraffic,
];

export const ALARM_SOUND_ENABLED_KEYS = [
    LocalUserPreferenceKeys.alarm.soundEnabledTrack,
    LocalUserPreferenceKeys.alarm.soundEnabledAreaEntry,
    LocalUserPreferenceKeys.alarm.soundEnabledRunwayCrossing,
    LocalUserPreferenceKeys.alarm.soundEnabledFunnelTraffic,
];
