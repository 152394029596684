import React from "react";
import styled, { useTheme } from "styled-components";
import { ReplayTimeViewModel } from "./ReplayTimeViewModel";
import { TYPES } from "../../../../di/Types";
import { t } from "i18next";
import { Clock } from "./Clock";
import { useViewModel } from "../../../../hooks/useViewModel";
import { useObservable } from "../../../../hooks";
import { PlaybackState } from "../../../../domain/PlaybackScene";
import { BASE_TEXT_STYLE } from "../../../appearance/theme/GlobalStyles";
import { ScaleLoader } from "react-spinners";
import { StatusView } from "./StatusView";
import { ClockContainer } from "../../../appearance/Sidebar";

const LoadingContainer = styled.p`
    margin-top: ${({ theme }) => theme.spacing.x4};
    text-align: center;
`;

const LoadingText = styled.span`
    ${BASE_TEXT_STYLE}
    margin-left: ${({ theme }) => theme.spacing.x2};
`;

export const ReplayTimeView = (): JSX.Element => {
    // Properties

    const viewModel: ReplayTimeViewModel = useViewModel(TYPES.ReplayTimeViewModel);
    const replayTime = useObservable(viewModel.time, "");
    const playbackState = useObservable(viewModel.playbackState, null);

    // Render

    return (
        <ClockContainer>
            <Clock time={replayTime} />
            <LoadingReplay playbackState={playbackState} />
        </ClockContainer>
    );
};

interface LoadingReplayProps {
    playbackState: PlaybackState | null;
}

const LoadingReplay = ({ playbackState }: LoadingReplayProps): JSX.Element => {
    // Properties

    const theme = useTheme();

    // Render

    if (playbackState === PlaybackState.LOADING) {
        return (
            <>
                <LoadingContainer>
                    <ScaleLoader color={theme.colors.text.text} height="10px" width="1px" loading={true} />
                    <LoadingText>{t("replay.loadingReplayData")}</LoadingText>
                </LoadingContainer>
            </>
        );
    } else {
        return <StatusView />;
    }
};
