import { ColorMode } from "../components/appearance/theme/Theme";
import { LocalUserPreferenceKeys, TrackLabel } from "./model";
import { AltitudeConfig } from "./model/Altitude";
import { DEFAULT_DISTANCE_UNIT } from "./model/DistanceUnit";
import { DEFAULT_TIME_DISPLAY_MODE } from "./model/TimeDisplayMode";
import { DEFAULT_VELOCITY_UNIT } from "./model/VelocityUnit";

export interface DefaultValueProvider {
    getValue<T>(key: string): T | null;
}

export class CommonDefaultValueProvider implements DefaultValueProvider {
    // Functions

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getValue(key: string): any {
        switch (key) {
            case LocalUserPreferenceKeys.filters.trackLabel:
                // Backwards compatibility with old preferences
                return localStorage.getItem(LocalUserPreferenceKeys.filters.deprecatedShowAltitudes)
                    ? TrackLabel.ALTITUDE
                    : TrackLabel.NONE;
            case LocalUserPreferenceKeys.filters.showADSBAircraft:
            case LocalUserPreferenceKeys.filters.showADSBVehicles:
            case LocalUserPreferenceKeys.filters.showADSBDrones:
            case LocalUserPreferenceKeys.filters.showADSBRotorcraft:
            case LocalUserPreferenceKeys.filters.showADSBOther:
                // Backwards compatibility with old preferences
                const showADSB = localStorage.getItem(LocalUserPreferenceKeys.filters.deprecatedShowADSB);
                return showADSB != null ? (JSON.parse(showADSB) as boolean) : true;
            case LocalUserPreferenceKeys.appearance.iconScale:
                return 1;
            case LocalUserPreferenceKeys.appearance.trailLength:
                return 10000;
            case LocalUserPreferenceKeys.appearance.rangeRingsOpacity:
                return 0.6;
            case LocalUserPreferenceKeys.appearance.displayNegativeAltitudeAsZero:
                return true;
            case LocalUserPreferenceKeys.appearance.timeDisplayMode:
                return DEFAULT_TIME_DISPLAY_MODE;
            case LocalUserPreferenceKeys.appearance.velocityUnit:
                return DEFAULT_VELOCITY_UNIT;
            case LocalUserPreferenceKeys.replay.speed:
                return 1;
            case LocalUserPreferenceKeys.appearance.mapBrightness:
                return 10;
            case LocalUserPreferenceKeys.appearance.finishedTrackLifetime:
                return 0;
            case LocalUserPreferenceKeys.appearance.finishedTrackOpacity:
                return 0.4;
            case LocalUserPreferenceKeys.appearance.showVRAzimuth:
                return true;
            case LocalUserPreferenceKeys.selections.selectedDistanceUnitName:
                return DEFAULT_DISTANCE_UNIT;
            case LocalUserPreferenceKeys.charts.showFunnelViewParkingCorridor:
                return true;
            case LocalUserPreferenceKeys.charts.runwayCrossingsThreshold:
                return 20;
            case LocalUserPreferenceKeys.charts.funnelViewThreshold:
                return 20;
            case LocalUserPreferenceKeys.appearance.showClassificationHistoryInTrajectory:
                return false;
            case LocalUserPreferenceKeys.alarm.alarmTrack:
                return true;
            case LocalUserPreferenceKeys.alarm.soundEnabledTrack:
                return this.deprecatedSoundEnabled ?? true;
            case LocalUserPreferenceKeys.alarm.areaEntry:
                return true;
            case LocalUserPreferenceKeys.alarm.soundEnabledAreaEntry:
                return this.deprecatedSoundEnabled ?? false;
            case LocalUserPreferenceKeys.alarm.alarmRunwayCrossing:
                return true;
            case LocalUserPreferenceKeys.alarm.soundEnabledRunwayCrossing:
                return this.deprecatedSoundEnabled ?? true;
            case LocalUserPreferenceKeys.alarm.alarmFunnelTraffic:
                return true;
            case LocalUserPreferenceKeys.alarm.soundEnabledFunnelTraffic:
                return this.deprecatedSoundEnabled ?? true;
            case LocalUserPreferenceKeys.observation.recentlyUsed:
                return [];
            case LocalUserPreferenceKeys.filters.lockMapToVehicleOrientation:
                return false;
            case LocalUserPreferenceKeys.appearance.showVrOnlyTracks:
                return false;
            case LocalUserPreferenceKeys.appearance.theme:
                return ColorMode.Dark;
            case LocalUserPreferenceKeys.appearance.altitudeConfig:
                return AltitudeConfig.WGS84;
            case LocalUserPreferenceKeys.filters.showTracksWithoutAltitude:
                return true;
        }

        if (key.startsWith(LocalUserPreferenceKeys.settingsSectionOpenPrefix)) {
            return true;
        }
        if (key.startsWith(LocalUserPreferenceKeys.legendSectionOpenPrefix)) {
            return true;
        }
        if (key.startsWith(LocalUserPreferenceKeys.altitudeFilterSectionOpenPrefix)) {
            return true;
        }
        return null;
    }

    // Backwards compatibility with old preferences
    private get deprecatedSoundEnabled(): boolean | undefined {
        const soundEnabled = localStorage.getItem(LocalUserPreferenceKeys.alarm.deprecatedSoundEnabled);
        return soundEnabled != null ? (JSON.parse(soundEnabled) as boolean) : undefined;
    }
}
