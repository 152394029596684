import React, { Component, PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";
import { Colors } from "./Colors";
import Loading from "./Loading";

interface Props {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isLoading?: boolean;
    text?: string;
    disabled: boolean;
    background: string;
    fillWidth?: boolean;
    color?: string;
}

const Button = styled.button<Props>`
    text-transform: uppercase;
    position: relative;
    padding: 11px 35px;
    border: none;
    border-radius: 100px;
    color: ${({ theme, color }) => color || theme.colors.text.text};
    flex-grow: ${(props) => (props.fillWidth ? "1" : "unset")};
    cursor: ${(props: Props) => (props.disabled ? "not-allowed" : "pointer")};
    font-weight: 500;
    font-size: 14px;
    background-color: ${(props: Props) => (props.disabled ? "rgba(255, 255, 255, 0.2)" : props.background)};
    :hover {
        opacity: ${(props: Props) => (props.disabled ? 1 : 0.9)};
    }
    :active {
        opacity: ${(props: Props) => (props.disabled ? 1 : 0.6)};
    }
`;

const LoadingContainer = styled.span`
    left: 50%;
    position: absolute;
    margin-left: -7.5px;
    top: 50%;
    margin-top: -7.5px;
`;

/**
 * @deprecated Use `Button` instead.
 */
export class RoundButton extends Component<PropsWithChildren<Props & React.HTMLAttributes<HTMLButtonElement>>> {
    // Static properties

    public static defaultProps = {
        isLoading: false,
        disabled: false,
        background: Colors.status.info,
    };

    // Public functions

    public render(): JSX.Element {
        const { children, ...rest } = this.props;
        return (
            <Button onClick={this.props.isLoading ? undefined : this.handleClick} {...rest}>
                <span style={{ opacity: this.props.isLoading ? 0 : 1 }}>{this.props.text}</span>
                {this.props.isLoading && (
                    <LoadingContainer>
                        <Loading />
                    </LoadingContainer>
                )}
                {!this.props.isLoading && children}
            </Button>
        );
    }

    private handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        // Prevent the button from submitting a form
        event.preventDefault();
        this.props.onClick?.(event);
    };
}

export const StyledCircleButton = styled(Button)`
    width: 40px;
    height: 40px;
    padding: 0;
    align-items: center;
    justify-content: center;
`;

interface CircleProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isLoading?: boolean;
    disabled: boolean;
    background: string;
    fillWidth?: boolean;
}

/**
 * @deprecated Use `Button` instead (a round variant needs to be created still).
 */
export class CircleButton extends Component<PropsWithChildren<CircleProps>> {
    // Static properties

    public static defaultProps = {
        isLoading: false,
        disabled: false,
        background: Colors.status.info,
    };

    // Public functions

    public render(): ReactNode {
        const { onClick, children, ...restOfProps } = this.props;
        return (
            <StyledCircleButton onClick={this.props.isLoading ? undefined : onClick} {...restOfProps}>
                {this.props.isLoading ? null : children}
                {this.props.isLoading && (
                    <LoadingContainer>
                        <Loading />
                    </LoadingContainer>
                )}
            </StyledCircleButton>
        );
    }
}
