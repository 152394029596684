import { BaseViewModel } from "../BaseViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { RadarRepository } from "../../domain/repositories/RadarRepository";
import { Radar, Location, BlankingSectorsState, RadarOperatingMode } from "../../domain/model";
import { LocalPreferencesRepository } from "../../domain/repositories/LocalPreferencesRepository";
import { LocalUserPreferenceKeys } from "../../domain/model/PreferencesData";
import { DistanceUnit } from "../../domain/model/DistanceUnit";
import { DistanceFormatter } from "../../domain/DistanceFormatter";
import { BlankingSector } from "../../domain/model/BlankingSector";
import { UIControlRepository } from "../../domain/repositories";

export class RadarControlPanelViewModel extends BaseViewModel {
    // Properties

    public get errorObservable(): Rx.Observable<Error> {
        return this.error.asObservable();
    }
    public get hasMultipleRadarsObservable(): Rx.Observable<boolean> {
        return this.radarRepository.radars.pipe(RxOperators.map((radars) => radars.length > 1));
    }
    public get selectedRadarObservable(): Rx.Observable<Radar | undefined> {
        return Rx.combineLatest([this.radarRepository.radars, this.radarRepository.selectedRadarId]).pipe(
            RxOperators.map(([radars, selectedRadarId]) => {
                this.selectedRadarIndex = radars.findIndex((radar) => radar.id === selectedRadarId);
                if (this.selectedRadarIndex < 0) {
                    return undefined;
                }
                return radars[this.selectedRadarIndex];
            }),
        );
    }
    public get previewPositionFromDraggingMap(): Rx.Observable<Location> {
        return this.radarRepository.repositioningState.pipe(
            RxOperators.filter((state) => state.position !== undefined && state.fromDraggingMap === true),
            RxOperators.map((state) => state.position!),
        );
    }
    public get altitudeUnitObservable(): Rx.Observable<DistanceUnit> {
        return this.distanceFormatter.selectedDistanceUnitObservable;
    }
    public get canShowRepositionPanel(): boolean {
        return this.radarRepository.canRepositionRadar;
    }
    public get canShowAlignmentPanel(): boolean {
        return this.radarRepository.canAlignRadar;
    }
    public get availableOperatingModesObservable(): Rx.Observable<RadarOperatingMode[]> {
        return this.radarRepository.getAvailableRadarOperatingModes();
    }
    public get isDynamicPositioningEnabled(): Rx.Observable<boolean> {
        return this.radarRepository.isDynamicPositioningEnabled;
    }

    private error = new Rx.Subject<Error>();
    private radars: Radar[] = [];
    private selectedRadarIndex = -1;

    // Lifecycle

    public constructor(
        public readonly distanceFormatter: DistanceFormatter,
        private readonly radarRepository: RadarRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly uiControlRepository: UIControlRepository,
    ) {
        super();
        this.subscribeToRadars();
    }

    // Public functions

    public getMaxNumberOfBlankingSectors(radarId: int): Rx.Observable<number> {
        return this.radarRepository.getMaxNumberOfBlankingSectors(radarId);
    }

    public getBlankingSectors(radarId: int): Rx.Observable<BlankingSector[]> {
        return this.radarRepository.getBlankingSectors(radarId);
    }

    public getBlankingSectorsState(): Rx.Observable<BlankingSectorsState> {
        return this.radarRepository.blankingSectorsState;
    }

    public saveBlankingSectors(radarId: int, sectors: BlankingSector[]): Rx.Observable<void> {
        return this.radarRepository.setBlankingSectors(radarId, sectors);
    }

    public toggleRadar(radarId: number): Rx.Observable<void> {
        if (this.isRadarRunning(radarId)) {
            return this.radarRepository.stopRadar(radarId);
        } else {
            return this.radarRepository.startRadar(radarId);
        }
    }

    public isRadarRunning(radarId: number): boolean {
        return this.getRadarById(radarId).status!.driveRunning;
    }

    public getRadarById(radarId: number): Radar {
        return this.radars.find((radar) => radar.id === radarId)!;
    }

    public hideRepositioningPreview(): void {
        try {
            this.radarRepository.setRepositioningState({ isActive: false });
        } catch (error) {
            console.warn("Failed to hide repositioning preview", error);
        }
    }

    public showRepositioningPreview(position: Location): void {
        this.radarRepository.setRepositioningState({ isActive: true, position: position });
    }

    public savePositionForRadar(radarId: number, position: Location, groundLevel: number | null): Rx.Observable<void> {
        return this.radarRepository.savePositionForRadar(radarId, position, groundLevel);
    }

    public showAlignmentPreview(dAngle: number, radarId: long, imageContrast: float): void {
        this.radarRepository.previewAlignment(dAngle, this.getRadarById(radarId), imageContrast);
    }

    public hideAlignmentPreview(): void {
        this.radarRepository.stopPreviewAlignment();
    }

    public showBlankingSectorsPreview(radarId: long, blankingSectors: BlankingSector[]): void {
        this.radarRepository.setBlankingSectorsState({
            isActive: true,
            blankingSectors: blankingSectors,
            radarId: radarId,
        });
    }

    public hideBlankingSectorsPreview(): void {
        this.radarRepository.setBlankingSectorsState({ isActive: false });
    }

    public saveNewAlignment(radarId: number, angle: number): Rx.Observable<void> {
        return this.radarRepository.saveNewAlignment(radarId, angle);
    }

    public autoAlignRadar(radarId: number): Rx.Observable<void> {
        return this.radarRepository.autoAlign(radarId);
    }

    public autoPositionRadar(radarId: number): Rx.Observable<void> {
        return this.radarRepository.autoPosition(radarId);
    }

    public getAlignmentImageContrast(): float {
        return this.localPreferencesRepository.getPreference<float>(LocalUserPreferenceKeys.radar.imageContrast, 0.5)!;
    }

    public setAlignmentImageContrast(value: float): void {
        this.localPreferencesRepository.setPreference(LocalUserPreferenceKeys.radar.imageContrast, value);
    }

    public setOperatingMode(modeId: string): Rx.Observable<void> {
        return this.radarRepository.setRadarOperatingMode(modeId);
    }

    public showAltitudeExplanationModal(): void {
        this.uiControlRepository.toggleHomeUIComponent("isAltitudeExplanationModalVisible", { isVisible: true });
    }

    // Private functions

    private subscribeToRadars(): void {
        const subscription = this.radarRepository.radars.subscribe((radars) => {
            this.radars = radars;
        });
        this.collectSubscription(subscription);
    }
}
