import { LocalPreferencesRepository, RadarRepository } from "../../../../domain/repositories";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { MapModuleViewModel } from "../MapModuleViewModel";
import * as Rx from "rxjs";
import { Radar, BlankingSectorsState, LocalUserPreferenceKeys, BlankingSector } from "../../../../domain/model";

export class RadarBlankingSectorsModuleViewModel extends MapModuleViewModel {
    // Properties

    public get blankingSectorsState(): Rx.Observable<BlankingSectorsState> {
        return this.radarRepository.blankingSectorsState;
    }

    public setBlankingSectorsState(state: BlankingSectorsState): void {
        return this.radarRepository.setBlankingSectorsState(state);
    }

    public get radars(): Rx.Observable<Radar[]> {
        return nonNullObservable(this.radarRepository.radars);
    }

    public get previewEnabled(): Rx.Observable<boolean> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<boolean>(
                LocalUserPreferenceKeys.appearance.showBlankingSectors,
            ),
            false,
        );
    }

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly radarRepository: RadarRepository,
    ) {
        super();
    }

    public getBlankingSectors(radarId: int): Rx.Observable<BlankingSector[]> {
        return this.radarRepository.getBlankingSectors(radarId);
    }
}
