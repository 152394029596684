import React, { Component } from "react";
import styled from "styled-components";
import { Colors } from "./Colors";
import Loading from "./Loading";

interface Props {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isLoading?: boolean;
    text?: string;
    disabled: boolean;
    color: string;
}

const Button = styled.button`
    text-transform: uppercase;
    background-color: transparent;
    padding: 11px 40px 11px 40px;
    border: none;
    border-radius: 100px;
    cursor: ${(props: Props) => (props.disabled ? "not-allowed" : "pointer")};
    font-weight: 500;
    font-size: 14px;
    color: ${(props: Props) => (props.disabled ? "rgba(255, 255, 255, 0.2)" : props.color)};
    :hover {
        opacity: ${(props: Props) => (props.disabled ? 1 : 0.8)};
    }
    :active {
        opacity: ${(props: Props) => (props.disabled ? 1 : 0.6)};
    }
`;

/**
 * @deprecated Use `Button` component instead
 */
export class TextButton extends Component<Props> {
    // Static properties

    public static defaultProps = {
        isLoading: false,
        disabled: false,
        color: Colors.status.info,
    };

    // Public functions

    public render(): JSX.Element {
        return (
            <Button {...this.props} disabled={this.props.disabled} color={this.props.color} onClick={this.handleClick}>
                {this.props.isLoading ? <Loading /> : <span>{this.props.text}</span>}
            </Button>
        );
    }

    private handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        // Prevent the button from submitting a form
        event.preventDefault();
        this.props.onClick(event);
    };
}
