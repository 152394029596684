import * as Rx from "rxjs";
import { AbstractStartableRepository } from "../../../../domain/repositories";
import { ADSBFlightRepository } from "../../../../domain/repositories/ADSBFlightRepository";
import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import {
    ADSBFlightsSnapshot,
    ADSBFlight,
    ADSBFlightsMap,
    Location,
    emitterCategoryFromProto,
} from "../../../../domain/model";
import { nonNullObservable } from "../../../../utils/RxUtils";
import {
    AdsbFlightUpdatesCollection,
    AdsbFlightUpdate,
} from "../../../../domain/model/proto/generated/adsbflightlist3_pb";
import _ from "lodash";
import { TrackSelector } from "../../../../domain/repositories/impl/TrackSelector";

export class BirdViewerADSBFlightRepository extends AbstractStartableRepository implements ADSBFlightRepository {
    public readonly flightsSnapshot: Rx.Observable<ADSBFlightsSnapshot>;
    public readonly selectedFlightId: Rx.Observable<number | null>;

    private readonly tracksSnapshotSubject = new Rx.BehaviorSubject<ADSBFlightsSnapshot | null>(null);
    private readonly trackSelector = new TrackSelector();
    private subscriptions = new Rx.Subscription();

    public constructor(private readonly api: BirdViewerAPI) {
        super();
        this.flightsSnapshot = nonNullObservable(this.tracksSnapshotSubject.asObservable());
        this.selectedFlightId = this.trackSelector.selectedTrackId;
    }

    public start(): void {
        this.subscriptions = new Rx.Subscription();
        this.getInitialADSBData();
    }

    public stop(): void {
        this.subscriptions.unsubscribe();
    }

    public toggleSelectedFlightId(trackId: number | null): void {
        this.trackSelector.toggleSelectedTrackId(trackId);
    }

    private getInitialADSBData(): void {
        const subscription = this.api.getInitialADSBFlightData().subscribe((data) => {
            const snapshot = ADSBFlightsSnapshot.fromProto(data);
            this.tracksSnapshotSubject.next(snapshot);
            this.getADSBDataUpdates();
        });
        this.subscriptions.add(subscription);
    }

    private getADSBDataUpdates(): void {
        const subscription = this.api.getADSBFlightDataUpdates().subscribe({
            next: (updates) => this.handleADSBDataUpdate(updates),
            error: (error) => this.retryIfStartedAndImplemented(() => this.getInitialADSBData(), error),
        });
        this.subscriptions.add(subscription);
    }

    private handleADSBDataUpdate(updates: AdsbFlightUpdatesCollection): void {
        const lastSnapshot = this.tracksSnapshotSubject.value;
        const newFlights: ADSBFlightsMap = (lastSnapshot && lastSnapshot.tracks) || new Map();
        updates
            .getFlightupdatesList()
            .flatMap((u) => u.getFlightupdateList())
            .forEach((update) => {
                switch (update.getUpdatetypeCase()) {
                    case AdsbFlightUpdate.UpdatetypeCase.NEWFLIGHT:
                        this.processNewFlight(newFlights, update);
                        break;
                    case AdsbFlightUpdate.UpdatetypeCase.DROPPEDFLIGHT:
                        this.processDroppedFlight(newFlights, update);
                        break;
                    case AdsbFlightUpdate.UpdatetypeCase.ALERTCHANGED:
                        this.processAlertChanged(newFlights, update);
                        break;
                    case AdsbFlightUpdate.UpdatetypeCase.EMERGENCYCHANGED:
                        this.processEmergencyChanged(newFlights, update);
                        break;
                    case AdsbFlightUpdate.UpdatetypeCase.ISONSURFACECHANGED:
                        this.processIsOnSurfaceChanged(newFlights, update);
                        break;
                    case AdsbFlightUpdate.UpdatetypeCase.SPICHANGED:
                        this.processSPIChanged(newFlights, update);
                        break;
                    case AdsbFlightUpdate.UpdatetypeCase.FLIGHTIDCHANGED:
                        this.processFlightIdChanged(newFlights, update);
                        break;
                    case AdsbFlightUpdate.UpdatetypeCase.SQUAWKCHANGED:
                        this.processSquawkChanged(newFlights, update);
                        break;
                    case AdsbFlightUpdate.UpdatetypeCase.NEWPOSITION:
                        this.processNewPosition(newFlights, update);
                        break;
                    case AdsbFlightUpdate.UpdatetypeCase.NEWCOURSE:
                        this.processNewCourse(newFlights, update);
                        break;
                    case AdsbFlightUpdate.UpdatetypeCase.EMITTERCATEGORYCHANGED:
                        this.processEmitterCategoryChanged(newFlights, update);
                        break;
                    case AdsbFlightUpdate.UpdatetypeCase.RESYNCFLIGHTLIST:
                        this.processResync();
                        break;
                    case AdsbFlightUpdate.UpdatetypeCase.UPDATETYPE_NOT_SET:
                        break;
                }
            });
        const lastUpdateId = _.maxBy(updates.getFlightupdatesList().map((u) => u.getUpdateid()));

        const newSnapshot = new ADSBFlightsSnapshot(
            lastUpdateId || (lastSnapshot && lastSnapshot.lastUpdateId) || 0,
            newFlights,
        );
        this.tracksSnapshotSubject.next(newSnapshot);
    }

    private processNewFlight(tracks: ADSBFlightsMap, update: AdsbFlightUpdate): void {
        const icao = update.getIcao();
        const alertChanged = update.getAlertchanged();
        const track = ADSBFlight.newEmpty(icao, (alertChanged && alertChanged.getValue()) || false);
        tracks.set(icao, track);
    }

    private processDroppedFlight(tracks: ADSBFlightsMap, update: AdsbFlightUpdate): void {
        tracks.delete(update.getIcao());
    }

    private processAlertChanged(tracks: ADSBFlightsMap, update: AdsbFlightUpdate): void {
        this.applyUpdate(tracks, update, () => ({ hasAlert: update.getAlertchanged()!.getValue() }));
    }

    private processEmergencyChanged(tracks: ADSBFlightsMap, update: AdsbFlightUpdate): void {
        this.applyUpdate(tracks, update, () => ({ hasEmergency: update.getEmergencychanged()!.getValue() }));
    }

    private processIsOnSurfaceChanged(tracks: ADSBFlightsMap, update: AdsbFlightUpdate): void {
        this.applyUpdate(tracks, update, () => ({ isOnSurface: update.getIsonsurfacechanged()!.getValue() }));
    }

    private processSPIChanged(tracks: ADSBFlightsMap, update: AdsbFlightUpdate): void {
        this.applyUpdate(tracks, update, () => ({ hasSPI: update.getSpichanged()!.getValue() }));
    }

    private processFlightIdChanged(tracks: ADSBFlightsMap, update: AdsbFlightUpdate): void {
        this.applyUpdate(tracks, update, () => ({ flightId: update.getFlightidchanged()!.getFlightId() }));
    }

    private processSquawkChanged(tracks: ADSBFlightsMap, update: AdsbFlightUpdate): void {
        this.applyUpdate(tracks, update, () => ({ squawk: update.getSquawkchanged()!.getSquawk() }));
    }

    private processNewPosition(flights: ADSBFlightsMap, update: AdsbFlightUpdate): void {
        const icao = update.getIcao();
        const flight = flights.get(icao);
        if (flight == null) {
            return;
        }

        const newPosition = Location.fromProto(update.getNewposition()!.getPosition());
        flights.set(
            icao,
            flight.clone({
                estimates: [...flight.estimates, { location: newPosition, timestamp: update.getTimestampMsec() }],
            }),
        );
    }

    private processNewCourse(flights: ADSBFlightsMap, update: AdsbFlightUpdate): void {
        this.applyUpdate(flights, update, () => {
            const course = update.getNewcourse()!;
            return {
                bearing: course.getBearing(),
                velocity: course.getGroundspeed(),
            };
        });
    }

    private processEmitterCategoryChanged(flights: ADSBFlightsMap, update: AdsbFlightUpdate): void {
        this.applyUpdate(flights, update, () => {
            const newCat = emitterCategoryFromProto(update.getEmittercategorychanged()!.getEmittercategory());
            return { emitterCategory: newCat };
        });
    }

    private processResync(): void {
        this.stop();
        this.tracksSnapshotSubject.next(null);
        this.start();
    }

    private applyUpdate(
        flights: ADSBFlightsMap,
        update: AdsbFlightUpdate,
        calculateChange: () => Partial<ADSBFlight>,
    ): void {
        const icao = update.getIcao();
        const track = flights.get(icao);
        if (track == null) {
            return;
        }

        flights.set(icao, track.clone(calculateChange()));
    }
}
