import { Units } from "@turf/turf";

export interface RangeMarker {
    radius: number;
    units: Units;
    label: string;
}

export function generateRangeMarkers(
    range: number,
    interval: number,
    units: Units,
    makeLabel: (r: number) => string,
): RangeMarker[] {
    const markers: RangeMarker[] = [];
    for (let radius = interval; radius <= range; radius += interval) {
        markers.push({
            radius,
            units,
            label: makeLabel(radius),
        });
    }
    return markers;
}
