import styled from "styled-components";
import React from "react";
import { OldColors } from "./Colors";
import { getPointFromReactEvent, getPointFromEvent } from "../../utils/UserInteractionEventsUtils";

const THUMB_SIZE = 16;

const Thumb = styled.div<{ isDragging: boolean }>`
    position: absolute;
    background-color: ${({ isDragging }) => (isDragging ? OldColors.primaryHoverTint : OldColors.primaryTint)};
    top: 0;
    margin-left: -${THUMB_SIZE / 2}px;
    width: ${THUMB_SIZE}px;
    height: ${THUMB_SIZE}px;
    border-radius: ${THUMB_SIZE / 2}px;
    :hover {
        background-color: ${OldColors.primaryHoverTint};
    }
`;

const Container = styled.div`
    flex: 1;
    height: ${THUMB_SIZE}px;
    position: relative;
    cursor: pointer;
    :hover ${Thumb} {
        background-color: ${OldColors.primaryHoverTint};
    }
`;

const Track = styled.div`
    width: 100%;
    height: 2px;
    top: ${THUMB_SIZE / 2}px;
    margin-top: -1px;
    background-color: ${OldColors.primaryTint};
    opacity: 1;
    position: absolute;
`;

const FilledTrack = styled.div`
    position: absolute;
    background-color: ${OldColors.primaryTint};
    opacity: 1;
    height: 2px;
    top: ${THUMB_SIZE / 2}px;
    margin-top: -1px;
`;

const Value = styled.p<{ left: number }>`
    position: absolute;
    top: 10px;
    text-align: center;
    color: ${({ theme }) => theme.colors.text.text};
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    transform: translateX(-50%);
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    left: ${(props) => props.left + "%"};
`;

interface State {
    isDragging: boolean;
    thumbPositionPercentage: number;
    value: number;
}

interface Props {
    stepSize: number;
    min: number;
    max: number;
    value: number;
    onChange: (value: number) => void;
    onSubmit: () => void;
    valueFormatter?: (value: number) => string;
}

export class SteppedSeekbar extends React.Component<Props, State> {
    // Static properties

    public static defaultProps = {
        min: 0,
        onSubmit: (): void => {},
    };

    // Properties

    private readonly container: React.RefObject<HTMLDivElement>;
    private lastBroadcastValue: number;

    // Lifecycle

    public constructor(props: Props) {
        super(props);

        this.container = React.createRef();
        this.lastBroadcastValue = this.props.value;
        this.state = {
            isDragging: false,
            value: props.value,
            thumbPositionPercentage: this.getPercentage(props.value),
        };
    }

    // Public functions

    public render(): React.ReactNode {
        return (
            <Container
                ref={this.container}
                onMouseDown={(event: React.MouseEvent) => this.onDragStart(event)}
                onTouchStart={(event: React.TouchEvent) => this.onDragStart(event)}
            >
                <Track />
                <FilledTrack style={{ width: this.state.thumbPositionPercentage + "%" }} />
                <Thumb isDragging={this.state.isDragging} style={{ left: this.state.thumbPositionPercentage + "%" }} />
                {this.props.valueFormatter && (
                    <Value left={Math.min(Math.max(5, this.state.thumbPositionPercentage), 90)}>
                        {this.props.valueFormatter(this.state.value)}
                    </Value>
                )}
            </Container>
        );
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.value !== this.props.value && !this.state.isDragging) {
            this.setState({
                value: this.props.value,
                thumbPositionPercentage: this.getPercentage(this.props.value),
            });
        }
    }

    public componentDidMount(): void {
        window.addEventListener("mouseup", this.onDragEnd);
        window.addEventListener("touchend", this.onDragEnd);
        window.addEventListener("mousemove", this.onDrag);
        window.addEventListener("touchmove", this.onDrag);
    }

    public componentWillUnmount(): void {
        window.removeEventListener("mouseup", this.onDragEnd);
        window.removeEventListener("touchend", this.onDragEnd);
        window.removeEventListener("mousemove", this.onDrag);
        window.removeEventListener("touchmove", this.onDrag);
    }

    // Private functions

    private onDragStart(event: React.MouseEvent | React.TouchEvent): void {
        this.setState({ isDragging: true });
        this.adjustThumbPositionByX(getPointFromReactEvent(event).x);
    }

    private onDragEnd = (): void => {
        if (this.state.isDragging) {
            this.props.onSubmit();
        }
        this.setState({ isDragging: false });
    };

    private onDrag = (event: MouseEvent | TouchEvent): void => {
        if (!this.state.isDragging) {
            return;
        }
        this.adjustThumbPositionByX(getPointFromEvent(event).x);
    };

    private adjustThumbPositionByX(x: number): void {
        if (this.container.current == null) {
            return;
        }
        const containerFrame = this.container.current.getBoundingClientRect();
        const position = x - containerFrame.left;
        let percentage = (position / containerFrame.width) * 100;
        if (percentage < 0) {
            percentage = 0;
        }
        if (percentage > 100) {
            percentage = 100;
        }
        let value = (percentage / 100) * (this.props.max - this.props.min);
        const step = Math.floor(value / this.props.stepSize);
        value = this.props.min + step * this.props.stepSize;
        percentage = this.getPercentage(value);
        if (value !== this.lastBroadcastValue) {
            this.lastBroadcastValue = value;
            this.setState({ thumbPositionPercentage: percentage, value: value });
            this.props.onChange(value);
        }
    }

    private getPercentage(value: number): number {
        return ((value - this.props.min) / (this.props.max - this.props.min)) * 100;
    }
}
