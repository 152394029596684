import * as Rx from "rxjs";
import { LocalUserPreferenceKeys, RadarMotionData } from "../../../../domain/model";
import { VelocityUnit } from "../../../../domain/model/VelocityUnit";
import { LocalPreferencesRepository, RadarRepository } from "../../../../domain/repositories";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { BaseViewModel } from "../../../BaseViewModel";

export class VehicleInfoViewModel extends BaseViewModel {
    // Properties

    public get radarMotionData(): Rx.Observable<RadarMotionData> {
        return this.radarRepository.motionData;
    }

    public get velocityUnit(): Rx.Observable<VelocityUnit> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<VelocityUnit>(
                LocalUserPreferenceKeys.appearance.velocityUnit,
            ),
        );
    }

    public get isMapOrientationLockedToVehicle(): Rx.Observable<boolean> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<boolean>(
                LocalUserPreferenceKeys.filters.lockMapToVehicleOrientation,
            ),
            false,
        );
    }

    public constructor(
        private readonly radarRepository: RadarRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
    ) {
        super();
    }

    // Public functions

    public changeMapOrientationLock(lock: boolean): void {
        this.localPreferencesRepository.setPreference(
            LocalUserPreferenceKeys.filters.lockMapToVehicleOrientation,
            lock,
        );
    }
}
