import * as RxOperators from "rxjs/operators";
import { nonNullObservable } from "../utils/RxUtils";
import { getTrackEstimatesWithinPeriod, Track } from "./model";
import { LocalPreferencesRepository, ReplayRepository, TrackRepository } from "./repositories";
import { SnapshotDiffCalculator } from "./SnapshotDiffCalculator";

export class TracksSnapshotDiffCalculator extends SnapshotDiffCalculator<Track> {
    public constructor(
        trackRepository: TrackRepository,
        replayRepository: ReplayRepository,
        localPreferencesRepository: LocalPreferencesRepository,
    ) {
        const trackUpdates = replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((scene) =>
                scene == null ? nonNullObservable(trackRepository.tracksSnapshot) : scene.tracks,
            ),
        );
        super(
            trackUpdates,
            localPreferencesRepository,
            (t) => t.endTime,
            (t, timestamp, lifeTime) => getTrackEstimatesWithinPeriod(t, timestamp, lifeTime).length > 0,
            (t) => t.hasRangeAndAzimuthCompatible(),
        );
    }
}
