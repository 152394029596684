import styled from "styled-components";
import React from "react";
import * as Rx from "rxjs";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { AltitudeSliderGraph, AltitudeSliderGraphData } from "./AltitudeSliderGraph";
import { AltitudeGuide, AltitudeSliderViewModel } from "./AltitudeSliderViewModel";
import { TYPES } from "../../di/Types";
import DI from "./../../di/DI";
import { HandleType } from "./HandleType";
import { t } from "i18next";
import { Panel } from "../appearance/panels/Panel";
import { PanelSection } from "../appearance/panels/PanelSection";
import { PanelHeader } from "../appearance/panels/PanelHeader";
import { Button } from "../appearance/button/Button";
import { FormControl } from "../appearance/forms/FormControl";
// import { FormMinMax } from "../appearance/forms/FormMinMax";
import { Form } from "../appearance/forms/Form";
import { PanelFooter } from "../appearance/panels/PanelFooter";

const ThinPanel = styled(Panel)`
    width: 200px;
`;

const GraphContainer = styled.div`
    width: 130px;
    height: 180px;

    @media only screen and (min-height: 500px) and (max-height: 549px) {
        height: 200px;
    }
    @media only screen and (min-height: 550px) and (max-height: 599px) {
        height: 230px;
    }
    @media only screen and (min-height: 600px) and (max-height: 649px) {
        height: 270px;
    }
    @media only screen and (min-height: 650px) and (max-height: 699px) {
        height: 300px;
    }
    @media only screen and (min-height: 700px) and (max-height: 749px) {
        height: 330px;
    }
    @media only screen and (min-height: 750px) and (max-height: 799px) {
        height: 370px;
    }
    @media only screen and (min-height: 800px) and (max-height: 869px) {
        height: 410px;
    }
    @media only screen and (min-height: 870px) {
        height: 450px;
    }
`;

interface Props {
    isAltitudeFilterSettingsVisible: boolean;
    onClose: () => void;
    onToggleSettingsPanel: () => void;
}

interface State {
    topLimit: number;
    bottomLimit: number;
    min: number;
    max: number;
    step: number;
    altitudesDensityMap: Map<number, number>;
    aircraftAltitudes: number[];
    topHandleLabel: string;
    bottomHandleLabel: string;
    guides: AltitudeGuide[];
}

export class AltitudeSlider extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private viewModel: AltitudeSliderViewModel = DI.get(TYPES.AltitudeSliderViewModel);

    public constructor(props: Props) {
        super(props);
        this.state = {
            topLimit: 0,
            bottomLimit: 0,
            min: 0,
            max: 1,
            step: 1,
            topHandleLabel: "",
            bottomHandleLabel: "",
            guides: [],
            altitudesDensityMap: new Map(),
            aircraftAltitudes: [],
        };
    }

    // Public functions

    public componentDidMount(): void {
        this.subscribeToObservables();
    }

    public render(): React.ReactNode {
        return (
            <ThinPanel>
                <PanelHeader label={t("general.altitude")} onClose={() => this.props.onClose()} />
                <PanelSection>
                    <Form vertical>
                        <GraphContainer>
                            <AltitudeSliderGraph
                                shouldShowGuideline={this.viewModel.shouldShowGuideline}
                                graphData={this.calculateGraphData(this.state.altitudesDensityMap, this.state.step)}
                                aircraftAltitudes={this.state.aircraftAltitudes}
                                selectedMinAltitude={this.state.bottomLimit}
                                selectedMaxAltitude={this.state.topLimit}
                                minAltitude={this.state.min}
                                maxAltitude={this.state.max}
                                altitudeStep={this.state.step}
                                altitudeGuides={this.state.guides}
                                topHandleLabel={this.state.topHandleLabel}
                                bottomHandleLabel={this.state.bottomHandleLabel}
                                onAltitudeRangeChange={(altitude, handleType) =>
                                    this.setLimitForHandle(altitude, handleType)
                                }
                            />
                        </GraphContainer>
                        {/**
                         * @todo: Uncomment this when form validation is fixed
                         */}
                        {/* <FormControl title={t("altitudeFilter.setAltitudeRange")} vertical>
                            <FormMinMax
                                maxInitialValue={this.state.topLimit}
                                maxObservable={this.viewModel.formattedMaxAltitudeOfInterest}
                                maxStep={this.state.step}
                                minInitialValue={this.state.bottomLimit}
                                minObservable={this.viewModel.formattedMinAltitudeOfInterest}
                                minStep={this.state.step}
                                onMaxChange={(value: number) => this.setLimitForHandle(value, HandleType.Top)}
                                onMinChange={(value: number) => this.setLimitForHandle(value, HandleType.Bottom)}
                            />
                        </FormControl> */}
                        <FormControl vertical>
                            <Button
                                active={this.props.isAltitudeFilterSettingsVisible}
                                label={t("general.clearSelection")}
                                layout="inline"
                                onClick={this.clearSelection.bind(this)}
                            />
                        </FormControl>
                    </Form>
                </PanelSection>
                <PanelFooter>
                    <Button
                        active={this.props.isAltitudeFilterSettingsVisible}
                        label={t("general.settings")}
                        layout="inline"
                        onClick={() => this.props.onToggleSettingsPanel()}
                    />
                </PanelFooter>
            </ThinPanel>
        );
    }

    // Private functions

    private subscribeToObservables(): void {
        this.collectSubscriptions(
            Rx.combineLatest([
                this.viewModel.minObservable,
                this.viewModel.maxObservable,
                this.viewModel.stepObservable,
            ]).subscribe(([min, max, step]) => this.setState({ min, max, step })),
            this.viewModel.getMinAltitudeOfInterestObservable().subscribe((bottomLimit) => {
                this.setState({ bottomLimit });
            }),
            this.viewModel.getMaxAltitudeOfInterestObservable().subscribe((topLimit) => {
                this.setState({ topLimit });
            }),
            this.viewModel.getAltitudeToTrackDensityObservable().subscribe((altitudesDensityMap) => {
                this.setState({ altitudesDensityMap });
            }),
            this.viewModel.getAircraftAltitudesObservable().subscribe((aircraftAltitudes) => {
                this.setState({ aircraftAltitudes });
            }),
            this.viewModel.getHandleLabels().subscribe((value) => {
                this.setState({ topHandleLabel: value[0], bottomHandleLabel: value[1] });
            }),
            this.viewModel.getAltitudeGuidesObservable().subscribe((guides) => {
                this.setState({ guides });
            }),
        );
    }

    private calculateGraphData(altitudesDensityMap: Map<number, number>, step: number): AltitudeSliderGraphData[] {
        const graphData = new Array<AltitudeSliderGraphData>();
        altitudesDensityMap.forEach((value, altitudeIndex) => {
            const altitude = altitudeIndex * step + step / 2;
            graphData.push({ altitude, value });
        });

        return graphData;
    }

    private setLimitForHandle(limit: number, handle: HandleType): void {
        const { min, max, step } = this.state;
        let bottomLimit: number = this.state.bottomLimit;
        let topLimit: number | null = this.state.topLimit;

        if (handle === HandleType.Top) {
            if (bottomLimit >= limit && bottomLimit > min && limit > min) {
                bottomLimit = limit - step;
            }
            if (bottomLimit < limit) {
                topLimit = limit;
            }
        } else if (handle === HandleType.Bottom) {
            if (topLimit <= limit && topLimit < max && limit < max) {
                topLimit = limit + step;
            }
            if (topLimit > limit) {
                bottomLimit = limit;
            }
        }
        // If the top limit is at or above the maximum value, set it to null (it means we don't have any limitation on top)
        if (topLimit >= max) {
            topLimit = null;
        }
        // If the bottom limit has gone below the minimum value, set it to the minimum value
        if (bottomLimit < min) {
            bottomLimit = min;
        }
        this.viewModel.setMaxAltitudeOfInterest(topLimit);
        this.viewModel.setMinAltitudeOfInterest(bottomLimit);
    }

    private clearSelection(): void {
        this.viewModel.setMaxAltitudeOfInterest(null);
        this.viewModel.setMinAltitudeOfInterest(null);
    }
}
