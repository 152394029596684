import { BaseViewModel } from "../BaseViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { LocalPreferencesRepository, LocationInfoRepository, RunwayTrafficRepository } from "../../domain/repositories";
import { FunnelViewSectorRates, getFunnelViewSectorRatesFromArray } from "./FunnelViewSectorRates";
import { getShowParkingCorridorObservable } from "./FunnelViewUtils";

export class FunnelViewViewModel extends BaseViewModel {
    // Properties

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly runwayTrafficRepository: RunwayTrafficRepository,
        private readonly locationInfoRepository: LocationInfoRepository,
    ) {
        super();
    }

    // Public functions

    public get funnelViewThreshold(): Rx.Observable<int> {
        return this.runwayTrafficRepository.funnelThreshold;
    }

    public getShowParkingCorridor(runwayId: int): Rx.Observable<boolean> {
        return getShowParkingCorridorObservable(this.localPreferencesRepository, this.locationInfoRepository, runwayId);
    }

    public observeRunwayTraffic(runwayId: int): Rx.Observable<FunnelViewSectorRates> {
        return this.runwayTrafficRepository.observeRunwayTraffic([runwayId]).pipe(
            RxOperators.map((runways) => runways[0].sectorTrafficRate),
            RxOperators.map((rates) => getFunnelViewSectorRatesFromArray(rates)),
        );
    }
}
