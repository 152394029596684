import React from "react";
import styled from "styled-components";
import { Inclination, Radar } from "../../domain/model";
import { OldColors } from "../appearance/Colors";
import { FormRoundInput } from "../appearance/FormRoundInput";
import { RoundButton } from "../appearance/RoundButton";
import { RoundInput } from "../appearance/RoundInput";
import * as Rx from "rxjs";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { showError } from "../../utils/MessageUtils";
import { SteppedSeekbar } from "../appearance/SteppedSeekbar";
import { parseNumberOrZero } from "../../utils/StringUtils";
import { PanelDescription } from "../appearance/panels/PanelText";
import { ReactComponent as InlineHelpIcon } from "../../res/images/ic_help_inline.svg";
import ReactTooltip from "react-tooltip";
import { RadarInclination } from "./RadarInclination";
import { t } from "i18next";

const TOOLTIP_ID = "radar-alignment-view-tooltip";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 8px;
    margin-left: 8px;

    button {
        margin: 0;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 14px;
    margin-bottom: 25px;
    span {
        font-size: 12px;
        line-height: 1.83;
        color: ${OldColors.primaryTint};
        padding-bottom: 4px;
        display: flex;
        align-items: center;

        a {
            display: flex;

            svg {
                margin-left: 2px;
                margin-top: -1px;
            }
        }
    }
`;

const VerticalSpace = styled.div`
    height: 10px;
    width: 100;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    button {
        margin-top: 10px;
    }
`;

interface Props {
    contrast: float;
    onContrastSet: (value: float) => void;
    onRequestAutoAlignment: () => Rx.Observable<void>;
    onRequestPreviewNewAlignment: (dAngle: number, imageContrast: float) => void;
    onRequestSaveNewAlignment: (angle: number) => Rx.Observable<void>;
    radar: Radar;
}

interface State {
    isSaving: boolean;
    isAutoAligning: boolean;
    canApplyChanges: boolean;
    angle: number;
    contrast: float;
    inclination?: Inclination;
}

export class RadarAlignment extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly angleInput: React.RefObject<RoundInput>;

    // Public functions

    public constructor(props: Props) {
        super(props);
        this.angleInput = React.createRef();

        this.state = {
            isSaving: false,
            isAutoAligning: false,
            canApplyChanges: false,
            angle: this.props.radar.orientation.rotationAngle,
            contrast: this.props.contrast,
            inclination: this.props.radar.status ? this.props.radar.status.inclination : undefined,
        };
    }

    public componentDidMount(): void {
        this.props.onRequestPreviewNewAlignment(0, this.state.contrast);
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.radar.orientation.rotationAngle !== this.props.radar.orientation.rotationAngle) {
            this.onChange(this.props.radar.orientation.rotationAngle);
        }
    }

    public render(): React.ReactNode {
        return (
            <Container>
                <form action={"#"}>
                    <PanelDescription>{t("radarControlPanel.descriptionAlignment")}</PanelDescription>
                    <InputContainer>
                        {this.renderInputTitle(t("radarControlPanel.rotation"))}
                        <FormRoundInput
                            disabled={this.state.isSaving || this.state.isAutoAligning}
                            onTextChange={this.onAngleInputChange.bind(this)}
                            ref={this.angleInput}
                            value={this.roundAngle(this.state.angle)}
                            type={"number"}
                        />
                        <VerticalSpace />
                        <SteppedSeekbar
                            value={this.state.angle}
                            min={-180}
                            max={180}
                            stepSize={0.1}
                            onChange={this.onAngleSeekbarChange.bind(this)}
                        />
                    </InputContainer>
                    <InputContainer>
                        {this.renderInputTitle(t("radarControlPanel.contrast"), t("radarControlPanel.contrastTooltip"))}
                        <SteppedSeekbar
                            value={Math.round(this.state.contrast * 100)}
                            min={0}
                            max={100}
                            stepSize={5}
                            onChange={this.onContrastChange.bind(this)}
                            valueFormatter={(value) => value.toString() + "%"}
                        />
                    </InputContainer>
                    {this.state.inclination && (
                        <>
                            <VerticalSpace />
                            <InputContainer>
                                {this.renderInputTitle(
                                    t("radarControlPanel.inclination"),
                                    t("radarControlPanel.inclinationStatus", {
                                        withinLimits: this.state.inclination.withinLimits
                                            ? t("radarControlPanel.inclinationStatusWithinLimits")
                                            : t("radarControlPanel.inclinationStatusOutsideLimits"),
                                    }),
                                )}
                                <RadarInclination inclination={this.state.inclination} />
                            </InputContainer>
                        </>
                    )}
                </form>
                <ButtonContainer>
                    {this.state.canApplyChanges && (
                        <RoundButton
                            disabled={this.state.isAutoAligning}
                            isLoading={this.state.isSaving}
                            text={t("radarControlPanel.applyChanges")}
                            onClick={this.saveChanges.bind(this)}
                        />
                    )}
                    {this.props.radar.status!.canPerformAutoAlignment && (
                        <RoundButton
                            disabled={this.state.isSaving}
                            isLoading={this.state.isAutoAligning}
                            text={t("radarControlPanel.autoAlign")}
                            onClick={this.autoAlign.bind(this)}
                        />
                    )}
                </ButtonContainer>
                <ReactTooltip id={TOOLTIP_ID} effect={"solid"} multiline={true} />
            </Container>
        );
    }

    // Private functions

    private saveChanges(): void {
        this.setState({ isSaving: true });
        const subscription = this.props.onRequestSaveNewAlignment(this.state.angle).subscribe({
            complete: () => {
                this.setState({ isSaving: false });
            },
            error: (error) => {
                showError(error);
                this.setState({ isSaving: false });
            },
        });
        this.collectSubscription(subscription);
    }

    private autoAlign(): void {
        this.setState({ isAutoAligning: true });
        const subscription = this.props.onRequestAutoAlignment().subscribe({
            complete: () => {
                this.setState({ isAutoAligning: false });
            },
            error: (error) => {
                showError(error);
                this.setState({ isAutoAligning: false });
            },
        });
        this.collectSubscription(subscription);
    }

    private onAngleInputChange(text: string): void {
        const newValue = parseNumberOrZero(text);
        if (this.state.angle !== newValue) {
            this.onChange(newValue);
        }
    }

    private onAngleSeekbarChange(newValue: number): void {
        if (this.state.angle !== newValue) {
            this.onChange(newValue);
        }
    }

    private onContrastChange(newValue: number): void {
        const floatValue = newValue / 100;
        if (this.state.contrast !== floatValue) {
            this.onChange(this.state.angle, floatValue);
            this.props.onContrastSet(floatValue);
        }
    }

    private onChange(angle: number, contrast: number = this.state.contrast): void {
        angle = Math.max(angle, -180);
        angle = Math.min(angle, 180);
        let canApplyChanges = false;
        if (angle !== this.props.radar.orientation.rotationAngle) {
            canApplyChanges = true;
        }
        this.setState({ angle, canApplyChanges, contrast });
        this.props.onRequestPreviewNewAlignment(this.props.radar.orientation.rotationAngle - angle, contrast);
    }

    private roundAngle(angle: number): string {
        return (Math.round(angle * 10) / 10).toString();
    }

    private renderInputTitle(title: string, tooltipText?: string): React.ReactElement | null {
        return (
            <span>
                {title}
                {tooltipText && (
                    /* eslint-disable */
                    <a data-for={TOOLTIP_ID} data-tip={tooltipText} data-iscapture="true">
                        <InlineHelpIcon />
                    </a>
                    /* eslint-enable */
                )}
            </span>
        );
    }
}
