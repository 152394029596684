import { SettingItemIcon } from "../../../../components/settings/SettingItemIcon";
import { SettingItemViewModel, SwitchItemViewModel } from "../../../../components/settings/SettingItemViewModel";
import { ServerPreferencesRepository } from "../../../../domain/repositories/ServerPreferencesRepository";
import { LocalPreferencesRepository } from "../../../../domain/repositories/LocalPreferencesRepository";
import GridIcon from "../../../../res/images/settings/grid.svg";
import { ServerUserPreferenceKeys } from "../../../../domain/model/PreferencesData";
import { SettingsItemSection } from "../../../../components/settings/SettingsItemSection";
import { SettingsErrorHandler } from "../../../../components/settings/SettingsErrorHandler";
import * as Rx from "rxjs";
import * as RxUtils from "../../../../utils/RxUtils";
import { TrailLengthSettingItemViewModel } from "../../../../components/settings/sections/items/TrailLengthSettingItemViewModel";
import { FinishedTrackLifetimeSettingItemViewModel } from "../../../../components/settings/sections/items/FinishedTrackLifetimeSettingItemViewModel";
import { FinishedTrackOpacitySettingItemViewModel } from "../../../../components/settings/sections/items/FinishedTrackOpacitySettingItemViewModel";
import { ShowClassificationHistorySettingItemViewModel } from "../../../../components/settings/sections/items/ShowClassificationHistorySettingItemViewModel";
import { TrackLabelSettingItemViewModel } from "../../../../components/settings/sections/items/TrackLabelSettingItemViewModel";
import { t } from "i18next";

export class BirdViewerFilterSettingsSection implements SettingsItemSection {
    // Properties

    public readonly id: string = "filter-settings";
    public readonly label: string | null = t("settings.tracks");

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly serverPreferencesRepository: ServerPreferencesRepository,
    ) {}

    // Public functions

    public generate(errorHandler: SettingsErrorHandler): Rx.Observable<SettingItemViewModel[]> {
        return Rx.of([
            new SwitchItemViewModel(
                t("settings.showHeatmap"),
                SettingItemIcon.withUrl(GridIcon),
                RxUtils.nonNullObservable(
                    this.observeServerPreference<boolean>(ServerUserPreferenceKeys.filters.showGrid),
                ),
                (value) => {
                    this.setServerPreference(
                        ServerUserPreferenceKeys.filters.showGrid,
                        value.toString(),
                        errorHandler.onError,
                    );
                },
            ),
            new TrailLengthSettingItemViewModel(this.localPreferencesRepository),
            new FinishedTrackLifetimeSettingItemViewModel(this.localPreferencesRepository),
            new FinishedTrackOpacitySettingItemViewModel(this.localPreferencesRepository),
            new TrackLabelSettingItemViewModel(this.localPreferencesRepository, true),
            new ShowClassificationHistorySettingItemViewModel(this.localPreferencesRepository),
        ]);
    }

    // Private functions

    private setServerPreference(key: string, newValue: string, onError: (error: Error) => void): void {
        this.serverPreferencesRepository.setPreference(key, newValue).subscribe({
            error: (error) => {
                error.message = t("settings.errorSavingSettings");
                onError(error);
            },
            complete: () => console.info(`Server preference '${key}' updated successfully`),
        });
    }

    private observeServerPreference<T>(key: string): Rx.Observable<T | null> {
        return this.serverPreferencesRepository.observePreference(key);
    }
}
