import { MapModule } from "../MapModule";
import { BaseTracksLayer } from "../../layers/tracks/BaseTracksLayer";
import { BaseTracksModuleViewModel } from "./BaseTracksModuleViewModel";
import { Trackable } from "../../../../domain/model";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";

export abstract class BaseTracksModule<
    VM extends BaseTracksModuleViewModel<TrackType>,
    TrackType extends Trackable,
> extends MapModule<VM> {
    // Properties

    public constructor(protected readonly viewModel: VM) {
        super();
    }

    // Protected functions

    protected subscribeToCommonEvents(layer: BaseTracksLayer<TrackType>): void {
        const vm = this.viewModel;
        this.collectSubscriptions(
            vm.iconScale.subscribe((value) => layer.updateIconAndTrailScale(value)),
            vm.tracksSnapshotDiff.subscribe({
                next: (diff) => layer.updateTracks(diff),
                error: (error) => console.error(error),
            }),
            vm.altitudeRangeOfInterest.subscribe((value) => layer.updateAltitudeRangeOfInterest(value)),
            vm.showTracksWithoutAltitude.subscribe((value) => layer.setShowTracksWithoutAltitude(value)),
            vm.trackLabel.subscribe((value) => layer.setTrackLabel(value)),
            vm.displayNegativeAltitudeAsZero.subscribe((value) => layer.updateDisplayNegativeAltitudeAsZero(value)),
            vm.trailLength.subscribe((value) => layer.updateTrailLength(value)),
            vm.velocityUnit.subscribe((value) => layer.updateVelocityUnit(value)),
            vm.getSelectedTrackId().subscribe((value) => layer.setSelectedTrackId(value)),
            Rx.NEVER.pipe(RxOperators.finalize(() => layer.onModuleDispose())).subscribe(),
            vm.trackIdsWithAlarms.subscribe((trackIds) => {
                layer.setTracksWithAlarms(trackIds);
            }),
            vm.forceDimTracksWithoutAlarms.subscribe((value) => layer.setForceDimTracksWithoutAlarms(value)),
            vm.altitudeConfig.subscribe((value) => layer.setAltitudeConfig(value)),
            vm.radarGroundLevel.subscribe((value) => layer.setRadarGroundLevel(value)),
        );
    }

    protected onTrackSelected = (trackId: number | null): void => {
        this.viewModel.selectTrack(trackId);
    };
}
