import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Inclination } from "../../domain/model";
import { clamp } from "../../utils/MathUtils";
import { Colors, OldColors } from "../appearance/Colors";

const Root = styled.div`
    width: 100%;
    height: 100%;
`;

const MARGIN = 10;
const MARKER_WIDTH = 8;
const MARKER_COUNT = 20;
const MIN_X = -2;
const MAX_X = 2;
const MIN_Y = -2;
const MAX_Y = 2;
const X_LABEL = "Roll";
const Y_LABEL = "Pitch";
const DOT_RADIUS = 4;
const FONT_SIZE = 12;

interface Props {
    inclination: Inclination;
}

export const RadarInclination = ({ inclination }: Props): React.ReactElement | null => {
    const canvasElement = useRef<HTMLCanvasElement>(null);
    const [canvasContext, setCanvasContext] = useState<CanvasRenderingContext2D | null>(null);
    const [dpr, setDevicePixelRatio] = useState<number>(1);

    const drawAxes = useCallback(
        (ctx: CanvasRenderingContext2D, cw: number, ch: number) => {
            const p = new Path2D();
            p.moveTo(MARGIN * dpr, ch / 2);
            p.lineTo(cw - MARGIN * dpr, ch / 2);
            p.moveTo(cw / 2, MARGIN * dpr);
            p.lineTo(cw / 2, ch - MARGIN * dpr);
            ctx.lineWidth = 2 * dpr;
            ctx.strokeStyle = Colors.secondary.white;
            ctx.stroke(p);
        },
        [dpr],
    );

    const drawAxisMarkers = useCallback(
        (ctx: CanvasRenderingContext2D, cw: number, ch: number) => {
            ctx.lineWidth = 1 * dpr;
            ctx.strokeStyle = Colors.secondary.white;
            const p = new Path2D();
            for (let i = 0; i <= MARKER_COUNT; i++) {
                const x = MARGIN * dpr + ((cw - 2 * MARGIN * dpr) / MARKER_COUNT) * i;
                const y = MARGIN * dpr + ((ch - 2 * MARGIN * dpr) / MARKER_COUNT) * i;
                p.moveTo(x, (ch - MARKER_WIDTH * dpr) / 2);
                p.lineTo(x, (ch + MARKER_WIDTH * dpr) / 2);
                p.moveTo((cw - MARKER_WIDTH * dpr) / 2, y);
                p.lineTo((cw + MARKER_WIDTH * dpr) / 2, y);
            }
            ctx.stroke(p);
        },
        [dpr],
    );

    const drawAxisLabels = useCallback(
        (ctx: CanvasRenderingContext2D, cw: number, ch: number) => {
            ctx.textAlign = "center";
            ctx.textBaseline = "top";
            ctx.strokeText(`${MIN_X}˚`, MARGIN * dpr, ch / 2 + MARKER_WIDTH * dpr);
            ctx.strokeText(`${MAX_X}˚`, cw - MARGIN * dpr, ch / 2 + MARKER_WIDTH * dpr);
            ctx.textAlign = "end";
            ctx.strokeText(X_LABEL, cw - MARGIN * dpr, ch / 2 + (MARKER_WIDTH + FONT_SIZE + 2) * dpr);
            ctx.textAlign = "start";
            ctx.textBaseline = "middle";
            ctx.strokeText(`${MIN_Y}˚`, cw / 2 + MARKER_WIDTH * dpr, ch - MARGIN * dpr);
            ctx.strokeText(`${MAX_Y}˚ ${Y_LABEL}`, cw / 2 + MARKER_WIDTH * dpr, MARGIN * dpr);
        },
        [dpr],
    );

    const drawInclination = useCallback(
        (ctx: CanvasRenderingContext2D, cw: number, ch: number, inclination: Inclination) => {
            const x = cw / 2 + (clamp(inclination.roll, MIN_X, MAX_X) / (MAX_X - MIN_X)) * (cw - 2 * MARGIN * dpr);
            const y = ch / 2 - (clamp(inclination.pitch, MIN_Y, MAX_Y) / (MAX_Y - MIN_Y)) * (ch - 2 * MARGIN * dpr);
            const p = new Path2D();
            p.arc(x, y, DOT_RADIUS * dpr, 0, 2 * Math.PI);
            ctx.strokeStyle = Colors.secondary.white;
            ctx.stroke(p);
            ctx.fillStyle = inclination.withinLimits ? Colors.status.info : Colors.status.danger;
            ctx.fill(p);
        },
        [dpr],
    );

    const redrawCanvas = useCallback(() => {
        const ctx = canvasContext;
        if (!ctx) {
            return;
        }
        const canvas = ctx.canvas;
        const ch = canvas.height;
        const cw = canvas.width;

        // Clear the canvas
        ctx.clearRect(0, 0, cw, ch);
        ctx.imageSmoothingEnabled = false;

        // Fill background
        ctx.fillStyle = OldColors.backgroundPrimaryDark;
        ctx.fillRect(0, 0, cw, ch);

        ctx.font = `normal ${FONT_SIZE * dpr}px 'Roboto'`;

        drawAxes(ctx, cw, ch);
        drawAxisMarkers(ctx, cw, ch);
        drawAxisLabels(ctx, cw, ch);
        drawInclination(ctx, cw, ch, inclination);

        ctx.restore();
    }, [canvasContext, dpr, inclination, drawAxes, drawAxisMarkers, drawAxisLabels, drawInclination]);

    const resizeCanvas = useCallback(() => {
        const canvas = canvasElement.current!;
        // Make it visually fill the positioned parent
        canvas.style.width = "100%";
        // canvas.style.height = "100%";
        canvas.style.height = `${canvas.offsetWidth}px`;
        // ...then set the internal size to matchxw
        canvas.width = canvas.offsetWidth * dpr;
        canvas.height = canvas.offsetWidth * dpr;
        redrawCanvas();
    }, [dpr, redrawCanvas]);

    useEffect(() => {
        const canvas = canvasElement.current!;
        resizeCanvas();
        setCanvasContext(canvas.getContext("2d")!);
    }, [resizeCanvas]);

    // Get the device pixel ratio, falling back to 1.
    useEffect(() => setDevicePixelRatio(window.devicePixelRatio || 1), []);

    // Listen to component resize and update canvas size
    useEffect(() => {
        // Debounce resize event
        let timeoutId: NodeJS.Timeout | null = null;
        const onResize = (): void => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => resizeCanvas(), 150);
        };
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [resizeCanvas]);

    return (
        <Root>
            <canvas ref={canvasElement} />
        </Root>
    );
};
