import styled from "styled-components";
import React, { Component } from "react";
import { Colors, CustomColors } from "../appearance/Colors";
import aircraftChevronIcon from "../../res/images/legend/track_aircraft_chevron.svg";
import { AltitudeGuide } from "./AltitudeSliderViewModel";
import _ from "lodash";
import { fillRoundRect } from "../../utils/CanvasUtils";
import { HandleType } from "./HandleType";
import { getPointFromEvent } from "../../utils/UserInteractionEventsUtils";
import { t } from "i18next";

const Container = styled.div`
    left: 0;
    width: 100%;
    height: 100%;
    position: relative;
`;

interface PointCalculationContext {
    minAltitude: number;
    maxAltitude: number;
    altitudeStep: number;
}

export interface AltitudeSliderGraphData {
    altitude: number;
    value: number;
}

interface Props {
    shouldShowGuideline: boolean;
    graphData: AltitudeSliderGraphData[];
    aircraftAltitudes: number[];
    selectedMinAltitude: number;
    selectedMaxAltitude: number;
    minAltitude: number;
    maxAltitude: number;
    altitudeStep: number;
    altitudeGuides: AltitudeGuide[];
    topHandleLabel: string;
    bottomHandleLabel: string;
    onAltitudeRangeChange: (altitude: number, handleType: HandleType) => void;
}

interface State {
    canvasWidth: number;
    canvasHeight: number;
}

export class AltitudeSliderGraph extends Component<Props, State> {
    // Properties
    private readonly canvas: React.RefObject<HTMLCanvasElement>;
    private altitudeToScreenY = new Map<number, number>();
    private pointCalculationContext?: PointCalculationContext;
    private aircraftChevronIcon: HTMLImageElement | null = null;
    private resizeEventListener: EventListenerOrEventListenerObject = () => this.adjustCanvasSize();
    private selectedHandle: HandleType = HandleType.None;

    private sliderTrackWidth = 6;

    private sliderHandleHeight = 22;
    private sliderHandleWidth = 66;
    private sliderHandleBorderRadius = 8;
    private sliderTouchPadding = 0;

    private graphLineWidth = 5;
    private graphPaddingHorizontal = 30;
    private guidelineBirdDensity = 50;

    private get totalRange(): number {
        return this.props.maxAltitude - this.props.minAltitude;
    }

    private get trackBottom(): number {
        // To make sure the graph guide labels are visible
        const fixedBottomSpace = 60;
        return this.state.canvasHeight - fixedBottomSpace;
    }

    private get trackTop(): number {
        return 20;
    }

    private get graphCenterX(): number {
        return Math.max(this.state.canvasWidth * 0.25, 60);
    }

    private get barMaxWidth(): number {
        return this.state.canvasWidth - this.graphCenterX - this.graphPaddingHorizontal;
    }

    private get boundingClientRect(): DOMRect {
        return this.canvas.current?.getBoundingClientRect() || new DOMRect();
    }

    // Lifecycle

    public constructor(props: Props) {
        super(props);
        this.state = {
            canvasWidth: 0,
            canvasHeight: 0,
        };
        this.canvas = React.createRef();
    }

    // Public functions

    public componentDidMount(): void {
        window.addEventListener("resize", this.resizeEventListener);
        window.addEventListener("mousedown", this.onTouchStart);
        window.addEventListener("touchstart", this.onTouchStart);
        window.addEventListener("mouseup", this.onTouchEnd);
        window.addEventListener("touchend", this.onTouchEnd);
        window.addEventListener("mousemove", this.onDrag);
        window.addEventListener("touchmove", this.onDrag);

        this.loadImages();
        this.adjustCanvasSize();
        this.draw();
        this.updatePointCalculations();
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.resizeEventListener);
        window.removeEventListener("mousedown", this.onTouchStart);
        window.removeEventListener("touchstart", this.onTouchStart);
        window.removeEventListener("mouseup", this.onTouchEnd);
        window.removeEventListener("touchend", this.onTouchEnd);
        window.removeEventListener("mousemove", this.onDrag);
        window.removeEventListener("touchmove", this.onDrag);
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.graphData !== this.props.graphData) {
            this.draw();
        }
        this.updatePointCalculations();
    }

    public render(): React.ReactNode {
        return (
            <Container>
                <canvas
                    ref={this.canvas}
                    style={{
                        width: this.state.canvasWidth,
                        height: this.state.canvasHeight,
                    }}
                    width={this.state.canvasWidth * window.devicePixelRatio}
                    height={this.state.canvasHeight * window.devicePixelRatio}
                />
            </Container>
        );
    }

    // #region Draw

    private draw(): void {
        if (this.canvas.current == null) {
            return;
        }

        const context = this.canvas.current.getContext("2d");
        if (context == null) {
            return;
        }

        // Reset scale
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.scale(window.devicePixelRatio, window.devicePixelRatio);

        // Clear canvas
        context.clearRect(0, 0, this.state.canvasWidth, this.state.canvasHeight);
        context.beginPath();
        context.closePath();
        context.save();

        // Set max value
        let maxValue = this.guidelineBirdDensity;
        if (!this.props.shouldShowGuideline) {
            maxValue = 0;
        }
        this.props.graphData
            .filter((item) => this.isAltitudeInSelectedRange(item.altitude))
            .forEach((item) => {
                maxValue = Math.max(maxValue, item.value);
            });

        // Draw horizontal bars at different altitudes
        this.drawBarGraph(context, this.props.graphData, maxValue);

        if (this.props.shouldShowGuideline) {
            this.drawGuideLine(context, maxValue);
        }

        // Draw altitude text labels
        this.drawAltitudeLabels(context);

        // Draw vertical slider track
        this.drawSliderTrack(context);

        // Draw aircraft tracks
        this.drawAircraft(context, this.props.aircraftAltitudes);

        // Draw slider handles
        this.drawHandles(context);
    }

    /**
     * Draws the horizontal bars at different altitudes.
     * @param context Context of the canvas.
     * @param data Graph data.
     * @param maxValue Upper limit of the graph.
     */
    private drawBarGraph(context: CanvasRenderingContext2D, data: AltitudeSliderGraphData[], maxValue: number): void {
        data.filter((item) => this.isAltitudeInSelectedRange(item.altitude)).forEach((item) => {
            const maxWidth = this.barMaxWidth;
            const graphCenterX = this.sliderTrackWidth / 2 + this.graphCenterX;
            const barWidth = (item.value / maxValue) * maxWidth;
            context.fillStyle = CustomColors.altitdeFilterGraph.horizontalBars;
            const y = this.altitudeToViewPosition(item.altitude) - this.graphLineWidth / 2;
            context.fillRect(graphCenterX, y, barWidth, this.graphLineWidth);
        });
        context.restore();
        context.save();
    }

    /**
     * Draws the guideline and the vertical line under the slider track.
     * @param context Context of the canvas.
     * @param maxValue Upper limit of the graph.
     */
    private drawGuideLine(context: CanvasRenderingContext2D, maxValue: number): void {
        const paddingBottom = 15;

        context.strokeStyle = CustomColors.altitdeFilterGraph.graphLines;

        // Tracks count guideline
        context.beginPath();
        const cx = this.graphCenterX;
        const maxWidth = this.barMaxWidth;
        const guidLineX = cx + (this.guidelineBirdDensity / maxValue) * maxWidth + 1;
        const top = this.trackTop;
        const bottom = this.trackBottom;
        context.moveTo(guidLineX, top);
        context.lineTo(guidLineX, bottom + paddingBottom);
        context.closePath();
        context.stroke();

        // Dotted vertical line under the slider track
        context.beginPath();
        context.moveTo(cx, bottom + this.graphLineWidth / 2);
        context.lineTo(cx, bottom + paddingBottom);
        context.closePath();
        context.stroke();

        // Solid horizontal line (density axis)
        context.beginPath();
        context.moveTo(cx - 6, bottom);
        context.lineTo(cx + maxWidth + this.graphPaddingHorizontal, bottom);
        context.closePath();
        context.stroke();

        // Labels
        context.fillStyle = CustomColors.altitdeFilterGraph.graphLines;
        context.font = "12px sans-serif";
        context.textBaseline = "middle";

        // Zero label
        context.textAlign = "center";
        context.fillText("0", cx, bottom + paddingBottom + 10);

        // Density axis label
        context.textAlign = "left";
        context.fillText(t("general.tracks"), cx - 4, bottom + paddingBottom + 25);

        // Guideline
        context.textAlign = "center";
        context.fillText(this.guidelineBirdDensity.toFixed(0), guidLineX, bottom + paddingBottom + 10);

        context.restore();
        context.save();
    }

    private drawAircraft(context: CanvasRenderingContext2D, aircraftAltitudes: number[]): void {
        aircraftAltitudes
            .filter((a) => a <= this.props.maxAltitude && a >= this.props.minAltitude)
            .forEach((aircraftAltitude) => {
                const y = this.altitudeToViewPosition(aircraftAltitude);
                const cx = this.graphCenterX;
                const offsetX = cx * 0.7;
                // Draw dashed lines
                context.beginPath();
                context.strokeStyle = CustomColors.altitdeFilterGraph.graphLines;
                context.setLineDash([6, 2]);
                context.moveTo(cx - this.sliderTrackWidth / 2, y);
                context.lineTo(cx - offsetX, y);
                context.closePath();
                context.stroke();
                context.restore();
                context.save();

                // Draw icons
                context.beginPath();
                context.strokeStyle = Colors.secondary.white;
                context.fillStyle = Colors.secondary.white;
                const circleRadius = 8;
                const iconCenter = [cx - offsetX - circleRadius - 1, y];
                context.arc(iconCenter[0], iconCenter[1], circleRadius, 0, 2 * Math.PI);
                context.fill();
                if (this.aircraftChevronIcon != null) {
                    context.drawImage(
                        this.aircraftChevronIcon,
                        iconCenter[0] - this.aircraftChevronIcon.width / 2,
                        iconCenter[1] - this.aircraftChevronIcon.height / 2,
                    );
                }
                context.closePath();
                context.restore();
                context.save();
            });
    }

    /**
     * Draws the vertical slider track line.
     * @param context Context of the canvas.
     */
    private drawSliderTrack(context: CanvasRenderingContext2D): void {
        context.fillStyle = Colors.secondary.white;
        const trackTop = this.trackTop;
        context.fillRect(
            this.graphCenterX - this.sliderTrackWidth / 2,
            trackTop - this.graphLineWidth / 2,
            this.sliderTrackWidth,
            this.trackBottom - trackTop + this.graphLineWidth,
        );

        context.fillStyle = Colors.status.info;
        const startY = this.altitudeToViewPosition(this.props.selectedMaxAltitude);
        context.fillRect(
            this.graphCenterX - this.sliderTrackWidth / 2,
            startY,
            this.sliderTrackWidth,
            this.altitudeToViewPosition(this.props.selectedMinAltitude) - startY,
        );
        context.restore();
        context.save();
    }

    /**
     * Draws the slider text labels.
     * @param context Context of the canvas.
     */
    private drawAltitudeLabels(context: CanvasRenderingContext2D): void {
        context.fillStyle = Colors.secondary.white;
        context.font = "12px sans-serif";
        context.textAlign = "right";
        context.textBaseline = "middle";
        const x = this.graphCenterX - 10;
        this.props.altitudeGuides.forEach((guide) => {
            const y = this.altitudeToViewPosition(guide.altitude);
            context.fillText(guide.label, x, y);
        });
        context.restore();
        context.save();
    }

    /**
     * Draws the slider handles.
     * @param context Context of the canvas.
     */
    private drawHandles(context: CanvasRenderingContext2D): void {
        const hh = this.sliderHandleHeight;
        const hw = this.sliderHandleWidth;
        const hbr = this.sliderHandleBorderRadius;
        const cx = this.graphCenterX - hw / 2;

        context.fillStyle = Colors.secondary.blue;
        context.font = "12px sans-serif";
        context.textAlign = "center";
        context.textBaseline = "middle";

        // Bottom handle
        const cyBottom = this.getSliderHandlePositionY(HandleType.Bottom);
        fillRoundRect(context, cx, cyBottom, hw, hh, hbr);
        // Top handle
        const cyTop = this.getSliderHandlePositionY(HandleType.Top);
        fillRoundRect(context, cx, cyTop, hw, hh, hbr);

        context.fillStyle = Colors.text.text;
        // Handle labels
        context.fillText(this.props.bottomHandleLabel, cx + hw / 2, cyBottom + hh / 2);
        context.fillText(this.props.topHandleLabel, cx + hw / 2, cyTop + hh / 2);

        context.restore();
        context.save();
    }

    // #endregion

    // #region Helpers

    private getPointCalculationContext(): PointCalculationContext {
        return {
            minAltitude: this.props.minAltitude,
            maxAltitude: this.props.maxAltitude,
            altitudeStep: this.props.altitudeStep,
        };
    }

    private updatePointCalculations(): void {
        const currentContext = this.getPointCalculationContext();
        if (_.isEqual(this.pointCalculationContext, currentContext)) {
            return;
        }

        // Calculate and save
        const { minAltitude: min, maxAltitude: max, altitudeStep: step } = currentContext;
        this.altitudeToScreenY.clear();
        for (let altitude = min; altitude < max + step; altitude += step) {
            const pos = this.altitudeToViewPosition(altitude) || 0;
            if (pos != null) {
                this.altitudeToScreenY.set(altitude, Math.floor(pos));
            }
        }

        this.pointCalculationContext = currentContext;
    }

    private adjustCanvasSize(): void {
        if (this.canvas.current == null || this.canvas.current.parentElement == null) {
            return;
        }

        const bounds = this.canvas.current.parentElement.getBoundingClientRect();
        this.setState({
            canvasWidth: bounds.width,
            canvasHeight: bounds.height,
        });
    }

    private getClosestAltitudeStepToY(y: number): number {
        this.updatePointCalculations();

        const containerFrame = this.boundingClientRect;
        const relativeY = y - containerFrame.top;

        const closestEntry = _.minBy(Array.from(this.altitudeToScreenY.entries()), ([, screenY]) =>
            Math.abs(screenY - relativeY),
        );
        if (closestEntry == null) {
            return 0;
        }

        return closestEntry[0];
    }

    private altitudeToViewPosition(altitude: number): number {
        const top = this.trackTop;
        const h = this.trackBottom - this.trackTop;
        const linear = (altitude - this.props.minAltitude) / this.totalRange;
        const logarithmic = Math.log10(linear * 9 + 1);
        return top + h - logarithmic * h;
    }

    private getSliderHandlePositionY(handleType: HandleType): number {
        let position: number | undefined;
        let otherHandlePosition: number | undefined;
        const offset = -this.sliderHandleHeight / 2;
        const tl = this.altitudeToViewPosition(this.props.selectedMaxAltitude);
        const bl = this.altitudeToViewPosition(this.props.selectedMinAltitude);

        switch (handleType) {
            case HandleType.Top:
                position = tl + offset;
                otherHandlePosition = bl + offset;
                break;

            case HandleType.Bottom:
                position = bl + offset;
                otherHandlePosition = tl + offset;
                break;
        }

        if (!position || !otherHandlePosition) {
            return 0;
        }

        if (handleType === HandleType.Bottom && Math.abs(position - otherHandlePosition) < this.sliderHandleHeight) {
            position = otherHandlePosition + this.sliderHandleHeight;
        }
        return position;
    }

    private isAltitudeInSelectedRange(altitude: number): boolean {
        return altitude >= this.props.selectedMinAltitude && altitude <= this.props.selectedMaxAltitude;
    }

    private loadImages(): void {
        const image = new Image();
        image.src = aircraftChevronIcon;
        image.onload = () => {
            this.aircraftChevronIcon = image;
        };
    }

    // #endregion

    // #region Event handlers

    private onTouchStart = (e: MouseEvent | TouchEvent): void => {
        const point = getPointFromEvent(e);
        const containerFrame = this.boundingClientRect;
        const relativeY = point.y - this.sliderHandleHeight / 2 - containerFrame.top;
        const relativeX = point.x - containerFrame.left;
        const topHandlePositionY = this.getSliderHandlePositionY(HandleType.Top);
        const bottomHandlePositionY = this.getSliderHandlePositionY(HandleType.Bottom);
        const cx = this.graphCenterX;

        const distanceToTopHandle = Math.abs(relativeY - topHandlePositionY);
        const distanceToBottomHandle = Math.abs(relativeY - bottomHandlePositionY);

        const hSpace = this.sliderHandleWidth / 2 + this.sliderTouchPadding;
        const vSpace = this.sliderHandleHeight / 2 + this.sliderTouchPadding;

        if (Math.abs(relativeX - cx) > hSpace) {
            return;
        }

        if (distanceToTopHandle > vSpace && distanceToBottomHandle > vSpace) {
            return;
        }

        if (distanceToTopHandle < distanceToBottomHandle) {
            this.selectedHandle = HandleType.Top;
        } else {
            this.selectedHandle = HandleType.Bottom;
        }
    };

    private onTouchEnd = (): void => {
        this.selectedHandle = HandleType.None;
    };

    private onDrag = (e: MouseEvent | TouchEvent): void => {
        if (this.selectedHandle === HandleType.None) {
            return;
        }

        const position = getPointFromEvent(e).y - this.sliderHandleHeight / 2;
        const altitude = this.getClosestAltitudeStepToY(position) || 0;
        this.props.onAltitudeRangeChange(altitude, this.selectedHandle);
    };

    // #endregion
}
