import React, { useRef } from "react";
import styled from "styled-components";
import { BASE_TEXT_STYLE } from "../theme/GlobalStyles";
import { LINE_HEIGHT_LARGE, LINE_HEIGHT_SMALL, INPUT_HEIGHT } from "../theme/Spacing";
import { StatusColorsType, getStatusHoverColor } from "../Colors";
import { Icon, IconType } from "../Icon";
import ReactTooltip from "react-tooltip";
import { PropsWithTooltip } from "../Tooltip";

const TOOLTIP_ID = "button-tooltip";

const DefaultButton = styled.button<{
    color?: StatusColorsType;
    disabled?: boolean;
    active?: boolean;
    horizontalFill?: boolean;
}>`
    ${BASE_TEXT_STYLE}
    display: inline-flex;
    flex-direction: row;
    justify-content: ${({ horizontalFill }) => (horizontalFill ? "space-between" : "center")};
    align-items: center;
    gap: ${({ theme }) => theme.spacing.x2};
    background-color: transparent;
    border: none;
    border-radius: ${({ theme }) => theme.spacing.x2};
    color: ${({ color, disabled, theme }) => theme.colors.status[disabled ? "disabled" : color ?? "neutral"]};
    text-transform: uppercase;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    transition: background-color ${({ theme }) => theme.transitionDuration} ease-out,
        color ${({ theme }) => theme.transitionDuration} ease-out;
    font-weight: 500;

    :is(:hover, :focus) {
        background-color: ${({ color, disabled, theme }) => {
            if (disabled) {
                return theme.colors.status.disabled;
            }
            if (color) {
                return getStatusHoverColor(color);
            }
            return theme.colors.status.infoHover;
        }};
        color: ${({ disabled, theme }) => (disabled ? theme.colors.text.text400 : theme.colors.text.text)};
    }
`;

const LargeButton = styled(DefaultButton)`
    padding: ${({ theme }) => theme.spacing.x2} ${({ theme }) => theme.spacing.x10};
    background-color: ${({ color, disabled, theme }) =>
        disabled ? theme.colors.text.text300 : theme.colors.status[color ?? "info"]};
    color: ${({ disabled, theme }) => (disabled ? theme.colors.text.text400 : theme.colors.text.text)};
    border-radius: ${({ theme }) => theme.spacing.x2};
    line-height: ${LINE_HEIGHT_LARGE};

    :is(:hover, :focus) {
        background-color: ${({ color, disabled, theme }) => {
            if (disabled) {
                return theme.colors.text.text300;
            }
            if (color) {
                return getStatusHoverColor(color);
            }
            return theme.colors.status.infoHover;
        }};
        color: ${({ disabled, theme }) => (disabled ? theme.colors.text.text90 : theme.colors.text.text)};
    }
`;

const RadioButton = styled(LargeButton)`
    background-color: ${({ active, disabled, theme }) => {
        if (disabled) {
            return theme.colors.text.text300;
        }
        if (active) {
            return theme.colors.status.info;
        }
        return theme.colors.interactive.input;
    }};

    :is(:hover, :focus) {
        background-color: ${({ active, disabled, theme }) => {
            if (disabled) {
                return theme.colors.text.text300;
            }
            if (active) {
                return theme.colors.status.infoHover;
            }
            return theme.colors.interactive.input90;
        }};
    }
`;

const InlineButton = styled(DefaultButton)`
    padding: ${({ theme }) => theme.spacing.x1} ${({ theme }) => theme.spacing.x2};
    min-height: ${INPUT_HEIGHT};
    text-align: left;
    background-color: ${({ color, disabled, active, theme }) => {
        if (!active) {
            return "transparent";
        }
        if (disabled) {
            return theme.colors.text.text300;
        }
        return theme.colors.status[color ?? "info"];
    }};
    color: ${({ color, disabled, active, theme }) => {
        if (active) {
            return theme.colors.text.text;
        }
        if (disabled) {
            return theme.colors.text.text300;
        }
        return theme.colors.status[color ?? "info"];
    }};
    border-radius: ${({ theme }) => theme.spacing.x1};
    line-height: ${LINE_HEIGHT_SMALL};

    :is(:hover, :focus) {
        ${({ color, disabled, active, theme }) => {
            if (disabled) {
                return "";
            }
            if (active) {
                return `
                    background-color: ${color ? getStatusHoverColor(color) : theme.colors.secondary.blue200};
                `;
            }
            return `
                background-color: ${theme.colors.interactive.textbuttonHover};
                color: ${theme.colors.status[color ?? "info"]};
            `;
        }}
    }
`;

interface Props {
    color?: StatusColorsType;
    disabled?: boolean;
    active?: boolean;
    iconType?: IconType;
    iconPosition?: "left" | "right";
    label?: string;
    layout?: "inline" | "inline-fill" | "radio";
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button = ({
    color,
    disabled,
    active,
    iconType,
    iconPosition = "right",
    label,
    layout,
    onClick,
    tooltip,
    tooltipPosition,
}: PropsWithTooltip<Props & React.HTMLAttributes<HTMLButtonElement>>): JSX.Element => {
    // Render

    const buttonRef = useRef<HTMLButtonElement>(null);
    let Component = LargeButton;
    switch (layout) {
        default:
            Component = LargeButton;
            break;
        case "inline":
        case "inline-fill":
            Component = InlineButton;
            break;
        case "radio":
            Component = RadioButton;
            break;
    }
    const showLabel = label !== undefined;
    const showIcon = iconType !== undefined;
    // Show a large icon if there is no label and a large button layout is used (which is default)
    const largeIcon = (layout === undefined || layout === "radio") && !showLabel;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        // Prevent the button from submitting a form
        event.preventDefault();
        // Remove focus from the button
        buttonRef.current?.blur();
        // Proceed with the onClick handler
        onClick?.(event);
    };

    return (
        <Component
            color={color}
            disabled={disabled}
            active={active}
            onClick={handleClick}
            horizontalFill={layout === "inline-fill"}
            data-for={TOOLTIP_ID}
            data-tip={tooltip}
            data-iscapture="true"
            ref={buttonRef}
        >
            {showIcon && iconPosition === "left" && <Icon iconType={iconType} large={largeIcon} />}
            {showLabel && label}
            {showIcon && iconPosition === "right" && <Icon iconType={iconType} large={largeIcon} />}
            <ReactTooltip id={TOOLTIP_ID} effect={"solid"} place={tooltipPosition ?? "bottom"} />
        </Component>
    );
};
