import { enumValueOrDefault } from "../../utils/EnumUtils";

export enum VelocityUnit {
    MSEC = "msec",
    KMH = "kmh",
}

export const DEFAULT_VELOCITY_UNIT = VelocityUnit.MSEC;

export const getVelocityUnit = (value: string | null): VelocityUnit =>
    enumValueOrDefault(VelocityUnit, value, DEFAULT_VELOCITY_UNIT);
