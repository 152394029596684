import {
    ReplayRepository,
    LocalPreferencesRepository,
    RadarRepository,
    UserLocationRepository,
    LocationInfoRepository,
    MeasurementPointsRepository,
    AlarmRepository,
} from "../../../../domain/repositories";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { MapModuleViewModel } from "../MapModuleViewModel";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import {
    LocalUserPreferenceKeys,
    AltitudeRange,
    Trackable,
    TrackLabel,
    MeasurementPoint,
    AlarmDataType,
} from "../../../../domain/model";
import { PlaybackState } from "../../../../domain/PlaybackScene";
import { SnapshotDiffCalculator, TrackableSnapshotDiff } from "../../../../domain/SnapshotDiffCalculator";
import { VelocityUnit } from "../../../../domain/model/VelocityUnit";
import { AltitudeConfig } from "../../../../domain/model/Altitude";

export abstract class BaseTracksModuleViewModel<TrackType extends Trackable> extends MapModuleViewModel {
    // Properties

    public get tracksSnapshotDiff(): Rx.Observable<TrackableSnapshotDiff<TrackType>> {
        return this.snapshotDiffCalculator.snapshotDiff;
    }

    public get playbackState(): Rx.Observable<PlaybackState | null> {
        return this.replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((s) => (s == null ? Rx.of(null) : s.state)),
            RxOperators.distinctUntilChanged(),
            RxOperators.debounceTime(500),
        );
    }

    public get altitudeRangeOfInterest(): Rx.Observable<AltitudeRange> {
        return Rx.combineLatest([
            this.localPreferencesRepository.observePreference<number>(
                LocalUserPreferenceKeys.filters.minAltitudeOfInterest,
            ),
            this.localPreferencesRepository.observePreference<number>(
                LocalUserPreferenceKeys.filters.maxAltitudeOfInterest,
            ),
        ]).pipe(
            RxOperators.map(([min, max]) => {
                return { top: max, bottom: min };
            }),
        );
    }

    public get showTracksWithoutAltitude(): Rx.Observable<boolean> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference(
                LocalUserPreferenceKeys.filters.showTracksWithoutAltitude,
            ),
            true,
        );
    }

    public get trackLabel(): Rx.Observable<TrackLabel> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<TrackLabel>(LocalUserPreferenceKeys.filters.trackLabel),
        );
    }

    public get hasReliableAltitudeInfo(): Rx.Observable<boolean> {
        return this.locationInfoRepository.hasReliableAltitudeInfo;
    }

    public get iconScale(): Rx.Observable<number> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<number>(LocalUserPreferenceKeys.appearance.iconScale),
        );
    }

    public get finishedTrackOpacity(): Rx.Observable<number> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<number>(
                LocalUserPreferenceKeys.appearance.finishedTrackOpacity,
            ),
        );
    }

    public get showClassificationHistoryInTrajectory(): Rx.Observable<boolean> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<boolean>(
                LocalUserPreferenceKeys.appearance.showClassificationHistoryInTrajectory,
            ),
        );
    }

    public get displayNegativeAltitudeAsZero(): Rx.Observable<boolean> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<boolean>(
                LocalUserPreferenceKeys.appearance.displayNegativeAltitudeAsZero,
            ),
        );
    }

    public get measurementPoints(): Rx.Observable<MeasurementPoint[]> {
        return this.measurementPointsRepository.measurementPoints;
    }

    public get trailLength(): Rx.Observable<number> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<number>(LocalUserPreferenceKeys.appearance.trailLength),
        );
    }

    public get velocityUnit(): Rx.Observable<VelocityUnit> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<VelocityUnit>(
                LocalUserPreferenceKeys.appearance.velocityUnit,
            ),
        );
    }

    public get trackIdsWithAlarms(): Rx.Observable<int[]> {
        return Rx.combineLatest([this.alarmRepository.activeRealtime, this.alarmRepository.alarmBoxHovering]).pipe(
            RxOperators.map(([alarms, alarmBoxHovering]) =>
                alarms
                    .filter((alarm) => alarmBoxHovering == null || alarm.type === alarmBoxHovering)
                    .flatMap((alarm) => {
                        switch (alarm.type) {
                            case AlarmDataType.AREA_ENTRY:
                            case AlarmDataType.DRONE:
                                return [alarm.trackId];
                            case AlarmDataType.FUNNEL_TRAFFIC_REACHED_THRESHOLD:
                                return alarm.trackIds;
                            default:
                                return [];
                        }
                    }),
            ),
            // De-duplicate track IDs
            RxOperators.map((trackIds) => Array.from(new Set(trackIds)).sort()),
            // Only emit a new value if the track IDs have changed
            RxOperators.distinctUntilChanged(
                (a, b) => a.length === b.length && a.every((value, index) => value === b[index]),
            ),
        );
    }

    public get forceDimTracksWithoutAlarms(): Rx.Observable<boolean> {
        return this.alarmRepository.alarmBoxHovering.pipe(
            RxOperators.map((alarmBoxHovering) => {
                switch (alarmBoxHovering) {
                    case AlarmDataType.AREA_ENTRY:
                    case AlarmDataType.DRONE:
                    case AlarmDataType.FUNNEL_TRAFFIC_REACHED_THRESHOLD:
                        return true;
                    default:
                        return false;
                }
            }),
            RxOperators.distinctUntilChanged(),
        );
    }

    public get radarGroundLevel(): Rx.Observable<number | null> {
        return this.radarRepository.groundLevel;
    }

    public get altitudeConfig(): Rx.Observable<AltitudeConfig | null> {
        return this.localPreferencesRepository.observePreference(
            LocalUserPreferenceKeys.appearance.altitudeConfig,
            AltitudeConfig.WGS84,
        )!;
    }

    public abstract getSelectedTrackId(): Rx.Observable<number | null>;
    public abstract selectTrack(trackId: number | null): void;

    protected abstract snapshotDiffCalculator: SnapshotDiffCalculator<TrackType>;

    public constructor(
        protected readonly localPreferencesRepository: LocalPreferencesRepository,
        protected readonly replayRepository: ReplayRepository,
        protected readonly radarRepository: RadarRepository,
        protected readonly userLocationRepository: UserLocationRepository,
        protected readonly locationInfoRepository: LocationInfoRepository,
        protected readonly measurementPointsRepository: MeasurementPointsRepository,
        protected readonly alarmRepository: AlarmRepository,
    ) {
        super();
    }

    // Public functions

    public setup(): void {
        super.setup();
        this.collectSubscriptions(
            this.playbackState.subscribe(
                (state) =>
                    this.snapshotDiffCalculator.resetFinishedTracks(
                        state === PlaybackState.PLAYING || state === PlaybackState.STOPPED,
                    ),
                (error) => console.error(error),
            ),
            this.localPreferencesRepository
                .observePreference(LocalUserPreferenceKeys.selections.selectedTileProviderId)
                .subscribe(() => this.selectTrack(null)),
        );
    }
}
