import { DistanceFormatter, DistanceFormatType } from "../domain/DistanceFormatter";
import { DistanceUnit } from "../domain/model";
import { VelocityUnit } from "../domain/model/VelocityUnit";
import { t } from "i18next";

export const formatVelocity = (velocity: number, velocityUnit: VelocityUnit): string => {
    switch (velocityUnit) {
        case VelocityUnit.KMH:
            const hourBasedVelocity = (60 * 60 * velocity) / 1000;
            return `${hourBasedVelocity.toFixed(1)} ${t("unit.kilometerPerHour")}`;
        case VelocityUnit.MSEC:
        default:
            return `${velocity.toFixed(1)} ${t("unit.meterPerSecond")}`;
    }
};

export const formatTrackAltitude = (
    distanceFormatter: DistanceFormatter,
    altitude?: number,
    displayNegativeAltitudeAsZero?: boolean,
    distanceFormatType?: DistanceFormatType,
): string | null => {
    if (altitude == null || isNaN(altitude)) {
        return null;
    }

    let sanitizedAltitude = altitude;
    if (displayNegativeAltitudeAsZero) {
        sanitizedAltitude = Math.max(Math.floor(sanitizedAltitude), 0);
    }
    const altitudeString = distanceFormatter.formatValueWithCurrentUnit(sanitizedAltitude, DistanceUnit.METRIC, {
        formatType: distanceFormatType || DistanceFormatType.NONE,
    });
    return altitudeString;
};
