const backgroundColors = {
    page: "#1B1D23",
    panel: "#000000",
    panelSection: "#16191D",
    shadow: "rgba(0, 0, 0, 0.5)",
};
const textColors = {
    text90: "#0D0F1B",
    text: "#ffffff",
    text200: "#C3C4CC",
    text300: "#999A9F",
    text400: "#616161",
};
const secondaryColors = {
    blue90: "#99ADFC",
    blue: "#5677FC",
    blue200: "#34458a",
    red: "#E62A10",
    red200: "#B81600",
    orange: "#FF9800",
    orange200: "#AC6702",
    yellow: "#FFEB3B",
    yellow200: "#D1BE13",
    green: "#2DB290",
    green200: "#1B6859",
    purple: "#9C27B0",
    white: textColors.text,
    white200: textColors.text200,
};
const interactiveColors = {
    input: "#2E3143",
    input90: "#393C4F",
    inputDisabled: textColors.text300,
    textbuttonHover: "#252943",
};
/**
 * Alias for secondaryColors
 */
const statusColors = {
    danger: secondaryColors.red,
    dangerHover: secondaryColors.red200,
    disabled: textColors.text300,
    disabledHover: textColors.text300,
    info: secondaryColors.blue,
    infoHover: secondaryColors.blue200,
    neutral: textColors.text,
    neutralHover: textColors.text200,
    success: secondaryColors.green,
    successHover: secondaryColors.green200,
    warning: secondaryColors.orange,
    warningHover: secondaryColors.orange200,
};

export const Colors = {
    backgrounds: backgroundColors,
    text: textColors,
    secondary: secondaryColors,
    interactive: interactiveColors,
    status: statusColors,
};

export type BackgroundColorsType = keyof typeof backgroundColors;
export type TextColorsType = keyof typeof textColors;
export type SecondaryColorsType = keyof typeof secondaryColors;
export type InteractiveColorsType = keyof typeof interactiveColors;
export type StatusColorsType = keyof typeof statusColors;

/**
 * This will return the hover variant of any status color.
 */
export const getStatusHoverColor = (color: StatusColorsType): string => {
    switch (color) {
        case "danger":
            return Colors.status.dangerHover;
        case "disabled":
            return Colors.status.disabledHover;
        default:
        case "info":
            return Colors.status.infoHover;
        case "neutral":
            return Colors.status.neutralHover;
        case "success":
            return Colors.status.successHover;
        case "warning":
            return Colors.status.warningHover;
    }
};

/**
 * Colors that are not part of the design system.
 */
export const CustomColors = {
    altitudeExplanation: {
        fillColor: "#363944",
        lineColor: "#8d96b2",
        lineColorShade: "#646B87",
    },
    altitdeFilterGraph: {
        graphLines: textColors.text400,
        horizontalBars: textColors.text300,
    },
    trackDefaults: {
        aircraftChevron: "#13182b",
        birdInnerCircles: "#13182b",
    },
};

/**
 * Preserving these colors for future light theme
 */
// const LightThemeColors = {
//     funnels: {
//         funnelSector: "#e6e6e6",
//         funnelSectorDetailed: "#26000000",
//         funnelSectorDetailedThreshold: "#33e62a10",
//         directionLabel: "#999999",
//         heightLabelIndexOdd: "#999999",
//         heightLabelIndexEven: "#000000",
//         trackShadow: "#000000",
//     },
// };

/**
 * @deprecated Use `Colors` instead.
 */
export const OldColors = {
    black: "#000000",
    grey: "#777777",
    whiteInactive: "#8a8a8a",
    backgroundPrimaryDark: "#0d0f1b",
    primaryTint: "#5677fc",
    primaryHoverTint: "#34458a",
    primaryTint50: "#5677fc77", // primaryTint + opacity 50%
    textSecondary: "#71747f",
    textSecondaryLighter: "#C3C4CC",
    disabledBackgroundColor: "#666666",
    disabledColor: "#c2c4cc",
};
