import * as mapboxgl from "mapbox-gl";
import { getColorAndOpacity } from "../../../../utils/ColorUtils";
import {
    Classification,
    Estimate,
    Track,
    getTrackEstimatesWithinPeriod,
    TracksSnapshotDiff,
    ClassificationIcon,
    TrackPlotType,
} from "../../../../domain/model";
import { Feature } from "geojson";
import * as MapUtils from "../../../../utils/MapUtils";
import { DistanceFormatter } from "../../../../domain/DistanceFormatter";
import { TrackObservationMode } from "../../../../domain/repositories";
import { generateObservedTrackSymbol, generateObservationSymbol } from "../ObservationSymbols";
import { PROPS, BaseTracksLayer, BaseTrackPartLayers, TrackLayerOptions, LineInfo } from "./BaseTracksLayer";
import { LayerEventListener } from "../InteractiveMapLayer";

const PREFIX_SYMBOL = "symbol-";
const PREFIX_SYMBOL_SELECTED = "symbol-selected-";
const PREFIX_SYMBOL_ALARM = "symbol-alarm-";
const LAYER_ALARM = "layer-tracks-alarm";
const LAYER_HEAD = "layer-tracks-head";
const LAYER_SELECTED_TRACK = "layer-selected-track";
const LAYER_HEAD_HOVER = "layer-tracks-head-hover";
const LAYER_TRAJECTORY = "layer-tracks-trajectory";
const SOURCE_TRACKS = "source-tracks";

const LAYER_OBSERVATION_STATE = "layer-track-observation-state-";
const PREFIX_SYMBOL_UNDER_OBSERVATION = "symbol-observation-";
const PREFIX_SYMBOL_OBSERVED_TRACK = "symbol-observed-";

const SOURCE_MISSING_TRACKS = "source-tracks-missing";
const LAYER_MISSING = "layer-tracks-missing";

const SYMBOL_ALARM = "symbol-alarm";

const UNKNOWN_CLASSIFICATION_NAME = "unknown";
const UNKNOWN_CLASSIFICATION_COLOR = "#ff00ff";
export const DARK_SYMBOL_COLOR = "#13182b";

interface TrackPartLayers extends BaseTrackPartLayers {
    missingLayer: mapboxgl.SymbolLayer;
    observationStateLayer: mapboxgl.SymbolLayer;
}

export const AIRCRAFT_ONLY_BEARING_VISUALIZATION: (track: Track) => boolean = (track) => track.isAirCraft;
export const ALL_TRACKS_BEARING_VISUALIZATION: (track: Track) => boolean = () => true;

export class TracksLayer extends BaseTracksLayer<Track> {
    // Static functions

    public static attachedTo(
        map: mapboxgl.Map,
        orderLayer: string,
        distanceFormatter: DistanceFormatter,
        options: TrackLayerOptions<Track>,
        onTrackSelected: ((trackId: number | null) => void) | null,
        onTrackClickedInObservation: ((track: Track) => void) | null,
    ): TracksLayer {
        return new TracksLayer(
            map,
            orderLayer,
            distanceFormatter,
            options,
            onTrackSelected,
            onTrackClickedInObservation,
        );
    }

    // Properties

    public shouldSuppressClickForObservation = false;
    public hasReliableAltitudeInfo = false;
    public showVrOnlyTracks = false;

    private classifications: Map<string, Classification> = new Map();
    private visibleClassificationNames: string[] = [];
    private applyAltitudeFilterClassifications: string[] = [];
    private trackObservationMode: TrackObservationMode = TrackObservationMode.None;
    // These tracks will keep their state in which time they were selected. So their estimations will not update.
    private observationSelectedTracks: Track[] = [];
    private observationMissingTracks: Track[] = [];
    private observedTrackIds: number[] = [];
    private removePreviousGestureListeners?: () => void;

    protected get trackSource(): string {
        return SOURCE_TRACKS;
    }

    private constructor(
        map: mapboxgl.Map,
        private orderLayer: string,
        distanceFormatter: DistanceFormatter,
        private readonly options: TrackLayerOptions<Track>,
        onTrackSelected: ((trackId: number | null) => void) | null,
        private readonly onTrackClickedInObservation: ((track: Track) => void) | null,
    ) {
        super(map, distanceFormatter, onTrackSelected);

        this.setup();
    }

    // Public functions

    public setClassifications(classifications: Map<string, Classification>): void {
        this.classifications = classifications;
        if (classifications.size === 0) {
            return;
        }
        this.loadClassificationIcons(this.map, classifications);
    }

    public updateTracks(diff: TracksSnapshotDiff): void {
        // Below steps do not neccessarily have an order. The comments are just there to explain chunks of code.
        // Step 1: Update the missing observation tracks
        const lastSnapshotTracksWithEstimates = diff.snapshotTracksWithEstimates;
        this.snapshotDiff = diff;

        const newMissingTrackIds = this.observationSelectedTracks
            .filter((selectedTrack) =>
                [...diff.snapshotTracksWithEstimates, ...this.observationMissingTracks].every(
                    (t) => t.id !== selectedTrack.id,
                ),
            )
            .map((t) => t.id);

        newMissingTrackIds.forEach((missingTrackId) => {
            const missingTrack = [...lastSnapshotTracksWithEstimates, ...diff.finishedTracks].find(
                (t) => t.id === missingTrackId,
            );
            if (missingTrack != null) {
                this.observationMissingTracks.push(missingTrack);
            }
        });

        // Step 2: Reorder, cleanup and group tracks so that they are ready to be drawn in the next step

        const allTracks = [...diff.snapshotTracksWithEstimates, ...diff.finishedTracks];
        const filteredTracks = allTracks.filter(
            (t) => this.getClosestEstimateTo(t, diff.snapshotTimestamp).classification != null,
        );

        // Step 3: Make features from tracks, assign them to their source and draw them

        this.updateTracksSource(filteredTracks, diff.snapshotTimestamp);
        this.updateMissingTracksSource(this.observationMissingTracks, diff.snapshotTimestamp);
    }

    public updateIconAndTrailScale(scale: number): void {
        super.updateIconAndTrailScale(scale);

        const layers = this.getTrackLayers();
        if (layers === null) {
            return;
        }

        // Icon scale: Images
        for (const classification of this.classifications.values()) {
            this.loadTrackOverlayIcon(this.map, classification, PREFIX_SYMBOL + this.getTag(classification));
        }
        this.loadAlarmTrackIcon(this.map, SYMBOL_ALARM);

        // Altitude label scale: SymbolLayout
        const textSize = (layers.headLayer.layout as mapboxgl.SymbolLayout)["text-size"];
        if (layers.headLayer && textSize) {
            this.map.setLayoutProperty(layers.headLayer.id, "text-size", textSize);
        }

        // Trail scale
        this.map.setPaintProperty(
            layers.trajectoryLayer.id,
            "line-width",
            (layers.trajectoryLayer.paint as mapboxgl.LinePaint)["line-width"],
        );
    }

    public setVisibleClassifications(visibleClassifications: Classification[]): void {
        this.visibleClassificationNames = visibleClassifications.map((c) => c.name);
        this.requestRedraw();
    }

    public setApplyAltitudeFilterClassifications(classificationNames: string[]): void {
        this.applyAltitudeFilterClassifications = classificationNames;
        this.requestRedraw();
    }

    public setFinishedTrackOpacity(opacity: float): void {
        super.setFinishedTrackOpacity(opacity, LAYER_HEAD, LAYER_HEAD_HOVER, LAYER_TRAJECTORY);
    }

    public setClassificationHistoryOnTrajectoryEnabled(enabled: boolean): void {
        super.setClassificationHistoryOnTrajectoryEnabled(enabled, LAYER_TRAJECTORY);
    }

    public setSelectedTracksInObservation(tracks: Track[]): void {
        this.observationSelectedTracks = tracks;
        // Keep only the missing tracks which are still selected
        this.observationMissingTracks = this.observationMissingTracks.filter((missing) =>
            tracks.some((t) => t.id === missing.id),
        );
        this.requestRedraw();
    }

    public setObservedTracks(trackIds: number[]): void {
        this.observedTrackIds = trackIds;
        this.requestRedraw();
    }

    public setTrackObservationMode(mode: TrackObservationMode): void {
        this.trackObservationMode = mode;
    }

    protected calculateEndTimeForTrack(track: Track): number | null {
        return track.endTime;
    }

    // Private functions

    private updateMissingTracksSource(missingTracks: Track[], timestamp: number): void {
        const source = this.map.getSource(SOURCE_MISSING_TRACKS) as mapboxgl.GeoJSONSource;
        if (source == null) {
            return;
        }

        if (missingTracks.length === 0) {
            // There are no track of this kind
            source.setData({
                type: "FeatureCollection",
                features: [],
            });
            return;
        }
        const features = this.getFeaturesFromMissingTracks(missingTracks, timestamp);

        source.setData({
            type: "FeatureCollection",
            features: features,
        });
    }

    private updateTracksSource(tracks: Track[], timestamp: number): void {
        const source = this.map.getSource(SOURCE_TRACKS) as mapboxgl.GeoJSONSource;
        if (source == null) {
            return;
        }

        // There are no tracks or tracks have been disabled
        if (!this.shouldShowTracks || tracks.length === 0) {
            source.setData({
                type: "FeatureCollection",
                features: [],
            });
            return;
        }

        const finishedTrackIds = this.finishedTracks.map((track) => track.id);
        const features = this.getFeaturesFromTracks(tracks, finishedTrackIds, timestamp);

        source.setData({
            type: "FeatureCollection",
            features: features,
        });
    }

    private shouldFilterTracksByAltitudeRange(classification: Classification): boolean {
        if (!this.hasReliableAltitudeInfo) {
            return false;
        }
        if (
            this.altitudeRangeOfInterest.top == null &&
            (this.altitudeRangeOfInterest.bottom == null || this.altitudeRangeOfInterest.bottom === 0)
        ) {
            return false;
        }
        return this.applyAltitudeFilterClassifications.includes(classification.name);
    }

    private filterOutTrackByAltitudeRange(track: Track, snapshotTimestamp: long): boolean {
        const range = this.altitudeRangeOfInterest;
        const lastAltitude = this.getLastKnownAltitude(track.estimates, snapshotTimestamp);
        if (lastAltitude == null) {
            return !this.showTracksWithoutAltitude;
        }
        const shouldFilter =
            (range.top != null && range.top < lastAltitude) || (range.bottom != null && range.bottom > lastAltitude);
        return shouldFilter;
    }

    private getFeaturesFromTracks(tracks: Track[], finishedTrackIds: int[], timestamp: long): Feature[] {
        return tracks
            .map((track) => this.makeTrackFeatures(track, finishedTrackIds.includes(track.id), timestamp))
            .flat();
    }

    private getFeaturesFromMissingTracks(tracks: Track[], timestamp: long): Feature[] {
        return tracks.map((track) => this.makeMissingTrackFeature(track, timestamp));
    }

    private makeTrackFeatures(track: Track, isFinished: boolean, timestamp: long): Feature[] {
        const timestampForEstimates = isFinished ? this.finishedTracksDeathTimes.get(track.id)! : timestamp;
        const processedEstimates = getTrackEstimatesWithinPeriod(track, timestampForEstimates, this.trailLength);

        // If this track has no estimates and is not finished, do not show it
        if (processedEstimates.length === 0) {
            if (!isFinished) {
                return [];
            }

            processedEstimates.push(track.lastEstimate);
        }
        const selected = this.observationSelectedTracks.some((t) => t.id === track.id);
        const headEstimate = processedEstimates[0];
        const classification = headEstimate.classification;

        // Check if this track should be visible
        if (classification && !this.visibleClassificationNames.includes(classification.name)) {
            return [];
        }

        // Check if this track should be filtered out by altitude
        if (
            classification &&
            this.shouldFilterTracksByAltitudeRange(classification) &&
            this.filterOutTrackByAltitudeRange(track, timestamp)
        ) {
            return [];
        }

        // Check if this track should be filtered out because VR only tracks are hidden
        if (!this.showVrOnlyTracks && track.trackPlotType === TrackPlotType.RANGE_ELEVATION) {
            return [];
        }

        const textColor = getColorAndOpacity(classification?.defaultColor || UNKNOWN_CLASSIFICATION_COLOR)[0];
        const textOffsetY =
            classification?.icon &&
            [ClassificationIcon.AIRCRAFT, ClassificationIcon.DRONE, ClassificationIcon.FIXED_WING].includes(
                classification.icon,
            )
                ? 1.75
                : 1.5;
        const alarmState = this.trackAlarmState(track);

        return [
            this.makeHeadFeature(
                track.id,
                track.icao,
                headEstimate.velocity,
                null,
                headEstimate.location,
                headEstimate.bearing,
                isFinished,
                selected,
                this.observedTrackIds.includes(track.id),
                alarmState,
                this.options.shouldVisualizeBearingFor(track),
                textColor,
                textOffsetY,
                classification?.name || UNKNOWN_CLASSIFICATION_NAME,
            ),
            ...this.makeTrajectoryFeature(
                track.id,
                this.getLineInfosFromEstimations(processedEstimates),
                isFinished,
                selected,
                alarmState,
            ).features,
        ];
    }

    private makeMissingTrackFeature(track: Track, timestamp: long): Feature {
        const estimate = this.getClosestEstimateTo(track, timestamp);
        return {
            type: "Feature",
            id: track.id,
            geometry: {
                type: "Point",
                coordinates: [estimate.location.longitude, estimate.location.latitude],
            },
            properties: {
                [PROPS.trackId]: track.id,
                [PROPS.missing]: true,
            },
        };
    }

    private setup(): void {
        const map = this.map;
        // NOTE: The order of these function calls is important - the layers must be removed before the source can be removed
        this.removeLayers(map);
        this.removeSources(map);
        this.addSources(map);
        this.addLayers(map);

        this.loadClassificationIcons(map, this.classifications);

        this.loadAlarmTrackIcon(map, SYMBOL_ALARM);
        this.finalizeSetup();
    }

    private loadClassificationIcons(map: mapboxgl.Map, classifications: Map<string, Classification>): void {
        for (const classification of classifications.values()) {
            const color = getColorAndOpacity(classification.defaultColor)[0];
            const identifier = this.getTag(classification);

            this.loadSelectedTrackIcon(map, classification, PREFIX_SYMBOL_SELECTED + identifier);
            this.loadTrackOverlayIcon(map, classification, PREFIX_SYMBOL + identifier);
            this.loadObservationIcon(map, color, PREFIX_SYMBOL_UNDER_OBSERVATION + identifier);
            this.loadObservedTrackIcon(map, color, PREFIX_SYMBOL_OBSERVED_TRACK + identifier);
            this.loadAlarmTrackIcon(map, PREFIX_SYMBOL_ALARM + identifier, color);
        }
    }

    private loadObservationIcon(map: mapboxgl.Map, color: string, id: string): void {
        if (map.hasImage(id)) {
            return;
        }

        const iconData = generateObservationSymbol({ color: color });
        this.loadImage(map, id, iconData);
    }

    private loadObservedTrackIcon(map: mapboxgl.Map, color: string, id: string): void {
        if (map.hasImage(id)) {
            return;
        }

        const iconData = generateObservedTrackSymbol({ color: color });
        this.loadImage(map, id, iconData);
    }

    private removeLayers(map: mapboxgl.Map): void {
        if (map.getLayer(LAYER_ALARM) != null) {
            map.removeLayer(LAYER_ALARM);
        }
        if (map.getLayer(LAYER_HEAD) != null) {
            map.removeLayer(LAYER_HEAD);
        }
        if (map.getLayer(LAYER_HEAD_HOVER) != null) {
            map.removeLayer(LAYER_HEAD_HOVER);
        }
        if (map.getLayer(LAYER_TRAJECTORY) != null) {
            map.removeLayer(LAYER_TRAJECTORY);
        }
        if (map.getLayer(LAYER_MISSING) != null) {
            map.removeLayer(LAYER_MISSING);
        }
        if (map.getLayer(LAYER_OBSERVATION_STATE) != null) {
            map.removeLayer(LAYER_OBSERVATION_STATE);
        }
        if (map.getLayer(LAYER_SELECTED_TRACK) != null) {
            map.removeLayer(LAYER_SELECTED_TRACK);
        }
    }

    private addLayers(map: mapboxgl.Map): void {
        const layers = this.getTrackLayers();
        if (layers == null) {
            return;
        }
        map.addLayer(layers.missingLayer, this.orderLayer);
        map.addLayer(layers.observationStateLayer, layers.missingLayer.id);
        map.addLayer(layers.headHoverLayer, layers.observationStateLayer.id);
        map.addLayer(layers.headLayer, layers.headHoverLayer.id);
        map.addLayer(layers.trajectoryLayer, layers.headLayer.id);
        map.addLayer(layers.alarmLayer, layers.headLayer.id);
        map.addLayer(layers.selectedTrackLayer, layers.headLayer.id);

        this.removePreviousGestureListeners?.();

        const clickListener = this.onTrackClicked();

        const trackClick: LayerEventListener<keyof mapboxgl.MapLayerEventType> = {
            type: "click",
            layer: LAYER_HEAD,
            listener: clickListener,
        };
        const missingTrackClick: LayerEventListener<keyof mapboxgl.MapLayerEventType> = {
            type: "click",
            layer: LAYER_MISSING,
            listener: clickListener,
        };
        const trackHover: LayerEventListener<keyof mapboxgl.MapLayerEventType> = {
            type: "mousemove",
            layer: LAYER_HEAD,
            listener: this.onMouseMove(SOURCE_TRACKS),
        };
        const trackHoverEnd: LayerEventListener<keyof mapboxgl.MapLayerEventType> = {
            type: "mouseleave",
            layer: LAYER_HEAD,
            listener: this.onMouseLeave(SOURCE_TRACKS),
        };

        const listeners = [trackClick, missingTrackClick, trackHover, trackHoverEnd];

        listeners.forEach((listener) => this.addLayerEventListener(listener));

        this.removePreviousGestureListeners = () =>
            listeners.forEach((listener) => this.removeLayerEventListener(listener));
    }

    private removeSources(map: mapboxgl.Map): void {
        if (map.getSource(SOURCE_TRACKS) != null) {
            map.removeSource(SOURCE_TRACKS);
        }
        if (map.getSource(SOURCE_MISSING_TRACKS) != null) {
            map.removeSource(SOURCE_MISSING_TRACKS);
        }
    }

    private addSources(map: mapboxgl.Map): void {
        map.addSource(SOURCE_TRACKS, MapUtils.EMPTY_GEOJSON_SOURCE);
        map.addSource(SOURCE_MISSING_TRACKS, MapUtils.EMPTY_GEOJSON_SOURCE);
    }

    private getTrackLayers(): TrackPartLayers | null {
        return {
            alarmLayer: this.getTrackAlarmLayer(LAYER_ALARM, SOURCE_TRACKS, PREFIX_SYMBOL_ALARM),
            headLayer: this.getTrackHeadLayer(LAYER_HEAD, SOURCE_TRACKS, PREFIX_SYMBOL, this.labelScale),
            headHoverLayer: this.getTrackHeadHoverLayer(
                LAYER_HEAD_HOVER,
                SOURCE_TRACKS,
                PREFIX_SYMBOL,
                this.labelScale,
            ),
            trajectoryLayer: this.getTrajectoryLayer(LAYER_TRAJECTORY, SOURCE_TRACKS),
            observationStateLayer: this.getObservationStateLayer(),
            missingLayer: this.getMissingTracksLayer(),
            selectedTrackLayer: this.getSelectedTrackLayer(
                LAYER_SELECTED_TRACK,
                SOURCE_TRACKS,
                PREFIX_SYMBOL_SELECTED,
                this.labelScale,
            ),
        };
    }

    private getObservationStateLayer(): mapboxgl.SymbolLayer {
        return {
            id: LAYER_OBSERVATION_STATE,
            type: "symbol",
            source: SOURCE_TRACKS,
            filter: ["==", "$type", "Point"],
            layout: {
                "icon-image": [
                    "case",
                    ["==", ["get", "observed"], true],
                    ["concat", PREFIX_SYMBOL_OBSERVED_TRACK, ["get", PROPS.classificationOrCategory]],
                    ["==", ["get", "selected"], true],
                    ["concat", PREFIX_SYMBOL_SELECTED, ["get", PROPS.classificationOrCategory]],
                    "",
                ],
                "icon-offset": ["case", ["==", ["get", "observed"], true], ["literal", [15, -15]], ["literal", [0, 0]]],
                "icon-allow-overlap": true,
                "icon-pitch-alignment": "map",
            },
        };
    }

    private getMissingTracksLayer(): mapboxgl.SymbolLayer {
        return {
            id: LAYER_MISSING,
            type: "symbol",
            source: SOURCE_MISSING_TRACKS,
            filter: ["==", "$type", "Point"],
            layout: {
                "icon-image": ["concat", PREFIX_SYMBOL_UNDER_OBSERVATION, ["get", PROPS.classificationOrCategory]],
                "icon-allow-overlap": true,
                "icon-pitch-alignment": "map",
            },
        };
    }

    private onTrackClicked =
        () =>
        (
            event: (mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) & {
                features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
            } & mapboxgl.EventData,
        ): void => {
            switch (this.trackObservationMode) {
                case TrackObservationMode.None:
                    this.handleClickForTrackInfo(event);
                    break;
                case TrackObservationMode.SingleTrackObservation:
                case TrackObservationMode.MultiTrackObservation:
                    this.handleClickForObservation(event);
                    break;
            }
        };

    private handleClickForObservation(
        event: (mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData,
    ): void {
        if (this.shouldSuppressClickForObservation || this.onTrackClickedInObservation == null) {
            return;
        }

        const trackId = this.getTrackIdFromEventFeatures(event.features);
        if (trackId == null) {
            return;
        }

        const track = this.findTrackById(trackId);
        if (track == null) {
            return;
        }

        this.onTrackClickedInObservation(track);
    }

    private getClosestEstimateTo(track: Track, timestamp: long): Estimate {
        return track.getClosestEstimateTo(timestamp) || track.lastEstimate;
    }

    protected findTrackById(trackId: number): Track | null {
        const allTracks = [...this.lastSnapshotTracks, ...this.finishedTracks, ...this.observationSelectedTracks];
        return allTracks.find((t) => t.id === trackId) || null;
    }

    protected flyToTrackIfFarAway(track: Track): void {
        const lastEstimate = this.getClosestEstimateTo(track, this.lastSnapshotTime);
        lastEstimate.location && this.flyToLocationIfNecessary(lastEstimate.location);
    }

    private getColorFromClassification(classification: Classification | null): string {
        const color = classification?.defaultColor || UNKNOWN_CLASSIFICATION_COLOR;
        const rgbColor = getColorAndOpacity(color)[0];
        return rgbColor;
    }

    /*
     * Creates a line per each group of estimations with the same classification.
     */
    private getLineInfosFromEstimations(estimations: Estimate[]): LineInfo[] {
        const lineInfos: LineInfo[] = [];
        let lastInfoIndex = 0;
        let lastInfoClassification = estimations[0]?.classification;
        estimations.forEach((estimate) => {
            if (estimate.classification?.id !== lastInfoClassification?.id) {
                // Connect the last line to the new line
                lineInfos[lastInfoIndex].coordinates.push([estimate.location.longitude, estimate.location.latitude]);
                lastInfoIndex++;
            }
            lastInfoClassification = estimate.classification;
            if (lineInfos[lastInfoIndex] == null) {
                const rgbColor = this.getColorFromClassification(estimate.classification);
                lineInfos[lastInfoIndex] = { color: rgbColor, coordinates: [] };
            }
            lineInfos[lastInfoIndex].coordinates.push([estimate.location.longitude, estimate.location.latitude]);
        });
        return lineInfos;
    }
}
