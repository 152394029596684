import {
    TrackRepository,
    LocationInfoRepository,
    LocalPreferencesRepository,
    SessionRepository,
    MapRepository,
    OverlayRepository,
    ReplayRepository,
    RadarRepository,
    AlarmRepository,
    OverlayEditorRepository,
    TrackObservationRepository,
    DeterrenceRepository,
    RunwayTrafficRepository,
    GroundObservationRepository,
    UserLocationRepository,
    UserManagementRepository,
    AlarmHistoryRepository,
    VersionRepository,
    TracksHistogramRepository,
    MeasurementPointsRepository,
    ADSBFlightRepository,
    UIControlRepository,
    ServerConfigRepository,
} from "../domain/repositories";
import {
    CommonLocalPreferencesRepository,
    CommonUIControlRepository,
    CommonUserLocationRepository,
} from "../domain/repositories/impl";
import { TimeViewModel } from "../components/sidebar/modules/timestatusview/TimeViewModel";
import { ReplayTimeViewModel } from "../components/sidebar/modules/timestatusview/ReplayTimeViewModel";
import { MapViewViewModel } from "../components/map/MapViewViewModel";
import { LoginViewModel } from "../components/login/LoginViewModel";
import { TileProvidersViewModel } from "../components/tileproviders/TileProvidersViewModel";
import { AltitudeSliderViewModel } from "../components/altitude/AltitudeSliderViewModel";
import { OverlayManagementViewModel } from "../components/overlays/OverlayManagementViewModel";
import { LegendViewModel } from "../components/legend/LegendViewModel";
import { CommonDateFormatter, DateFormatter } from "../infrastructure/DateFormatter";
import { DefaultValueProvider, CommonDefaultValueProvider } from "../domain/DefaultValueProvider";
import { ClientDataProvider, SessionBasedClientDataProvider } from "../domain/ClientDataProvider";
import { ReplayControlsViewModel } from "../components/replay/controls/ReplayControlsViewModel";
import DI from "./../di/DI";
import { TYPES } from "../di/Types";
import { SettingsViewModel } from "../components/settings/SettingsViewModel";
import { SettingsSectionsProvider } from "../components/settings/SettingsSectionsProvider";
import { HomeViewModel } from "../components/home/HomeViewModel";
import { UserManagementViewModel } from "../components/usermanagement/UserManagementViewModel";
import { RadarControlPanelViewModel } from "../components/radarcontrolpanel/RadarControlPanelViewModel";
import { AlarmViewModel } from "../components/alarm/AlarmViewModel";
import { CommonTrackSymbolGenerator } from "../components/map/layers/CommonTrackSymbolGenerator";
import { TrackSymbolGenerator } from "../components/map/layers/TrackSymbolGenerator";
import { DistanceFormatter } from "../domain/DistanceFormatter";
import { AltitudeFilterStatusIndicatorViewModel } from "../components/sidebar/modules/timestatusview/status/AltitudeFilterStatusIndicatorViewModel";
import { ClassificationFilterStatusIndicatorViewModel } from "../components/sidebar/modules/timestatusview/status/ClassificationFilterStatusIndicatorViewModel";
import { RainStatusIndicatorViewModel } from "../components/sidebar/modules/timestatusview/status/RainStatusIndicatorViewModel";
import { AlarmStatusIndicatorViewModel } from "../components/sidebar/modules/timestatusview/status/AlarmStatusIndicatorViewModel";
import { AboutViewModel } from "../components/about/AboutViewModel";
import { OverlayEditorViewModel } from "../components/overlays/edit/OverlayEditorViewModel";
import { OverlayShapeRemoverViewModel } from "../components/overlays/edit/OverlayShapeRemoverViewModel";
import { ObservationViewModel } from "../components/observation/ObservationViewModel";
import { ReplayRequestViewModel } from "../components/replay/requestview/ReplayRequestViewModel";
import { RunwayCrossingChartViewModel } from "../components/runwaycrossingschart/RunwayCrossingChartViewModel";
import { DeterrenceViewModel } from "../components/deterrence/DeterrenceViewModel";
import { FunnelViewViewModel } from "../components/funnelview/FunnelViewViewModel";
import { FlavorConfig } from "../infrastructure/FlavorConfig";
import { DetailedFunnelViewViewModel } from "../components/detailedfunnelview/DetailedFunnelViewViewModel";
import { RunwayTrafficBottomSheetViewModel } from "../components/charts/RunwayTrafficBottomSheetViewModel";
import { TrackListViewModel } from "../components/tracklist/TrackListViewModel";
import { TracksSnapshotDiffCalculator } from "../domain/TracksSnapshotDiffCalculator";
import { DataSimulationIndicatorViewModel } from "../components/sidebar/modules/timestatusview/status/DataSimulationIndicatorViewModel";
import { LanguageSelectionViewModel } from "../components/languageSelection/LanguageSelectionViewModel";
import { AltitudeFilterSettingsViewModel } from "../components/altitude/AltitudeFilterSettingsViewModel";
import { TrackInfoViewModel } from "../components/sidebar/modules/trackinfo/TrackInfoViewModel";
import { TrackSelectionRepository } from "../domain/repositories/TrackSelectionRepository";
import { CommonTrackSelectionRepository } from "../domain/repositories/impl/CommonTrackSelectionRepository";
import { ADSBFlightsSnapshotDiffCalculator } from "../domain/ADSBFlightsSnapshotDiffCalculator";
import { SidebarViewModel } from "../components/sidebar/SidebarViewModel";
import { VehicleInfoViewModel } from "../components/sidebar/modules/vehicleinfo/VehicleInfoViewModel";
import { EdgeGlowViewModel } from "../components/edgeglow/EdgeGlowViewModel";
import { ClockViewModel } from "../components/sidebar/modules/timestatusview/ClockViewModel";
import { TimeSyncStatusIndicatorViewModel } from "../components/sidebar/modules/timestatusview/status/TimeSyncStatusIndicatorViewModel";
import { RunwayTrafficButtonViewModel } from "../components/toolbar/modules/RunwayTrafficButtonViewModel";
import { ToolbarButtonViewModel } from "../components/toolbar/modules/ToolbarButtonViewModel";
import { ToolbarViewModel } from "../components/toolbar/ToolbarViewModel";
import { RadarStatusViewModel } from "../components/sidebar/modules/radarstatus/RadarStatusViewModel";
import { RadarNameViewModel } from "../components/sidebar/modules/radarname/RadarNameViewModel";
import { AlarmHistoryViewModel } from "../components/sidebar/modules/eventlist/AlarmHistoryViewModel";
import { EventListViewModel } from "../components/sidebar/modules/eventlist/EventListViewModel";

export interface DependencyProvider {
    setup(): void;
}

export abstract class CommonDependencyProvider implements DependencyProvider {
    public setup(): void {
        // Singletons
        DI.bind<DefaultValueProvider>(TYPES.DefaultValueProvider).toConstantValue(new CommonDefaultValueProvider());
        DI.bind<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository).toConstantValue(
            new CommonLocalPreferencesRepository(DI.get<DefaultValueProvider>(TYPES.DefaultValueProvider)),
        );
        DI.bind<DateFormatter>(TYPES.DateFormatter).toConstantValue(
            new CommonDateFormatter(DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository)),
        );
        DI.bind<UserLocationRepository>(TYPES.UserLocationRepository).toConstantValue(
            new CommonUserLocationRepository(),
        );
        DI.bind<ClientDataProvider>(TYPES.ClientDataProvider).toConstantValue(
            new SessionBasedClientDataProvider(DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository)),
        );
        DI.bind<DistanceFormatter>(TYPES.DistanceFormatter).toConstantValue(
            new DistanceFormatter(DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository)),
        );
        DI.bind<TrackSymbolGenerator>(TYPES.TrackSymbolGenerator).toConstantValue(new CommonTrackSymbolGenerator());

        this.setupInfrastructureImplementations();
        this.setupDomainImplementations();
        this.setupSettingSectionsProvider();

        DI.bind<TrackSelectionRepository>(TYPES.TrackSelectionRepository).toConstantValue(
            new CommonTrackSelectionRepository(
                DI.get<TrackRepository>(TYPES.TrackRepository),
                DI.getOptional<ADSBFlightRepository>(TYPES.ADSBFlightRepository),
                DI.get<ReplayRepository>(TYPES.ReplayRepository),
                DI.get<RadarRepository>(TYPES.RadarRepository),
                DI.get<TrackObservationRepository>(TYPES.TrackObservationRepository),
                DI.get<TracksSnapshotDiffCalculator>(TYPES.TracksSnapshotDiffCalculator),
                DI.getOptional<ADSBFlightsSnapshotDiffCalculator>(TYPES.ADSBFlightsSnapshotDiffCalculator),
            ),
        );
        DI.bind<UIControlRepository>(TYPES.UIControlRepository).toConstantValue(new CommonUIControlRepository());

        // Factories
        DI.bind<LanguageSelectionViewModel>(TYPES.LanguageSelectionViewModel).toDynamicValue(
            () => new LanguageSelectionViewModel(),
        );
        DI.bind<HomeViewModel>(TYPES.HomeViewModel).toDynamicValue(
            () =>
                new HomeViewModel(
                    DI.get<ServerConfigRepository>(TYPES.ServerConfigRepository),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                    DI.get<SessionRepository>(TYPES.SessionRepository),
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                    DI.get<TrackSelectionRepository>(TYPES.TrackSelectionRepository),
                    DI.get<RadarRepository>(TYPES.RadarRepository),
                    DI.get<UIControlRepository>(TYPES.UIControlRepository),
                ),
        );
        DI.bind<MapViewViewModel>(TYPES.MapViewViewModel).toDynamicValue(
            () =>
                new MapViewViewModel(
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                    DI.get<MapRepository>(TYPES.MapRepository),
                    DI.get<RadarRepository>(TYPES.RadarRepository),
                    DI.get<UserLocationRepository>(TYPES.UserLocationRepository),
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<UIControlRepository>(TYPES.UIControlRepository),
                ),
        );
        DI.bind<TimeViewModel>(TYPES.TimeViewModel).toDynamicValue(
            () =>
                new TimeViewModel(
                    DI.get<DateFormatter>(TYPES.DateFormatter),
                    DI.get<TrackRepository>(TYPES.TrackRepository),
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                ),
        );
        DI.bind<ReplayTimeViewModel>(TYPES.ReplayTimeViewModel).toDynamicValue(
            () =>
                new ReplayTimeViewModel(
                    DI.get<DateFormatter>(TYPES.DateFormatter),
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                ),
        );
        DI.bind<TileProvidersViewModel>(TYPES.TileProvidersViewModel).toDynamicValue(
            () => new TileProvidersViewModel(DI.get<MapRepository>(TYPES.MapRepository)),
        );
        DI.bind<LegendViewModel>(TYPES.LegendViewModel).toDynamicValue(
            () =>
                new LegendViewModel(
                    DI.get<TrackRepository>(TYPES.TrackRepository),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                    DI.get<DistanceFormatter>(TYPES.DistanceFormatter),
                ),
        );
        DI.bind<SettingsViewModel>(TYPES.SettingsViewModel).toDynamicValue(
            () =>
                new SettingsViewModel(
                    DI.get<SettingsSectionsProvider>(TYPES.SettingsSectionsProvider),
                    DI.get<SessionRepository>(TYPES.SessionRepository),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                ),
        );
        DI.bind<LoginViewModel>(TYPES.LoginViewModel).toDynamicValue(
            () =>
                new LoginViewModel(
                    DI.get<SessionRepository>(TYPES.SessionRepository),
                    DI.get<ServerConfigRepository>(TYPES.ServerConfigRepository),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                ),
        );
        DI.bind<AltitudeSliderViewModel>(TYPES.AltitudeSliderViewModel).toDynamicValue(
            () =>
                new AltitudeSliderViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<TrackRepository>(TYPES.TrackRepository),
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                    DI.get<DistanceFormatter>(TYPES.DistanceFormatter),
                    DI.get<FlavorConfig>(TYPES.FlavorConfig),
                ),
        );
        DI.bind<AltitudeFilterSettingsViewModel>(TYPES.AltitudeFilterSettingsViewModel).toDynamicValue(
            () =>
                new AltitudeFilterSettingsViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<TrackRepository>(TYPES.TrackRepository),
                ),
        );
        DI.bind<OverlayManagementViewModel>(TYPES.OverlayManagementViewModel).toDynamicValue(
            () =>
                new OverlayManagementViewModel(
                    DI.get<OverlayRepository>(TYPES.OverlayRepository),
                    DI.get<OverlayEditorRepository>(TYPES.OverlayEditorRepository),
                    DI.get<UIControlRepository>(TYPES.UIControlRepository),
                ),
        );
        DI.bind<OverlayEditorViewModel>(TYPES.OverlayEditorViewModel).toDynamicValue(
            () =>
                new OverlayEditorViewModel(
                    DI.get<OverlayEditorRepository>(TYPES.OverlayEditorRepository),
                    DI.get<DistanceFormatter>(TYPES.DistanceFormatter),
                ),
        );
        DI.bind<OverlayShapeRemoverViewModel>(TYPES.OverlayShapeRemoverViewModel).toDynamicValue(
            () => new OverlayShapeRemoverViewModel(DI.get<OverlayEditorRepository>(TYPES.OverlayEditorRepository)),
        );
        DI.bind<ReplayControlsViewModel>(TYPES.ReplayControlsViewModel).toDynamicValue(
            () =>
                new ReplayControlsViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                ),
        );
        DI.bind<UserManagementViewModel>(TYPES.UserManagementViewModel).toDynamicValue(
            () =>
                new UserManagementViewModel(
                    DI.get<UserManagementRepository>(TYPES.UserManagementRepository),
                    DI.get<SessionRepository>(TYPES.SessionRepository),
                ),
        );
        DI.bind<RadarControlPanelViewModel>(TYPES.RadarControlPanelViewModel).toDynamicValue(
            () =>
                new RadarControlPanelViewModel(
                    DI.get<DistanceFormatter>(TYPES.DistanceFormatter),
                    DI.get<RadarRepository>(TYPES.RadarRepository),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<UIControlRepository>(TYPES.UIControlRepository),
                ),
        );
        DI.bind<AlarmViewModel>(TYPES.AlarmViewModel).toDynamicValue(
            () =>
                new AlarmViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<AlarmRepository>(TYPES.AlarmRepository),
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                ),
        );
        DI.bind<AltitudeFilterStatusIndicatorViewModel>(TYPES.AltitudeFilterStatusIndicatorViewModel).toDynamicValue(
            () =>
                new AltitudeFilterStatusIndicatorViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                ),
        );
        DI.bind<ClassificationFilterStatusIndicatorViewModel>(
            TYPES.ClassificationFilterStatusIndicatorViewModel,
        ).toDynamicValue(
            () =>
                new ClassificationFilterStatusIndicatorViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<TrackRepository>(TYPES.TrackRepository),
                ),
        );
        DI.bind<DataSimulationIndicatorViewModel>(TYPES.TimeStatusIndicatorViewModel).toDynamicValue(
            () =>
                new DataSimulationIndicatorViewModel(
                    DI.get<TrackRepository>(TYPES.TrackRepository),
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                ),
        );
        DI.bind<RainStatusIndicatorViewModel>(TYPES.RainStatusIndicatorViewModel).toDynamicValue(
            () =>
                new RainStatusIndicatorViewModel(
                    DI.get<TrackRepository>(TYPES.TrackRepository),
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                ),
        );
        DI.bind<AlarmStatusIndicatorViewModel>(TYPES.AlarmStatusIndicatorViewModel).toDynamicValue(
            () =>
                new AlarmStatusIndicatorViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<FlavorConfig>(TYPES.FlavorConfig),
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                ),
        );
        DI.bind<AlarmHistoryViewModel>(TYPES.AlarmHistoryViewModel).toDynamicValue(
            () =>
                new AlarmHistoryViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                    DI.getOptional<AlarmHistoryRepository>(TYPES.AlarmHistoryRepository),
                ),
        );
        DI.bind<AboutViewModel>(TYPES.AboutViewModel).toDynamicValue(
            () =>
                new AboutViewModel(
                    DI.get<VersionRepository>(TYPES.VersionRepository),
                    DI.get<FlavorConfig>(TYPES.FlavorConfig),
                ),
        );
        DI.bind<ObservationViewModel>(TYPES.ObservationViewModel).toDynamicValue(
            () =>
                new ObservationViewModel(
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                    DI.get<TrackObservationRepository>(TYPES.TrackObservationRepository),
                    DI.get<FlavorConfig>(TYPES.FlavorConfig),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),

                    DI.getOptional<GroundObservationRepository>(TYPES.GroundObservationRepository),
                ),
        );
        DI.bind<ReplayRequestViewModel>(TYPES.ReplayRequestViewModel).toDynamicValue(
            () =>
                new ReplayRequestViewModel(
                    DI.get<TrackRepository>(TYPES.TrackRepository),
                    DI.get<TracksHistogramRepository>(TYPES.TracksHistogramRepository),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<UIControlRepository>(TYPES.UIControlRepository),
                ),
        );
        DI.bind<RunwayCrossingChartViewModel>(TYPES.RunwayCrossingChartViewModel).toDynamicValue(
            () =>
                new RunwayCrossingChartViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<RunwayTrafficRepository>(TYPES.RunwayTrafficRepository),
                ),
        );
        DI.bind<FunnelViewViewModel>(TYPES.FunnelViewViewModel).toDynamicValue(
            () =>
                new FunnelViewViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<RunwayTrafficRepository>(TYPES.RunwayTrafficRepository),
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                ),
        );
        DI.bind<DetailedFunnelViewViewModel>(TYPES.DetailedFunnelViewViewModel).toDynamicValue(
            () =>
                new DetailedFunnelViewViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<RunwayTrafficRepository>(TYPES.RunwayTrafficRepository),
                    DI.get<DistanceFormatter>(TYPES.DistanceFormatter),
                    DI.get<TrackRepository>(TYPES.TrackRepository),
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                ),
        );
        DI.bind<DeterrenceViewModel>(TYPES.DeterrenceViewModel).toDynamicValue(
            () =>
                new DeterrenceViewModel(
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<DeterrenceRepository>(TYPES.DeterrenceRepository),
                    DI.get<DateFormatter>(TYPES.DateFormatter),
                ),
        );
        DI.bind<RunwayTrafficBottomSheetViewModel>(TYPES.RunwayTrafficBottomSheetViewModel).toDynamicValue(
            () =>
                new RunwayTrafficBottomSheetViewModel(
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                    DI.get<RunwayTrafficRepository>(TYPES.RunwayTrafficRepository),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                ),
        );
        DI.bind<TrackListViewModel>(TYPES.TrackListViewModel).toDynamicValue(
            () =>
                new TrackListViewModel(
                    DI.get<TrackRepository>(TYPES.TrackRepository),
                    DI.get<TracksSnapshotDiffCalculator>(TYPES.TracksSnapshotDiffCalculator),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                ),
        );
        DI.bind<TrackInfoViewModel>(TYPES.TrackInfoViewModel).toDynamicValue(
            () =>
                new TrackInfoViewModel(
                    DI.get<TrackSelectionRepository>(TYPES.TrackSelectionRepository),
                    DI.get<MeasurementPointsRepository>(TYPES.MeasurementPointsRepository),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                    DI.get<RadarRepository>(TYPES.RadarRepository),
                    DI.get<DistanceFormatter>(TYPES.DistanceFormatter),
                ),
        );
        DI.bind<SidebarViewModel>(TYPES.SidebarViewModel).toDynamicValue(
            () =>
                new SidebarViewModel(
                    DI.get<ReplayRepository>(TYPES.ReplayRepository),
                    DI.get<SessionRepository>(TYPES.SessionRepository),
                    DI.get<UIControlRepository>(TYPES.UIControlRepository),
                    DI.get<RadarRepository>(TYPES.RadarRepository),
                    DI.get<AlarmRepository>(TYPES.AlarmRepository),
                ),
        );
        DI.bind<VehicleInfoViewModel>(TYPES.VehicleInfoViewModel).toDynamicValue(
            () =>
                new VehicleInfoViewModel(
                    DI.get<RadarRepository>(TYPES.RadarRepository),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                ),
        );
        DI.bind<EdgeGlowViewModel>(TYPES.EdgeGlowViewModel).toDynamicValue(
            () =>
                new EdgeGlowViewModel(
                    DI.get<AlarmRepository>(TYPES.AlarmRepository),
                    DI.get<RadarRepository>(TYPES.RadarRepository),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                ),
        );
        DI.bind<ClockViewModel>(TYPES.ClockViewModel).toDynamicValue(
            () => new ClockViewModel(DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository)),
        );
        DI.bind<TimeSyncStatusIndicatorViewModel>(TYPES.TimeSyncStatusIndicatorViewModel).toDynamicValue(
            () =>
                new TimeSyncStatusIndicatorViewModel(
                    DI.get<TrackRepository>(TYPES.TrackRepository),
                    DI.get<SessionRepository>(TYPES.SessionRepository),
                ),
        );
        DI.bind<ToolbarViewModel>(TYPES.ToolbarViewModel).toDynamicValue(
            () =>
                new ToolbarViewModel(
                    DI.get<FlavorConfig>(TYPES.FlavorConfig),
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                    DI.getOptional<DeterrenceRepository>(TYPES.DeterrenceRepository),
                ),
        );
        DI.bind<ToolbarButtonViewModel>(TYPES.ToolbarButtonViewModel).toDynamicValue(
            () => new ToolbarButtonViewModel(DI.get<UIControlRepository>(TYPES.UIControlRepository)),
        );
        DI.bind<RunwayTrafficButtonViewModel>(TYPES.RunwayTrafficButtonViewModel).toDynamicValue(
            () =>
                new RunwayTrafficButtonViewModel(
                    DI.get<UIControlRepository>(TYPES.UIControlRepository),
                    DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
                    DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
                ),
        );
        DI.bind<RadarStatusViewModel>(TYPES.RadarStatusViewModel).toDynamicValue(
            () => new RadarStatusViewModel(DI.get<RadarRepository>(TYPES.RadarRepository)),
        );
        DI.bind<RadarNameViewModel>(TYPES.RadarNameViewModel).toDynamicValue(
            () => new RadarNameViewModel(DI.get<RadarRepository>(TYPES.RadarRepository)),
        );
        DI.bind<EventListViewModel>(TYPES.EventListViewModel).toDynamicValue(
            () =>
                new EventListViewModel(
                    DI.get<AlarmRepository>(TYPES.AlarmRepository),
                    DI.getOptional<AlarmHistoryRepository>(TYPES.AlarmHistoryRepository),
                ),
        );
        this.setupMapModules();
    }

    protected abstract setupInfrastructureImplementations(): void;
    protected abstract setupDomainImplementations(): void;
    protected abstract setupSettingSectionsProvider(): void;
    protected abstract setupMapModules(): void;
}
