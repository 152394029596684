import React, { useEffect } from "react";
import { RingLoader } from "react-spinners";
import styled, { useTheme } from "styled-components";
import { APP_CONFIG_KEYS, getRuntimeConfig } from "../../infrastructure/AppConfig";
import { FullscreenBrandedPage } from "./FullscreenBrandedPage";
import { RobinLoader } from "./RobinLoader";

const FADE_DURATION_MILLISECONDS = 300;
export const LOADER_CONTAINER_HEIGHT = 200;

const Container = styled(FullscreenBrandedPage)<{ isVisible: boolean }>`
    pointer-events: ${(props) => (props.isVisible ? "auto" : "none")};

    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    transition: opacity ${(props) => (props.isVisible ? 0 : FADE_DURATION_MILLISECONDS)}ms ease-out;
`;

const LoaderContainer = styled.div`
    padding: ${LOADER_CONTAINER_HEIGHT / 4}px 0;
    width: 50%;
    display: flex;
    justify-content: center;
`;

interface Props {
    isLoading?: boolean;
}

export const FullscreenLoading = (props: Props): JSX.Element | null => {
    // Properties
    const theme = useTheme();
    const isLoading = props.isLoading ?? true;
    const [isRemoved, setIsRemoved] = React.useState(false);
    const showBranding = !getRuntimeConfig<boolean>(APP_CONFIG_KEYS.HIDE_ROBIN_BRANDING);

    // Remove the loading screen after the fade-out animation has completed
    useEffect(() => {
        if (isLoading) {
            return;
        }
        const timeout = setTimeout(() => setIsRemoved(true), FADE_DURATION_MILLISECONDS);
        return () => clearTimeout(timeout);
    }, [isLoading]);

    // Render

    if (isRemoved) {
        return null;
    }

    return (
        <Container isVisible={isLoading}>
            <LoaderContainer>
                {showBranding ? (
                    <RobinLoader style={{ height: `${LOADER_CONTAINER_HEIGHT / 2}px` }} />
                ) : (
                    <RingLoader color={theme.colors.text.text} size={LOADER_CONTAINER_HEIGHT / 2} />
                )}
            </LoaderContainer>
        </Container>
    );
};
