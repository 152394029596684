import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { DateFormatter } from "../../../../infrastructure/DateFormatter";
import { ReplayRepository } from "../../../../domain/repositories";
import { TracksSnapshot } from "../../../../domain/model";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { PlaybackState } from "../../../../domain/PlaybackScene";

export class ReplayTimeViewModel {
    // Properties

    public get time(): Rx.Observable<string> {
        return this.dateFormatter.formatFullDateObservable(nonNullObservable(this.timeSubject), {
            excludeDateToday: true,
        });
    }

    public get playbackState(): Rx.Observable<PlaybackState | null> {
        return this.replayRepository.currentPlaybackScene.pipe(
            RxOperators.switchMap((scene) => (scene != null ? scene.state : Rx.of(null))),
        );
    }

    private timeSubject = new Rx.BehaviorSubject<Date | null>(null);

    public constructor(
        private readonly dateFormatter: DateFormatter,
        private readonly replayRepository: ReplayRepository,
    ) {
        replayRepository.currentPlaybackScene
            .pipe(RxOperators.flatMap((scene) => (scene ? scene.tracks : Rx.NEVER)))
            .subscribe({
                next: (snapshot) => this.broadcastTime(snapshot),
                error: (error) => console.error(error),
            });
    }

    // Private functions

    private broadcastTime(snapshot: TracksSnapshot | null): void {
        if (snapshot == null) {
            return;
        }
        this.timeSubject.next(new Date(snapshot.timestamp));
    }
}
