import React, { ReactNode } from "react";
import cogoToast from "../components/toast";
import { CTOptions, CTReturn, HideToastFunction } from "../components/toast/types";
import { ReactComponent as WarningIcon } from "../res/images/warning.svg";
import styled from "styled-components";
import { TextButton } from "../components/appearance/TextButton";
import { t } from "i18next";
import { AlarmData, AlarmDataType } from "../domain/model";

const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const CloseButton = styled(TextButton)`
    align-self: flex-start;
    padding: 6px 0px;
`;

// NOTE: This syntax is needed to allow this code to run in jest tests, otherwise it will error on WarningIcon being undefined
const ErrorIcon = styled((props) => <WarningIcon {...props} />)`
    color: ${({ theme }) => theme.colors.status.danger};
`;

interface ApiError extends Error {
    messageId?: string;
}

interface Options {
    title: string;
    message: string;
    showCloseButton?: boolean;
    duration?: number;
    onClick?: (toast: { hide: HideToastFunction }) => void;
}

function createToastMessage(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getToast: () => any,
    { message, showCloseButton }: Options,
): string | ReactNode {
    if (showCloseButton !== true) {
        return message;
    }
    return (
        <MessageContainer>
            <p>{message}</p>
            <CloseButton
                onClick={(e) => {
                    e.stopPropagation();
                    getToast().hide();
                }}
                text={t("general.dismiss")}
            />
        </MessageContainer>
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultOptions = (getToast: () => any, options: Options, defaultTitle: string): CTOptions => {
    return {
        position: "bottom-center",
        hideAfter: options.duration === undefined ? 5 : options.duration,
        heading: (options && options.title) || defaultTitle,
        bar: { size: "0px" },
        onClick: options.onClick != null ? () => options.onClick!(getToast()) : () => getToast().hide(),
    };
};

export function showErrorWithOptions(options: Options): CTReturn {
    const toast: CTReturn = cogoToast.error(
        createToastMessage(() => toast, options),
        {
            ...defaultOptions(() => toast, options, t("general.error")),
            // eslint-disable-next-line react/display-name,react/react-in-jsx-scope
            renderIcon: () => <ErrorIcon />,
        },
    );
    return toast;
}

function translateApiMessage(messageString: string, messageId?: string): string {
    const key = `apiMessage_${messageId || messageString}`;
    try {
        const text = t(`messages.${key}`);
        if (text === messageId) {
            return messageString;
        }
        return text;
    } catch (error) {
        return messageString;
    }
}

export function showError(error: ApiError, overrideOptions?: Options): CTReturn {
    console.error(error);
    const o = overrideOptions;
    const translatedMessage = translateApiMessage(error.message, error.messageId);

    return showErrorWithOptions({
        ...o,
        title: (o && o.title) || t("general.error"),
        message: (o && o.message) || translatedMessage || error.message,
    });
}

export function showWarning(options: Options): CTReturn {
    console.warn(options.message);
    const toast: CTReturn = cogoToast.warn(
        createToastMessage(() => toast, options),
        defaultOptions(() => toast, options, "Warning!"),
    );
    return toast;
}

export function showInfo(options: Options): CTReturn {
    console.info(options.message);
    const toast: CTReturn = cogoToast.info(
        createToastMessage(() => toast, options),
        defaultOptions(() => toast, options, "Info!"),
    );
    return toast;
}

export function showUpdateAvailable(registration: ServiceWorkerRegistration): void {
    showInfo({
        title: t("messages.updateAvailable"),
        message: t("messages.applyUpdateInstruction"),
        duration: 0,
        onClick: (toast) => {
            const sw = registration.waiting;
            sw && sw.postMessage({ type: "SKIP_WAITING" });
            toast.hide();
            window.location.reload();
        },
    });
}

export function generateAlarmMessage(alarmData: AlarmData): string {
    switch (alarmData.type) {
        case AlarmDataType.AREA_ENTRY:
            return `${t("messages.trackEnteredArea")}: ${alarmData.overlayIds.join(", ")}`;
        case AlarmDataType.DRONE:
            return t("messages.droneDetected");
        case AlarmDataType.RUNWAY_CROSSING_REACHED_THRESHOLD:
            return t("messages.runwayCrossingReachedThreshold");
        case AlarmDataType.FUNNEL_TRAFFIC_REACHED_THRESHOLD:
            return t("messages.funnelTrafficReachedThreshold");
    }
}
