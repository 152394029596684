import React from "react";
import { TYPES } from "../../di/Types";
import DI from "../../di/DI";
import { BaseSubscriptionHandlerComponent } from "../BaseSubscriptionHandlerComponent";
import { BottomSheet, BOTTOM_SHEET_ANIMATION_DURATION_MS } from "./BottomSheet";
import { DetailedFunnelView } from "../detailedfunnelview/DetailedFunnelView";
import { RunwayCrossingsChart } from "../runwaycrossingschart/RunwayCrossingChart";
import { FunnelView } from "../funnelview/FunnelView";
import styled, { withTheme } from "styled-components";
import { AirBaseData, Runway, RunwayFunnel } from "../../domain/model";
import { RunwayTrafficBottomSheetViewModel } from "./RunwayTrafficBottomSheetViewModel";
import { ArrowDirection, generateArrowIconSource } from "../appearance/Arrow";
import { REPLAY_CONTROLS_HEIGHT } from "../replay/controls/ReplayControls";
import { t } from "i18next";
import { Theme } from "../appearance/theme/Theme";
import { Button } from "../appearance/button/Button";

const SHEET_HEIGHT_SIMPLE = 270;
const SHEET_HEIGHT_DETAILED_MIN = 350;
const SHEET_HEIGHT_DETAILED_MAX = 600;

export enum RunwayTrafficViewMode {
    SIMPLE_VIEW,
    DETAILED_VIEW,
}

const RunwayButtonsContainer = styled.div`
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -10px;
    z-index: 1;

    span {
        height: fit-content;
        margin-right: 20px;

        :last-child {
            margin-right: 0;
        }
    }
`;

const ChartsFitter = styled.div<{ mode: RunwayTrafficViewMode }>`
    width: 100%;
    height: 100%;
    display: flex;
    opacity: ${({ mode }) => (mode === RunwayTrafficViewMode.SIMPLE_VIEW ? 1 : 0)};
    transition: opacity ${BOTTOM_SHEET_ANIMATION_DURATION_MS};

    > div {
        :first-child {
            flex: 1;
        }
        :last-child {
            flex: 1.7;
        }
    }
`;

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
`;

const ExpansionIndicator = styled.div<{ isExpanded: boolean }>`
    cursor: pointer;
    background: ${({ theme }) => theme.colors.secondary.blue};
    position: relative;
    height: ${({ theme }) => theme.spacing.x10};
    width: ${({ theme }) => theme.spacing.x10};
    min-height: ${({ theme }) => theme.spacing.x10};
    min-width: ${({ theme }) => theme.spacing.x10};
    border-radius: 50%;
    left: 50%;
    top: ${({ isExpanded }) => (isExpanded ? "-20" : "-17")}px;
    z-index: 100;
`;

const ArrowContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Arrow = styled.img<{ isExpanded: boolean }>`
    transform: rotate(${(props) => (props.isExpanded ? 0 : 180)}deg);
    transition: transform ${BOTTOM_SHEET_ANIMATION_DURATION_MS}ms ease-out;

    :hover {
        opacity: 0.5;
    }
`;

interface Props {
    onVisibilityChange?: (open: boolean) => void;
    onModeChange?: (mode: RunwayTrafficViewMode) => void;
    onHeightChange?: () => void;
    theme: Theme;
}

interface State {
    isVisible: boolean;
    selectedRunwayForCharts: Runway | null;
    hasRunwayTrafficData: boolean;
    hasFunnelViewData: boolean;
    locationData: AirBaseData | null;
    mode: RunwayTrafficViewMode;
    sheetHeight: number;
    bottomSheetInset?: number;
    canToggleMode: boolean;
}

export class RunwayTrafficBottomSheetComponent extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private bottomSheet = React.createRef<BottomSheet>();
    private readonly viewModel: RunwayTrafficBottomSheetViewModel = DI.get(TYPES.RunwayTrafficBottomSheetViewModel);

    public constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            isVisible: false,
            selectedRunwayForCharts: null,
            hasRunwayTrafficData: false,
            hasFunnelViewData: false,
            locationData: null,
            mode: RunwayTrafficViewMode.SIMPLE_VIEW,
            sheetHeight: SHEET_HEIGHT_SIMPLE,
            bottomSheetInset: undefined,
            canToggleMode: true,
        };
    }

    // Public functions

    public componentDidMount(): void {
        this.collectSubscriptions(
            this.viewModel.shouldShowBottomSheet.subscribe((shouldShow) => this.showBottomSheet(shouldShow)),
            this.viewModel.mode.subscribe((mode) => this.updateMode(mode)),
            this.viewModel.isLiveMode.subscribe((isLiveMode) =>
                this.setState({ bottomSheetInset: isLiveMode ? undefined : REPLAY_CONTROLS_HEIGHT }),
            ),
            this.viewModel.hasRunwayCrossingData.subscribe((value) => this.setState({ hasRunwayTrafficData: value })),
            this.viewModel.hasFunnelTrafficData.subscribe((value) => this.setState({ hasFunnelViewData: value })),
            this.viewModel.selectedRunway.subscribe((value) => this.setState({ selectedRunwayForCharts: value })),
            this.viewModel.locationData.subscribe((value) => this.setState({ locationData: value })),
            this.viewModel.canToggleMode.subscribe((value) => this.setState({ canToggleMode: value })),
        );
    }

    public render(): React.ReactNode {
        if (!this.state.isVisible) {
            return null;
        }
        const selectedRunway = this.state.selectedRunwayForCharts;
        const ld = this.state.locationData;
        const selectedRunwayFunnel =
            (selectedRunway && ld && ld.runwayFunnel.find((f) => f.runwayId === selectedRunway.id)) || null;

        return (
            selectedRunway && (
                <BottomSheet
                    height={this.state.sheetHeight + "px"}
                    ref={this.bottomSheet}
                    openOnMount
                    resizable={
                        this.state.mode === RunwayTrafficViewMode.DETAILED_VIEW && {
                            minHeight: SHEET_HEIGHT_DETAILED_MIN,
                            maxHeight: SHEET_HEIGHT_DETAILED_MAX,
                            onResize: this.onResizeBottomSheet,
                        }
                    }
                    insetY={this.state.bottomSheetInset}
                >
                    {this.state.canToggleMode &&
                        this.renderExpansionIndicator(this.state.mode !== RunwayTrafficViewMode.SIMPLE_VIEW)}
                    <ContentContainer>
                        {this.getRunwayButtons()}
                        {this.state.mode === RunwayTrafficViewMode.SIMPLE_VIEW
                            ? this.renderSimpleView(selectedRunway)
                            : this.renderDetailedView(selectedRunway, selectedRunwayFunnel)}
                    </ContentContainer>
                </BottomSheet>
            )
        );
    }

    // Private fucnctions

    private renderSimpleView(runway: Runway): React.ReactNode {
        return (
            <ChartsFitter mode={this.state.mode}>
                {this.state.hasFunnelViewData && (
                    <FunnelView
                        key={`simple-funnel-${runway.id}`}
                        runway={runway}
                        onClick={this.state.canToggleMode ? () => this.viewModel.toggleMode() : undefined}
                    />
                )}
                {this.state.hasRunwayTrafficData && (
                    <RunwayCrossingsChart key={`crossing-chart-${runway.id}`} runway={runway} />
                )}
            </ChartsFitter>
        );
    }

    private renderDetailedView(runway: Runway, funnel: RunwayFunnel | null): React.ReactNode {
        return (
            funnel && (
                <DetailedFunnelView
                    key={`detailed-funnel-${runway.id}`}
                    runway={runway}
                    funnel={funnel}
                    onClick={this.state.canToggleMode ? () => this.viewModel.toggleMode() : undefined}
                />
            )
        );
    }

    private getRunwayButtons(): React.ReactNode {
        if (this.state.locationData == null) {
            return null;
        }

        const runways = this.state.locationData.runways;

        if (runways.length < 1) {
            return null;
        }

        return (
            <RunwayButtonsContainer>{runways.map((runway) => this.makeButtonForRunway(runway))}</RunwayButtonsContainer>
        );
    }

    private makeButtonForRunway(runway: Runway): React.ReactNode {
        const splitName = runway.name.split(" ");
        const label = splitName.length > 1 ? splitName[1] : runway.name;
        const selectedRunwayId = this.state.selectedRunwayForCharts && this.state.selectedRunwayForCharts.id;
        return (
            <Button
                active={selectedRunwayId === runway.id}
                label={label}
                layout="inline"
                onClick={() => this.viewModel.setSelectedRunway(runway)}
                key={runway.id}
            />
        );
    }

    private updateMode(mode: RunwayTrafficViewMode): void {
        if (this.state.mode === mode) {
            return;
        }
        const sheetHeight =
            mode === RunwayTrafficViewMode.DETAILED_VIEW ? SHEET_HEIGHT_DETAILED_MAX : SHEET_HEIGHT_SIMPLE;
        this.setState({ mode, sheetHeight });
        this.props.onModeChange && this.props.onModeChange(mode);
    }

    private renderExpansionIndicator(isExpanded: boolean): JSX.Element {
        return (
            <ExpansionIndicator onClick={() => this.viewModel.toggleMode()} isExpanded={isExpanded}>
                <ArrowContainer>
                    <Arrow
                        isExpanded={isExpanded}
                        src={generateArrowIconSource(
                            {
                                color: this.props.theme.colors.text.text,
                                direction: ArrowDirection.DOWN,
                            },
                            true,
                        )}
                        alt={`${t("general.toggleClickTo")} ${
                            isExpanded ? t("general.toggleCollapse") : t("general.toggleExpand")
                        }`}
                    />
                </ArrowContainer>
            </ExpansionIndicator>
        );
    }

    private onResizeBottomSheet = (sheetHeight: number): void => {
        this.setState({ sheetHeight });
        this.props.onHeightChange && this.props.onHeightChange();
    };

    private showBottomSheet = (shouldShow: boolean): void => {
        if (shouldShow) {
            this.setState({ isVisible: true });
            this.bottomSheet.current && this.bottomSheet.current.open();
            this.props.onVisibilityChange && this.props.onVisibilityChange(true);
        } else {
            const completion: () => void = () => {
                this.setState({ isVisible: false });
                this.props.onVisibilityChange && this.props.onVisibilityChange(false);
            };
            this.bottomSheet.current ? this.bottomSheet.current.close(completion) : completion();
        }
    };
}

export const RunwayTrafficBottomSheet = withTheme(RunwayTrafficBottomSheetComponent);
