import { ROTATION_STOPS } from "../../../../utils/MapUtils";
import {
    AltitudeRange,
    Classification,
    EmitterCategory,
    Trackable,
    Location,
    checkEstimateExpiration,
    locationToCoord,
    TrackLabel,
    TrackAlarmState,
} from "../../../../domain/model";
import { TrackSymbolGenerator } from "../TrackSymbolGenerator";
import DI from "../../../../di/DI";
import { TYPES } from "../../../../di/Types";
import { AlarmTrackImage } from "../AlarmTrackImage";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import mapboxgl from "mapbox-gl";
import { Feature, FeatureCollection, Position } from "geojson";
import { DistanceFormatter } from "../../../../domain/DistanceFormatter";
import { BaseEstimate } from "../../../../domain/model/BaseEstimate";
import { TrackableSnapshotDiff } from "../../../../domain/SnapshotDiffCalculator";
import * as turf from "@turf/turf";
import { InteractiveMapLayer } from "../InteractiveMapLayer";
import { formatTrackAltitude, formatVelocity } from "../../../../utils/Presentation";
import { DEFAULT_VELOCITY_UNIT, VelocityUnit } from "../../../../domain/model/VelocityUnit";
import { AltitudeConfig } from "../../../../domain/model/Altitude";

const LAST_ESTIMATE_ALTITUDE_THRESHOLD_MILLISECONDS = 30000;
const ALTITUDE_BASE_TEXT_SIZE = 14;
const REDRAW_THROTTLE_MS = 200;

export const PROPS = {
    trackId: "trackId",
    trackICAO: "trackICAO",
    label: "label",
    finished: "finished",
    rotation: "rotation",
    alarm: "alarm",
    featureState: "feature-state",
    estimationColor: "estimation-color",
    defaultColor: "default-color",
    headTextColor: "head-text-color",
    headTextOffset: "head-text-offset",
    classificationOrCategory: "classification-or-category",
    // Radar Tracks only
    missing: "missing",
    selected: "selected",
    observed: "observed",
};

export interface LineInfo {
    coordinates: Position[];
    color?: string;
}

export interface TrackLayerOptions<TrackType> {
    shouldVisualizeBearingFor(track: TrackType): boolean;
}

export interface BaseTrackPartLayers {
    alarmLayer: mapboxgl.SymbolLayer;
    headLayer: mapboxgl.SymbolLayer;
    trajectoryLayer: mapboxgl.LineLayer;
    headHoverLayer: mapboxgl.SymbolLayer;
    selectedTrackLayer: mapboxgl.SymbolLayer;
}

export abstract class BaseTracksLayer<TrackType extends Trackable = Trackable> extends InteractiveMapLayer {
    public shouldShowTracks = true;
    public trailLength: number = Number.MAX_VALUE;
    public referenceAltitude: float = 0.0;
    public altitudeRangeOfInterest: AltitudeRange = { top: null, bottom: null };
    public displayNegativeAltitudeAsZero = true;

    protected labelScale = 1;
    protected iconScale = 1;
    protected trailScale = 1;

    protected velocityUnit = DEFAULT_VELOCITY_UNIT;

    protected showTracksWithoutAltitude = false;
    protected trackLabel: TrackLabel = TrackLabel.NONE;

    protected snapshotDiff?: TrackableSnapshotDiff<TrackType>;
    protected abstract get trackSource(): string;
    protected get lastSnapshotTime(): number {
        return this.snapshotDiff ? this.snapshotDiff.snapshotTimestamp : 0;
    }
    protected get lastSnapshotTracks(): TrackType[] {
        return this.snapshotDiff ? this.snapshotDiff.snapshotTracksWithEstimates : [];
    }
    protected get finishedTracks(): TrackType[] {
        return this.snapshotDiff ? this.snapshotDiff.finishedTracks : [];
    }
    protected get finishedTracksDeathTimes(): Map<int, long> {
        return this.snapshotDiff ? this.snapshotDiff.finishedTracksDeathTimes : new Map();
    }

    protected redrawSubject = new Rx.Subject<void>();

    protected postSetupActions = new Array<() => void>();

    private trackSymbolGenerator: TrackSymbolGenerator = DI.get<TrackSymbolGenerator>(TYPES.TrackSymbolGenerator);
    private isSetup = false;
    private hoveredTrackId?: number | string;
    private selectedTrackId?: number | string;
    private alarmingTracks: int[] = [];
    private dimTracks = false;
    private dimTracksTimeout: number | null = null;
    private radarGroundLevel: number | null = null;
    private altitudeConfig: AltitudeConfig = AltitudeConfig.WGS84;

    public constructor(
        readonly map: mapboxgl.Map,
        protected readonly distanceFormatter: DistanceFormatter,
        protected readonly onTrackSelected: ((trackId: number | null) => void) | null,
    ) {
        super(map);
        this.subscribeToRedrawSubject();
    }

    // Public functions

    public setSelectedTrackId(trackId: number | null): void {
        if (this.selectedTrackId !== undefined) {
            this.map.setFeatureState({ source: this.trackSource, id: this.selectedTrackId }, { selected: false });
        }

        if (trackId != null) {
            this.map.setFeatureState({ source: this.trackSource, id: trackId }, { selected: true });
        }

        this.selectedTrackId = trackId ?? undefined;
    }

    public updateIconAndTrailScale(scale: number): void {
        this.iconScale = scale;
        this.trailScale = scale;
        this.labelScale = scale;
        this.requestRedraw();
    }

    public updateVelocityUnit(unit: VelocityUnit): void {
        this.velocityUnit = unit;
        this.requestRedraw();
    }

    public updateAltitudeRangeOfInterest(value: AltitudeRange): void {
        this.altitudeRangeOfInterest = value;
        this.requestRedraw();
    }

    public setShowTracksWithoutAltitude(value: boolean): void {
        this.showTracksWithoutAltitude = value;
        this.requestRedraw();
    }

    public updateDisplayNegativeAltitudeAsZero(value: boolean): void {
        this.displayNegativeAltitudeAsZero = value;
        this.requestRedraw();
    }

    public updateTrailLength(value: number): void {
        this.trailLength = value;
        this.requestRedraw();
    }

    public setFinishedTrackOpacity(
        opacity: float,
        headLayerId: string,
        headHoverLayerId: string,
        trajectoryLayerId: string,
    ): void {
        this.doPostSetup(() => {
            const exp = this.getTrackOpacityExpression(opacity);
            const hoverExp = this.getTrackHoverOpacityExpression(opacity);

            this.map.setPaintProperty(headLayerId, "icon-opacity", exp);
            this.map.setPaintProperty(headLayerId, "text-opacity", exp);
            this.map.setPaintProperty(headHoverLayerId, "icon-opacity", hoverExp);
            this.map.setPaintProperty(trajectoryLayerId, "line-opacity", exp);
        });
    }

    public setClassificationHistoryOnTrajectoryEnabled(enabled: boolean, trajectoryLayerId: string): void {
        this.doPostSetup(() =>
            this.map.setPaintProperty(trajectoryLayerId, "line-color", this.getLineColorExpression(enabled)),
        );
    }

    public focusOnTrack(trackId: number | null): void {
        if (!trackId) {
            return;
        }

        const track = trackId ? this.findTrackById(trackId) : null;
        track && this.flyToTrackIfFarAway(track);
    }

    public setTrackLabel(label: TrackLabel): void {
        this.trackLabel = label;
        this.requestRedraw();
    }

    protected abstract calculateEndTimeForTrack(track: TrackType): number | null;
    protected abstract findTrackById(trackId: number): TrackType | null;
    protected abstract flyToTrackIfFarAway(track: TrackType): void;
    public abstract updateTracks(diff: TrackableSnapshotDiff<TrackType>): void;

    protected doPostSetup(action: () => void): void {
        if (this.isSetup) {
            action();
        } else {
            this.postSetupActions.push(action);
        }
    }

    protected finalizeSetup(): void {
        this.isSetup = true;
        this.postSetupActions.forEach((action) => action());
        this.postSetupActions = [];
    }

    protected getTrackHeadLayer(
        layerId: string,
        sourceId: string,
        symbolIdPrefix: string,
        labelScale: float,
    ): mapboxgl.SymbolLayer {
        return {
            id: layerId,
            type: "symbol",
            source: sourceId,
            filter: ["==", "$type", "Point"],
            layout: {
                "text-field": ["get", PROPS.label],
                "text-allow-overlap": true,
                "text-offset": ["get", PROPS.headTextOffset],
                "text-size": ALTITUDE_BASE_TEXT_SIZE * labelScale,
                "icon-image": ["concat", symbolIdPrefix, ["get", PROPS.classificationOrCategory]],
                "icon-allow-overlap": true,
                "icon-rotate": {
                    property: PROPS.rotation,
                    type: "categorical",
                    stops: ROTATION_STOPS,
                    default: 0,
                },
                "icon-rotation-alignment": "map",
                "icon-pitch-alignment": "map",
            },
            paint: {
                "text-color": ["get", PROPS.headTextColor],
            },
        };
    }

    protected getSelectedTrackLayer(
        layerId: string,
        sourceId: string,
        symbolIdPrefix: string,
        labelScale: float,
    ): mapboxgl.SymbolLayer {
        return {
            id: layerId,
            type: "symbol",
            source: sourceId,
            filter: ["==", "$type", "Point"],
            layout: {
                "text-allow-overlap": true,
                "text-offset": ["get", PROPS.headTextOffset],
                "text-size": ALTITUDE_BASE_TEXT_SIZE * labelScale,
                "icon-image": ["concat", symbolIdPrefix, ["get", PROPS.classificationOrCategory]],
                "icon-allow-overlap": true,
                "icon-rotate": {
                    property: PROPS.rotation,
                    type: "categorical",
                    stops: ROTATION_STOPS,
                    default: 0,
                },
                "icon-rotation-alignment": "map",
                "icon-pitch-alignment": "map",
            },
            paint: {
                "icon-opacity": ["case", ["boolean", [PROPS.featureState, "selected"], false], 1, 0],
                "text-color": ["get", PROPS.headTextColor],
            },
        };
    }

    protected getTrackHeadHoverLayer(
        layerId: string,
        sourceId: string,
        symbolIdPrefix: string,
        labelScale: float,
    ): mapboxgl.SymbolLayer {
        const base = this.getTrackHeadLayer(layerId, sourceId, symbolIdPrefix, labelScale);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (base.layout as any)["text-field"];
        return {
            ...base,
            layout: {
                ...base.layout,
                "icon-size": 1.25,
            },
            paint: {
                ...base.paint,
                "icon-opacity": 0, // This will be set later in `setFinishedTrackOpacity`
            },
        };
    }

    protected getTrajectoryLayer(layerId: string, sourceId: string): mapboxgl.LineLayer {
        return {
            id: layerId,
            type: "line",
            source: sourceId,
            filter: ["==", "$type", "LineString"],
            layout: {
                "line-join": "round",
                "line-cap": "round",
            },
            paint: {
                "line-color": ["get", PROPS.estimationColor],
                "line-width": [
                    "case",
                    ["boolean", [PROPS.featureState, "hover"], false],
                    4 * this.trailScale,
                    2 * this.trailScale,
                ],
            },
        };
    }

    protected getTrackAlarmLayer(layerId: string, sourceId: string, symbolId: string): mapboxgl.SymbolLayer {
        return {
            id: layerId,
            type: "symbol",
            source: sourceId,
            filter: ["all", ["==", "$type", "Point"], ["==", PROPS.alarm, TrackAlarmState.ALARMING]],
            layout: {
                "icon-image": ["concat", symbolId, ["get", PROPS.classificationOrCategory]],
                "icon-allow-overlap": true,
                "icon-pitch-alignment": "map",
            },
        };
    }

    protected getLastKnownAltitude(estimates: BaseEstimate[], snapshotTimestamp: long): number | null {
        const lastEstimateWithAltitude = Array.from(estimates)
            .reverse()
            .find((estimate) => {
                return (
                    !isNaN(estimate.location.altitude) &&
                    checkEstimateExpiration(estimate, snapshotTimestamp, LAST_ESTIMATE_ALTITUDE_THRESHOLD_MILLISECONDS)
                );
            });

        if (lastEstimateWithAltitude == null) {
            return null;
        }

        const relativeAltitude = lastEstimateWithAltitude.location.getRelativeAltitude(this.referenceAltitude);
        const altitude =
            relativeAltitude != null && !isNaN(relativeAltitude) && relativeAltitude !== 0 ? relativeAltitude : 0;
        if (altitude === 0) {
            return null;
        }

        return altitude;
    }

    protected makeHeadFeature(
        trackId: number,
        trackICAO: number,
        trackVelocity: number,
        name: string | null,
        location: Location,
        bearing: number,
        finished: boolean,
        selected: boolean,
        observed: boolean,
        alarm: TrackAlarmState,
        visualizeBearing: boolean,
        textColor: string,
        textOffsetY: number,
        classificationOrCategory: string,
    ): Feature {
        let label = "";
        switch (this.trackLabel) {
            case TrackLabel.ALTITUDE:
                const altitude =
                    this.altitudeConfig !== AltitudeConfig.WGS84 && this.radarGroundLevel
                        ? location.altitude - this.radarGroundLevel
                        : location.altitude;
                label = formatTrackAltitude(this.distanceFormatter, altitude, this.displayNegativeAltitudeAsZero) ?? "";
                break;
            case TrackLabel.GROUND_SPEED:
                label = formatVelocity(trackVelocity, this.velocityUnit);
                break;
        }
        return {
            type: "Feature",
            id: trackId,
            geometry: {
                type: "Point",
                coordinates: [location.longitude, location.latitude],
            },
            properties: {
                [PROPS.trackId]: trackId,
                [PROPS.trackICAO]: trackICAO,
                [PROPS.label]: name ? `${name}\n${label}` : label,
                [PROPS.finished]: finished,
                [PROPS.selected]: selected,
                [PROPS.observed]: observed,
                [PROPS.rotation]: visualizeBearing ? Math.floor((bearing * 180) / Math.PI) : 0,
                [PROPS.alarm]: alarm,
                [PROPS.headTextColor]: textColor,
                [PROPS.headTextOffset]: [0, textOffsetY],
                [PROPS.classificationOrCategory]: classificationOrCategory,
            },
        };
    }

    protected makeTrajectoryFeature(
        trackId: int,
        lines: LineInfo[],
        finished: boolean,
        selected: boolean,
        alarm: TrackAlarmState,
    ): FeatureCollection {
        const features: Feature[] = lines.map((line) => ({
            type: "Feature",
            id: trackId,
            properties: {
                [PROPS.trackId]: trackId,
                [PROPS.finished]: finished,
                [PROPS.selected]: selected,
                [PROPS.estimationColor]: line.color,
                [PROPS.defaultColor]: lines[0]?.color,
                [PROPS.alarm]: alarm,
            },
            geometry: {
                type: "LineString",
                coordinates: line.coordinates,
            },
        }));

        return {
            type: "FeatureCollection",
            features: features,
        };
    }

    protected getTag(info: Classification | EmitterCategory | string): string {
        if (info instanceof Classification) {
            return info.name;
        } else {
            return info as string;
        }
    }

    protected loadImage(map: mapboxgl.Map, id: string, iconData: string | null): void {
        if (iconData == null) {
            return;
        }

        const image = new Image();
        image.onload = () => {
            if (map == null || map.getStyle() == null) {
                return;
            }
            if (map.hasImage(id)) {
                map.removeImage(id);
            }
            map.addImage(id, image, { pixelRatio: 1 });
        };
        image.src = iconData;
    }

    protected loadTrackOverlayIcon(
        map: mapboxgl.Map,
        classificationOrCategory: Classification | EmitterCategory,
        id: string,
    ): void {
        const iconData = this.trackSymbolGenerator.generateSymbolBase64(classificationOrCategory, this.iconScale);
        this.loadImage(map, id, iconData);
    }

    protected loadSelectedTrackIcon(
        map: mapboxgl.Map,
        classificationOrCategory: Classification | EmitterCategory,
        symbolId: string,
    ): void {
        if (this.map.hasImage(symbolId)) {
            this.map.removeImage(symbolId);
        }
        const iconData = this.trackSymbolGenerator.generateSelectionSymbolBase64(classificationOrCategory);
        this.loadImage(map, symbolId, iconData);
    }

    protected loadAlarmTrackIcon(map: mapboxgl.Map, symbolId: string, color?: string): void {
        if (this.map.hasImage(symbolId)) {
            this.map.removeImage(symbolId);
        }
        map.addImage(symbolId, new AlarmTrackImage(map, 30 * this.iconScale, color));
    }

    protected getTrackIdFromEventFeatures(features?: mapboxgl.MapboxGeoJSONFeature[] | undefined): number | null {
        if (features == null || features.length === 0) {
            return null;
        }

        return features[0]!.properties && features[0]!.properties[PROPS.trackId];
    }

    protected onMouseMove =
        (sourceId: string) =>
        (
            event: (mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) & {
                features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
            } & mapboxgl.EventData,
        ): void => {
            if (event.features && event.features.length > 0) {
                if (this.hoveredTrackId) {
                    this.map.setFeatureState({ source: sourceId, id: this.hoveredTrackId }, { hover: false });
                }
                this.hoveredTrackId = event.features[0].id;
                this.map.setFeatureState({ source: sourceId, id: this.hoveredTrackId }, { hover: true });
            }
        };

    protected onMouseLeave = (sourceId: string) => (): void => {
        if (this.hoveredTrackId) {
            this.map.setFeatureState({ source: sourceId, id: this.hoveredTrackId }, { hover: false });
        }
        this.hoveredTrackId = undefined;
    };

    protected handleClickForTrackInfo(
        event: (mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) & {
            features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
        } & mapboxgl.EventData,
    ): void {
        const trackId = this.getTrackIdFromEventFeatures(event.features);
        this.focusOnTrack(trackId);
        this.onTrackSelected && this.onTrackSelected(trackId);
    }

    protected requestRedraw(): void {
        this.redrawSubject.next();
    }

    protected flyToLocationIfNecessary(location: Location): void {
        if (this.isLocationInMapBounds(location)) {
            return;
        }
        this.map.flyTo({ center: [location.longitude, location.latitude] });
    }

    protected isLocationInMapBounds(location: Location): boolean {
        return this.isPointInMapBounds(locationToCoord(location));
    }

    protected isPointInMapBounds(coord: turf.Coord): boolean {
        const bounds = this.map.getBounds();
        const boundsPolygon = turf.polygon([
            [
                bounds.getNorthWest().toArray(),
                bounds.getNorthEast().toArray(),
                bounds.getSouthEast().toArray(),
                bounds.getSouthWest().toArray(),
                bounds.getNorthWest().toArray(),
            ],
        ]);
        return turf.booleanPointInPolygon(coord, boundsPolygon);
    }

    protected trackAlarmState(track: { id: number }): TrackAlarmState {
        if (this.alarmingTracks.includes(track.id)) {
            return TrackAlarmState.ALARMING;
        }
        if (this.dimTracks && this.alarmingTracks.length > 0) {
            return TrackAlarmState.OTHER_TRACK_ALARMING;
        }
        return TrackAlarmState.NOT_ALARMING;
    }

    public setTracksWithAlarms(alarmingTrackIds: int[]): void {
        this.alarmingTracks = alarmingTrackIds;
        this.requestRedraw();
    }

    public setForceDimTracksWithoutAlarms(forceDimTracks: boolean): void {
        this.dimTracks = forceDimTracks;
        this.setDimTracksTimeout(0);
        this.requestRedraw();
    }

    public setAltitudeConfig(config: AltitudeConfig | null): void {
        this.altitudeConfig = config ?? AltitudeConfig.WGS84;
        this.requestRedraw();
    }

    public setRadarGroundLevel(level: number | null): void {
        this.radarGroundLevel = level;
    }

    // Private functions

    private setDimTracksTimeout(timeout: number): void {
        if (this.dimTracksTimeout) {
            clearTimeout(this.dimTracksTimeout);
        }
        if (timeout > 0) {
            this.dimTracksTimeout = window.setTimeout(() => {
                this.dimTracks = false;
            }, timeout);
        }
    }

    private subscribeToRedrawSubject(): void {
        this.redrawSubject
            .pipe(RxOperators.throttleTime(REDRAW_THROTTLE_MS, undefined, { leading: true, trailing: true }))
            .subscribe(() => this.redraw());
    }

    private redraw(): void {
        if (this.snapshotDiff) {
            this.updateTracks(this.snapshotDiff);
        }
    }

    private getLineColorExpression(classificationHistoryEnabled: boolean): mapboxgl.Expression {
        const defaultColorExpression: mapboxgl.Expression = ["get", PROPS.defaultColor];
        if (classificationHistoryEnabled) {
            return ["case", ["has", PROPS.estimationColor], ["get", PROPS.estimationColor], defaultColorExpression];
        } else {
            return defaultColorExpression;
        }
    }

    private getTrackOpacityExpression(finishedOpacity: number): mapboxgl.Expression {
        return [
            "case",
            ["==", ["get", PROPS.finished], true],
            finishedOpacity,
            ["case", ["==", ["get", PROPS.alarm], TrackAlarmState.OTHER_TRACK_ALARMING], 0.5, 1],
        ];
    }

    private getTrackHoverOpacityExpression(finishedOpacity: number): mapboxgl.Expression {
        return [
            "case",
            ["all", ["==", ["get", PROPS.finished], true], ["boolean", ["feature-state", "hover"], false]],
            finishedOpacity,
            ["boolean", ["feature-state", "hover"], false],
            1,
            0,
        ];
    }
}
