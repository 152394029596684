import { BlankingSector } from "../../../domain/model";
import { degToRad } from "../../../utils/MathUtils";
import { SECTORS_ROTATION_OFFSET_RADIANS } from "./BlankingSectorsImage";

export class BlankingSectorsPreviewImage {
    // Properties
    public width = this.radius * 2;
    public height = this.radius * 2;

    private context: CanvasRenderingContext2D;
    private blankingSectors: BlankingSector[] = [];

    public constructor(private readonly radius: int) {
        const canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext("2d")!;
    }

    public getCanvas(): HTMLCanvasElement {
        return this.context.canvas;
    }

    public update(blankingSectors?: BlankingSector[]): void {
        this.blankingSectors = blankingSectors || [];
        this.render();
    }

    // Called once before every frame where the icon will be used
    public render(): void {
        const context = this.context;
        if (context == null) {
            return;
        }

        const r = this.radius;
        const cx = this.width / 2;
        const cy = this.height / 2;

        context.clearRect(0, 0, this.width, this.height);

        // Draw blanking sectors
        this.blankingSectors.forEach((sector) => {
            context.beginPath();
            context.moveTo(cx, cy);
            context.arc(
                cx,
                cy,
                r,
                degToRad(sector.startAngle) + SECTORS_ROTATION_OFFSET_RADIANS,
                degToRad(sector.startAngle + sector.span) + SECTORS_ROTATION_OFFSET_RADIANS,
            );
            context.lineTo(cx, cy);
            context.fillStyle = "rgba(86, 119, 252, 0.5)";
            context.fill();
        });
    }
}
