import styled from "styled-components";
import { OVERLAY_SPACING } from "../theme/Spacing";

/**
 * @deprecated Use `Panel` instead
 */
export const PanelRoot = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${OVERLAY_SPACING};
    width: 200px;
    max-height: calc(var(--dvh-unit) * 100 - ${OVERLAY_SPACING} * 4);
    overflow-y: auto;
`;
