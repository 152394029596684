import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import { GroundObservation, Location, locationToGeoLocationProto, StatusResponse } from "../../../../domain/model";
import {
    LoggerReportRequest as LoggerReportRequestProto,
    LoggerReportList as LoggerReportListProto,
    LoggerReportType as LoggerReportTypeProto,
    DeleteReportMsg as DeleteReportMsgProto,
    LogNoteMsg as LogNoteMsgProto,
    LoggerCommandMsg as LoggerCommandMsgProto,
} from "../../../../domain/model/proto/generated/logger3_pb";
import {
    AbstractStartableRepository,
    GroundObservationMode,
    GroundObservationRepository,
} from "../../../../domain/repositories";
import { t } from "i18next";

export class BirdViewerGroundObservationRepository
    extends AbstractStartableRepository
    implements GroundObservationRepository
{
    // Properties

    public get mode(): Rx.Observable<GroundObservationMode> {
        return this.modeSubject.asObservable();
    }

    public get selectedLocations(): Rx.Observable<Location[]> {
        return this.selectedLocationsSubject.asObservable();
    }

    public get groundObservations(): Rx.Observable<GroundObservation[]> {
        return this.groundObservationsSubject.asObservable();
    }

    public get selectedGroundObservationIds(): Rx.Observable<long[]> {
        return this.selectedGroundObservationIdsSubject.asObservable();
    }

    public get shouldSuppressClickEvent(): Rx.Observable<boolean> {
        return this.shouldSuppressClickEventSubject.asObservable();
    }

    private readonly modeSubject = new Rx.BehaviorSubject<GroundObservationMode>(GroundObservationMode.None);
    private readonly selectedLocationsSubject = new Rx.BehaviorSubject<Location[]>([]);
    private readonly groundObservationsSubject = new Rx.BehaviorSubject<GroundObservation[]>([]);
    private readonly selectedGroundObservationIdsSubject = new Rx.BehaviorSubject<long[]>([]);
    private readonly shouldSuppressClickEventSubject = new Rx.BehaviorSubject<boolean>(false);
    private subscriptions = new Rx.Subscription();

    public constructor(private api: BirdViewerAPI) {
        super();
    }

    // Public functions

    public start(): void {
        this.subscriptions = new Rx.Subscription();
        this.startFetchingGroundObservations();
    }

    public stop(): void {
        this.subscriptions.unsubscribe();
        this.clearSelection();
    }

    public setMode(mode: GroundObservationMode): void {
        this.modeSubject.next(mode);
    }

    public selectLocation(location: Location): void {
        switch (this.modeSubject.value) {
            case GroundObservationMode.SingleGroundObservation:
                this.selectedLocationsSubject.next([location]);
                this.selectedGroundObservationIdsSubject.next([]);
                break;
        }
    }

    public toggleGroundObservation(id: long): void {
        switch (this.modeSubject.value) {
            case GroundObservationMode.SingleGroundObservation:
                const currentSelectedIds = Array.from(this.selectedGroundObservationIdsSubject.value);
                this.selectedGroundObservationIdsSubject.next(currentSelectedIds.includes(id) ? [] : [id]);
                this.selectedLocationsSubject.next([]);
                break;
        }
    }

    public clearSelection(): void {
        this.selectedLocationsSubject.next([]);
        this.selectedGroundObservationIdsSubject.next([]);
    }

    public addObservationForSelectedLocations(notes: string): Rx.Observable<void> {
        return this.selectedLocationsSubject.pipe(
            RxOperators.take(1),
            RxOperators.flatMap((locations) =>
                Rx.from(locations).pipe(
                    RxOperators.flatMap((location) => this.addGroundObservation(notes, location)),
                    RxOperators.toArray(),
                    RxOperators.flatMap((r) => this.processGroundObservationResponse(r, "submit")),
                ),
            ),
        );
    }

    public removeSelectedGroundObservations(): Rx.Observable<void> {
        return this.selectedGroundObservationIds.pipe(
            RxOperators.take(1),
            RxOperators.flatMap((ids) => Rx.from(ids)),
            RxOperators.flatMap((id) => this.removeGroundObservation(id)),
            RxOperators.toArray(),
            RxOperators.flatMap((r) => this.processGroundObservationResponse(r, "remove")),
        );
    }

    public updateSelectedGroundObservations(notes: string): Rx.Observable<void> {
        return this.selectedGroundObservationIds.pipe(
            RxOperators.take(1),
            RxOperators.flatMap((ids) => Rx.from(ids)),
            RxOperators.flatMap((id) =>
                this.groundObservations.pipe(
                    RxOperators.take(1),
                    RxOperators.map((observations) => observations.find((o) => o.id === id)!),
                ),
            ),
            RxOperators.flatMap((o) => this.updateGroundObservation(o, notes)),
            RxOperators.toArray(),
            RxOperators.flatMap((r) => this.processGroundObservationResponse(r, "update")),
        );
    }

    public fetchGroundObservationsUpdatesOnce(): void {
        this.fetchGroundObservationsUpdates(false);
    }

    public suppressClickEvent(timeout: int): void {
        this.shouldSuppressClickEventSubject.next(true);
        setTimeout(() => {
            this.shouldSuppressClickEventSubject.next(false);
        }, timeout);
    }

    // Private functions

    private startFetchingGroundObservations(): void {
        const request = new LoggerReportRequestProto();
        const subscription = this.api.getLoggerReportList(request).subscribe(
            (value) => {
                this.handleGroundObservationsUpdate(value);
                this.fetchGroundObservationsUpdates(true);
            },
            (error) => this.retryIfStartedAndImplemented(() => this.startFetchingGroundObservations(), error),
        );
        this.subscriptions.add(subscription);
    }

    private fetchGroundObservationsUpdates(shouldRetry: boolean): void {
        const subscription = this.api.getLoggerReportUpdates().subscribe(
            (value) => this.handleGroundObservationsUpdate(value),
            (error) =>
                shouldRetry &&
                this.retryIfStartedAndImplemented(() => this.fetchGroundObservationsUpdates(true), error),
        );
        this.subscriptions.add(subscription);
    }

    private handleGroundObservationsUpdate(reportList: LoggerReportListProto): void {
        const currentGroundObservations = this.groundObservationsSubject.value;
        const removedObservationIds = reportList
            .getDeletedloggerreportList()
            .filter((r) => r.getLoggerreporttype() === LoggerReportTypeProto.NOTE)
            .map((r) => r.getReportid());
        const newObservations = reportList
            .getLoggerreportList()
            .filter((r) => r.getLoggerreporttype() === LoggerReportTypeProto.NOTE)
            .map(GroundObservation.fromProto);
        this.groundObservationsSubject.next(
            currentGroundObservations
                .filter((o) => !removedObservationIds.some((id) => id === o.id))
                .concat(...newObservations),
        );
    }

    private addGroundObservation(notes: string, location: Location): Rx.Observable<StatusResponse> {
        const message = new LogNoteMsgProto();
        message.setPosition(locationToGeoLocationProto(location));
        message.setComment(notes);
        const request = new LoggerCommandMsgProto();
        request.setLognote(message);
        return this.sendLoggerCommandMessage(request);
    }

    private updateGroundObservation(observation: GroundObservation, notes: string): Rx.Observable<StatusResponse> {
        return this.removeGroundObservation(observation.id).pipe(
            RxOperators.flatMap((response) =>
                response.isOk ? this.addGroundObservation(notes, observation.location) : Rx.throwError({ response }),
            ),
        );
    }

    private removeGroundObservation(observationId: long): Rx.Observable<StatusResponse> {
        const message = new DeleteReportMsgProto();
        message.setLoggerreporttype(LoggerReportTypeProto.NOTE);
        message.setReportid(observationId);
        const request = new LoggerCommandMsgProto();
        request.setDeletereport(message);
        return this.sendLoggerCommandMessage(request);
    }

    private sendLoggerCommandMessage(request: LoggerCommandMsgProto): Rx.Observable<StatusResponse> {
        return this.api
            .handleLoggerCommand(request)
            .pipe(RxOperators.map((response) => StatusResponse.fromProto(response)));
    }

    private processGroundObservationResponse(
        responseArray: StatusResponse[],
        action: "submit" | "remove" | "update",
    ): Rx.Observable<void> {
        return new Rx.Observable<void>((subscriber) => {
            const failures = responseArray.filter((r) => !r.isOk);
            failures.forEach((r) => console.error(`Failed to ${action} ground observation: ${r.message}`));
            const failCount = failures.length;
            if (failCount === 0) {
                this.clearSelection();
                subscriber.complete();
            } else {
                const errorMessage = this.getErrorMessage(action, failCount > 1);
                subscriber.error(Error(errorMessage));
            }
        });
    }

    private getErrorMessage(action: "submit" | "remove" | "update", plural: boolean): string {
        if (!plural) {
            switch (action) {
                case "remove":
                    return t("observation.errorDeleteGroundObservation");
                case "update":
                    return t("observation.errorEditGroundObservation");
                case "submit":
                    return t("observation.errorSubmitGroundObservation");
                    defaut: return "";
            }
        } else {
            switch (action) {
                case "remove":
                    return t("observation.errorDeleteGroundObservationPlural");
                case "update":
                    return t("observation.errorEditGroundObservationPlural");
                case "submit":
                    return t("observation.errorSubmitGroundObservationPlural");
                    defaut: return "";
            }
        }
    }
}
