import React from "react";
import {
    BaseRepository,
    TrackRepository,
    LocationInfoRepository,
    GridAnalysisRepository,
    SessionRepository,
    LocalPreferencesRepository,
    ServerPreferencesRepository,
    MapRepository,
    OverlayRepository,
    RadarRepository,
    ReplayRepository,
    AlarmRepository,
    ServerConfigRepository,
    DeterrenceRepository,
    TrackObservationRepository,
    GroundObservationRepository,
    ADSBFlightRepository,
    RunwayTrafficRepository,
} from "../../domain/repositories";
import { TYPES } from "../../di/Types";
import DI from "../../di/DI";
import { BaseApp } from "../../components/BaseApp";
import { Helmet } from "react-helmet";

export class App extends BaseApp {
    // Properties

    protected repositories: BaseRepository[] = [
        DI.get<ServerConfigRepository>(TYPES.ServerConfigRepository),
        DI.get<TrackRepository>(TYPES.TrackRepository),
        DI.get<RadarRepository>(TYPES.RadarRepository),
        DI.get<ReplayRepository>(TYPES.ReplayRepository),
        DI.get<LocationInfoRepository>(TYPES.LocationInfoRepository),
        DI.get<GridAnalysisRepository>(TYPES.GridAnalysisRepository),
        DI.get<SessionRepository>(TYPES.SessionRepository),
        DI.get<LocalPreferencesRepository>(TYPES.LocalPreferencesRepository),
        DI.get<ServerPreferencesRepository>(TYPES.ServerPreferencesRepository),
        DI.get<MapRepository>(TYPES.MapRepository),
        DI.get<OverlayRepository>(TYPES.OverlayRepository),
        DI.get<AlarmRepository>(TYPES.AlarmRepository),
        DI.get<DeterrenceRepository>(TYPES.DeterrenceRepository),
        DI.get<TrackObservationRepository>(TYPES.TrackObservationRepository),
        DI.get<GroundObservationRepository>(TYPES.GroundObservationRepository),
        DI.get<ADSBFlightRepository>(TYPES.ADSBFlightRepository),
        DI.get<RunwayTrafficRepository>(TYPES.RunwayTrafficRepository),
    ];

    protected renderHead(): JSX.Element {
        return (
            <Helmet>
                <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/icons/bird-viewer-favicon.ico`} />
                <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/icons/bird-viewer-192x192.png`} />
                <link rel="manifest" href={`${process.env.PUBLIC_URL}/manifest-bv.json`} />
                <title>{this.appName}</title>
            </Helmet>
        );
    }
}
