import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { LocalUserPreferenceKeys } from "../../domain/model";
import { LocalPreferencesRepository, LocationInfoRepository } from "../../domain/repositories";
import { getColorAndOpacity } from "../../utils/ColorUtils";
import { nonNullObservable } from "../../utils/RxUtils";

export function setContextColorBasedOnSectorRate(
    ctx: CanvasRenderingContext2D,
    rate: number | undefined,
    threshold: number,
    baseColor: string,
    thresholdColor: string,
): void {
    const colorString = rate && rate >= threshold ? thresholdColor : baseColor;
    const [color, opacity] = getColorAndOpacity(colorString);
    ctx.fillStyle = color;
    ctx.globalAlpha = opacity;
}

export function getShowParkingCorridorObservable(
    localPreferencesRepository: LocalPreferencesRepository,
    locationInfoRepository: LocationInfoRepository,
    runwayId: number,
): Rx.Observable<boolean> {
    const preferenceObservable = nonNullObservable(
        localPreferencesRepository.observePreference(LocalUserPreferenceKeys.charts.showFunnelViewParkingCorridor),
        true,
    );
    return Rx.combineLatest([preferenceObservable, locationInfoRepository.hasParkingCorridorData(runwayId)]).pipe(
        RxOperators.map(([preference, hasParkingCorridorData]) => preference && hasParkingCorridorData),
    );
}
