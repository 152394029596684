import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import {
    LocalUserPreferenceKeys,
    GeolocationPosition,
    RangeMarker,
    generateRangeMarkers,
} from "../../../../domain/model";
import { LocalPreferencesRepository, UserLocationRepository, UserLocationState } from "../../../../domain/repositories";
import { FlavorConfig } from "../../../../infrastructure/FlavorConfig";
import * as RxUtils from "../../../../utils/RxUtils";
import { MapModuleViewModel } from "../MapModuleViewModel";
import { DistanceFormatter } from "../../../../domain/DistanceFormatter";
import { Units } from "@turf/turf";

export class UserLocationModuleViewModel extends MapModuleViewModel {
    // Properties

    public get userLocationPosition(): Rx.Observable<GeolocationPosition | undefined> {
        return this.userLocationRespository.userLocationPosition;
    }

    public get shouldShowUserLocation(): Rx.Observable<boolean> {
        return this.userLocationRespository.userLocationState.pipe(
            RxOperators.map((s) => s !== UserLocationState.INACTIVE),
        );
    }

    public get shouldShowUserLocationRange(): Rx.Observable<boolean> {
        return RxUtils.nonNullObservable(
            this.localPreferencesRepository.observePreference<boolean>(
                LocalUserPreferenceKeys.filters.showUserLocationRange,
            ),
        );
    }

    public get userLocationRangeRingsOpacity(): Rx.Observable<number> {
        return RxUtils.nonNullObservable(
            this.localPreferencesRepository.observePreference<number>(
                LocalUserPreferenceKeys.appearance.rangeRingsOpacity,
            ),
        );
    }

    public get userLocationRangeMarkers(): Rx.Observable<RangeMarker[]> {
        return this.distanceFormatter.selectedDistanceUnitObservable.pipe(
            RxOperators.map((distanceUnit) => {
                const config = this.flavorConfig.userLocationRangeRingsConfig[distanceUnit];
                return generateRangeMarkers(config.range, config.interval, config.units as Units, (r) =>
                    this.distanceFormatter.formatValueWithCurrentUnit(r, distanceUnit, {
                        simplifyLargeValues: true,
                    }),
                );
            }),
        );
    }

    public constructor(
        private readonly userLocationRespository: UserLocationRepository,
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly flavorConfig: FlavorConfig,
        private readonly distanceFormatter: DistanceFormatter,
    ) {
        super();
    }
}
