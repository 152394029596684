import mapboxgl from "mapbox-gl";
import * as Rx from "rxjs";
import { Location } from "../../../domain/model";
import { MapModuleViewModel } from "./MapModuleViewModel";
import { LayerManager } from "./LayerManager";

export abstract class MapModule<VM extends MapModuleViewModel> {
    // Properties

    protected abstract readonly viewModel: VM;

    // Public functions

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public setup(map: mapboxgl.Map, referenceLocation: Location, layerManager: LayerManager): void {
        this.viewModel.setup();
    }

    public dispose(): void {
        this.viewModel.dispose();
    }

    // Protected functions

    protected collectSubscription(subscription: Rx.Subscription): void {
        this.viewModel.collectSubscription(subscription);
    }

    protected collectSubscriptions(...subscriptions: Rx.Subscription[]): void {
        this.viewModel.collectSubscriptions(...subscriptions);
    }
}
