import * as mapboxgl from "mapbox-gl";
import { Radar, BlankingSector, Location } from "../../../domain/model";
import * as MapUtils from "../../../utils/MapUtils";
import { Feature, FeatureCollection } from "geojson";
import { BlankingSectorsImage } from "./BlankingSectorsImage";
import RadarIcon from "../../../res/images/radar_blue.svg";
import * as turf from "@turf/turf";
import { generateArrowIconSource } from "../../appearance/Arrow";
import { OldColors } from "../../appearance/Colors";

const BLANKING_SECTORS_SOURCE_ID = "source-radar-blanking-sector";
export const BLANKING_SECTORS_LAYER_ID = "layer-radar-blanking-sector";
const RADAR_POSITION_SOURCE_ID = "source-radar-position-in-blanking-sectors";

const BLANKING_SECTOR_RADAR_ICON_SYMBOL_ID = "symbol-radar-blanking-sector-icon";
export const BLANKING_SECTOR_RADAR_ICON_LAYER_ID = "layer-radar-blanking-sector-icon";

const BLANKING_SECTOR_HANDLE_SYMBOL_ID = "symbol-radar-blanking-sector-handle";
export const BLANKING_SECTOR_HANDLES_LAYER_ID = "layer-radar-blanking-sector-handle";
const BLANKING_SECTOR_HANDLES_SOURCE_ID = "source-radar-blanking-sector-handles";

const DEFAULT_RADAR_RANGE_METERS = 1000;
const HANDLES_DISTANCE_OVER_RADAR_RANGE = 1.05;

const PROPERTY_ROTATION = "rotation";
const PROPERTY_HANDLE_TYPE = "handle-type";
const PROPERTY_HANDLE_ID = "id";
const PROPERTY_HANDLE_BLANKING_SECTOR_INDEX = "bs-idx";
const PROPERTY_SECTOR = "sector";
const PROPERTY_VISIBLE = "visible";
const PROPERTY_HAS_ZERO_SPAN = "has-zero-span";

type HandleType = "start" | "end";

const DYNAMIC_HANDLE_RADIUS_GROWTH_THRESHOLD = 5;
const DYNAMIC_HANDLE_RADIUS_GROWTH_SPACE_METERS = 50;

export class RadarBlankingSectorsLayer {
    // Static functions

    public static attachedTo(
        map: mapboxgl.Map,
        orderLayer: string,
        onSectorChange: (index: number, blankingSector: BlankingSector) => void,
    ): RadarBlankingSectorsLayer {
        return new RadarBlankingSectorsLayer(map, orderLayer, onSectorChange);
    }

    // Properties
    private radars: Radar[] = [];
    private selectedRadar?: Radar;
    private image: BlankingSectorsImage;
    private handleSourceFeatures: Feature[] = [];

    private constructor(
        private readonly map: mapboxgl.Map,
        private orderLayer: string,
        private onSectorChange: (index: number, blankingSector: BlankingSector) => void,
    ) {
        this.image = new BlankingSectorsImage(this.getBlankingSectorImageSize() / 2);
        this.setup();
    }

    // Public functions

    public setRadars(radars: Radar[]): void {
        this.radars = radars;
    }

    public setBlankingSectorState(active: boolean, radarId?: int, blankingSectors?: BlankingSector[]): void {
        const blankingSectorsSource = this.map.getSource(BLANKING_SECTORS_SOURCE_ID) as mapboxgl.CanvasSource;
        const radarPositionSource = this.map.getSource(RADAR_POSITION_SOURCE_ID) as mapboxgl.GeoJSONSource;
        if (!blankingSectorsSource || !radarPositionSource) {
            return;
        }

        if (!active) {
            radarPositionSource.setData({
                type: "FeatureCollection",
                features: [],
            });
            blankingSectorsSource.setCoordinates([
                [0, 1],
                [1, 1],
                [1, 0],
                [0, 0],
            ]);
            return;
        }

        const selectedRadar = this.radars.find((r) => r.id === radarId);
        this.selectedRadar = selectedRadar;
        if (selectedRadar == null) {
            return;
        }

        this.updateHandleFeatures(selectedRadar, blankingSectors || []);

        radarPositionSource.setData({
            type: "FeatureCollection",
            features: [this.getPositionFeatureFromRadar(selectedRadar)],
        });

        this.image.update(blankingSectors);
        blankingSectorsSource.play();
        blankingSectorsSource.pause();
        blankingSectorsSource.setCoordinates(this.getRadarShapeCoordinatesFromRadar(selectedRadar));
        const cameraCenter = this.map.getCenter();
        MapUtils.flyToIfFarAway(
            this.map,
            selectedRadar.position,
            new Location(cameraCenter.lat, cameraCenter.lng, 0),
            100,
        );
    }

    public setEnabled(enabled: boolean): void {
        const visibility = enabled ? "visible" : "none";
        this.map.setLayoutProperty(BLANKING_SECTORS_LAYER_ID, "visibility", visibility);
        this.map.setLayoutProperty(BLANKING_SECTOR_RADAR_ICON_LAYER_ID, "visibility", visibility);
        this.map.setLayoutProperty(BLANKING_SECTOR_HANDLES_LAYER_ID, "visibility", visibility);
    }

    // Private functions

    private setup(): void {
        this.setupInteractionsForRadars();
        this.addBlankingSectorLayer();
        this.addRadarIconLayer();
    }

    private addBlankingSectorLayer(): void {
        this.map.addSource(BLANKING_SECTORS_SOURCE_ID, {
            type: "canvas",
            canvas: this.image.getCanvas(),
            coordinates: [
                [0, 1],
                [1, 1],
                [1, 0],
                [0, 0],
            ],
            animate: false,
        });
        this.map.addLayer(
            {
                id: BLANKING_SECTORS_LAYER_ID,
                type: "raster",
                source: BLANKING_SECTORS_SOURCE_ID,
                paint: {
                    "raster-fade-duration": 0, // Ensure no fade in/out on re-rendering
                },
            },
            this.orderLayer,
        );
    }

    private addRadarIconLayer(): void {
        this.map.addSource(RADAR_POSITION_SOURCE_ID, MapUtils.EMPTY_GEOJSON_SOURCE);
        MapUtils.loadImageFromSVG(RadarIcon, (image) => this.map.addImage(BLANKING_SECTOR_RADAR_ICON_SYMBOL_ID, image));
        this.map.addLayer(
            {
                id: BLANKING_SECTOR_RADAR_ICON_LAYER_ID,
                type: "symbol",
                source: RADAR_POSITION_SOURCE_ID,
                filter: ["==", "$type", "Point"],
                layout: {
                    "icon-allow-overlap": true,
                    "icon-image": BLANKING_SECTOR_RADAR_ICON_SYMBOL_ID,
                    "icon-pitch-alignment": "map",
                },
                paint: {},
            },
            this.orderLayer,
        );
    }

    private getPositionFeatureFromRadar(radar: Radar): Feature {
        return {
            type: "Feature",
            properties: {},
            geometry: {
                type: "Point",
                coordinates: [radar.position.longitude, radar.position.latitude],
            },
        };
    }
    private getRadarShapeCoordinatesFromRadar(radar: Radar): number[][] {
        const radarRadiusCircle = turf.circle(
            [radar.position.longitude, radar.position.latitude],
            (radar.orientation.range || DEFAULT_RADAR_RANGE_METERS) * this.image.getImageSizeToRadarRangeRadiusRatio(),
            { units: "meters" },
        );
        const bbox = turf.bbox(radarRadiusCircle);
        return [
            [bbox[0], bbox[3]],
            [bbox[2], bbox[3]],
            [bbox[2], bbox[1]],
            [bbox[0], bbox[1]],
        ];
    }

    private getBlankingSectorImageSize(): int {
        return Math.floor(Math.min(window.innerWidth, window.innerHeight));
    }

    private setupInteractionsForRadars(): void {
        MapUtils.loadImageFromSVG(generateArrowIconSource({ direction: 0, color: OldColors.primaryTint }), (image) =>
            this.map.addImage(BLANKING_SECTOR_HANDLE_SYMBOL_ID, image, { pixelRatio: 1.5 }),
        );
        this.setupHandles(this.map);
    }

    private setupHandles(map: mapboxgl.Map): void {
        const layerId = BLANKING_SECTOR_HANDLES_LAYER_ID;
        const sourceId = BLANKING_SECTOR_HANDLES_SOURCE_ID;
        const canvas = map.getCanvasContainer();
        let selectedFeatureId: string;

        map.addSource(sourceId, MapUtils.EMPTY_GEOJSON_SOURCE);

        map.addLayer({
            id: layerId,
            type: "symbol",
            source: sourceId,
            filter: ["==", "$type", "Point"],
            layout: {
                "icon-allow-overlap": true,
                "icon-rotate": ["get", PROPERTY_ROTATION],
                "icon-image": [
                    "case",
                    ["==", ["get", PROPERTY_VISIBLE], false],
                    "",
                    ["==", ["get", PROPERTY_HAS_ZERO_SPAN], true],
                    "",
                    BLANKING_SECTOR_HANDLE_SYMBOL_ID,
                ],
                "icon-size": 2,
                "icon-rotation-alignment": "map",
            },
            paint: {},
        });

        const setHandleVisibility = (visible: boolean, apply = true): void => {
            this.handleSourceFeatures.forEach((f) => {
                f.properties![PROPERTY_VISIBLE] = visible;
            });

            if (!apply) {
                return;
            }

            (map.getSource(sourceId) as mapboxgl.GeoJSONSource).setData({
                type: "FeatureCollection",
                features: this.handleSourceFeatures,
            });
        };

        const onMove = (e: (mapboxgl.MapTouchEvent | mapboxgl.MapMouseEvent) & mapboxgl.EventData): void => {
            const pointerCoords = e.lngLat;
            const selectedRadar = this.selectedRadar;
            if (!selectedRadar || !selectedFeatureId) {
                return;
            }

            const radarCoords = selectedRadar?.position.toGeoJSONLocation();

            // Set a UI indicator for dragging.
            canvas.style.cursor = "grabbing";

            // Update the handle
            const bearing = turf.bearing(radarCoords, [pointerCoords.lng, pointerCoords.lat]);
            const handleRotation = 180 + bearing;
            const range =
                (this.selectedRadar?.orientation.range || DEFAULT_RADAR_RANGE_METERS) *
                HANDLES_DISTANCE_OVER_RADAR_RANGE;
            const dynamicRange = range + this.calculateDynamicHandleDistanceFromLabel(bearing);
            const pointOnCircle = turf.destination(radarCoords, dynamicRange, bearing, {
                units: "meters",
            });
            const selectedFeature = this.handleSourceFeatures.find(
                (f) => f.properties![PROPERTY_HANDLE_ID] == selectedFeatureId,
            )!;
            selectedFeature.geometry = pointOnCircle.geometry;
            const props = selectedFeature.properties!;
            // Update rotation of the feature
            props[PROPERTY_ROTATION] = handleRotation;
            setHandleVisibility(false, false);

            (map.getSource(sourceId) as mapboxgl.GeoJSONSource).setData({
                type: "FeatureCollection",
                features: this.handleSourceFeatures,
            });

            const handleType = props[PROPERTY_HANDLE_TYPE] as HandleType;
            const sector = props[PROPERTY_SECTOR] as BlankingSector;
            const sectorIndex = props[PROPERTY_HANDLE_BLANKING_SECTOR_INDEX];

            const normalizedBearing = Math.floor((360 + bearing) % 360);
            switch (handleType) {
                case "start":
                    this.onSectorChange(sectorIndex, new BlankingSector(normalizedBearing, Math.floor(sector.span)));
                    break;
                case "end":
                    this.onSectorChange(
                        sectorIndex,
                        new BlankingSector(Math.floor(sector.startAngle), normalizedBearing - sector.startAngle),
                    );
                    break;
            }
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const onUp = (e: (mapboxgl.MapTouchEvent | mapboxgl.MapMouseEvent) & mapboxgl.EventData): void => {
            // NOTE: To get the last position of cursor, use: e.lngLat

            canvas.style.cursor = "";

            setHandleVisibility(true);

            // Unbind mouse/touch events
            map.off("mousemove", onMove);
            map.off("touchmove", onMove);
        };

        // When the cursor enters a feature in
        // the point layer, prepare for dragging.
        map.on("mouseenter", layerId, () => {
            canvas.style.cursor = "move";
        });

        map.on("mouseleave", layerId, () => {
            canvas.style.cursor = "";
        });

        map.on("mousedown", layerId, (e) => {
            // Prevent the default map drag behavior.
            e.preventDefault();

            canvas.style.cursor = "grab";

            selectedFeatureId = e.features![0].properties![PROPERTY_HANDLE_ID];
            setHandleVisibility(false);
            map.on("mousemove", onMove);
            map.once("mouseup", onUp);
        });

        map.on("touchstart", layerId, (e) => {
            if (e.points.length !== 1) {
                return;
            }

            // Prevent the default map drag behavior.
            e.preventDefault();

            selectedFeatureId = e.features![0].properties![PROPERTY_HANDLE_ID];
            setHandleVisibility(false);
            map.on("touchmove", onMove);
            map.once("touchend", onUp);
        });
    }

    private updateHandleFeatures(radar: Radar, blankingSectors: BlankingSector[]): void {
        const handlesSource = this.map.getSource(BLANKING_SECTOR_HANDLES_SOURCE_ID) as mapboxgl.GeoJSONSource;
        if (!handlesSource) {
            return;
        }
        const createHandle = (index: number, type: HandleType, sector: BlankingSector): Feature => {
            const handleId = `handle-${index}-${type}`;
            const oldHandle = this.handleSourceFeatures?.find((f) => f.properties![PROPERTY_HANDLE_ID] === handleId);
            let bearing = 0;
            switch (type) {
                case "start":
                    bearing = sector.startAngle;
                    break;
                case "end":
                    bearing = sector.startAngle + sector.span;
                    break;
            }
            const rotation = bearing + 180;
            const dynamicRange = range + this.calculateDynamicHandleDistanceFromLabel(rotation);
            return turf.destination(radar.position.toGeoJSONLocation(), dynamicRange, bearing, {
                units: "meters",
                properties: {
                    [PROPERTY_HANDLE_BLANKING_SECTOR_INDEX]: index,
                    [PROPERTY_HANDLE_TYPE]: type,
                    [PROPERTY_HANDLE_ID]: handleId,
                    [PROPERTY_ROTATION]: rotation,
                    [PROPERTY_VISIBLE]: oldHandle?.properties?.[PROPERTY_VISIBLE],
                    [PROPERTY_SECTOR]: sector,
                    [PROPERTY_HAS_ZERO_SPAN]: sector.span == 0,
                },
            });
        };

        const range = (radar.orientation.range || DEFAULT_RADAR_RANGE_METERS) * HANDLES_DISTANCE_OVER_RADAR_RANGE;
        const newHandleFeatures = blankingSectors
            .map((s, index) => [createHandle(index, "start", s), createHandle(index, "end", s)])
            .flat();

        const sourceGeoJson: FeatureCollection = {
            type: "FeatureCollection",
            features: newHandleFeatures,
        };

        this.handleSourceFeatures = newHandleFeatures;
        handlesSource.setData(sourceGeoJson);
    }

    private calculateDynamicHandleDistanceFromLabel(rotation: number): number {
        const t = DYNAMIC_HANDLE_RADIUS_GROWTH_THRESHOLD;
        const d = DYNAMIC_HANDLE_RADIUS_GROWTH_SPACE_METERS;
        if (rotation % 90 <= t) {
            return Math.sin((((t - (rotation % t)) / t) * Math.PI) / 2) * d;
        } else if (rotation % 90 >= 90 - t) {
            return Math.sin((((rotation % t) / t) * Math.PI) / 2) * d;
        } else {
            return 0;
        }
    }
}
