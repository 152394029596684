import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { BirdViewerAPI } from "../../../../domain/BirdViewerAPI";
import {
    DeterrenceDeviceList,
    DeterrenceDeviceActivationStatus,
    DeterrenceDeviceUpdateList,
    DeterrenceEvent,
    StatusResponse,
    DeterrenceDevice,
    DeterrenceDeviceState,
    stateForDevice,
} from "../../../../domain/model";
import {
    DeterrenceCommandMsg as DeterrenceCommandMsgProto,
    DeterrenceEventsListRequest as DeterrenceEventsListRequestProto,
    DeterrenceStartMsg as DeterrenceStartMsgProto,
} from "../../../../domain/model/proto/generated/deterrencedevices3_pb";
import { AbstractStartableRepository, DeterrenceRepository } from "../../../../domain/repositories";

const DETERRENCE_DEVICES_UPDATE_INTERVAL = 500;
const DETERRENCE_EVENTS_UPDATE_INTERVAL = 1_000;
const DETERRENCE_EVENTS_HISTORY_DAYS = 1;

export class BirdViewerDeterrenceRepository extends AbstractStartableRepository implements DeterrenceRepository {
    // Properties

    public get showDeterrenceDevicesObservable(): Rx.Observable<boolean> {
        return this.showDeterrenceDevicesSubject.asObservable();
    }

    private readonly showDeterrenceDevicesSubject = new Rx.BehaviorSubject<boolean>(false);
    private readonly selectedDeviceIdsSubject = new Rx.BehaviorSubject<int[]>([]);
    private readonly fetchDeterrenceDeviceUpdatesTrigger = new Rx.Subject<void>();
    private deterrenceDevicesObservable?: Rx.Observable<[DeterrenceDevice, DeterrenceDeviceState][]>;
    private deterrenceEventsObservable?: Rx.Observable<DeterrenceEvent[]>;
    private subscriptions = new Rx.Subscription();

    // Public functions

    public constructor(private api: BirdViewerAPI) {
        super();
    }

    public start(): void {
        this.subscriptions = new Rx.Subscription();
    }

    public stop(): void {
        this.subscriptions.unsubscribe();
        this.showDeterrenceDevicesSubject.next(false);
        this.selectedDeviceIdsSubject.next([]);
        this.deterrenceDevicesObservable = undefined;
        this.deterrenceEventsObservable = undefined;
    }

    public getDeterrenceActionAllowed(): Rx.Observable<boolean> {
        return this.api.getDeterrenceDeviceList().pipe(
            RxOperators.map(DeterrenceDeviceList.fromProto),
            RxOperators.map((response) => response.allowDeterrenceAction),
        );
    }

    public setDeterrenceVisible(visible: boolean): void {
        this.showDeterrenceDevicesSubject.next(visible);
    }

    public toggleSelectedState(...deviceIds: int[]): void {
        const selectedDeviceIds = this.selectedDeviceIdsSubject.value;
        deviceIds.forEach((deviceId) => {
            const index = selectedDeviceIds.indexOf(deviceId);
            if (index >= 0) {
                selectedDeviceIds.splice(index, 1);
            } else {
                selectedDeviceIds.push(deviceId);
            }
        });
        this.selectedDeviceIdsSubject.next(selectedDeviceIds);
    }

    public observeDeterrenceDevices(): Rx.Observable<[DeterrenceDevice, DeterrenceDeviceState][]> {
        if (this.deterrenceDevicesObservable) {
            return this.deterrenceDevicesObservable;
        }
        this.deterrenceDevicesObservable = this.createDeterrenceDevicesObservable().pipe(
            Rx.shareReplay({ refCount: true }),
        );
        return this.deterrenceDevicesObservable;
    }

    public observeDeterrenceEvents(): Rx.Observable<DeterrenceEvent[]> {
        if (this.deterrenceEventsObservable) {
            return this.deterrenceEventsObservable;
        }
        this.deterrenceEventsObservable = this.createDeterrenceEventsObservable().pipe(
            Rx.shareReplay({ refCount: true }),
        );
        return this.deterrenceEventsObservable;
    }

    public fireSelectedDevices(): Rx.Observable<DeterrenceDeviceActivationStatus> {
        return this.selectedDeviceIdsSubject.pipe(
            RxOperators.take(1),
            RxOperators.concatMap((array) => Rx.from(array)),
            RxOperators.flatMap((deviceId) => this.sendDeterrenceActivationMessage(deviceId)),
            RxOperators.tap(() => {
                // Trigger an update to get the activated state
                this.fetchDeterrenceDeviceUpdatesTrigger.next();
                // Clear the selection state
                this.selectedDeviceIdsSubject.next([]);
            }),
        );
    }

    // Private functions

    private createDeterrenceDevicesObservable(): Rx.Observable<[DeterrenceDevice, DeterrenceDeviceState][]> {
        return this.api.getDeterrenceDeviceList().pipe(
            RxOperators.map(DeterrenceDeviceList.fromProto),
            RxOperators.flatMap((deviceList) => {
                let deterrenceDevices = deviceList.deterrenceDevices;
                const deterrenceDevicesObservable = this.api
                    .getDeterrenceDeviceUpdates(
                        DETERRENCE_DEVICES_UPDATE_INTERVAL,
                        this.fetchDeterrenceDeviceUpdatesTrigger.asObservable(),
                    )
                    .pipe(
                        RxOperators.map(DeterrenceDeviceUpdateList.fromProto),
                        RxOperators.map((updateList) => {
                            deterrenceDevices = this.handleDeviceUpdates(deterrenceDevices, updateList);
                            return deterrenceDevices;
                        }),
                    );
                return Rx.combineLatest([deterrenceDevicesObservable, this.selectedDeviceIdsSubject.asObservable()]);
            }),
            RxOperators.map(([deviceList, selectedDeviceIds]) =>
                deviceList.map((device) => [device, stateForDevice(device, selectedDeviceIds)]),
            ),
        );
    }

    private handleDeviceUpdates(
        deterrenceDevices: DeterrenceDevice[],
        updateList: DeterrenceDeviceUpdateList,
    ): DeterrenceDevice[] {
        if (deterrenceDevices.length === 0 || updateList.updates.length === 0) {
            return deterrenceDevices;
        }
        return deterrenceDevices.map((device) => {
            const update = updateList.updates.find((value) => value.id === device.id);
            if (!update) {
                return device;
            }
            return {
                ...device,
                active: update.isActive,
                remaining: update.remaining,
                used: update.usedToday,
                connected: update.connected,
                statusCode: update.statusCode,
            };
        });
    }

    private createDeterrenceEventsObservable(): Rx.Observable<DeterrenceEvent[]> {
        return this.api.getDeterrenceDeviceList().pipe(
            RxOperators.map(DeterrenceDeviceList.fromProto),
            RxOperators.flatMap((deviceList) =>
                this.getDeterrenceEvents().pipe(
                    RxOperators.map((events) =>
                        events.map((event) => ({
                            ...event,
                            deviceName: deviceList.deterrenceDevices.find((d) => d.id === event.deviceId)?.prettyName,
                        })),
                    ),
                ),
            ),
        );
    }

    private getDeterrenceEvents(): Rx.Observable<DeterrenceEvent[]> {
        const request = new DeterrenceEventsListRequestProto();
        request.setDays(DETERRENCE_EVENTS_HISTORY_DAYS);
        return this.api.getDeterrenceEventsList(request).pipe(
            RxOperators.map(DeterrenceEvent.arrayFromProto),
            RxOperators.flatMap((events) => {
                let deterrenceEvents = events;
                return this.api.getDeterrenceEventUpdates(DETERRENCE_EVENTS_UPDATE_INTERVAL).pipe(
                    RxOperators.map(DeterrenceEvent.arrayFromProto),
                    RxOperators.map((updates) => {
                        deterrenceEvents = this.handleEventUpdates(deterrenceEvents, updates);
                        return deterrenceEvents;
                    }),
                );
            }),
        );
    }

    private handleEventUpdates(deterrenceEvents: DeterrenceEvent[], updates: DeterrenceEvent[]): DeterrenceEvent[] {
        if (updates.length === 0) {
            return deterrenceEvents;
        }
        return deterrenceEvents.concat(updates).sort((a, b) => b.timestamp - a.timestamp);
    }

    private sendDeterrenceActivationMessage(deviceId: int): Rx.Observable<DeterrenceDeviceActivationStatus> {
        const request = this.createDeterrenceStartRequest(deviceId);
        return this.sendDeterrenceCommandMessage(request).pipe(
            RxOperators.map((statusCode) => new DeterrenceDeviceActivationStatus(deviceId, statusCode)),
        );
    }

    private createDeterrenceStartRequest(deviceId: int): DeterrenceCommandMsgProto {
        const deterrenceStartMessage = new DeterrenceStartMsgProto();
        deterrenceStartMessage.setDeviceid(deviceId);
        const request = new DeterrenceCommandMsgProto();
        request.setDeterrencestart(deterrenceStartMessage);
        return request;
    }

    private sendDeterrenceCommandMessage(request: DeterrenceCommandMsgProto): Rx.Observable<StatusResponse> {
        return this.api
            .handleDeterrenceCommand(request)
            .pipe(RxOperators.map((response) => StatusResponse.fromProto(response)));
    }
}
