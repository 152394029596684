import _ from "lodash";
import * as Rx from "rxjs";
import { nonNullObservable } from "../utils/RxUtils";
import { LocalUserPreferenceKeys, Trackable, TrackableSnapshot } from "./model";
import { LocalPreferencesRepository } from "./repositories";

const FINISHED_TRACKS_CLEANUP_INTERVAL = 1000;

export interface TrackableSnapshotDiff<TrackType extends Trackable> {
    snapshotTracksWithEstimates: TrackType[];
    snapshotTimestamp: number;
    finishedTracks: TrackType[];
    finishedTracksDeathTimes: Map<int, long>;
}

export class SnapshotDiffCalculator<TrackType extends Trackable> {
    public snapshotDiff: Rx.Observable<TrackableSnapshotDiff<TrackType>>;

    private subscriptions = new Rx.Subscription();
    private lastSnapshot: TrackableSnapshot<TrackType> | null = null;
    private finishedTrackLifetime = 10000;
    private finishedTracks = new Array<TrackType>();
    private finishedTracksDeathTimes = new Map<int, long>();
    private trailLength: number = Number.MAX_VALUE;
    private snapshotDiffSubject = new Rx.BehaviorSubject<TrackableSnapshotDiff<TrackType>>({
        snapshotTracksWithEstimates: [],
        snapshotTimestamp: 0,
        finishedTracks: [],
        finishedTracksDeathTimes: new Map(),
    });

    private get lastSnapshotTime(): number {
        return this.lastSnapshot == null ? 0 : this.lastSnapshot.timestamp;
    }

    private get lastSnapshotTracks(): TrackType[] {
        return this.lastSnapshot == null ? [] : Array.from(this.lastSnapshot.tracks.values());
    }

    private get trailLengthObservable(): Rx.Observable<number> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<number>(LocalUserPreferenceKeys.appearance.trailLength),
        );
    }

    public get finishedTrackLifetimeObservable(): Rx.Observable<number> {
        return nonNullObservable(
            this.localPreferencesRepository.observePreference<number>(
                LocalUserPreferenceKeys.appearance.finishedTrackLifetime,
            ),
        );
    }

    public constructor(
        private snapshotObservable: Rx.Observable<TrackableSnapshot<TrackType> | null>,
        private localPreferencesRepository: LocalPreferencesRepository,
        private calculateEndTimeForTrack: (track: TrackType) => void,
        private hasTrackEstimatesWithinPeriod: (track: TrackType, timestamp: long, lifeTime: number) => boolean,
        private trackFilter: (track: TrackType) => boolean = () => true,
    ) {
        this.snapshotDiff = this.snapshotDiffSubject.asObservable();
        this.subscriptions.add(this.snapshotObservable.subscribe((snapshot) => this.handleSnapshotUpdate(snapshot)));
        this.subscriptions.add(this.trailLengthObservable.subscribe((value) => (this.trailLength = value)));
        this.subscriptions.add(
            this.finishedTrackLifetimeObservable.subscribe((value) => (this.finishedTrackLifetime = value)),
        );
        setInterval(this.cleanupFinishedTracks.bind(this), FINISHED_TRACKS_CLEANUP_INTERVAL);
    }

    public resetFinishedTracks(clearLastSnapshot: boolean): void {
        if (clearLastSnapshot) {
            this.lastSnapshot = null;
        }
        this.finishedTracks = [];
        this.finishedTracksDeathTimes.clear();
    }

    private handleSnapshotUpdate(snapshot: TrackableSnapshot<TrackType> | null): void {
        if (snapshot == null) {
            // TODO: Should we reset finished tracks here?
            return;
        }

        const snapshotTracks = Array.from(snapshot.tracks.values()).filter((t) => this.trackFilter(t));
        const lastSnapshotTracks = this.lastSnapshotTracks;

        // Below steps do not neccessarily have an order. The comments are just there to explain chunks of code.
        // Step 1: Filter snapshot tracks to only those with estimates in the period of interest

        const snapshotTracksWithEstimates = snapshotTracks.filter((t) => {
            const timestamp = this.finishedTracksDeathTimes.get(t.id) || snapshot.timestamp;
            return this.hasTrackEstimatesWithinPeriod(t, timestamp, this.trailLength);
        });
        const lastSnapshotTracksWithEstimates = lastSnapshotTracks.filter((t) => {
            const timestamp = this.finishedTracksDeathTimes.get(t.id) || this.lastSnapshotTime;
            return this.hasTrackEstimatesWithinPeriod(t, timestamp, this.trailLength);
        });

        // Step 2: Update the finished tracks

        const finishedTracks = lastSnapshotTracksWithEstimates.filter(
            (snapshotTrack) => !snapshotTracksWithEstimates.some((track) => track.id === snapshotTrack.id),
        );
        this.finishedTracks.push(...finishedTracks);
        finishedTracks.forEach((t) => this.finishedTracksDeathTimes.set(t.id, snapshot.timestamp));

        this.lastSnapshot = snapshot;

        this.cleanupFinishedTracks();

        // Emit new snapshot diff

        this.snapshotDiffSubject.next({
            snapshotTracksWithEstimates,
            snapshotTimestamp: this.lastSnapshot.timestamp,
            finishedTracks: this.finishedTracks,
            finishedTracksDeathTimes: this.finishedTracksDeathTimes,
        });
    }

    private cleanupFinishedTracks(): void {
        const oldFinishedTracks = new Array(...this.finishedTracks);
        const newFinishedTracks = new Array(...this.finishedTracks).filter((track) => {
            const endTime = this.calculateEndTimeForTrack(track);
            return (
                endTime != null &&
                this.lastSnapshotTime - this.finishedTrackLifetime <
                    (this.finishedTracksDeathTimes.get(track.id) || endTime)
            );
        });

        _.differenceBy(oldFinishedTracks, newFinishedTracks, (track) => track.id).forEach((track) =>
            this.finishedTracksDeathTimes.delete(track.id),
        );
        this.finishedTracks = newFinishedTracks;
    }
}
