import { Feature, GeoJsonProperties, Geometry, Position } from "geojson";
import mapboxgl from "mapbox-gl";
import * as MapUtils from "../../../utils/MapUtils";
import { UserLocationImage } from "./UserLocationImage";
import { UserHeadingImage } from "./UserHeadingImage";
import { OldColors } from "../../appearance/Colors";
import * as turf from "@turf/turf";
import { GeolocationCoordinates } from "../../../domain/model";

const USER_LOCATION_LAYER_ID = "layer-user-location";
const USER_LOCATION_SOURCE_ID = "source-user-location";
const SYMBOL_USER_LOCATION = "symbol-user-location";

const USER_LOCATION_ACCURACY_LAYER_ID = "layer-user-location-accuracy";
const USER_LOCATION_ACCURACY_SOURCE_ID = "source-user-location-accuracy";

const USER_HEADING_LAYER_ID = "layer-user-heading";
const USER_HEADING_SOURCE_ID = "source-user-heading";
const SYMBOL_USER_HEADING = "symbol-user-heading";
const SYMBOL_USER_HEADING_NONE = "symbol-user-heading-none";

export class UserLocationLayer {
    public static attachedTo(map: mapboxgl.Map, orderLayer: string): UserLocationLayer {
        return new UserLocationLayer(map, orderLayer);
    }

    private constructor(private readonly map: mapboxgl.Map, private orderLayer: string) {
        this.setup();
    }

    public setEnabled(enabled: boolean): void {
        const visibility = enabled ? "visible" : "none";
        this.map.setLayoutProperty(USER_LOCATION_ACCURACY_LAYER_ID, "visibility", visibility);
        this.map.setLayoutProperty(USER_LOCATION_LAYER_ID, "visibility", visibility);
        this.map.setLayoutProperty(USER_HEADING_LAYER_ID, "visibility", visibility);
    }

    public setUserLocation({ longitude, latitude, heading, accuracy }: GeolocationCoordinates): void {
        const position = [longitude, latitude];
        this.setLocationSourceData(position);
        this.setHeadingSourceData(position, heading);
        this.setLocationAccuracySourceData(position, accuracy);
    }

    private setup(): void {
        this.addUserLocationAccuracyLayer();
        this.addUserHeadingLayer();
        this.addUserLocationLayer();
    }

    private addUserLocationAccuracyLayer(): void {
        this.map.addSource(USER_LOCATION_ACCURACY_SOURCE_ID, MapUtils.EMPTY_GEOJSON_SOURCE);

        this.map.addLayer(
            {
                id: USER_LOCATION_ACCURACY_LAYER_ID,
                type: "fill",
                source: USER_LOCATION_ACCURACY_SOURCE_ID,
                paint: {
                    "fill-color": OldColors.primaryTint,
                    "fill-opacity": 0.3,
                },
            },
            this.orderLayer,
        );
    }

    private addUserLocationLayer(): void {
        this.map.addSource(USER_LOCATION_SOURCE_ID, MapUtils.EMPTY_GEOJSON_SOURCE);

        this.map.addImage(SYMBOL_USER_LOCATION, new UserLocationImage(this.map));

        this.map.addLayer(
            {
                id: USER_LOCATION_LAYER_ID,
                type: "symbol",
                source: USER_LOCATION_SOURCE_ID,
                layout: {
                    "icon-image": SYMBOL_USER_LOCATION,
                    "icon-allow-overlap": true,
                    "icon-pitch-alignment": "map",
                },
            },
            this.orderLayer,
        );
    }

    private addUserHeadingLayer(): void {
        this.map.addSource(USER_HEADING_SOURCE_ID, MapUtils.EMPTY_GEOJSON_SOURCE);

        this.map.addImage(SYMBOL_USER_HEADING, new UserHeadingImage(this.map));
        this.map.addImage(SYMBOL_USER_HEADING_NONE, new UserHeadingImage(this.map, true));

        this.map.addLayer(
            {
                id: USER_HEADING_LAYER_ID,
                type: "symbol",
                source: USER_HEADING_SOURCE_ID,
                layout: {
                    "icon-image": ["case", ["has", "heading"], SYMBOL_USER_HEADING, SYMBOL_USER_HEADING_NONE],
                    "icon-allow-overlap": true,
                    "icon-pitch-alignment": "map",
                    "icon-rotate": ["get", "heading"],
                    "icon-rotation-alignment": "map",
                },
            },
            this.orderLayer,
        );
    }

    private setLocationSourceData(position: Position): void {
        const source = this.map.getSource(USER_LOCATION_SOURCE_ID) as mapboxgl.GeoJSONSource;
        if (source == null) {
            return;
        }
        source.setData({
            type: "Feature",
            properties: {
                heading: position[2],
            },
            geometry: {
                type: "Point",
                coordinates: position,
            },
        });
    }

    private setHeadingSourceData(position: Position, heading?: number): void {
        const source = this.map.getSource(USER_HEADING_SOURCE_ID) as mapboxgl.GeoJSONSource;
        if (source == null) {
            return;
        }
        const properties: GeoJsonProperties = {};
        if (heading) {
            properties.heading = heading;
        }
        source.setData({
            type: "Feature",
            properties: properties,
            geometry: {
                type: "Point",
                coordinates: position,
            },
        });
    }

    private setLocationAccuracySourceData(position: Position, accuracy: number): void {
        const source = this.map.getSource(USER_LOCATION_ACCURACY_SOURCE_ID) as mapboxgl.GeoJSONSource;
        if (source == null) {
            return;
        }
        source.setData(
            turf.circle(position, accuracy, {
                units: "meters",
            }) as Feature<Geometry, GeoJsonProperties>,
        );
    }
}
