import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { RadarOperatingMode, RadarOperatingModeStatus } from "../../domain/model";
import { RadioSwitch, RadioSwitchGroup } from "../appearance/RadioSwitch";
import { VerticalSpace } from "../appearance/Space";
import { PanelSubtitle } from "../appearance/panels/PanelSubtitle";
import { PanelDescription, PanelParagraph } from "../appearance/panels/PanelText";
import { RoundButton } from "../appearance/RoundButton";
import { ReactComponent as WarningIcon } from "../../res/images/warning.svg";
import { t } from "i18next";

const SPACE_HEGHT = 15;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 8px;
    margin-left: 8px;
`;

const InlineButton = styled(RoundButton)`
    display: inline;
    margin-right: 6px;
    ::last-child {
        margin: 0;
    }
`;

const Warning = styled(WarningIcon)`
    margin-right: 6px;
    color: ${({ theme }) => theme.colors.status.warning};
`;

interface OperatingModeSwitcherProps {
    activeMode: RadarOperatingModeStatus | undefined;
    setNewMode: (modeId: string) => void;
    modes: RadarOperatingMode[];
}

export const OperatingModeSwitcher = ({
    activeMode,
    setNewMode,
    modes,
}: OperatingModeSwitcherProps): React.ReactElement => {
    // Properties

    const [targetModeId, setTargetModeId] = useState<string>("");
    const [isSwitchingMode, setIsSwitchingMode] = useState<boolean>(false);
    const theme = useTheme();

    // Local functions

    const setTargetMode = (modeId: string): void => {
        if (!isSwitchingMode && activeMode && activeMode.id !== modeId) {
            setIsSwitchingMode(true);
            setTargetModeId(modeId);
        }
    };
    const confirmModeSwitch = (): void => {
        setNewMode(targetModeId);
        cancelModeSwitch();
    };
    const cancelModeSwitch = (): void => {
        setTargetModeId("");
        setIsSwitchingMode(false);
    };

    // Render

    return (
        <Container>
            <PanelDescription>{t("radarControlPanel.operatingModeSwitchExplanation")}</PanelDescription>
            <RadioSwitchGroup>
                {modes.map((mode) => (
                    <RadioSwitch
                        active={activeMode && activeMode.id === mode.id}
                        disabled={isSwitchingMode}
                        onClick={() => setTargetMode(mode.id)}
                        key={mode.id}
                    >
                        {mode.displayName}
                    </RadioSwitch>
                ))}
            </RadioSwitchGroup>
            {isSwitchingMode && (
                <>
                    <VerticalSpace height={SPACE_HEGHT} />
                    <PanelSubtitle>{t("radarControlPanel.operatingModeSwitchWarningTitle")}:</PanelSubtitle>
                    <PanelParagraph>{t("radarControlPanel.operatingModeSwitchWarningDescription")}</PanelParagraph>
                    <PanelParagraph>
                        <InlineButton
                            text={t("general.yes")}
                            onClick={() => confirmModeSwitch()}
                            background={theme.colors.status.warning}
                        />
                        <InlineButton text={t("general.no")} onClick={() => cancelModeSwitch()} />
                    </PanelParagraph>
                </>
            )}
            {activeMode && !activeMode.ready && (
                <>
                    <VerticalSpace height={SPACE_HEGHT} />
                    <PanelParagraph>
                        <Warning /> {t("radarControlPanel.busySwitchingOperatingMode")}
                    </PanelParagraph>
                </>
            )}
        </Container>
    );
};
