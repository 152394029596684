import React from "react";
import DI from "../../../di/DI";
import { TYPES } from "../../../di/Types";
import {
    ReplayRequestViewModel,
    REPLAY_DURATIONS_INTERVAL_MINUTES,
    REPLAY_DURATIONS_TOTAL_MINUTES,
} from "./ReplayRequestViewModel";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "./datepicker-style.css";
import { SteppedSeekbar } from "../../appearance/SteppedSeekbar";
import { ACTIVE_LANGUAGE, registerDatepickerLocale } from "../../../infrastructure/localization";
import { ReplayAvailability } from "./ReplayAvailability";
import { t } from "i18next";
import { TimeDisplayMode } from "../../../domain/model/TimeDisplayMode";
import { Form } from "../../appearance/forms/Form";
import { FormControl } from "../../appearance/forms/FormControl";
import { Button } from "../../appearance/button/Button";
import { PanelParagraph } from "../../appearance/panels/PanelText";
import { BaseSubscriptionHandlerComponent } from "../../BaseSubscriptionHandlerComponent";
import { Panel } from "../../appearance/panels/Panel";
import { PanelHeader } from "../../appearance/panels/PanelHeader";
import { PanelFooter, PanelFooterText } from "../../appearance/panels/PanelFooter";
import { PanelSection } from "../../appearance/panels/PanelSection";
import { DurationText } from "./DurationText";
import { InputField } from "../../appearance/forms/InputField";
import { FormDescription } from "../../appearance/forms/FormText";

export interface Duration {
    hours: number;
    minutes: number;
}

const TimeInputsContainer = styled.div`
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.x4};

    .react-datepicker-wrapper {
        width: 100%;
    }
`;

/**
 * This is just to prevent the gap from `TimeInputsContainer` is being applied twice when the datepicker opens.
 * */
const DatePickerWrapper = styled.div`
    flex: 1;
`;

const SeekbarContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

interface Props {
    onRequestSubmitted: (startTimestamp: long, endTimestamp: long) => void;
}

interface State {
    additionalTimes: Date[];
    selectedDate: Date | null;
    maxTime: Date;
    duration: long;
    replayAvailability: ReplayAvailability;
    showing: boolean;
}

export class ReplayRequest extends BaseSubscriptionHandlerComponent<Props, State> {
    // Properties

    private readonly viewModel: ReplayRequestViewModel = DI.get(TYPES.ReplayRequestViewModel);
    private datepickerLocale = ACTIVE_LANGUAGE.datepickerLocale;

    public constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            showing: false,
            additionalTimes: [],
            selectedDate: null,
            maxTime: new Date(),
            duration: REPLAY_DURATIONS_INTERVAL_MINUTES * 60000,
            replayAvailability: ReplayAvailability.CHECKING,
        };

        registerDatepickerLocale();
    }

    // Public functions

    public componentDidMount(): void {
        this.collectSubscriptions(
            this.viewModel.maxTimeObservable.subscribe((maxTime) => this.setState({ maxTime })),
            this.viewModel.selectedDateObservable.subscribe((selectedDate) => {
                this.setState({ selectedDate });
                this.updateSubmitButton(selectedDate, this.state.duration);
            }),
            this.viewModel.defaultDateObservable.subscribe((defaultDate) => {
                this.setState({ additionalTimes: [defaultDate] });
                this.viewModel.selectDate(defaultDate);
            }),
            this.viewModel.replayAvailabilityObservable.subscribe((a) => this.setState({ replayAvailability: a })),
        );
        setTimeout(() => {
            this.setState({ showing: true });
        });
    }

    public render(): React.ReactNode {
        return (
            <Panel>
                <PanelHeader label={t("replay.replay")} onClose={() => this.viewModel.closeReplayRequestPanel()} />
                <PanelSection>
                    <Form vertical>
                        <FormControl title={t("replay.startDateAndTime")} vertical>
                            <TimeInputsContainer>
                                <DatePickerWrapper>
                                    <DatePicker
                                        selected={this.state.selectedDate}
                                        onChange={(date) => this.viewModel.selectDate(date)}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={10}
                                        timeCaption="time"
                                        maxDate={this.viewModel.maxDate}
                                        customInput={<InputField />}
                                        injectTimes={this.state.additionalTimes}
                                        maxTime={this.state.maxTime}
                                        minTime={this.viewModel.minDate}
                                        dateFormat="MMMM d, yyyy HH:mm"
                                        locale={this.datepickerLocale}
                                    />
                                </DatePickerWrapper>
                                {this.viewModel.timeDisplayMode === TimeDisplayMode.UTC && (
                                    <FormDescription uppercase>{this.viewModel.timeDisplayMode}</FormDescription>
                                )}
                            </TimeInputsContainer>
                            {this.state.replayAvailability == ReplayAvailability.NOT_AVAILABLE && (
                                <PanelParagraph>{t("replay.noRadarData")}</PanelParagraph>
                            )}
                        </FormControl>
                        <FormControl title={t("replay.duration")} vertical>
                            <SeekbarContainer>
                                <SteppedSeekbar
                                    max={REPLAY_DURATIONS_TOTAL_MINUTES - REPLAY_DURATIONS_INTERVAL_MINUTES}
                                    value={0}
                                    stepSize={REPLAY_DURATIONS_INTERVAL_MINUTES}
                                    onChange={(value) => {
                                        const newDuration = (value + REPLAY_DURATIONS_INTERVAL_MINUTES) * 60 * 1000;
                                        this.setState({ duration: newDuration });
                                        if (this.state.selectedDate) {
                                            this.updateSubmitButton(this.state.selectedDate, newDuration);
                                        }
                                    }}
                                />
                            </SeekbarContainer>
                            <DurationText {...this.calculateDuration()} />
                        </FormControl>
                    </Form>
                </PanelSection>
                <PanelFooter>
                    <PanelFooterText text={t("replay.description")} />
                    <Button
                        disabled={this.state.replayAvailability != ReplayAvailability.AVAILABLE}
                        label={t("replay.watchReplay")}
                        onClick={this.submitRequest.bind(this)}
                    />
                </PanelFooter>
            </Panel>
        );
    }

    // Private functions

    private calculateDuration(): Duration {
        let minutes = this.state.duration / 60000;
        const hours = Math.floor(minutes / 60);
        minutes = hours > 0 ? minutes % (hours * 60) : minutes;
        return {
            hours,
            minutes,
        };
    }

    private submitRequest(): void {
        if (!this.state.selectedDate) {
            return;
        }

        const range = this.viewModel.calculateRequestRange(this.state.selectedDate, this.state.duration);
        if (!range) {
            return;
        }

        const [startTimestamp, endTimestamp] = range;
        this.props.onRequestSubmitted(startTimestamp, endTimestamp);
        this.viewModel.closeReplayRequestPanel();
    }

    private updateSubmitButton(selectedDate: Date, duration: number): void {
        const range = this.viewModel.calculateRequestRange(selectedDate, duration);
        if (range) {
            this.viewModel.checkReplayAvailability(range);
        }
    }
}
