import {
    AlarmRepository,
    LocalPreferencesRepository,
    LocationInfoRepository,
    MeasurementPointsRepository,
    RadarRepository,
    ReplayRepository,
    TrackObservationMode,
    TrackObservationRepository,
    UserLocationRepository,
} from "../../../../domain/repositories";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import {
    ADSBFlight,
    ADSBFlightsSnapshotDiff,
    EmitterCategory,
    EmitterCategoryGroup,
    LocalUserPreferenceKeys,
    getAllEmitterCategories,
    getEmitterCategoryGroup,
} from "../../../../domain/model";
import { ADSBFlightRepository } from "../../../../domain/repositories/ADSBFlightRepository";
import { BaseTracksModuleViewModel } from "./BaseTracksModuleViewModel";
import { nonNullObservable } from "../../../../utils/RxUtils";
import { ADSBFlightsSnapshotDiffCalculator } from "../../../../domain/ADSBFlightsSnapshotDiffCalculator";

export class ADSBFlightsModuleViewModel extends BaseTracksModuleViewModel<ADSBFlight> {
    private trackObservationMode: TrackObservationMode = TrackObservationMode.None;

    public get tracksSnapshotDiff(): Rx.Observable<ADSBFlightsSnapshotDiff> {
        return this.snapshotDiffCalculator.snapshotDiff;
    }

    public get visibleEmitterCategories(): Rx.Observable<EmitterCategory[]> {
        const showADSBAircraft = nonNullObservable(
            this.localPreferencesRepository.observePreference(LocalUserPreferenceKeys.filters.showADSBAircraft),
        );
        const showADSBVehicles = nonNullObservable(
            this.localPreferencesRepository.observePreference(LocalUserPreferenceKeys.filters.showADSBVehicles),
        );
        const showADSBDrones = nonNullObservable(
            this.localPreferencesRepository.observePreference(LocalUserPreferenceKeys.filters.showADSBDrones),
        );
        const showADSBRotorcraft = nonNullObservable(
            this.localPreferencesRepository.observePreference(LocalUserPreferenceKeys.filters.showADSBRotorcraft),
        );
        const showADSBOther = nonNullObservable(
            this.localPreferencesRepository.observePreference(LocalUserPreferenceKeys.filters.showADSBOther),
        );
        const allEmitterCategories = getAllEmitterCategories();
        return Rx.combineLatest([
            showADSBAircraft,
            showADSBVehicles,
            showADSBDrones,
            showADSBRotorcraft,
            showADSBOther,
        ]).pipe(
            RxOperators.map(([showADSBAircraft, showADSBVehicles, showADSBDrones, showADSBRotorcraft, showADSBOther]) =>
                allEmitterCategories.filter((ec) => {
                    const group = getEmitterCategoryGroup(ec);
                    switch (group) {
                        case EmitterCategoryGroup.AircraftEmitterCategory:
                            return showADSBAircraft;
                        case EmitterCategoryGroup.VehicleEmitterCategory:
                            return showADSBVehicles;
                        case EmitterCategoryGroup.RotorcraftEmitterCategory:
                            return showADSBRotorcraft;
                        case EmitterCategoryGroup.DroneEmitterCategory:
                            return showADSBDrones;
                        case EmitterCategoryGroup.OtherEmitterCategory:
                            return showADSBOther;
                    }
                }),
            ),
        );
    }

    public get applyAltitudeFilterEmitterCategories(): Rx.Observable<EmitterCategory[]> {
        const allEmitterCategories = getAllEmitterCategories();
        return Rx.of([
            LocalUserPreferenceKeys.filters.applyAltitudeFilterADSBAircraft,
            LocalUserPreferenceKeys.filters.applyAltitudeFilterADSBVehicles,
            LocalUserPreferenceKeys.filters.applyAltitudeFilterADSBDrone,
            LocalUserPreferenceKeys.filters.applyAltitudeFilterADSBRotorcraft,
            LocalUserPreferenceKeys.filters.applyAltitudeFilterADSBOther,
        ]).pipe(
            RxOperators.flatMap((keys) =>
                Rx.combineLatest(
                    keys.map((key) => nonNullObservable(this.localPreferencesRepository.observePreference(key), false)),
                ),
            ),
            RxOperators.map(
                ([
                    applyAltitudeFilterADSBAircraft,
                    applyAltitudeFilterADSBVehicles,
                    applyAltitudeFilterADSBDrone,
                    applyAltitudeFilterADSBRotorcraft,
                    applyAltitudeFilterADSBOther,
                ]) =>
                    allEmitterCategories.filter((ec) => {
                        const group = getEmitterCategoryGroup(ec);
                        switch (group) {
                            case EmitterCategoryGroup.AircraftEmitterCategory:
                                return applyAltitudeFilterADSBAircraft;
                            case EmitterCategoryGroup.VehicleEmitterCategory:
                                return applyAltitudeFilterADSBVehicles;
                            case EmitterCategoryGroup.DroneEmitterCategory:
                                return applyAltitudeFilterADSBDrone;
                            case EmitterCategoryGroup.RotorcraftEmitterCategory:
                                return applyAltitudeFilterADSBRotorcraft;
                            case EmitterCategoryGroup.OtherEmitterCategory:
                                return applyAltitudeFilterADSBOther;
                        }
                    }),
            ),
        );
    }

    public getSelectedTrackId(): Rx.Observable<number | null> {
        return this.adsbFlightRepository.selectedFlightId;
    }

    public constructor(
        localPreferencesRepository: LocalPreferencesRepository,
        replayRepository: ReplayRepository,
        radarRepository: RadarRepository,
        userLocationRepository: UserLocationRepository,
        locationInfoRepository: LocationInfoRepository,
        measurementPointsRepository: MeasurementPointsRepository,
        alarmRepository: AlarmRepository,
        private readonly adsbFlightRepository: ADSBFlightRepository,
        protected readonly snapshotDiffCalculator: ADSBFlightsSnapshotDiffCalculator,
        private readonly trackObservationRepository: TrackObservationRepository,
    ) {
        super(
            localPreferencesRepository,
            replayRepository,
            radarRepository,
            userLocationRepository,
            locationInfoRepository,
            measurementPointsRepository,
            alarmRepository,
        );
    }

    public setup(): void {
        super.setup();
        this.collectSubscriptions(this.subscribeToTrackObservationMode());
    }

    public selectTrack(flightId: number | null): void {
        switch (this.trackObservationMode) {
            case TrackObservationMode.None:
                this.adsbFlightRepository.toggleSelectedFlightId(flightId);
                break;
            default:
                break;
        }
    }

    public get alarmBoxHovering(): Rx.Observable<string | null> {
        if (this.alarmRepository) {
            return this.alarmRepository.alarmBoxHovering;
        }
        return Rx.EMPTY;
    }

    private subscribeToTrackObservationMode(): Rx.Subscription {
        return this.trackObservationRepository.mode.subscribe((mode) => {
            this.trackObservationMode = mode;
        });
    }
}
