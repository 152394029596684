import { BaseViewModel } from "../../../../BaseViewModel";
import { LocalPreferencesRepository, TrackRepository } from "../../../../../domain/repositories";
import { getClassificationVisibilityKey, LocalUserPreferenceKeys } from "../../../../../domain/model";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { nonNullObservable } from "../../../../../utils/RxUtils";

export class ClassificationFilterStatusIndicatorViewModel extends BaseViewModel {
    // Properties

    public get isClassificationFilterActive(): Rx.Observable<boolean> {
        return this.trackRepository.classificationsMap.pipe(
            RxOperators.flatMap((c) =>
                Rx.combineLatest(
                    [
                        ...Array.from(c.values()).map((c) => getClassificationVisibilityKey(c.name)),
                        LocalUserPreferenceKeys.filters.showADSBAircraft,
                        LocalUserPreferenceKeys.filters.showADSBVehicles,
                    ].map((key) => this.observeVisibility(key)),
                ),
            ),
            RxOperators.map((values) => values.includes(false)),
        );
    }

    public constructor(
        private readonly localPreferencesRepository: LocalPreferencesRepository,
        private readonly trackRepository: TrackRepository,
    ) {
        super();
    }

    // Private functions

    private observeVisibility(key: string): Rx.Observable<boolean> {
        return nonNullObservable(this.localPreferencesRepository.observePreference<boolean>(key), true);
    }
}
