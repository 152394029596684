import { DAY } from "../utils/DateTimeUtils";
import * as Rx from "rxjs";
import * as RxOperators from "rxjs/operators";
import { LocalPreferencesRepository } from "../domain/repositories";
import { DEFAULT_TIME_DISPLAY_MODE, getTimeDisplayMode, TimeDisplayMode } from "../domain/model/TimeDisplayMode";
import { LocalUserPreferenceKeys } from "../domain/model";
import { nonNullObservable } from "../utils/RxUtils";
import { ACTIVE_LANGUAGE } from "./localization";
import { t } from "i18next";

const DATE_TIME_POSTFIX_UTC = " UTC";

export interface TimeFormatOptions {
    timeDisplayMode?: TimeDisplayMode;
    postfixUTC?: boolean;
    includeSeconds?: boolean;
}

export interface DateTimeFormatOptions {
    timeDisplayMode?: TimeDisplayMode;
    postfixUTC?: boolean;
    excludeDateToday?: boolean;
}

export type TimeFormatObservableOptions = Omit<TimeFormatOptions, "timeDisplayMode">;
export type DateTimeFormatObservableOptions = Omit<DateTimeFormatOptions, "timeDisplayMode">;

export interface DateFormatter {
    formatTime(date: Date, options?: TimeFormatOptions): string;
    formatTimeObservable(
        date: Date | Rx.Observable<Date>,
        options?: TimeFormatObservableOptions,
    ): Rx.Observable<string>;
    formatFullDate(date: Date, options?: DateTimeFormatOptions): string;
    formatFullDateObservable(
        date: Date | Rx.Observable<Date>,
        options?: DateTimeFormatObservableOptions,
    ): Rx.Observable<string>;
    formatRelativeDate(date: Date): string;
}

export class CommonDateFormatter implements DateFormatter {
    private timeDisplayMode = DEFAULT_TIME_DISPLAY_MODE;

    private get displayModeObservable(): Rx.Observable<TimeDisplayMode> {
        return nonNullObservable(
            this.localPreferencesRepository
                .observePreference<string>(LocalUserPreferenceKeys.appearance.timeDisplayMode)
                .pipe(RxOperators.map((value) => getTimeDisplayMode(value))),
        );
    }

    public constructor(private localPreferencesRepository: LocalPreferencesRepository) {
        this.displayModeObservable.subscribe((t) => (this.timeDisplayMode = t));
    }

    // Public functions

    public formatTime(date: Date, options?: TimeFormatOptions): string {
        const timeDisplayMode = (options && options.timeDisplayMode) || this.timeDisplayMode;
        const isUTC = timeDisplayMode === TimeDisplayMode.UTC;
        const hours = isUTC ? date.getUTCHours() : date.getHours();
        const minutes = isUTC ? date.getUTCMinutes() : date.getMinutes();
        let s = this.appendLeadingZeroes(hours) + ":" + this.appendLeadingZeroes(minutes);
        if (options && options.includeSeconds) {
            s += ":" + this.appendLeadingZeroes(date.getUTCSeconds());
        }
        if (isUTC && options && options.postfixUTC) {
            s += DATE_TIME_POSTFIX_UTC;
        }

        return s;
    }

    public formatFullDate(date: Date, options?: DateTimeFormatOptions): string {
        const timeDisplayMode = (options && options.timeDisplayMode) || this.timeDisplayMode;
        const isUTC = timeDisplayMode === TimeDisplayMode.UTC;
        const hours = isUTC ? date.getUTCHours() : date.getHours();
        const minutes = isUTC ? date.getUTCMinutes() : date.getMinutes();
        const seconds = isUTC ? date.getUTCSeconds() : date.getSeconds();
        const postfix = (isUTC && options && options.postfixUTC && DATE_TIME_POSTFIX_UTC) || "";

        const alz = this.appendLeadingZeroes;
        const timeString = `${alz(hours)}:${alz(minutes)}:${alz(seconds)}${postfix}`;

        if (options?.excludeDateToday && this.getDayDifferenceToNow(date) < 1) {
            return timeString;
        }

        const year = isUTC ? date.getUTCFullYear() : date.getFullYear();
        const month = isUTC ? date.getUTCMonth() : date.getMonth();
        const day = isUTC ? date.getUTCDate() : date.getDate();
        return `${alz(day)}-${alz(month + 1)}-${year} ${timeString}`;
    }

    public formatTimeObservable(
        date: Date | Rx.Observable<Date>,
        options?: TimeFormatObservableOptions,
    ): Rx.Observable<string> {
        const observable = date instanceof Date ? Rx.of(date) : date;
        return Rx.combineLatest([observable, this.displayModeObservable]).pipe(
            RxOperators.map(([date, displayMode]) =>
                this.formatTime(date, { ...options, timeDisplayMode: displayMode }),
            ),
        );
    }

    public formatFullDateObservable(
        date: Rx.Observable<Date>,
        options?: DateTimeFormatObservableOptions,
    ): Rx.Observable<string> {
        const observable = date instanceof Date ? Rx.of(date) : date;
        return Rx.combineLatest([observable, this.displayModeObservable]).pipe(
            RxOperators.map(([date, displayMode]) =>
                this.formatFullDate(date, { ...options, timeDisplayMode: displayMode }),
            ),
        );
    }

    public formatRelativeDate(date: Date): string {
        const deltaDays = this.getDayDifferenceToNow(date);
        const languageCode = ACTIVE_LANGUAGE.intlLocale;

        if (deltaDays < 1) {
            return t("unit.today");
        } else if (deltaDays < 2) {
            return t("unit.yesterday");
        } else if (deltaDays <= DAY * 365) {
            const dateTimeFormat = new Intl.DateTimeFormat(languageCode, { month: "long", day: "numeric" });
            const [{ value: month }, , { value: day }] = dateTimeFormat.formatToParts(date);
            return `${month} ${day}`;
        } else {
            const dateTimeFormat = new Intl.DateTimeFormat(languageCode, {
                month: "long",
                day: "numeric",
                year: "numeric",
            });
            const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);
            return `${month} ${day} ${year}`;
        }
    }

    // Private functions

    private appendLeadingZeroes(n: number): string {
        if (n <= 9) {
            return "0" + n;
        }

        return n + "";
    }

    private getDayDifferenceToNow(date: Date): number {
        return Math.floor(new Date().getTime() / DAY) - Math.floor(date.getTime() / DAY);
    }
}
