import { RunwayTraffic as RunwayTrafficProto } from "./proto/generated/atcinfo3_pb";
import _ from "lodash";

export class SectorTrackMessage {
    public constructor(public readonly trackIds: int[], public readonly aircraftIds: int[]) {}
}

// Combines 1 or more SectorTrackMessage objects into a single SectorTrackMessage with unique arrays of track and aircraft ids
export function mergeSectorMessages(...array: SectorTrackMessage[]): SectorTrackMessage {
    return {
        trackIds: _.uniq(array.flatMap((v) => v.trackIds)),
        aircraftIds: _.uniq(array.flatMap((v) => v.aircraftIds)),
    };
}

export class RunwayTraffic {
    public static from(model: RunwayTrafficProto): RunwayTraffic {
        return new RunwayTraffic(
            model.getRunwayid(),
            model.getTimestampMsec(),
            model.getTracksonrunway(),
            model.getSectortrafficrateList(),
            model
                .getSectortracksList()
                .map((sector) => new SectorTrackMessage(sector.getTracksList(), sector.getAircrafttracksList())),
        );
    }

    public constructor(
        public readonly runwayId: int,
        public readonly timestamp: long,
        public readonly tracksOnRunway: int,
        public readonly sectorTrafficRate: float[], // Traffic Rate per sector (bird density values)
        public readonly sectorMessages: SectorTrackMessage[], // tracks-ids per sector (for detailed view, the tracks included in TR calculation)
    ) {}
}

// Takes a collection of RunwayTraffic objects and combines their sectorTrafficRate values into a single sectorTrafficRate
// The combined rate values for each sector are a simple mean of the sector rate in each RunwayTraffic object
export function aggregateSectorTrafficRate(runwayTrafficArray: RunwayTraffic[]): number[] {
    if (runwayTrafficArray.length === 0) {
        return [];
    }
    return runwayTrafficArray
        .reduce<number[]>(
            (value, runwayTraffic) => runwayTraffic.sectorTrafficRate.map((rate, index) => rate + (value[index] || 0)),
            [],
        )
        .map((rate) => rate / runwayTrafficArray.length);
}

// Takes a collection of RunwayTraffic objects and combines their sectorMessages values into an array of track messages per sector
export function aggregateSectorMessages(runwayTrafficArray: RunwayTraffic[]): SectorTrackMessage[] {
    if (runwayTrafficArray.length === 0) {
        return [];
    }
    return runwayTrafficArray.reduce<SectorTrackMessage[]>(
        (value, runwayTraffic) =>
            runwayTraffic.sectorMessages.map((sectorMessage, index) =>
                index < value.length ? mergeSectorMessages(sectorMessage, value[index]) : sectorMessage,
            ),
        [],
    );
}

export function aggregateTracksOnRunway(runwayTrafficArray: RunwayTraffic[]): int {
    if (runwayTrafficArray.length === 0) {
        return 0;
    }
    if (runwayTrafficArray.length === 1) {
        return runwayTrafficArray[0].tracksOnRunway;
    }
    let sumv = 0;
    for (const item of runwayTrafficArray) {
        sumv += item.tracksOnRunway;
    }
    return sumv / runwayTrafficArray.length;
}
