import React from "react";
import { t } from "i18next";
import { useViewModel } from "../../../../hooks/useViewModel";
import { TrackInfoViewModel } from "./TrackInfoViewModel";
import { TYPES } from "../../../../di/Types";
import { PanelTitle } from "../../../appearance/panels/PanelTitle";
import { useObservable } from "../../../../hooks";
import { ADSBFlight, Track } from "../../../../domain/model";
import { RadarTrackInfo } from "./RadarTrackInfo";
import { formatTrackAltitude, formatVelocity } from "../../../../utils/Presentation";
import { DistanceFormatType } from "../../../../domain/DistanceFormatter";
import { DEFAULT_VELOCITY_UNIT } from "../../../../domain/model/VelocityUnit";
import { ADSBFlightInfo } from "./ADSBFlightInfo";
import { PanelDescription } from "../../../appearance/panels/PanelText";
import { ScrollableSidebarContent } from "../../../appearance/Sidebar";

export const TrackInfo = (): JSX.Element => {
    // Properties

    const viewModel: TrackInfoViewModel = useViewModel(TYPES.TrackInfoViewModel);
    const selectedTrack = useObservable(viewModel.selectedTrack, null);
    const showAltitude = useObservable(viewModel.showAltitude, false);
    const radarGroundLevel = useObservable(viewModel.radarGroundLevel, null);
    const displayNegativeAltitudeAsZero = useObservable(viewModel.displayNegativeAltitudeAsZero, false);
    const velocityUnit = useObservable(viewModel.velocityUnit, DEFAULT_VELOCITY_UNIT);
    const lastSnapshotTime = useObservable(viewModel.lastSnapshotTime, 0);
    const measurementPoints = useObservable(viewModel.measurementPoints, []);

    // Render

    return (
        <ScrollableSidebarContent>
            <PanelTitle>{t("trackInfo.trackInfo")}</PanelTitle>
            {!selectedTrack && (
                <PanelDescription>
                    {t("trackInfo.selectTrackInstruction")}
                    <br />
                    {t("trackInfo.groundObservationInstruction")}
                </PanelDescription>
            )}
            {selectedTrack && selectedTrack instanceof Track && (
                <RadarTrackInfo
                    radarGroundLevel={radarGroundLevel}
                    formatAltitude={(v) =>
                        showAltitude
                            ? formatTrackAltitude(
                                  viewModel.distanceFormatter,
                                  v,
                                  displayNegativeAltitudeAsZero,
                                  DistanceFormatType.SPACED,
                              )
                            : null
                    }
                    formatVelocity={(v) => formatVelocity(v, velocityUnit)}
                    formatValueWithCurrentUnit={viewModel.distanceFormatter.formatValueWithCurrentUnit.bind(
                        viewModel.distanceFormatter,
                    )}
                    track={selectedTrack}
                    lastSnapshotTime={lastSnapshotTime}
                    measurementPoints={measurementPoints}
                />
            )}
            {selectedTrack && selectedTrack instanceof ADSBFlight && (
                <ADSBFlightInfo
                    flight={selectedTrack}
                    formatValueWithCurrentUnit={viewModel.distanceFormatter.formatValueWithCurrentUnit.bind(
                        viewModel.distanceFormatter,
                    )}
                    lastSnapshotTime={lastSnapshotTime}
                    measurementPoints={measurementPoints}
                />
            )}
        </ScrollableSidebarContent>
    );
};
